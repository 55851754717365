import React, { useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import DraggableGrid from '../../Grid/DraggableGrid';
import Grid from '../../Grid/Grid';
import AdaptableField from '../../../molecules/Adaptable/AdaptableField';
import FakeField from '../../../molecules/FakeField/FakeField';
import { getTypologyKind, getTypologyByType } from '../../../../helpers/typology';
import SVG from '../../../atoms/SVG/SVG';
import Button from '../../../atoms/Button/Button';
import styles from './FieldBox.css';
import cssvars from '../../../../styles/variables';
import { DATA } from '../../../../constants/typology/data';

function FieldBox({ fieldBox, draggable, onClick, onDelete, focused }) {
  const RenderBox = draggable ? DraggableGrid.Box : Grid.Box;
  const typology = useMemo(() => getTypologyByType(fieldBox.type), [fieldBox && fieldBox.type]);
  const isData = useMemo(() => getTypologyKind(fieldBox.type) === DATA, [fieldBox.type]);

  const handleDelete = useCallback(
    (event) => {
      onDelete();
      event.stopPropagation();
    },
    [onDelete],
  );

  const boxProps = useMemo(
    () => ({
      x: fieldBox.item.x,
      y: fieldBox.item.y,
      width: fieldBox.item.width,
      height: fieldBox.item.height,
    }),
    [fieldBox.item],
  );

  return (
    <>
      {/* White background with low z-index : hides grid dots but not blocks */}
      <Grid.Box
        {...boxProps}
        style={{
          background: 'white',
        }}
      />
      <RenderBox
        {...boxProps}
        item={fieldBox.item}
        style={{
          borderColor: focused
            ? isData
              ? cssvars['color-focus-data']
              : cssvars['color-focus']
            : isData
            ? cssvars['color-focus-data-semi-transparent']
            : 'transparent',
        }}
        className={styles.FieldBox}
        onClick={onClick}
      >
        {focused && onDelete && (
          <Button className={styles.FieldBox__remove} icon="trash" rounded onClick={handleDelete} />
        )}
        {!isData ? (
          <AdaptableField
            fieldType={fieldBox.type}
            fieldHoC={FakeField}
            label={fieldBox.label}
            validation={fieldBox}
            readonly
            verticalAlign={true}
          />
        ) : (
          <div className={styles.FieldBox__dataPreview}>
            {typology && (
              <SVG
                className={styles.FieldBox__dataGlyph}
                glyph={typology.meta.icon}
                style={{ fill: typology.meta.color }}
              />
            )}
          </div>
        )}
      </RenderBox>
    </>
  );
}

FieldBox.propTypes = {
  fieldBox: PropTypes.object,
  draggable: PropTypes.bool,
  onClick: PropTypes.func,
  focused: PropTypes.bool,
  onDelete: PropTypes.func,
};

FieldBox.defaultProps = {
  fieldBox: {},
};

export default FieldBox;
