import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import RadioColorInput from '../../../atoms/RadioColorInput/RadioColorInput';

function RadioColorFieldRender({ input, ...props }) {
  return <RadioColorInput {...input} {...props} />;
}

RadioColorFieldRender.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
};

function RadioColorField({ name, label, required, information, ...props }) {
  const id = useMemo(() => `name_${uuid()}`, [name]);
  return (
    <FormField label={label} name={name} htmlFor={id} information={information} required={required}>
      <Field name={name} component={RadioColorFieldRender} id={id} {...props} />
    </FormField>
  );
}

RadioColorField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  information: PropTypes.string,
};

export default RadioColorField;
