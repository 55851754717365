import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { bem } from '../../../helpers/styles';
import styles from './Layout.css';
import { classnames } from '../../../helpers/classnames';

function Layout({ direction = 'row', flex, children, align, gutter, className = '', style }) {
  const mergedStyle = useMemo(
    () => ({
      ...style,
      display: 'flex',
      flexDirection: direction,
      flex,
      alignItems: align,
    }),
    [direction],
  );
  return (
    <div style={mergedStyle} className={classnames([bem(styles, 'Layout', { gutter }), className])}>
      {children}
    </div>
  );
}

Layout.propTypes = {
  direction: PropTypes.string,
  flex: PropTypes.number,
  children: PropTypes.node,
  align: PropTypes.string,
  gutter: PropTypes.boolean,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Layout;
