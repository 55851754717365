import React, { useEffect } from 'react';
import { getFormValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateForm } from '../../../../../store/actions/editForm';
import useCurrentForm from '../../../../../hooks/useCurrentForm';
import styles from './KeyboardShortcuts.css';
import KeyboardShortcutsForm from '../../../../form/KeyboardShortcutsForm/KeyboardShortcutsForm';
import QualitySelectTemplate from '../../../../templates/QualitySelectTemplate/QualitySelectTemplate';

function DrawerContentKeyboardShortcuts() {
  const [form] = useCurrentForm();
  const values = useSelector(getFormValues('keyboardShortcuts'));
  const dispatch = useDispatch();

  useEffect(() => {
    if (values && values.shortcuts) {
      dispatch(
        updateForm({
          keyboardShortcuts: values.shortcuts,
        }),
      );
    }
  }, [values]);

  if (!form) {
    return null;
  }

  return (
    <>
      <QualitySelectTemplate icon="strategy" titleKey="QualityNavTemplate.keyboardShortcuts" />
      <div className={styles.DrawerContentKeyboardShortcuts}>
        {form && (
          <KeyboardShortcutsForm initialValues={{ shortcuts: form.keyboardShortcuts || [] }} />
        )}
      </div>
    </>
  );
}

export default DrawerContentKeyboardShortcuts;
