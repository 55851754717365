import React, { useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../../../hooks/useActions';
import useTranslate from '../../../../../../hooks/useTranslate';
import {
  setFocusedIterationRule as setFocusedIterationRuleAction,
  setSelectFields as setSelectFieldsAction,
  setSelectMode as setSelectModeAction,
} from '../../../../../../store/actions/editForm';
import { addIterationOverloadRule as addIterationOverloadRuleAction } from '../../../../../../store/actions/form';
import {
  focusedIterationOverloadRuleSelector,
  formQualitySelector,
  iterationOverloadRulesSelector,
} from '../../../../../../store/selectors/editForm';
import Button from '../../../../../atoms/Button/Button';
import IterationOverloadRule from '../../../../../molecules/IterationOverloadRule/IterationOverloadRule';
import QualitySelectTemplate from '../../../../../templates/QualitySelectTemplate/QualitySelectTemplate';
import cssvars from '../../../../../../styles/variables';
import styles from './IterationOverload.css';

function DrawerIterationOverloadPage() {
  const i18n = useTranslate();
  const rules = useSelector(iterationOverloadRulesSelector);
  const focusedRule = useSelector(focusedIterationOverloadRuleSelector);
  const [
    setFocusedIterationRule,
    addIterationOverloadRule,
    setSelectMode,
    setSelectFields,
  ] = useActions([
    setFocusedIterationRuleAction,
    addIterationOverloadRuleAction,
    setSelectModeAction,
    setSelectFieldsAction,
  ]);
  const { respondentPerTask, iterationCount } = useSelector(formQualitySelector);
  const diff = iterationCount - respondentPerTask;

  const iterationOptions = useMemo(
    () =>
      Number.isInteger(diff) && diff > 0
        ? new Array(iterationCount - respondentPerTask)
            .fill(0)
            .map((_, i) => i + respondentPerTask + 1)
            .map((v) => ({
              label: v.toString(),
              value: v,
            }))
        : [],
    [respondentPerTask, iterationCount],
  );

  // Enable / disable select mode on mount / unmount
  useEffect(() => {
    setSelectFields([]);
    setSelectMode(true);
    return () => setSelectMode(false);
  }, []);

  const handleAddRule = useCallback(() => {
    // Handle the case where user started to click on fields but no rules exists yet
    // We clear the state to prevent initializing a rule with a bunch of uneeded fields
    setSelectFields([]);
    addIterationOverloadRule({
      name: i18n('DrawerIterationOverloadPage.newRuleLabel', { count: rules.length + 1 }),
    });
  }, [rules]);

  return (
    <div className={styles.DrawerIterationOverloadPage}>
      <QualitySelectTemplate icon="ruler" titleKey="QualityNavTemplate.iterationOverload" />
      <h3 className={styles.DrawerIterationOverloadPage__title}>
        {i18n('DrawerIterationOverloadPage.title')}
      </h3>
      <p className={styles.DrawerIterationOverloadPage__description}>
        {i18n('DrawerIterationOverloadPage.description')}
      </p>
      <div className={styles.DrawerIterationOverloadPage__buttons}>
        <Button
          onClick={handleAddRule}
          block
          color={cssvars['color-accent']}
          rounded
          className={styles.DrawerIterationOverloadPage__newButton}
          icon="plus_circle"
        >
          {i18n('DrawerIterationOverloadPage.newRule')}
        </Button>
      </div>
      <div>
        {rules.length ? (
          rules.map((rule) => (
            <IterationOverloadRule
              rule={rule}
              key={rule.id}
              open={focusedRule && focusedRule.id === rule.id}
              onFocus={setFocusedIterationRule}
              iterationOptions={iterationOptions}
            />
          ))
        ) : (
          <p className={styles.DrawerIterationOverloadPage__emptyState}>
            {i18n('DrawerIterationOverloadPage.empty')}
          </p>
        )}
      </div>
    </div>
  );
}

export default DrawerIterationOverloadPage;
