import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import TextField from '../../../molecules/Field/Text/TextField';
import useTranslate from '../../../../hooks/useTranslate';
import SwitchField from '../../../molecules/Field/Switch/SwitchField';
import styles from './AdvancedSelectFragment.css';
import NumberField from '../../../molecules/Field/Number/NumberField';
import InjectFragment from '../Inject/InjectFragment';

// Encapsulate specific behavior for advanced select typology
function AdvancedSelectFragment({ form }) {
  const i18n = useTranslate();
  const dispatch = useDispatch();

  const { remoteOptionsEnabled, remoteOptions } = useSelector((state) =>
    formValueSelector(form)(state, 'remoteOptionsEnabled', 'remoteOptions.queryMode'),
  );
  const injectChoices = useSelector((state) => formValueSelector(form)(state, 'injectChoices'));
  const injectLabelValue = useSelector((state) =>
    formValueSelector(form)(state, 'injectLabelValue'),
  );

  useEffect(() => {
    // Clear the whole block when option is unticked
    if (!remoteOptionsEnabled) {
      dispatch(change(form, 'remoteOptions', null));
    }
  }, [remoteOptionsEnabled]);

  useEffect(() => {
    // Clear the whole block when option is unticked
    if (!injectChoices) {
      dispatch(change(form, 'injectChoices', null));
    }
  }, [injectChoices]);

  return (
    <div className={styles.AdvancedSelectFragment}>
      <InjectFragment
        form={form}
        fragmentType="advancedSelect"
        remoteOptionsEnabled={remoteOptionsEnabled}
      />
      <SwitchField name="remoteOptionsEnabled" label={i18n('field.options.remoteOptionsEnabled')} />
      {remoteOptionsEnabled ? (
        <>
          <TextField name="remoteOptions.url" label={i18n('field.options.remoteOptions.url')} />
          <TextField
            name="remoteOptions.authorization"
            label={i18n('field.options.remoteOptions.authorization')}
          />
          <p className={styles.AdvancedSelectFragment__help}>
            {i18n('AdvancedSelectFragment.remoteHelp')}
            &nbsp;
            <a href="http://objectpath.org/tutorial.html" target="_blank" rel="noreferrer noopener">
              {i18n('AdvancedSelectFragment.remoteHelpMore')}
            </a>
          </p>
          <TextField
            name="remoteOptions.pathOptions"
            label={i18n('field.options.remoteOptions.pathOptions')}
          />
          <TextField
            name="remoteOptions.pathLabel"
            label={i18n('field.options.remoteOptions.pathLabel')}
          />
          <TextField
            name="remoteOptions.pathValue"
            label={i18n('field.options.remoteOptions.pathValue')}
          />
          <SwitchField
            name="remoteOptions.queryMode"
            label={i18n('field.options.remoteOptions.queryMode')}
            information={i18n('field.options.remoteOptions.queryModeInstructions')}
          />
          {remoteOptions?.queryMode && (
            <NumberField
              name="remoteOptions.minChars"
              label={i18n('field.options.remoteOptions.minChars')}
            />
          )}
        </>
      ) : (
        !injectChoices && (
          <TextField
            name="choices"
            label={i18n('field.options.choices')}
            rows={10}
            placeholder={injectLabelValue ? i18n('field.options.labelValuePlaceholder') : null}
          />
        )
      )}
    </div>
  );
}

AdvancedSelectFragment.propTypes = {
  form: PropTypes.string,
  fieldInject: PropTypes.bool,
};

export default AdvancedSelectFragment;
