import { v4 as uuid } from 'uuid';
export const ANSWER_TASK_GET = 'ANSWER_GET';
export const ANWSER_TASK_GET_SUCCESS = 'ANSWER_GET_SUCCESS';
export const ANSWER_HIT_GET = 'ANSWER_HIT_GET';
export const ANSWER_CERTIFICATION_GET = 'ANSWER_CERTIFICATION_GET';
export const ANWSER_HIT_GET_SUCCESS = 'ANSWER_HIT_GET_SUCCESS';
export const ANSWER_CERTIFICATION_GET_SUCCESS = 'ANSWER_CERTIFICATION_GET_SUCCESS';

export const getAnswerTask = (taskId) => ({
  type: ANSWER_TASK_GET,
  fetch: {
    method: 'GET',
    url: `{{backendUrl}}/TaskService/Task/${taskId}/GetTask`,
    auth: true,
    parse: 'parseAnswer',
  },
  meta: { requestId: uuid() },
});

export const getAnswerHit = (hitId) => ({
  type: ANSWER_HIT_GET,
  fetch: {
    method: 'GET',
    url: `{{backendUrl}}/TaskService/Hit/${hitId}`,
    auth: true,
    parse: 'parseAnswerHit',
  },
  meta: { requestId: uuid() },
});

export const getAnswerCertification = (certificationUserTestId, questionId) => ({
  type: ANSWER_CERTIFICATION_GET,
  fetch: {
    method: 'GET',
    url: `{{backendUrl}}/CertificationService/CertificationUserTest/${certificationUserTestId}/Questions/${questionId}`,
    auth: true,
    parse: 'parseAnswerCertification',
  },
  meta: { requestId: uuid() },
});