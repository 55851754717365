import React from 'react';
import styles from './DrawerContentQuality.css';
import useTranslate from '../../../../../hooks/useTranslate';
import QualityNavTemplate from '../../../../templates/QualityNavTemplate/QualityNavTemplate';

function DrawerContentQualityPage() {
  const i18n = useTranslate();

  return (
    <div className={styles.DrawerContentQuality}>
      <h3 className={styles.DrawerContentQuality__title}>{i18n('DrawerContentQuality.title')}</h3>
      <p className={styles.DrawerContentQuality__description}>
        {i18n('DrawerContentQuality.description')}
      </p>
      <QualityNavTemplate />
    </div>
  );
}

DrawerContentQualityPage.propTypes = {};

export default DrawerContentQualityPage;
