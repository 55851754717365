import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../atoms/Input/Input';

const Link = ({ placeholder, value, onChange, onBlur }) => {
  return (
    <Input
      type="url"
      pattern="https://.*"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

Link.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

Link.defaultProps = {
  onChange: () => null,
  onBlur: () => null,
};

export default Link;
