import React from 'react';
import PropTypes from 'prop-types';
import styles from './DrawerHeader.css';

function DrawerHeader({ children }) {
  return <div className={styles.DrawerHeader}>{children}</div>;
}

DrawerHeader.propTypes = {
  children: PropTypes.node,
};

export default DrawerHeader;
