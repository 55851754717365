import uniq from './uniq';

export default function shallowCompare(obj1, obj2) {
  const t1 = typeof obj1;
  const t2 = typeof obj2;
  if (t1 !== t2) {
    return false;
  }

  if ((!obj1 || !obj2 || (t1 !== 'object' && t2 !== 'object')) && obj1 !== obj2) {
    return false;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  const allkeys = uniq(keys1.concat(keys2));
  for (let i = 0; i < allkeys.length; i++) {
    const key = allkeys[i];
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
}
