import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { populatePath } from '../../../helpers/path';
import List from '../../atoms/list/List';
import styles from './QualityNavTemplate.css';
import useTranslate from '../../../hooks/useTranslate';
import { useSelector } from 'react-redux';
import { formBlocksSelector, formQualitySelector } from '../../../store/selectors/editForm';
import {
  PATH_FIELD_NO_COMPARE,
  PATH_FIELD_TO_COMPARE,
  PATH_BLOCKS_SORTING,
  PATH_KEYBOARD_SHORTCUTS,
  PATH_VALIDATION_FORM,
  PATH_ADVANCED_FIELD_TO_COMPARE,
  PATH_ITERATION_OVERLOAD,
} from '../../../constants/paths';
import useFeatToogle from '../../../hooks/useFeatToggle';
import cssvars from '../../../styles/variables';
import { bem } from '../../../helpers/styles';
import { classnames } from '../../../helpers/classnames';
import useQuery from '../../../hooks/useQuery';

// Simple wrapper around NavLink to point to current url if enabled=false
const ToggableNavLink = ({ enabled, ...props }) => {
  const { url } = useRouteMatch();
  return <NavLink {...props} to={enabled ? props.to : url} />;
};

ToggableNavLink.propTypes = {
  to: PropTypes.string,
  enabled: PropTypes.bool,
};

function QualityNavTemplate({ itemClassname }) {
  const { params } = useRouteMatch();
  const featCompareFields = useFeatToogle('compareFields');
  const featNoCompareFields = useFeatToogle('noCompareFields');
  const featBlocksSorting = useFeatToogle('blocksSorting');
  const featValidation = useFeatToogle('validationSection');
  const i18n = useTranslate();
  const { strategy, respondentPerTask, iterationCount } = useSelector(formQualitySelector);
  const blocks = useSelector(formBlocksSelector);
  const query = useQuery();

  const multiBrowsable = strategy === 'multi';
  const multiBlocksBrowsable =
    blocks.reduce((total, current) => total + (current.duplicable ? 1 : 0), 0) > 0 &&
    strategy === 'multi';
  const multiOverloadBrowsable = iterationCount > respondentPerTask && strategy === 'multi';

  return (
    <List className={styles.QualityNavTemplate}>
      {featValidation && (
        <NavLink
          to={populatePath(PATH_VALIDATION_FORM, params, query)}
          className={styles.QualityNavTemplate__a}
        >
          <List.Item className={itemClassname} color={cssvars['color-quality-icon']} icon={'ruler'}>
            {i18n('QualityNavTemplate.validation')}
          </List.Item>
        </NavLink>
      )}
      <NavLink
        to={populatePath(PATH_KEYBOARD_SHORTCUTS, params, query)}
        className={styles.QualityNavTemplate__a}
      >
        <List.Item
          color={cssvars['color-quality-icon']}
          icon={'strategy'}
          className={itemClassname}
        >
          {i18n('QualityNavTemplate.keyboardShortcuts')}
        </List.Item>
      </NavLink>
      {featCompareFields && (
        <ToggableNavLink
          to={populatePath(PATH_FIELD_TO_COMPARE, params, query)}
          className={styles.QualityNavTemplate__a}
          enabled={multiBrowsable}
        >
          <List.Item
            className={classnames([
              itemClassname,
              bem(styles, 'QualityNavTemplate__item', { disabled: !multiBrowsable }),
            ])}
            color={cssvars['color-quality-icon']}
            icon={'compare_fields'}
          >
            {i18n('QualityNavTemplate.toCompare')}
          </List.Item>
        </ToggableNavLink>
      )}

      {featNoCompareFields && (
        <ToggableNavLink
          to={populatePath(PATH_FIELD_NO_COMPARE, params, query)}
          className={styles.QualityNavTemplate__a}
          enabled={multiBrowsable}
        >
          <List.Item
            className={classnames([
              itemClassname,
              bem(styles, 'QualityNavTemplate__item', { disabled: !multiBrowsable }),
            ])}
            color={cssvars['color-quality-icon']}
            icon={'nocompare'}
          >
            {i18n('QualityNavTemplate.noCompare')}
          </List.Item>
        </ToggableNavLink>
      )}
      {featCompareFields && (
        <ToggableNavLink
          to={populatePath(PATH_ADVANCED_FIELD_TO_COMPARE, params, query)}
          className={styles.QualityNavTemplate__a}
          enabled={multiBrowsable}
        >
          <List.Item
            className={classnames([
              itemClassname,
              bem(styles, 'QualityNavTemplate__item', { disabled: !multiBrowsable }),
            ])}
            color={cssvars['color-quality-icon']}
            icon={'compare_fields'}
          >
            {i18n('QualityNavTemplate.advancedToCompare')}
          </List.Item>
        </ToggableNavLink>
      )}
      <ToggableNavLink
        to={populatePath(PATH_ITERATION_OVERLOAD, params, query)}
        className={styles.QualityNavTemplate__a}
        enabled={multiOverloadBrowsable}
      >
        <List.Item
          className={classnames([
            itemClassname,
            bem(styles, 'QualityNavTemplate__item', { disabled: !multiOverloadBrowsable }),
          ])}
          color={cssvars['color-quality-icon']}
          icon={'nocompare'}
        >
          {i18n('QualityNavTemplate.iterationOverload')}
        </List.Item>
      </ToggableNavLink>
      {featBlocksSorting && (
        <ToggableNavLink
          to={populatePath(PATH_BLOCKS_SORTING, params, query)}
          className={styles.QualityNavTemplate__a}
          enabled={multiBlocksBrowsable}
        >
          <List.Item
            className={classnames([
              itemClassname,
              bem(styles, 'QualityNavTemplate__item', { disabled: !multiBlocksBrowsable }),
            ])}
            color={cssvars['color-quality-icon']}
            icon={'compare_blocks'}
          >
            {i18n('QualityNavTemplate.blocksSorting')}
          </List.Item>
        </ToggableNavLink>
      )}
    </List>
  );
}

QualityNavTemplate.propTypes = {
  itemClassname: PropTypes.string,
};

export default QualityNavTemplate;
