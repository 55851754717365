import React, { useState, useEffect, useContext, useMemo, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import { getNodeInitialValues, reflow } from '../../../../helpers/formTree';
import ContributeFormNode from '../ContributeFormNode';
import GridContext from '../../../organisms/Grid/GridContext';
import BlockItemControls from '../../../organisms/BlockItemControls/BlockItemControls';
import Grid from '../../../organisms/Grid/Grid';
import BlockControls from '../../../organisms/BlockControls/BlockControls';
import { FieldArray } from 'redux-form';
import { ERROR_BELOW_MINROWS, ERROR_ABOVE_MAXROWS } from '../../../../constants/errorType';
import { getBlockDarkerColor } from '../../../../helpers/color';

function boxesHeightsReducer(state = {}, action) {
  switch (action.type) {
    case 'set':
      return { ...state, [action.index]: action.height };
    default:
      throw new Error();
  }
}

function ContributeFormNodeBlockRow({ node, field, onHeightChange, y, readonly, ...props }) {
  // Tracks individual children height (a block row can have N child nodes)
  // We use a reducer to avoid simulatneous calls to override each other state
  const [boxesHeights, setBoxHeight] = useReducer(boxesHeightsReducer, {});
  // Tracks boxes overrides
  const [boxesOverrides, setBoxesOverrides] = useState([]);

  useEffect(() => {
    // When a child height change we recompute all boxes heights
    const boxes = node.children.map((node, i) => ({
      ...node.box,
      // Field must not go above their defined Y coordinates
      // Blocks can occupy above rows if free so we initialize Y coord with 0
      y: node.nodeType === 'block_node' ? 0 : node.box.y,
      height: Number.isInteger(boxesHeights[i]) ? boxesHeights[i] : node.box.height,
    }));

    // and apply reflow() so that no children collides
    const boxesReflow = reflow(boxes);

    // Find the max height so we can passe it to the parent
    const maxHeight = boxesReflow.reduce(
      (max, cur) => (cur.height !== 0 ? Math.max(max, cur.y + cur.height) : max),
      0,
    );
    onHeightChange(maxHeight);

    // Sets the boxes overrides so children are re-drawn
    setBoxesOverrides(boxesReflow);
  }, [node, boxesHeights, y]);

  return node.children.map((child, i) => {
    const yOverride = (boxesOverrides[i] ? boxesOverrides[i].y : child.box.y) + y;
    return (
      <>
        {__DEBUG_GRID && (
          <div
            style={{
              position: 'absolute',
              right: 0,
              background: 'rgba(255,0,0,0.7)',
              fontSize: 10,
              color: 'white',
              zIndex: 10000,
              top: yOverride * 72,
            }}
          >
            {JSON.stringify({
              yOverride,
            })}
          </div>
        )}
        <ContributeFormNode
          {...props}
          key={i}
          node={child}
          boxOverride={{
            y: yOverride,
          }}
          onHeightChange={(height) => {
            setBoxHeight({ type: 'set', index: i, height });
          }}
          parentFieldName={`${field}.`}
          readonly={readonly}
        />
      </>
    );
  });
}

function ContributeFormNodeBlockFields({
  node,
  boxOverride,
  fields,
  onHeightChange,
  readonly,
  blocksValues,
  meta: { error },
  ...props
}) {
  const grid = useContext(GridContext);
  // Tracks individual children height (a block can have N rows)
  const [boxesHeights, setBoxHeight] = useReducer(boxesHeightsReducer, {});
  // Track folded start
  const [folded, setFolded] = useState(false);

  const enableFloatingControls = node.floatingControls || false;

  // Compute the real height of the child grid : actual row count
  const realHeight = useMemo(
    () =>
      // Maxes the sum of all row height :
      // - We try to use a child box height if defined
      // - Otherwise we use the default rowHeight of the node
      fields.reduce((total, f, i) => {
        return (
          total +
          (Number.isInteger(boxesHeights[i]) ? boxesHeights[i] : node.rowHeight) +
          (!enableFloatingControls ? 1 : 0)
        );
      }, 0) + (enableFloatingControls ? 1 : 0),
    [node, fields.length, boxesHeights],
  ); // Recompute block box if needed : // - We the node is first mounted

  // check if there is duplicated block with hydrated values
  const getHydratedBlock = () => {
    if (props.reviewTree && props.reviewTree.blockArray && props.reviewTree.blockArray.length > 0) {
      props.reviewTree.blockArray.map((value, i) => {
        if (
          i > 0 &&
          props.reviewTree[props.reviewTree.blockArray[i].id] &&
          props.reviewTree[props.reviewTree.blockArray[i].id].$items &&
          props.reviewTree[props.reviewTree.blockArray[i].id].$items.length > 1
        ) {
          if (
            props.reviewTree.blockArray[i - 1].box.left ===
              props.reviewTree.blockArray[i].box.left &&
            blocksValues?.[node.id]?.nbFields > 0
          ) {
            function init() {
              fields.push(getNodeInitialValues(node));
            }
            function end() {
              fields.remove(fields.length);
            }
            setTimeout(init, 10);
            setTimeout(end, 20);
          }
        }
      });
    }
  };
  // - When a field is added / removed
  // - When the box overrides given by the parent changes
  // - When a child height change

  const realBox = useMemo(
    () => ({
      ...node.box,
      y: boxOverride ? boxOverride.y : node.box.y,
      height:
        folded || fields.length === 0
          ? 1
          : Math.min(realHeight, node.maxRowsEnabled ? node.maxRows : Number.MAX_SAFE_INTEGER),
    }),
    [node, boxOverride && boxOverride.y, realHeight, folded, fields.length],
  );

  // Compute style of bloc grid box
  const style = useMemo(
    () => ({
      background: node.color,
      // Enable overflow auto if real height is greater than allowed height
      overflowY: !folded && realHeight > realBox.height ? 'auto' : 'hidden',
      overflowX: 'hidden',

      // Used to debug boxes
      border: __DEBUG_GRID ? `2px dashed ${getBlockDarkerColor(node.color)}` : undefined,
    }),
    [realHeight, realBox, node, folded],
  );

  // When real box changes sizes, send this to the parent
  useEffect(() => {
    if (fields.length === 0) {
      onHeightChange(node.duplicable ? 1 : 0);
    } else {
      onHeightChange(realBox.height);
    }
  }, [realBox, realHeight, fields.length]);

  // We need to offset each row depending on previous children heights so we need
  // a way to track y offset
  let yOffset = 0;
  useEffect(() => {
    if (fields.length === 0 && !node.duplicable) {
      fields.push({});
    }
    getHydratedBlock();
  }, []);

  return (
    <Grid.Box {...realBox} style={style}>
      {__DEBUG_GRID && (
        <div
          style={{
            position: 'absolute',
            background: 'rgba(0,0,0,0.8)',
            fontSize: 10,
            color: 'white',
            zIndex: 10000,
          }}
        >
          {JSON.stringify({
            x: realBox.x,
            y: realBox.y,
            width: realBox.width,
            height: realBox.height,
            realHeight,
          })}
        </div>
      )}
      <BlockControls
        {...props}
        block={node}
        folded={folded}
        duplicable={node.rowHeight !== 0}
        onAddClick={() => fields.push(getNodeInitialValues(node))}
        onRemoveClick={() => fields.remove(0)}
        onFoldClick={() => setFolded(!folded)}
        readonly={readonly}
        reviewOptions={blocksValues ? blocksValues[node.id] : null}
        fieldName={fields.name}
        error={error}
      />
      {!folded && (
        <Grid
          columns={node.box.width}
          rowHeight={grid.rowHeight}
          fixedRows={1}
          gutter={0}
          dotBackground={false}
        >
          {fields.map((field, i) => {
            // Add the previous child height to current y offset
            if (i > 0) {
              yOffset +=
                (boxesHeights[i - 1] || node.rowHeight) + (!enableFloatingControls ? 1 : 0);
            }

            return (
              <div>
                {/* User can't delete first row */}
                {i !== 0 && (
                  <BlockItemControls
                    row={yOffset - (enableFloatingControls ? 0 : 1)}
                    onDelete={() => fields.remove(i)}
                    onAddClick={() => fields.push(getNodeInitialValues(node))}
                    color={node.color}
                    readonly={readonly}
                    folded={folded}
                    hideSeparator={enableFloatingControls}
                  />
                )}
                <ContributeFormNodeBlockRow
                  {...props}
                  key={i}
                  node={node}
                  field={field}
                  y={yOffset}
                  onHeightChange={(height) => setBoxHeight({ type: 'set', index: i, height })}
                  readonly={readonly}
                  blocksValues={blocksValues}
                />
              </div>
            );
          })}
        </Grid>
      )}
    </Grid.Box>
  );
}

ContributeFormNodeBlockFields.propTypes = {
  node: PropTypes.object,
  boxOverride: PropTypes.object,
  fields: PropTypes.object,
  onHeightChange: PropTypes.func,
  readonly: PropTypes.bool,
  blocksValues: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.object,
  }),
};
export default function ContributeFormNodeBlock({ node, parentFieldName = '', ...props }) {
  const validate = useCallback(
    (rows) => {
      if (node.rowsRange) {
        if (typeof node.rowsRange?.min === 'number' && rows && rows.length < node.rowsRange.min) {
          return {
            type: ERROR_BELOW_MINROWS,
            value: node.rowsRange.min,
          };
        } else if (typeof node.rowsRange?.max === 'number' && rows.length > node.rowsRange.max) {
          return {
            type: ERROR_ABOVE_MAXROWS,
            value: node.rowsRange.max,
          };
        }
      }
    },
    [node.rowsRange],
  );
  // Block rows are implemented as redux-form FieldArray to be in syc with the form state
  return (
    <FieldArray
      name={`${parentFieldName}${node.id}.$items`}
      node={node}
      parentFieldName={parentFieldName}
      component={ContributeFormNodeBlockFields}
      validate={validate}
      {...props}
    />
  );
}

ContributeFormNodeBlock.propTypes = {
  node: PropTypes.object,
  parentFieldName: PropTypes.string,
};
