import React from 'react';
import PropTypes from 'prop-types';
import useTranslate from '../../../hooks/useTranslate';
import styles from './FieldError.css';

function FieldError({ error, message }) {
  const i18n = useTranslate();

  return (
    <div className={styles.FieldError}>{message || i18n(`FieldError.${error.type}`, error)}</div>
  );
}

FieldError.propTypes = {
  message: PropTypes.string,
  error: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.any,
  }),
};

export default FieldError;
