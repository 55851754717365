export const LITERAL_NUMBER = 'LITERAL_NUMBER';
export const LITERAL_STRING = 'LITERAL_STRING';
export const FIELD_REFERENCE = 'FIELD_REFERENCE';
export const OPERATOR_ADD = 'OPERATOR_ADD';
export const OPERATOR_SUBSTRACT = 'OPERATOR_SUBSTRACT';
export const OPERATOR_MULTIPLY = 'OPERATOR_MULTIPLY';
export const OPERATOR_DIVIDE = 'OPERATOR_DIVIDE';
export const OPERATOR_MODULO = 'OPERATOR_MODULO';
export const OPERATOR_GT = 'OPERATOR_GT';
export const OPERATOR_GTE = 'OPERATOR_GTE';
export const OPERATOR_LT = 'OPERATOR_LT';
export const OPERATOR_LTE = 'OPERATOR_LTE';
export const OPERATOR_EQ = 'OPERATOR_EQ';
export const OPERATOR_NEQ = 'OPERATOR_NEQ';
export const OPERATOR_IN = 'OPERATOR_IN';
export const OPERATOR_NOTIN = 'OPERATOR_NOTIN';
export const OPERATOR_LEFT_PARENTHESIS = 'OPERATOR_LEFT_PARENTHESIS';
export const OPERATOR_RIGHT_PARENTHESIS = 'OPERATOR_RIGHT_PARENTHESIS';
export const OPERATOR_AND = 'OPERATOR_AND';
export const OPERATOR_OR = 'OPERATOR_OR';
export const OPERATOR_XOR = 'OPERATOR_XOR';
export const OPERATOR_NOT = 'OPERATOR_NOT';
export const FUNCTION_FLOOR = 'FUNCTION_FLOOR';
export const FUNCTION_CEIL = 'FUNCTION_CEIL';
export const FUNCTION_ROUND = 'FUNCTION_ROUND';
