import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../molecules/Modal/Modal';
import useTranslate from '../../../hooks/useTranslate';
import Button from '../../atoms/Button/Button';
import styles from './ContributeFormErrorModal.css';

function ContributeFormErrorModal({ open, errors, onClose, onForceSubmit, syncErrors }) {
  const i18n = useTranslate();

  if ((!errors || !errors.length) && (!syncErrors || !syncErrors.length)) {
    return null;
  }

  return (
    <Modal title={i18n('ContributeFormErrorModal.title')} open={open}>
      <p className={styles.ContributeFormErrorModal__description}>
        {i18n('ContributeFormErrorModal.description')}
      </p>
      <ul className={styles.ContributeFormErrorModal__errors}>
        {errors?.map((err, i) => (
          <li key={i}>
            <p className={styles.ContributeFormErrorModal__name}>
              {i18n(`ContributeFormErrorModal.${err.isForeach ? 'rowError' : 'error'}`, {
                count: i + 1,
                row: err.row,
              })}
              - {err.name}
            </p>
            <p className={styles.ContributeFormErrorModal__errorMessage}>{err.errorMessage}</p>
          </li>
        ))}
        {syncErrors?.map((err, i) => (
          <li key={i}>
            <p className={styles.ContributeFormErrorModal__name}>
              {i18n(`FieldError.${err.type}`, err)} - {err.label}
            </p>
          </li>
        ))}
      </ul>
      <div className={styles.ContributeFormErrorModal__actions}>
        <div className={styles.ContributeFormErrorModal__actionPane} />
        <Button type="button" onClick={onClose}>
          {i18n('ContributeFormErrorModal.button')}
        </Button>
        <div className={styles.ContributeFormErrorModal__actionPane}>
          {onForceSubmit && (
            <button
              type="button"
              onClick={onForceSubmit}
              className={styles.ContributeFormErrorModal__forceButton}
            >
              {i18n('ContributeFormErrorModal.forceValidation')}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}

ContributeFormErrorModal.propTypes = {
  onForceSubmit: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  open: PropTypes.bool,
  errors: PropTypes.array,
  onClose: PropTypes.func,
  syncErrors: PropTypes.array,
};

export default ContributeFormErrorModal;
