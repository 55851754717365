import { CREATING } from '../constants/formState'


export const emptyForm = {
  showFormTitle: false,
  allowError: false,
  averageDuration: '00:10:00',
  blocks: [],
  compareFields: undefined,
  date: undefined,
  enterToValidate: undefined,
  fields: [],
  hasSubdivide: false,
  id: "demo",
  instructions: undefined,
  iterationOverloads: undefined,
  keySkill: undefined,
  keyboardShortcuts: undefined,
  lastBlockOrder: 0,
  minimumDuration: '00:00:00',
  name: 'Mock form',
  noCompareFields: undefined,
  prefillInputs: undefined,
  quality: { respondentPerTask: 10, proofreadingCount: 1, matchingCount: 2, iterationCount: 3 },
  state: CREATING,
  subdivide: 1,
  submitLabel: undefined,
  validationRules: undefined,
  fontSize: 12
};
