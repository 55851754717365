import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import Input from '../../../atoms/Input/Input';
function ShortCutFieldRender({ input, id, meta: { error, touched }, ...props }) {
  const { value, onChange } = input;
  const [shortcut, SetShortCut] = useState(value);
  const handleChange = useCallback(
    (val) => {
      if (val.length <= 1) {
        SetShortCut(val);
        onChange(val);
      }
    },
    [shortcut, input],
  );

  useEffect(() => {
    if (value) SetShortCut(value);
  }, [value]);

  return (
    <>
      <Input
        {...input}
        value={shortcut}
        onChange={handleChange}
        id={id}
        block
        {...props}
        error={touched && error}
      />
    </>
  );
}
ShortCutFieldRender.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
};

function ShortCutField({ name, label, required, fieldHoC: FieldHOC, ...props }) {
  const id = useMemo(() => `name_${uuid()}`, [name]);
  return (
    <FormField label={label} name={name} htmlFor={id} required={required}>
      <FieldHOC name={name} component={ShortCutFieldRender} id={id} {...props} />
    </FormField>
  );
}
ShortCutField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  fieldHoC: PropTypes.func,
};
ShortCutField.defaultProps = {
  fieldHoC: Field,
  validation: {},
};
export default ShortCutField;
