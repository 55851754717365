import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { contribtueFormActiveSelector } from '../../../store/selectors/form';
import { usePdfOcrViewerContext } from '../../organisms/PdfOcrViewer/PdfOcrViewerContext';

function getFieldFromAnswer(answer, fieldId) {
  if (answer == null) {
    return null;
  }

  const parts = fieldId.split('.');
  if (parts.length === 1) {
    return answer.fields?.find((f) => f.id === fieldId);
  }

  return traverseBlocks(answer.blocks, parts);
}

function traverseBlocks(blocks, parts) {
  // Find the block
  const block = blocks.find((b) => b.id === parts[0]);
  if (block == null) {
    return null;
  }

  // Grab the row
  const rowIndex = parts[1].match(/\d+/)[0];
  const row = block.rows[rowIndex];

  return getFieldFromAnswer(row, parts.slice(2).join('.'));
}

function PDFOCRGeolocProbe({ answer }) {
  const activeField = useSelector(contribtueFormActiveSelector);
  const { viewers } = usePdfOcrViewerContext();

  useEffect(() => {
    // Geoloc doesn't have an id for the viewer in case of multiple viewers,
    // always grab the first one
    const viewer = viewers[Object.keys(viewers)[0]];
    if (viewer == null) {
      return;
    }

    if (activeField == null) {
      viewer.goTo(null);
      return;
    }

    // Find the field in answers
    const field = getFieldFromAnswer(answer, activeField);

    // Notify the PdfOcrViewer that a field is selected
    viewer.goTo(field);
  }, [activeField, answer, viewers]);

  return null;
}

PDFOCRGeolocProbe.propTypes = {
  form: PropTypes.object,
  answer: PropTypes.object,
};

export default PDFOCRGeolocProbe;
