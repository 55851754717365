import React from 'react';
import PropTypes from 'prop-types';
import styles from './Drawer.css';
import DrawerHeader from './Header/DrawerHeader';
import DrawerTitle from './Title/DrawerTitle';
import DrawerVerticalNav from './VerticalNav/DrawerVerticalNav';
import DrawerContent from './Content/DrawerContent';
import DrawerBody from './Body/DrawerBody';
import DrawerFormControls from './FormControls/DrawerFormControls';
import DrawerFooter from './Footer/DrawerFooter';

function Drawer({ children }) {
  return (
    <>
      <div className={styles.Drawer} style={{ position: 'fixed' }}>
        {children}
      </div>
      <div className={styles.Drawer} />
    </>
  );
}

Drawer.Header = DrawerHeader;
Drawer.Title = DrawerTitle;
Drawer.Body = DrawerBody;
Drawer.Content = DrawerContent;
Drawer.VerticalNav = DrawerVerticalNav;
Drawer.FormControls = DrawerFormControls;
Drawer.Footer = DrawerFooter;

Drawer.propTypes = {
  children: PropTypes.node,
};

export default Drawer;
