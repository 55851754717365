import { FIELD_TYPOLOGIES } from './field';
import { DATA_TYPOLOGIES } from './data';
import {
  ADDRESS,
  ADVANCEDSELECT,
  DATETIME,
  EMAIL,
  FILEUPLOAD,
  GPSCOORDINATES,
  MCQ,
  NUMBER,
  PHONENUMBER,
  RICHTEXT,
  SHORTTEXT,
  URL,
  RIB,
} from './fieldType';

const TYPOLOGIES = [...FIELD_TYPOLOGIES, ...DATA_TYPOLOGIES];

// Typologies that can't be added to compare fields
export const TYPOLOGIES_COMPARE_INVALID = [FILEUPLOAD, MCQ, ADVANCEDSELECT];

export const TYPOLOGIES_ADVANCED_COMPARE = [
  SHORTTEXT,
  RICHTEXT,
  EMAIL,
  URL,
  PHONENUMBER,
  ADVANCEDSELECT,
  MCQ,
  GPSCOORDINATES,
  ADDRESS,
  RIB,
  DATETIME,
  NUMBER,
];

export const TYPOLOGIES_ITERATION_OVERLOAD = [
  SHORTTEXT,
  RICHTEXT,
  EMAIL,
  URL,
  MCQ,
  ADVANCEDSELECT,
  DATETIME,
  NUMBER,
];

export default TYPOLOGIES;
