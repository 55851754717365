import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Flags from 'country-flag-icons/react/3x2';
import SVG from '../SVG/SVG';

function Flag({ countryCode, className }) {
  const FlagIcon = useMemo(() => Flags[countryCode], [countryCode]);
  if (countryCode === 'XX') {
    const NoFlag = () => (
      <SVG glyph={'question_mark'} className={className} style={'width: 18px'} />
    );
    return <NoFlag />;
  }
  return FlagIcon && <FlagIcon className={className} />;
}

Flag.propTypes = {
  countryCode: PropTypes.string,
  className: PropTypes.string,
};

export default Flag;
