import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import useFetchAction from '../../../hooks/useFetchAction';
import { getAnswerHit as getAnswerHitAction } from '../../../store/actions/answer';
import { useParams } from 'react-router';
import Spinner from '../../atoms/Spinner/Spinner';
import ContributeAnswerFormTemplate from '../../templates/ContributeAnswerFormTemplate/ContributeAnswerFormTemplate';
import ErrorContributeTemplate from '../../templates/ErrorContributeTemplate/ErrorContributeTemplate';
import ContributeAnswerDetailTemplate from '../../templates/ContributeAnswerDetailTemplate/ContributeAnswerDetailTemplate';
import ContributeAnswer from '../../organisms/ContributeAnswer/ContributeAnswer';
import ContributeCloseButtonTemplate from '../../templates/ContributeCloseButtonTemplate/ContributeCloseButtonTemplate';
import { getFormTask as getFormTaskAction } from '../../../store/actions/task';

function ContributeViewHitPage({ withMarking }) {
  const params = useParams();
  const [getAnswerHit, answerHitLoading, answerHitError, answerHit] = useFetchAction(getAnswerHitAction);

  const [getForm, formLoading, formError, form] = useFetchAction(getFormTaskAction);

  // Creates a key-value pair of task data
  const formData = useMemo(() => {
    const formData = { ...answerHit?.json };

    answerHit?.columns.forEach((col) => {
      if (formData.data.find((elem) => elem.id === col.name) == null) {
        formData.data.push({
          id: col.name,
          type: col.type,
          value: col.data,
        });
      }
    });

    return formData;
  }, [answerHit]);

  const columnAnswer =
    answerHit && answerHit.Columns
      ? answerHit.Columns.map((elem) => {
          const tempObj = {
            id: elem.Name,
            type: elem.Type,
            value: elem.Data,
          };
          return tempObj;
        })
      : [];

  // First we load the task
  useEffect(() => {
    getAnswerHit(params.hitId);
  }, []);

  // Once task is loaded, load the form
  useEffect(() => {
    if (answerHit?.formId && !form && !formLoading) {
      getForm(answerHit.formId);
    }
  }, [answerHit, form, formLoading]);

  if (!answerHitError && !formError) {
    if (!form || formLoading || answerHitLoading) {
      return <Spinner />;
    }
  }

  const emptyAnswer = { blocks: [], data: columnAnswer, fields: [] };

  return (
    <ContributeAnswer>
      <ContributeCloseButtonTemplate isView projectId={answerHit.projectId} />
      {withMarking && (
        <ContributeAnswerDetailTemplate
          answer={answerHit}
          form={form}
        ></ContributeAnswerDetailTemplate>
      )}
      <ErrorContributeTemplate answerError={answerHitError} formError={formError}>
        <ContributeAnswerFormTemplate answer={formData ? formData : emptyAnswer} form={form} />
      </ErrorContributeTemplate>
    </ContributeAnswer>
  );
}

ContributeViewHitPage.propTypes = {
  withMarking: PropTypes.bool,
};

export default ContributeViewHitPage;
