import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import RichText from '../../RichText/RichText';
import FieldError from '../../Error/FieldError';
import { getConfidenceFieldStyle } from '../../../../helpers/form';

function RichTextFieldRender({
  input,
  label,
  placeholder,
  id,
  readonly,
  hasModulesCustom,
  confidence,
  meta: { error, touched },
  isFocusOnInit,
  validation,
  height,
}) {
  const style = useMemo(() => !touched && getConfidenceFieldStyle(confidence), [
    confidence,
    touched,
  ]);

  return (
    <>
      <RichText
        {...input}
        isFocusOnInit={isFocusOnInit}
        error={touched && error}
        label={label}
        id={id}
        block
        height={height}
        readonly={readonly}
        placeholder={placeholder}
        hasModulesCustom={hasModulesCustom}
        style={style}
        validation={validation}
      />
      {touched && error && <FieldError error={error} />}
    </>
  );
}

RichTextFieldRender.propTypes = {
  height: PropTypes.string,
  validation: PropTypes.object,
  isFocusOnInit: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  readonly: PropTypes.bool,
  hasModulesCustom: PropTypes.bool,
  confidence: PropTypes.number,
};

function RichTextField({
  name,
  label,
  required,
  information,
  verticalAlign,
  fieldHoC: FieldHoC,
  hasModulesCustom,
  answersChoices,
  validation,
  ...props
}) {
  const id = useMemo(() => `name_${uuid()}`, [name]);
  return (
    <FormField
      label={label}
      name={name}
      htmlFor={id}
      required={required}
      information={information}
      verticalAlign={verticalAlign}
      answersChoices={answersChoices}
    >
      <FieldHoC
        name={name}
        component={RichTextFieldRender}
        id={id}
        validation={validation}
        {...props}
        hasModulesCustom={hasModulesCustom}
      />
    </FormField>
  );
}

RichTextField.propTypes = {
  height: PropTypes.string,
  validation: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.string,
  information: PropTypes.string,
  fieldHoC: PropTypes.func,
  verticalAlign: PropTypes.bool,
  hasModulesCustom: PropTypes.bool,
  answersChoices: PropTypes.array,
};

RichTextField.defaultProps = {
  fieldHoC: Field,
  verticalAlign: true,
  hasModulesCustom: false,
  answersChoices: null,
};

export default RichTextField;
