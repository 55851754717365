import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFormValues, initialize } from 'redux-form';
import { getTypologyByType } from '../../../../../../helpers/typology';
import useTranslate from '../../../../../../hooks/useTranslate';
import { updateBlock, updateField } from '../../../../../../store/actions/form';
import {
  formAdvancedCompareFieldsSelector,
  currentFormSelector,
  formAdvancedCompareBlocksSelector,
} from '../../../../../../store/selectors/editForm';
import Button from '../../../../../atoms/Button/Button';
import List from '../../../../../atoms/list/List';
import AdvancedComparisonRulesForm from '../../../../../form/AvancedComparisonRulesForm/AvancedComparisonRulesForm';
import QualitySelectTemplate from '../../../../../templates/QualitySelectTemplate/QualitySelectTemplate';
import styles from './AdvancedToCompare.css';

function AdvancedToComparePage() {
  const [focused, setFocused] = useState();
  const i18n = useTranslate();
  const fields = useSelector(formAdvancedCompareFieldsSelector);
  const blocks = useSelector(formAdvancedCompareBlocksSelector);
  const form = useSelector(currentFormSelector);
  const dispatch = useDispatch();
  const typology = focused ? getTypologyByType(focused.type) : null;
  const values = useSelector(getFormValues('advancedComparisonRules'));

  // Hydrate form when we select something
  useEffect(() => {
    if (focused) {
      dispatch(initialize('advancedComparisonRules', focused.comparisonRules));
    }
  }, [focused?.id]);

  // Push updates to the store
  useEffect(() => {
    if (values && focused) {
      const updates = {
        comparisonRules: {
          ...(focused.comparisonRules || {}),
          ...values,
        },
      };

      if (focused.type) {
        // Field case
        dispatch(updateField(form.id, focused.id, updates));
      } else {
        // Block case
        dispatch(updateBlock(form.id, focused.id, updates));
      }
    }
  }, [values, focused?.id]);

  return (
    <>
      <QualitySelectTemplate
        icon="compare_fields"
        titleKey="QualityNavTemplate.advancedToCompare"
      />
      <div className={styles.AdvancedToCompare}>
        <h3 className={styles.AdvancedToCompare__title}>{i18n('AdvancedToComparePage.title')}</h3>
        <p className={styles.AdvancedToCompare__description}>
          {i18n('AdvancedToComparePage.description')}
        </p>
        {focused ? (
          <>
            <div>
              <Button
                onClick={() => setFocused(null)}
                block
                className={styles.AdvancedToCompare__backButton}
                icon="arrow_left_simple"
              >
                {i18n('AdvancedToComparePage.back')}
              </Button>
            </div>
            <div className={styles.AdvancedToCompare__itemPlaceholder}>
              <List.Item
                icon={typology ? typology.meta.icon : 'layers'}
                color={focused.color || typology.meta.color}
              >
                {focused.name || focused.label || i18n(`field.${focused.type}.label`)}
              </List.Item>
            </div>
            <AdvancedComparisonRulesForm resource={typology?.type || 'block'} field={focused} />
          </>
        ) : (
          <>
            {fields.length > 0 &&
              fields.map((field, i) => {
                const typology = getTypologyByType(field.type);

                return (
                  <List.Item
                    key={i}
                    icon={typology.meta.icon}
                    color={typology.meta.color}
                    onClick={() => setFocused(field)}
                    className={styles.AdvancedToCompare__clickable}
                  >
                    {field.label || i18n(`field.${field.type}.label`)}
                  </List.Item>
                );
              })}
            {blocks.length > 0 &&
              blocks.map((block, i) => {
                return (
                  <List.Item
                    key={i}
                    icon={'layers'}
                    color={block.color}
                    onClick={() => setFocused(block)}
                    className={styles.AdvancedToCompare__clickable}
                  >
                    {block.name || '...'}
                  </List.Item>
                );
              })}
            {fields.length + blocks.length === 0 && (
              <p className={styles.AdvancedToCompare__empty}>
                {i18n('AdvancedToComparePage.empty')}
              </p>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default AdvancedToComparePage;
