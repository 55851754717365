import React from 'react';
import PropTypes from 'prop-types';
import styles from './DrawerDetail.css';
import DrawerDetailHeader from './Header/DrawerDetailHeader';
import DrawerDetailBody from './Body/DrawerDetailBody';
import DrawerDetailFooter from './Footer/DrawerDetailFooter';

function DrawerDetail({ children }) {
  return <div className={styles.DrawerDetail}>{children}</div>;
}

DrawerDetail.Header = DrawerDetailHeader;
DrawerDetail.Body = DrawerDetailBody;
DrawerDetail.Footer = DrawerDetailFooter;

DrawerDetail.propTypes = {
  children: PropTypes.node,
};

export default DrawerDetail;
