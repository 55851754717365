import React from 'react';
import PropTypes from 'prop-types';
import SVG from '../../atoms/SVG/SVG';
import styles from './Spoiler.css';
import { classnames } from '../../../helpers/classnames';
import { bem } from '../../../helpers/styles';

function Spoiler({ open, renderHeader, title, render, onClick, className }) {
  return (
    <div className={classnames([bem(styles, 'Spoiler', { open }), className])}>
      <div className={styles.Spoiler__header} onClick={onClick}>
        <SVG glyph="rectangle" className={bem(styles, 'Spoiler__caret', { open })} />
        {renderHeader ? renderHeader() : title}
      </div>
      {open && render()}
    </div>
  );
}

Spoiler.propTypes = {
  open: PropTypes.bool,
  renderHeader: PropTypes.func,
  title: PropTypes.string,
  render: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default Spoiler;
