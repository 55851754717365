import { apiSelectTypologieTypeMapper, buildFormTree, mapFormTree } from '../helpers/formTree';
import { PUBLISHED } from '../constants/formState';
// backend format => front format
export function parseForm(json) {
  const formJson = json.template ? json.template : {};
  return {
    id: json.idForm,
    state: json.state,
    date: json.creationDate,
    enterToValidate: formJson.enterToValidate,
    fields: formJson.fields || [],
    name: formJson.name || `App #${json.idForm}`,
    averageDuration: formJson.averageDuration || '00:10:00',
    minimumDuration: formJson.minimumDuration || '00:00:00',
    keySkill: formJson.keySkill,
    hasSubdivide: formJson.hasSubdivide || false,
    subdivide: formJson.subdivide || 1,
    allowError: formJson.allowError || false,
    submitLabel: formJson.submitLabel,
    instructions: formJson.instructions,
    validationRules: formJson.validationRules,
    keyboardShortcuts: formJson.keyboardShortcuts,
    iterationOverloads: formJson.iterationOverloads,
    quality: {
      ...formJson.quality,
      // This field should default to 1
      respondentPerTask: formJson?.quality?.respondentPerTask || 1,
      proofreadingCount: formJson?.quality?.proofreadingCount || 1,
      matchingCount: formJson?.quality?.matchingCount || 2,
      iterationCount: formJson?.quality?.iterationCount || 3,
      strategy: formJson?.quality?.strategy || 'simple',
    },
    blocks: formJson.blocks || [],
    lastBlockOrder: formJson.lastBlockOrder || 0,
    noCompareFields: formJson.noCompareFields,
    compareFields: formJson.compareFields,
    prefillInputs: formJson.prefillInputs,
    showFormTitle: formJson.showFormTitle || false,
    fontSize: formJson.fontSize || 12,
    disableBrowserShortcuts: formJson.disableBrowserShortcuts || false,
    gridSize: formJson.gridSize || 12,
  };
}

// front format => backend format
export function formatForm(json) {
  return {
    IdForm: json.id,
    IsPublished: json.state === PUBLISHED,
    OutputJsonSample: json?.outputJsonSample,
    Template: {
      name: json.name,
      averageDuration: json.averageDuration,
      minimumDuration: json.minimumDuration,
      keySkill: json.keySkill,
      hasSubdivide: json.hasSubdivide,
      subdivide: json.subdivide,
      allowError: json.allowError,
      submitLabel: json.submitLabel,
      instructions: json.instructions,
      fields: json.fields,
      data: json.data,
      blocks: json.blocks,
      quality: json.quality,
      validationRules: json.validationRules,
      lastBlockOrder: json.lastBlockOrder || 0,
      enterToValidate: json.enterToValidate,
      noCompareFields: json.noCompareFields,
      compareFields: json.compareFields,
      keyboardShortcuts: json.keyboardShortcuts,
      iterationOverloads: json.iterationOverloads,
      prefillInputs: json.prefillInputs,
      showFormTitle: json.showFormTitle,
      fontSize: json.fontSize || 10,
      disableBrowserShortcuts: json.disableBrowserShortcuts || false,
      gridSize: json.gridSize || 12,
      // Wirk needs the tree structure for analysis
      tree: mapFormTree(buildFormTree(json), apiSelectTypologieTypeMapper),
    },
  };
}
