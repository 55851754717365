import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './BlockControls.css';
import Button from '../../atoms/Button/Button';
import GridContext from '../Grid/GridContext';
import { bem } from '../../../helpers/styles';
import { getBlockDarkerColor } from '../../../helpers/color';
import ConfidenceIcon from '../../atoms/ConfidenceIcon/ConfidenceIcon';
import Dropdown from '../../molecules/Dropdown/Dropdown';
import { useDispatch } from 'react-redux';
import { arrayPush } from 'redux-form';
import FieldError from '../../molecules/Error/FieldError';

function BlockControls({
  block,
  folded,
  onAddClick,
  onFoldClick,
  onRemoveClick,
  duplicable,
  readonly,
  fieldName,
  reviewOptions,
  error,
}) {
  //BLOCKS HEADER
  const dispatch = useDispatch();

  const grid = useContext(GridContext);

  const style = useMemo(
    () => ({
      height: grid?.rowHeight || undefined,
    }),
    [grid],
  );

  const darkerColor = useMemo(
    () => (block.darkerColor ? block.darkerColor : getBlockDarkerColor(block.color)),
    [block && block.color],
  );

  const colorStyle = useMemo(
    () => ({
      color: darkerColor,
      fill: darkerColor,
    }),
    [darkerColor],
  );

  const handleReviewRowClick = (value) => {
    dispatch(arrayPush('contribute', fieldName, value));
  };

  let checkAnswerValue =
    reviewOptions &&
    reviewOptions.rows.map((row) => row.label !== (undefined || null)).includes(true);

  return (
    <div className={styles.BlockControls} style={style}>
      {block.nameEnabled && (
        <div className={styles.BlockControls__name} style={colorStyle}>
          {block.name}
        </div>
      )}
      {block.foldable && (
        <Button
          type="button"
          icon="caret"
          rounded
          tabIndex={-1}
          className={bem(styles, 'BlockControls__buttonFold', { folded })}
          style={colorStyle}
          onClick={onFoldClick}
        />
      )}
      {reviewOptions && reviewOptions.nbFields > 0 && checkAnswerValue && (
        <div className={styles.BlockControls__review}>
          <ConfidenceIcon confidence={reviewOptions.confidence} />
          <Dropdown
            options={reviewOptions.rows}
            icon="arrow_dropdown_down"
            onSelect={handleReviewRowClick}
          />
        </div>
      )}
      <div className={styles.BlockControls__flex}>{error && <FieldError error={error} />}</div>
      {block.duplicable && duplicable && (
        <>
          <Button
            type="button"
            icon="trash"
            rounded
            tabIndex={-1}
            className={styles.BlockControls__button}
            style={colorStyle}
            onClick={onRemoveClick}
            disabled={readonly}
          />
          <Button
            type="button"
            icon="plus"
            rounded
            tabIndex={-1}
            className={styles.BlockControls__button}
            style={colorStyle}
            onClick={onAddClick}
            disabled={readonly}
          />
        </>
      )}
    </div>
  );
}

BlockControls.propTypes = {
  block: PropTypes.object,
  onAddClick: PropTypes.func,
  onFoldClick: PropTypes.func,
  folded: PropTypes.bool,
  duplicable: PropTypes.bool,
  readonly: PropTypes.bool,
  fieldName: PropTypes.string,
  reviewOptions: PropTypes.shape({
    confidence: PropTypes.number,
    rows: PropTypes.array,
  }),
  error: PropTypes.object,
  onRemoveClick: PropTypes.func,
};

export default BlockControls;
