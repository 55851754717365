import { NUMBER_REGEX, NUMBER_STRICT_REGEX } from '../constants/regex';
import { bignumber } from 'mathjs';
export const float = (value) => {
  if (typeof value === 'string') {
    value = value.replace(/,/, '.');
  }

  if (['boolean', 'undefined', 'object', 'function'].includes(typeof value) || value === '') {
    return null;
  }

  const number = typeof value === 'number' ? parseFloat(value) : parseFloat(Number(value));

  if (isNaN(number)) return null;

  return number;
};

export const integer = (value) => {
  if (typeof value === 'string') {
    value = value.replace(/,/, '.');
  }

  if (['boolean', 'undefined', 'object', 'function'].includes(typeof value) || value === '') {
    return null;
  }

  const number = typeof value !== 'number' ? Number(value) : value;

  if (isNaN(number)) return null;

  return number;
};

export const bigint = (value) => {
  if (typeof value === 'string') {
    value = value.replace(/,/, '.');
    if (value === '-' || value === '.' || value === '-.') return null;
  }

  return bignumber(value);
};

export const numberRegex = (value) => {
  if (!value || typeof value !== 'string') return '';

  let number = [...value].map((char) => {
    if (NUMBER_REGEX.test(char)) return char;
    return;
  });

  if (!number) return '';

  number = number.join('');

  if (/[.,]/g.test(number)) {
    number = number.replace(/[.,]/g, ((i) => (m) => (!i++ ? m : ''))(0));
  }

  if (/[-]/g.test(number)) {
    number = number.replace(
      /[-]/g,
      ((i) => (m) => {
        return !i++ ? m : '';
      })(0),
    );
  }

  if (!NUMBER_STRICT_REGEX.test(number)) number = number.replace(/[-]/g, '');

  return number;
};
