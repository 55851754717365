import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useFetchAction from '../../../hooks/useFetchAction';
import { createForm as createFormAction } from '../../../store/actions/form';
import { Redirect } from 'react-router';
import { populatePath } from '../../../helpers/path';
import { PATH_EDITFIELDS_FORM } from '../../../constants/paths';
import Spinner from '../../atoms/Spinner/Spinner';
import useQuery from '../../../hooks/useQuery';

function CreatePage() {
  const dispatch = useDispatch();
  const [createForm, , , response] = useFetchAction(createFormAction);
  const query = useQuery();

  // Creates a form silently and redirect to settings page
  useEffect(() => {
    dispatch(createForm({}));
  }, []);

  // Redirect to edit form page if success
  if (response) {
    return (
      <Redirect
        to={populatePath(
          PATH_EDITFIELDS_FORM,
          {
            formId: response.id,
          },
          query,
        )}
      />
    );
  }

  return <Spinner />;
}

export default CreatePage;
