import React, { useCallback } from 'react';
import { v4 as id } from 'uuid';
import PropTypes from 'prop-types';
import styles from './FormulaInput.css';
import FormulaDropdown from './FormulaDropdown/FormulaDropdown';
import useTranslate from '../../../hooks/useTranslate';
import {
  LITERAL_NUMBER,
  LITERAL_STRING,
  OPERATOR_ADD,
  OPERATOR_DIVIDE,
  OPERATOR_GT,
  OPERATOR_MULTIPLY,
  OPERATOR_SUBSTRACT,
  OPERATOR_MODULO,
  OPERATOR_LT,
  OPERATOR_LTE,
  OPERATOR_NEQ,
  OPERATOR_GTE,
  OPERATOR_EQ,
  OPERATOR_LEFT_PARENTHESIS,
  OPERATOR_RIGHT_PARENTHESIS,
  OPERATOR_IN,
  OPERATOR_NOTIN,
  OPERATOR_NOT,
  OPERATOR_OR,
  OPERATOR_XOR,
  OPERATOR_AND,
} from '../../../constants/formula';
import FormulaToken from './FormulaToken/FormulaToken';
import { useDrop } from 'react-dnd';
import { FORMULA_TOKEN } from '../../../constants/draggableType';
import { bem } from '../../../helpers/styles';

function FormulaInput({
  value,
  onChange,
  placeholder,
  error,
  onAddField,
  withOperators = true,
  withComparison = true,
  withLiterals = true,
  withParenthesis = true,
  overload,
}) {
  const i18n = useTranslate();
  const normalized = Array.isArray(value) ? value : [];
  const [, drop] = useDrop({ accept: FORMULA_TOKEN });

  // Helper to find token index from id
  const findIndex = (id) => normalized.findIndex((item) => item.id === id);

  // Add a token to the formula
  const addToken = useCallback(
    (token) => {
      onChange([
        ...normalized,
        {
          ...token,
          // Create an unique ID for each token
          id: id(),
        },
      ]);
    },
    [normalized, onChange],
  );

  // Splice a token from the formula from index
  const handleDelete = useCallback(
    (index) => {
      onChange(normalized.filter((_, i) => i !== index));
    },
    [normalized],
  );

  // Update token handler
  const handleTokenChange = useCallback(
    (tokenId, changes) => {
      onChange(
        normalized.map((token) => (token.id === tokenId ? { ...token, ...changes } : token)),
      );
    },
    [normalized],
  );

  // Handles swaping two formula tokens
  const handleSwap = useCallback(
    (oldId, newId) => {
      const oldIndex = findIndex(oldId);
      const newIndex = findIndex(newId);
      const valueClone = [...normalized];
      valueClone[oldIndex] = normalized[newIndex];
      valueClone[newIndex] = normalized[oldIndex];
      onChange(valueClone);
    },
    [normalized, findIndex],
  );

  // Toolber handlers for each token type
  const handlers = {
    [LITERAL_NUMBER]: useCallback(() => {
      addToken({
        type: LITERAL_NUMBER,
        value: parseFloat(prompt(i18n(`FormulaInput.prompt.${LITERAL_NUMBER}`))) || 0,
      });
    }, [addToken]),
    [LITERAL_STRING]: useCallback(() => {
      addToken({
        type: LITERAL_STRING,
        value: prompt(i18n(`FormulaInput.prompt.${LITERAL_STRING}`)),
      });
    }, [addToken]),
    [OPERATOR_ADD]: useCallback(() => {
      addToken({
        type: OPERATOR_ADD,
      });
    }, [addToken]),
    [OPERATOR_ADD]: useCallback(() => {
      addToken({
        type: OPERATOR_ADD,
      });
    }, [addToken]),
    [OPERATOR_SUBSTRACT]: useCallback(() => {
      addToken({
        type: OPERATOR_SUBSTRACT,
      });
    }, [addToken]),
    [OPERATOR_MULTIPLY]: useCallback(() => {
      addToken({
        type: OPERATOR_MULTIPLY,
      });
    }, [addToken]),
    [OPERATOR_DIVIDE]: useCallback(() => {
      addToken({
        type: OPERATOR_DIVIDE,
      });
    }, [addToken]),
    [OPERATOR_MODULO]: useCallback(() => {
      addToken({
        type: OPERATOR_MODULO,
      });
    }, [addToken]),
    [OPERATOR_GT]: useCallback(() => {
      addToken({
        type: OPERATOR_GT,
      });
    }, [addToken]),
    [OPERATOR_GTE]: useCallback(() => {
      addToken({
        type: OPERATOR_GTE,
      });
    }, [addToken]),
    [OPERATOR_LT]: useCallback(() => {
      addToken({
        type: OPERATOR_LT,
      });
    }, [addToken]),
    [OPERATOR_LTE]: useCallback(() => {
      addToken({
        type: OPERATOR_LTE,
      });
    }, [addToken]),
    [OPERATOR_EQ]: useCallback(() => {
      addToken({
        type: OPERATOR_EQ,
      });
    }, [addToken]),
    [OPERATOR_NEQ]: useCallback(() => {
      addToken({
        type: OPERATOR_NEQ,
      });
    }, [addToken]),
    [OPERATOR_IN]: useCallback(() => {
      addToken({
        type: OPERATOR_IN,
      });
    }, [addToken]),
    [OPERATOR_NOTIN]: useCallback(() => {
      addToken({
        type: OPERATOR_NOTIN,
      });
    }, [addToken]),
    [OPERATOR_LEFT_PARENTHESIS]: useCallback(() => {
      addToken({
        type: OPERATOR_LEFT_PARENTHESIS,
      });
    }, [addToken]),
    [OPERATOR_RIGHT_PARENTHESIS]: useCallback(() => {
      addToken({
        type: OPERATOR_RIGHT_PARENTHESIS,
      });
    }, [addToken]),
    [OPERATOR_AND]: useCallback(() => {
      addToken({
        type: OPERATOR_AND,
      });
    }, [addToken]),
    [OPERATOR_OR]: useCallback(() => {
      addToken({
        type: OPERATOR_OR,
      });
    }, [addToken]),
    [OPERATOR_XOR]: useCallback(() => {
      addToken({
        type: OPERATOR_XOR,
      });
    }, [addToken]),
    [OPERATOR_NOT]: useCallback(() => {
      addToken({
        type: OPERATOR_NOT,
      });
    }, [addToken]),
  };

  return (
    <div className={bem(styles, 'FormulaInput', { error })}>
      <div className={styles.FormulaInput__toolbar}>
        {withOperators && (
          <FormulaDropdown icon="plus">
            <FormulaDropdown.Item onClick={handlers[OPERATOR_ADD]}>Add</FormulaDropdown.Item>
            <FormulaDropdown.Item onClick={handlers[OPERATOR_SUBSTRACT]}>
              Substract
            </FormulaDropdown.Item>
            <FormulaDropdown.Item onClick={handlers[OPERATOR_MULTIPLY]}>
              Multiply
            </FormulaDropdown.Item>
            <FormulaDropdown.Item onClick={handlers[OPERATOR_DIVIDE]}>Divide</FormulaDropdown.Item>
            <FormulaDropdown.Item onClick={handlers[OPERATOR_MODULO]}>Modulo</FormulaDropdown.Item>
            <FormulaDropdown.Item onClick={handlers[OPERATOR_NOT]}>NOT</FormulaDropdown.Item>
            <FormulaDropdown.Item onClick={handlers[OPERATOR_AND]}>AND</FormulaDropdown.Item>
            <FormulaDropdown.Item onClick={handlers[OPERATOR_OR]}>OR</FormulaDropdown.Item>
            <FormulaDropdown.Item onClick={handlers[OPERATOR_XOR]}>XOR</FormulaDropdown.Item>
          </FormulaDropdown>
        )}
        {withComparison && (
          <FormulaDropdown icon="equal">
            <FormulaDropdown.Item onClick={handlers[OPERATOR_EQ]}>Equal</FormulaDropdown.Item>
            <FormulaDropdown.Item onClick={handlers[OPERATOR_NEQ]}>Not Equal</FormulaDropdown.Item>
            <FormulaDropdown.Item onClick={handlers[OPERATOR_LTE]}>
              Lesser than
            </FormulaDropdown.Item>
            <FormulaDropdown.Item onClick={handlers[OPERATOR_LT]}>
              Strictly lesser than
            </FormulaDropdown.Item>
            <FormulaDropdown.Item onClick={handlers[OPERATOR_GTE]}>
              Greater than
            </FormulaDropdown.Item>
            <FormulaDropdown.Item onClick={handlers[OPERATOR_GT]}>
              Strictly greater than
            </FormulaDropdown.Item>
            <FormulaDropdown.Item onClick={handlers[OPERATOR_IN]}>In</FormulaDropdown.Item>
            <FormulaDropdown.Item onClick={handlers[OPERATOR_NOTIN]}>Not In</FormulaDropdown.Item>
          </FormulaDropdown>
        )}
        {withParenthesis && (
          <FormulaDropdown label="()">
            <FormulaDropdown.Item onClick={handlers[OPERATOR_LEFT_PARENTHESIS]}>
              (
            </FormulaDropdown.Item>
            <FormulaDropdown.Item onClick={handlers[OPERATOR_RIGHT_PARENTHESIS]}>
              )
            </FormulaDropdown.Item>
          </FormulaDropdown>
        )}
        {withLiterals && overload && (
          <FormulaDropdown onClick={handlers[LITERAL_STRING]} label="T"></FormulaDropdown>
        )}
        {withLiterals && !overload && (
          <FormulaDropdown label="T">
            <FormulaDropdown.Item onClick={handlers[LITERAL_STRING]}>String</FormulaDropdown.Item>
            <FormulaDropdown.Item onClick={handlers[LITERAL_NUMBER]}>Number</FormulaDropdown.Item>
          </FormulaDropdown>
        )}
        {onAddField && <FormulaDropdown label="&" onClick={onAddField}></FormulaDropdown>}
      </div>
      <div className={styles.FormulaInput__body} ref={drop}>
        {normalized && normalized.length ? (
          normalized.map((token, i) => (
            <FormulaToken
              key={token.id}
              index={i}
              {...token}
              onSwap={handleSwap}
              onDelete={() => handleDelete(i)}
              onChange={handleTokenChange}
            />
          ))
        ) : (
          <span className={styles.FormulaInput__placeholder}>{placeholder}</span>
        )}
      </div>
    </div>
  );
}

FormulaInput.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  onAddField: PropTypes.func,
  withOperators: PropTypes.bool,
  withComparison: PropTypes.bool,
  withLiterals: PropTypes.bool,
  withParenthesis: PropTypes.bool,
  overload: PropTypes.bool,
};

export default FormulaInput;
