import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import TextField from '../../molecules/Field/Text/TextField';
import Form from '../../atoms/Form/Form';
import DatetimeField from '../../molecules/Field/Datetime/DatetimeField';
import useTranslate from '../../../hooks/useTranslate';
import SelectField from '../../molecules/Field/Select/SelectField';
import SwitchField from '../../molecules/Field/Switch/SwitchField';
import NumberField from '../../molecules/Field/Number/NumberField';

function SettingsForm({ handleSubmit, onBlur, change, disabled, initialValues }) {
  const i18n = useTranslate();
  const [hasSubdivide, setHasSubdivide] = useState(initialValues.hasSubdivide);

  return (
    <Form onSubmit={handleSubmit}>
      <DatetimeField
        onBlur={onBlur}
        label={i18n('SettingsForm.averageDuration')}
        name="averageDuration"
        datetimeFormat="time"
        iconRight="clock"
        disabled={disabled}
      />
      <DatetimeField
        onBlur={onBlur}
        label={i18n('SettingsForm.minimumDuration')}
        name="minimumDuration"
        datetimeFormat="time"
        iconRight="clock"
        disabled={disabled}
      />
      <SwitchField
        label={i18n('SettingsForm.subdivide')}
        name="hasSubdivide"
        onChange={(value) => {
          setHasSubdivide(value);
          if (!value) change('subdivide', 1);
        }}
        onBlur={onBlur}
        disabled={disabled}
      />
      {hasSubdivide && <NumberField name="subdivide" onBlur={onBlur} />}
      <TextField
        label={i18n('SettingsForm.submitLabel')}
        name="submitLabel"
        onBlur={onBlur}
        verticalAlign={false}
        disabled={disabled}
      />
      <SwitchField
        label={i18n('SettingsForm.allowError')}
        name="allowError"
        onBlur={onBlur}
        disabled={disabled}
      />
      <SwitchField
        label={i18n('SettingsForm.enterToValidate')}
        name="enterToValidate"
        onBlur={onBlur}
        disabled={disabled}
      />
      <SwitchField
        label={i18n('SettingsForm.showFormTitle')}
        name="showFormTitle"
        onBlur={onBlur}
        disabled={disabled}
      />
      <NumberField
        label={i18n('SettingsForm.fontSize')}
        name="fontSize"
        onBlur={onBlur}
        disabled={disabled}
      />
      <SwitchField
        label={i18n('SettingsForm.disableBrowserShortcuts')}
        name="disableBrowserShortcuts"
        onBlur={onBlur}
        disabled={disabled}
      />
      <NumberField
        label={i18n('SettingsForm.gridSize')}
        name="gridSize"
        onBlur={onBlur}
        disabled={disabled}
      />
    </Form>
  );
}

SettingsForm.defaultProps = {
  handleSubmit: () => {},
  onBlur: () => {},
  change: () => {},
  initialValues: {},
};

SettingsForm.propTypes = {
  handleSubmit: PropTypes.func,
  onBlur: PropTypes.func,
  change: PropTypes.func,
  initialValues: PropTypes.object,
  disabled: PropTypes.bool,
};

export default reduxForm({
  form: 'settings',
  destroyOnUnmount: false,
})(SettingsForm);
