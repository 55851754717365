export function parseDuration(str) {
  const [strHour, strMinute] = str.split(':');
  return parseInt(strHour, 10) * 60 + parseInt(strMinute, 10);
}

export function parseDurationToSeconds(duration) {
  if (typeof duration !== 'string') return 0;

  const match = duration.match(/(.*):(.*)/);
  if (!match || (match && !match[1] && !match[2])) return 0;
  const hourToSecond = parseInt(match[1]) * 3600;
  const minuteToSecond = parseInt(match[2]) * 60;

  return hourToSecond + minuteToSecond;
}

export function parseSecondsToDuration(seconds) {
  if (typeof seconds !== 'number') return 0;

  return new Date(seconds * 1000).toISOString().substr(11, 8);
}
