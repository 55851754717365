import React from 'react';

// Grid context used to expose to all tree
const GridContext = React.createContext({
  columns: 0,
  columnWidth: 0,
  rowHeight: 0,
  gutter: 0,
  boundingRect: null,
});

export default GridContext;
