import useTranslate from '../hooks/useTranslate';
import { getOneOfCookie } from '../helpers/cookies';
import { getLang } from '../helpers/navigator';
import * as config from '../helpers/config';

export const  countries = () => {
let lng = getOneOfCookie(config.get('i18n.cookie')) || getLang();
  //  const i18n = useTranslate();
let allCountries = [
    { value: 'AD', label: 'Andorre' },
    { value: 'AE', label: 'Émirats Arabes Unis' },
    { value: 'AF', label: 'Afghanistan' },
    { value: 'AG', label: 'Antigua-Et-Barbuda' },
    { value: 'AI', label: 'Anguilla' },
    { value: 'AL', label: 'Albanie' },
    { value: 'AM', label: 'Arménie' },
    { value: 'AO', label: 'Angola' },
    { value: 'AQ', label: 'Antarctique' },
    { value: 'AR', label: 'Argentine' },
    { value: 'AS', label: 'Samoa Américaines' },
    { value: 'AT', label: 'Autriche' },
    { value: 'AU', label: 'Australie' },
    { value: 'AW', label: 'Aruba' },
    { value: 'AX', label: 'Îles Åland' },
    { value: 'AZ', label: 'Azerbaïdjan' },
    { value: 'BA', label: 'Bosnie-Herzégovine' },
    { value: 'BB', label: 'Barbade' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'BE', label: 'Belgique' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BG', label: 'Bulgarie' },
    { value: 'BH', label: 'Bahreïn' },
    { value: 'BI', label: 'Burundi' },
    { value: 'BJ', label: 'Bénin' },
    { value: 'BL', label: 'Saint-Barthélemy' },
    { value: 'BM', label: 'Bermudes' },
    { value: 'BN', label: 'Brunei Darussalam' },
    { value: 'BO', label: 'État Plurinational De Bolivie' },
    { value: 'BQ', label: 'Pays-Bas caribéens' },
    { value: 'BR', label: 'Brésil' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BT', label: 'Bhoutan' },
    { value: 'BV', label: 'Île Bouvet' },
    { value: 'BW', label: 'Botswana' },
    { value: 'BY', label: 'Biélorussie' },
    { value: 'BZ', label: 'Belize' },
    { value: 'CA', label: 'Canada' },
    { value: 'CC', label: 'Îles Cocos' },
    { value: 'CD', label: 'République Démocratique Du Congo' },
    { value: 'CF', label: 'République Centrafricaine' },
    { value: 'CG', label: 'Congo' },
    { value: 'CH', label: 'Suisse' },
    { value: 'CI', label: "Côte D'Ivoire" },
    { value: 'CK', label: 'Îles Cook' },
    { value: 'CL', label: 'Chili' },
    { value: 'CM', label: 'Cameroun' },
    { value: 'CN', label: 'Chine' },
    { value: 'CO', label: 'Colombie' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'CU', label: 'Cuba' },
    { value: 'CV', label: 'Cap-Vert' },
    { value: 'CW', label: 'Curaçao' },
    { value: 'CX', label: 'Île Christmas' },
    { value: 'CY', label: 'Chypre' },
    { value: 'CZ', label: 'Tchéquie' },
    { value: 'DE', label: 'Allemagne' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'DK', label: 'Danemark' },
    { value: 'DM', label: 'Dominique' },
    { value: 'DO', label: 'République Dominicaine' },
    { value: 'DZ', label: 'Algérie' },
    { value: 'EC', label: 'Équateur' },
    { value: 'EE', label: 'Estonie' },
    { value: 'EG', label: 'Égypte' },
    { value: 'EH', label: 'Sahara Occidental' },
    { value: 'ER', label: 'Érythrée' },
    { value: 'ES', label: 'Espagne' },
    { value: 'ET', label: 'Éthiopie' },
    { value: 'FI', label: 'Finlande' },
    { value: 'FJ', label: 'Fidji' },
    { value: 'FK', label: 'Îles Malouines' },
    { value: 'FM', label: 'États Fédérés De Micronésie' },
    { value: 'FO', label: 'Îles Féroé' },
    { value: 'FR', label:  'France' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GB', label: 'Royaume-Uni' },
    { value: 'GD', label: 'Grenade' },
    { value: 'GE', label: 'Géorgie' },
    { value: 'GF', label: 'Guyane' },
    { value: 'GG', label: 'Guernesey' },
    { value: 'GH', label: 'Ghana' },
    { value: 'GI', label: 'Gibraltar' },
    { value: 'GL', label: 'Groenland' },
    { value: 'GM', label: 'Gambie' },
    { value: 'GN', label: 'Guinée' },
    { value: 'GQ', label: 'Guinée Équatoriale' },
    { value: 'GR', label: 'Grèce' },
    { value: 'GS', label: 'Géorgie du Sud-et-les Îles Sandwich du Sud' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'GU', label: 'Guam' },
    { value: 'GW', label: 'Guinée-Bissau' },
    { value: 'GY', label: 'Guyana' },
    { value: 'HK', label: 'Hong Kong' },
    { value: 'HM', label: 'Îles Heard Et McDonald' },
    { value: 'HN', label: 'Honduras' },
    { value: 'HR', label: 'Croatie' },
    { value: 'HT', label: 'Haïti' },
    { value: 'HU', label: 'Hongrie' },
    { value: 'ID', label: 'Indonésie' },
    { value: 'IE', label: 'Irlande' },
    { value: 'IL', label: 'Israël' },
    { value: 'IM', label: 'Île De Man' },
    { value: 'IN', label: 'Inde' },
    { value: 'IO', label: "Territoire Britannique De L'océan Indien" },
    { value: 'IQ', label: 'Irak' },
    { value: 'IR', label: 'Iran' },
    { value: 'IS', label: 'Islande' },
    { value: 'IT', label: 'Italie' },
    { value: 'JE', label: 'Jersey' },
    { value: 'JM', label: 'Jamaïque' },
    { value: 'JO', label: 'Jordanie' },
    { value: 'JP', label: 'Japon' },
    { value: 'KE', label: 'Kenya' },
    { value: 'KG', label: 'Kirghizistan' },
    { value: 'KH', label: 'Cambodge' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'KM', label: 'Comores' },
    { value: 'KN', label: 'Saint-Christophe-et-Niévès' },
    { value: 'KP', label: 'Corée du nord' },
    { value: 'KR', label: 'Corée du sud' },
    { value: 'KW', label: 'Koweït' },
    { value: 'KY', label: 'Îles Caïmans' },
    { value: 'KZ', label: 'Kazakhstan' },
    { value: 'LA', label: 'Laos' },
    { value: 'LB', label: 'Liban' },
    { value: 'LC', label: 'Sainte-Lucie' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'LR', label: 'Liberia' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LT', label: 'Lituanie' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'LV', label: 'Lettonie' },
    { value: 'LY', label: 'Libye' },
    { value: 'MA', label: 'Maroc' },
    { value: 'MC', label: 'Monaco' },
    { value: 'MD', label: 'République De Moldavie' },
    { value: 'ME', label: 'Monténégro' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'MH', label: 'Îles Marshall' },
    { value: 'MK', label: 'Macédoine' },
    { value: 'ML', label: 'Mali' },
    { value: 'MM', label: 'Birmanie' },
    { value: 'MN', label: 'Mongolie' },
    { value: 'MO', label: 'Macao' },
    { value: 'MP', label: 'Îles Mariannes Du Nord' },
    { value: 'MR', label: 'Mauritanie' },
    { value: 'MS', label: 'Montserrat' },
    { value: 'MT', label: 'Malte' },
    { value: 'MU', label: 'Maurice' },
    { value: 'MV', label: 'Maldives' },
    { value: 'MW', label: 'Malawi' },
    { value: 'MX', label: 'Mexique' },
    { value: 'MY', label: 'Malaisie' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'NA', label: 'Namibie' },
    { value: 'NE', label: 'Niger' },
    { value: 'NF', label: 'Île Norfolk' },
    { value: 'NG', label: 'Nigéria' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'NL', label: 'Pays-Bas' },
    { value: 'NO', label: 'Norvège' },
    { value: 'NP', label: 'Népal' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NU', label: 'Niue' },
    { value: 'NZ', label: 'Nouvelle-Zélande' },
    { value: 'OM', label: 'Oman' },
    { value: 'PA', label: 'Panama' },
    { value: 'PE', label: 'Pérou' },
    { value: 'PG', label: 'Papouasie-Nouvelle-Guinée' },
    { value: 'PH', label: 'Philippines' },
    { value: 'PK', label: 'Pakistan' },
    { value: 'PL', label: 'Pologne' },
    { value: 'PN', label: 'Pitcairn' },
    { value: 'PR', label: 'Porto Rico' },
    { value: 'PS', label: 'Palestine' },
    { value: 'PT', label: 'Portugal' },
    { value: 'PW', label: 'Palaos' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'QA', label: 'Qatar' },
    { value: 'RO', label: 'Roumanie' },
    { value: 'RS', label: 'Serbie' },
    { value: 'RU', label: 'Russie' },
    { value: 'RW', label: 'Rwanda' },
    { value: 'SA', label: 'Arabie Saoudite' },
    { value: 'SB', label: 'Îles Salomon' },
    { value: 'SC', label: 'Seychelles' },
    { value: 'SD', label: 'Soudan' },
    { value: 'SE', label: 'Suède' },
    { value: 'SG', label: 'Singapour' },
    { value: 'SH', label: 'Sainte-Hélène' },
    { value: 'SI', label: 'Slovénie' },
    { value: 'SJ', label: 'Svalbard Et Jan Mayen' },
    { value: 'SK', label: 'Slovaquie' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'SM', label: 'Saint-Marin' },
    { value: 'SN', label: 'Sénégal' },
    { value: 'SO', label: 'Somalie' },
    { value: 'SR', label: 'Suriname' },
    { value: 'SS', label: 'Soudan Du Sud' },
    { value: 'ST', label: 'Sao Tomé-Et-Principe' },
    { value: 'SV', label: ' Salvador' },
    { value: 'SX', label: 'Saint-Martin (Partie Néerlandaise)' },
    { value: 'SY', label: 'Syrie' },
    { value: 'SZ', label: 'Swaziland' },
    { value: 'TC', label: 'Îles Turques-et-Caïques' },
    { value: 'TD', label: 'Tchad' },
    { value: 'TF', label: 'Terres Australes Françaises' },
    { value: 'TG', label: 'Togo' },
    { value: 'TH', label: 'Thaïlande' },
    { value: 'TJ', label: 'Tadjikistan' },
    { value: 'TK', label: 'Tokelau' },
    { value: 'TL', label: 'Timor-Leste' },
    { value: 'TM', label: 'Turkménistan' },
    { value: 'TN', label: 'Tunisie' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TR', label: 'Turquie' },
    { value: 'TT', label: 'Trinité-Et-Tobago' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'TW', label: 'Taïwan' },
    { value: 'TZ', label: 'Tanzanie' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'UG', label: 'Ouganda' },
    { value: 'UM', label: 'Îles Mineures Éloignées Des États-Unis' },
    { value: 'US', label: 'États-Unis' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'UZ', label: 'Ouzbékistan' },
    { value: 'VA', label: 'Saint-Siège (État De La Cité Du Vatican)' },
    { value: 'VC', label: 'Saint-Vincent-Et-Les Grenadines' },
    { value: 'VE', label: 'Venezuela' },
    { value: 'VG', label: 'Îles Vierges Britanniques' },
    { value: 'VI', label: 'Îles Vierges Des États-Unis' },
    { value: 'VN', label: 'Viet Nam' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'WS', label: 'Samoa' },
    { value: 'YE', label: 'Yémen' },
    { value: 'YT', label: 'Mayotte' },
    { value: 'ZA', label: ' Afrique Du Sud' },
    { value: 'ZM', label: 'Zambie' },
    { value: 'ZW', label: 'Zimbabwe' },
    { value: 'XX', label: 'Non Renseigné' },
];
switch (lng) {
    case 'fr-FR':
        allCountries = [
            { value: 'AD', label: 'Andorre' },
            { value: 'AE', label: 'Émirats Arabes Unis' },
            { value: 'AF', label: 'Afghanistan' },
            { value: 'AG', label: 'Antigua-Et-Barbuda' },
            { value: 'AI', label: 'Anguilla' },
            { value: 'AL', label: 'Albanie' },
            { value: 'AM', label: 'Arménie' },
            { value: 'AO', label: 'Angola' },
            { value: 'AQ', label: 'Antarctique' },
            { value: 'AR', label: 'Argentine' },
            { value: 'AS', label: 'Samoa Américaines' },
            { value: 'AT', label: 'Autriche' },
            { value: 'AU', label: 'Australie' },
            { value: 'AW', label: 'Aruba' },
            { value: 'AX', label: 'Îles Åland' },
            { value: 'AZ', label: 'Azerbaïdjan' },
            { value: 'BA', label: 'Bosnie-Herzégovine' },
            { value: 'BB', label: 'Barbade' },
            { value: 'BD', label: 'Bangladesh' },
            { value: 'BE', label: 'Belgique' },
            { value: 'BF', label: 'Burkina Faso' },
            { value: 'BG', label: 'Bulgarie' },
            { value: 'BH', label: 'Bahreïn' },
            { value: 'BI', label: 'Burundi' },
            { value: 'BJ', label: 'Bénin' },
            { value: 'BL', label: 'Saint-Barthélemy' },
            { value: 'BM', label: 'Bermudes' },
            { value: 'BN', label: 'Brunei Darussalam' },
            { value: 'BO', label: 'État Plurinational De Bolivie' },
            { value: 'BQ', label: 'Pays-Bas caribéens' },
            { value: 'BR', label: 'Brésil' },
            { value: 'BS', label: 'Bahamas' },
            { value: 'BT', label: 'Bhoutan' },
            { value: 'BV', label: 'Île Bouvet' },
            { value: 'BW', label: 'Botswana' },
            { value: 'BY', label: 'Biélorussie' },
            { value: 'BZ', label: 'Belize' },
            { value: 'CA', label: 'Canada' },
            { value: 'CC', label: 'Îles Cocos' },
            { value: 'CD', label: 'République Démocratique Du Congo' },
            { value: 'CF', label: 'République Centrafricaine' },
            { value: 'CG', label: 'Congo' },
            { value: 'CH', label: 'Suisse' },
            { value: 'CI', label: "Côte D'Ivoire" },
            { value: 'CK', label: 'Îles Cook' },
            { value: 'CL', label: 'Chili' },
            { value: 'CM', label: 'Cameroun' },
            { value: 'CN', label: 'Chine' },
            { value: 'CO', label: 'Colombie' },
            { value: 'CR', label: 'Costa Rica' },
            { value: 'CU', label: 'Cuba' },
            { value: 'CV', label: 'Cap-Vert' },
            { value: 'CW', label: 'Curaçao' },
            { value: 'CX', label: 'Île Christmas' },
            { value: 'CY', label: 'Chypre' },
            { value: 'CZ', label: 'Tchéquie' },
            { value: 'DE', label: 'Allemagne' },
            { value: 'DJ', label: 'Djibouti' },
            { value: 'DK', label: 'Danemark' },
            { value: 'DM', label: 'Dominique' },
            { value: 'DO', label: 'République Dominicaine' },
            { value: 'DZ', label: 'Algérie' },
            { value: 'EC', label: 'Équateur' },
            { value: 'EE', label: 'Estonie' },
            { value: 'EG', label: 'Égypte' },
            { value: 'EH', label: 'Sahara Occidental' },
            { value: 'ER', label: 'Érythrée' },
            { value: 'ES', label: 'Espagne' },
            { value: 'ET', label: 'Éthiopie' },
            { value: 'FI', label: 'Finlande' },
            { value: 'FJ', label: 'Fidji' },
            { value: 'FK', label: 'Îles Malouines' },
            { value: 'FM', label: 'États Fédérés De Micronésie' },
            { value: 'FO', label: 'Îles Féroé' },
            { value: 'FR', label:  'France' },
            { value: 'GA', label: 'Gabon' },
            { value: 'GB', label: 'Royaume-Uni' },
            { value: 'GD', label: 'Grenade' },
            { value: 'GE', label: 'Géorgie' },
            { value: 'GF', label: 'Guyane' },
            { value: 'GG', label: 'Guernesey' },
            { value: 'GH', label: 'Ghana' },
            { value: 'GI', label: 'Gibraltar' },
            { value: 'GL', label: 'Groenland' },
            { value: 'GM', label: 'Gambie' },
            { value: 'GN', label: 'Guinée' },
            { value: 'GQ', label: 'Guinée Équatoriale' },
            { value: 'GR', label: 'Grèce' },
            { value: 'GS', label: 'Géorgie du Sud-et-les Îles Sandwich du Sud' },
            { value: 'GT', label: 'Guatemala' },
            { value: 'GU', label: 'Guam' },
            { value: 'GW', label: 'Guinée-Bissau' },
            { value: 'GY', label: 'Guyana' },
            { value: 'HK', label: 'Hong Kong' },
            { value: 'HM', label: 'Îles Heard Et McDonald' },
            { value: 'HN', label: 'Honduras' },
            { value: 'HR', label: 'Croatie' },
            { value: 'HT', label: 'Haïti' },
            { value: 'HU', label: 'Hongrie' },
            { value: 'ID', label: 'Indonésie' },
            { value: 'IE', label: 'Irlande' },
            { value: 'IL', label: 'Israël' },
            { value: 'IM', label: 'Île De Man' },
            { value: 'IN', label: 'Inde' },
            { value: 'IO', label: "Territoire Britannique De L'océan Indien" },
            { value: 'IQ', label: 'Irak' },
            { value: 'IR', label: 'Iran' },
            { value: 'IS', label: 'Islande' },
            { value: 'IT', label: 'Italie' },
            { value: 'JE', label: 'Jersey' },
            { value: 'JM', label: 'Jamaïque' },
            { value: 'JO', label: 'Jordanie' },
            { value: 'JP', label: 'Japon' },
            { value: 'KE', label: 'Kenya' },
            { value: 'KG', label: 'Kirghizistan' },
            { value: 'KH', label: 'Cambodge' },
            { value: 'KI', label: 'Kiribati' },
            { value: 'KI', label: 'Kiribati' },
            { value: 'KM', label: 'Comores' },
            { value: 'KN', label: 'Saint-Christophe-et-Niévès' },
            { value: 'KP', label: 'Corée du nord' },
            { value: 'KR', label: 'Corée du sud' },
            { value: 'KW', label: 'Koweït' },
            { value: 'KY', label: 'Îles Caïmans' },
            { value: 'KZ', label: 'Kazakhstan' },
            { value: 'LA', label: 'Laos' },
            { value: 'LB', label: 'Liban' },
            { value: 'LC', label: 'Sainte-Lucie' },
            { value: 'LI', label: 'Liechtenstein' },
            { value: 'LK', label: 'Sri Lanka' },
            { value: 'LR', label: 'Liberia' },
            { value: 'LS', label: 'Lesotho' },
            { value: 'LT', label: 'Lituanie' },
            { value: 'LU', label: 'Luxembourg' },
            { value: 'LV', label: 'Lettonie' },
            { value: 'LY', label: 'Libye' },
            { value: 'MA', label: 'Maroc' },
            { value: 'MC', label: 'Monaco' },
            { value: 'MD', label: 'République De Moldavie' },
            { value: 'ME', label: 'Monténégro' },
            { value: 'MG', label: 'Madagascar' },
            { value: 'MH', label: 'Îles Marshall' },
            { value: 'MK', label: 'Macédoine' },
            { value: 'ML', label: 'Mali' },
            { value: 'MM', label: 'Birmanie' },
            { value: 'MN', label: 'Mongolie' },
            { value: 'MO', label: 'Macao' },
            { value: 'MP', label: 'Îles Mariannes Du Nord' },
            { value: 'MR', label: 'Mauritanie' },
            { value: 'MS', label: 'Montserrat' },
            { value: 'MT', label: 'Malte' },
            { value: 'MU', label: 'Maurice' },
            { value: 'MV', label: 'Maldives' },
            { value: 'MW', label: 'Malawi' },
            { value: 'MX', label: 'Mexique' },
            { value: 'MY', label: 'Malaisie' },
            { value: 'MZ', label: 'Mozambique' },
            { value: 'NA', label: 'Namibie' },
            { value: 'NE', label: 'Niger' },
            { value: 'NF', label: 'Île Norfolk' },
            { value: 'NG', label: 'Nigéria' },
            { value: 'NI', label: 'Nicaragua' },
            { value: 'NL', label: 'Pays-Bas' },
            { value: 'NO', label: 'Norvège' },
            { value: 'NP', label: 'Népal' },
            { value: 'NR', label: 'Nauru' },
            { value: 'NU', label: 'Niue' },
            { value: 'NZ', label: 'Nouvelle-Zélande' },
            { value: 'OM', label: 'Oman' },
            { value: 'PA', label: 'Panama' },
            { value: 'PE', label: 'Pérou' },
            { value: 'PG', label: 'Papouasie-Nouvelle-Guinée' },
            { value: 'PH', label: 'Philippines' },
            { value: 'PK', label: 'Pakistan' },
            { value: 'PL', label: 'Pologne' },
            { value: 'PN', label: 'Pitcairn' },
            { value: 'PR', label: 'Porto Rico' },
            { value: 'PS', label: 'Palestine' },
            { value: 'PT', label: 'Portugal' },
            { value: 'PW', label: 'Palaos' },
            { value: 'PY', label: 'Paraguay' },
            { value: 'QA', label: 'Qatar' },
            { value: 'RO', label: 'Roumanie' },
            { value: 'RS', label: 'Serbie' },
            { value: 'RU', label: 'Russie' },
            { value: 'RW', label: 'Rwanda' },
            { value: 'SA', label: 'Arabie Saoudite' },
            { value: 'SB', label: 'Îles Salomon' },
            { value: 'SC', label: 'Seychelles' },
            { value: 'SD', label: 'Soudan' },
            { value: 'SE', label: 'Suède' },
            { value: 'SG', label: 'Singapour' },
            { value: 'SH', label: 'Sainte-Hélène' },
            { value: 'SI', label: 'Slovénie' },
            { value: 'SJ', label: 'Svalbard Et Jan Mayen' },
            { value: 'SK', label: 'Slovaquie' },
            { value: 'SL', label: 'Sierra Leone' },
            { value: 'SM', label: 'Saint-Marin' },
            { value: 'SN', label: 'Sénégal' },
            { value: 'SO', label: 'Somalie' },
            { value: 'SR', label: 'Suriname' },
            { value: 'SS', label: 'Soudan Du Sud' },
            { value: 'ST', label: 'Sao Tomé-Et-Principe' },
            { value: 'SV', label: ' Salvador' },
            { value: 'SX', label: 'Saint-Martin (Partie Néerlandaise)' },
            { value: 'SY', label: 'Syrie' },
            { value: 'SZ', label: 'Swaziland' },
            { value: 'TC', label: 'Îles Turques-et-Caïques' },
            { value: 'TD', label: 'Tchad' },
            { value: 'TF', label: 'Terres Australes Françaises' },
            { value: 'TG', label: 'Togo' },
            { value: 'TH', label: 'Thaïlande' },
            { value: 'TJ', label: 'Tadjikistan' },
            { value: 'TK', label: 'Tokelau' },
            { value: 'TL', label: 'Timor-Leste' },
            { value: 'TM', label: 'Turkménistan' },
            { value: 'TN', label: 'Tunisie' },
            { value: 'TO', label: 'Tonga' },
            { value: 'TR', label: 'Turquie' },
            { value: 'TT', label: 'Trinité-Et-Tobago' },
            { value: 'TV', label: 'Tuvalu' },
            { value: 'TW', label: 'Taïwan' },
            { value: 'TZ', label: 'Tanzanie' },
            { value: 'UA', label: 'Ukraine' },
            { value: 'UG', label: 'Ouganda' },
            { value: 'UM', label: 'Îles Mineures Éloignées Des États-Unis' },
            { value: 'US', label: 'États-Unis' },
            { value: 'UY', label: 'Uruguay' },
            { value: 'UZ', label: 'Ouzbékistan' },
            { value: 'VA', label: 'Saint-Siège (État De La Cité Du Vatican)' },
            { value: 'VC', label: 'Saint-Vincent-Et-Les Grenadines' },
            { value: 'VE', label: 'Venezuela' },
            { value: 'VG', label: 'Îles Vierges Britanniques' },
            { value: 'VI', label: 'Îles Vierges Des États-Unis' },
            { value: 'VN', label: 'Viet Nam' },
            { value: 'VU', label: 'Vanuatu' },
            { value: 'WS', label: 'Samoa' },
            { value: 'YE', label: 'Yémen' },
            { value: 'YT', label: 'Mayotte' },
            { value: 'ZA', label: ' Afrique Du Sud' },
            { value: 'ZM', label: 'Zambie' },
            { value: 'ZW', label: 'Zimbabwe' },
            { value: 'XX', label: 'Non Renseigné' },
        ];
        break;
    case 'en':
        allCountries = [
            { value: 'AD', label: 'Andorra' },
            { value: 'AE', label: 'United Arab Emirates' },
            { value: 'AF', label: 'Afganistán' },
            { value: 'AG', label: 'Antigua and Barbuda	' },
            { value: 'AI', label: 'Anguilla' },
            { value: 'AL', label: 'Albania' },
            { value: 'AM', label: 'Armenia' },
            { value: 'AO', label: 'angola' },
            { value: 'AQ', label: 'Antártida' },
            { value: 'AR', label: 'Argentina' },
            { value: 'AS', label: 'American Samoa' },
            { value: 'AT', label: 'Austria' },
            { value: 'AU', label: 'Australia' },
            { value: 'AW', label: 'Aruba' },
            { value: 'AX', label: 'Åland Islands' },
            { value: 'AZ', label: 'Azerbaijan' },
            { value: 'BA', label: 'Bosnia and Herzegovina' },
            { value: 'BB', label: 'Barbados' },
            { value: 'BD', label: 'Bangladesh' },
            { value: 'BE', label: 'Belgium' },
            { value: 'BF', label: 'Burkina Faso' },
            { value: 'BG', label: 'Bulgaria' },
            { value: 'BH', label: 'Bahrain' },
            { value: 'BI', label: 'Burundi' },
            { value: 'BJ', label: 'Benin' },
            { value: 'BL', label: 'Saint Barthélemy' },
            { value: 'BM', label: 'Bermuda' },
            { value: 'BN', label: 'Brunei Darussalam' },
            { value: 'BO', label: 'Bolivia (Plurinational State of)' },
            { value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba' },
            { value: 'BR', label: 'Brazil' },
            { value: 'BS', label: 'Bahamas ' },
            { value: 'BT', label: 'Bhutan' },
            { value: 'BV', label: 'Bouvet Island' },
            { value: 'BW', label: 'Botswana' },
            { value: 'BY', label: 'Belarus' },
            { value: 'BZ', label: 'Belize' },
            { value: 'CA', label: 'Canada' },
            { value: 'CC', label: 'Cocos (Keeling) Islands' },
            { value: 'CD', label: 'Congo (the Democratic Republic of the)' },
            { value: 'CF', label: 'Central African Republic' },
            { value: 'CG', label: 'Congo' },
            { value: 'CH', label: 'Switzerland' },
            { value: 'CI', label: "Côte D'Ivoire" },
            { value: 'CK', label: 'Cook Islands' },
            { value: 'CL', label: 'Chile' },
            { value: 'CM', label: 'Cameroon' },
            { value: 'CN', label: 'China' },
            { value: 'CO', label: 'Colombia' },
            { value: 'CR', label: 'Costa Rica' },
            { value: 'CU', label: 'Cuba' },
            { value: 'CV', label: 'Cabo Verde' },
            { value: 'CW', label: 'Curaçao' },
            { value: 'CX', label: 'Christmas Island' },
            { value: 'CY', label: 'Cyprus' },
            { value: 'CZ', label: 'Czechia' },
            { value: 'DE', label: 'Germany' },
            { value: 'DJ', label: 'Djibouti' },
            { value: 'DK', label: 'Denmark' },
            { value: 'DM', label: 'Dominica' },
            { value: 'DO', label: 'Dominican Republic' },
            { value: 'DZ', label: 'Algeria' },
            { value: 'EC', label: 'Ecuador' },
            { value: 'EE', label: 'Estonia' },
            { value: 'EG', label: 'Egypt' },
            { value: 'EH', label: 'Western Sahara' },
            { value: 'ER', label: 'Eritrea' },
            { value: 'ES', label: 'Spain' },
            { value: 'ET', label: 'Ethiopia' },
            { value: 'FI', label: 'Finland' },
            { value: 'FJ', label: 'Fiji' },
            { value: 'FK', label: 'Falkland Islands' },
            { value: 'FM', label: 'Micronesia' },
            { value: 'FO', label: 'Faroe Islands' },
            { value: 'FR', label:  'France' },
            { value: 'GA', label: 'Gabon' },
            { value: 'GB', label: 'United Kingdom of Great Britain' },
            { value: 'GD', label: 'Grenada' },
            { value: 'GE', label: 'Georgia' },
            { value: 'GF', label: 'French Guiana' },
            { value: 'GG', label: 'Guernsey' },
            { value: 'GH', label: 'Ghana' },
            { value: 'GI', label: 'Gibraltar' },
            { value: 'GL', label: 'Greenland' },
            { value: 'GM', label: 'Gambia ' },
            { value: 'GN', label: 'Guinea' },
            { value: 'GQ', label: 'Equatorial Guinea' },
            { value: 'GR', label: 'Greece' },
            { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
            { value: 'GT', label: 'Guatemala' },
            { value: 'GU', label: 'Guam' },
            { value: 'GW', label: 'Guinea-Bissau' },
            { value: 'GY', label: 'Guyana' },
            { value: 'HK', label: 'Hong Kong' },
            { value: 'HM', label: 'Heard Island and McDonald Islands' },
            { value: 'HN', label: 'Honduras' },
            { value: 'HR', label: 'Croatia' },
            { value: 'HT', label: 'Haiti' },
            { value: 'HU', label: 'Hungary' },
            { value: 'ID', label: 'Indonesia' },
            { value: 'IE', label: 'Ireland' },
            { value: 'IL', label: 'Israel' },
            { value: 'IM', label: 'Isle of Man' },
            { value: 'IN', label: 'India' },
            { value: 'IO', label: "British Indian Ocean Territory" },
            { value: 'IQ', label: 'Irak' },
            { value: 'IR', label: 'Iran' },
            { value: 'IS', label: 'Iceland' },
            { value: 'IT', label: 'Italy' },
            { value: 'JE', label: 'Jersey' },
            { value: 'JM', label: 'Jamaica' },
            { value: 'JO', label: 'Jordan' },
            { value: 'JP', label: 'Japan' },
            { value: 'KE', label: 'Kenya' },
            { value: 'KG', label: 'Kyrgyzstan' },
            { value: 'KH', label: 'Cambodia' },
            { value: 'KI', label: 'Kiribati' },
            { value: 'KM', label: 'Comoros ' },
            { value: 'KN', label: 'Saint Kitts and Nevis' },
            { value: 'KP', label: 'North Korea' },
            { value: 'KR', label: 'South Korea' },
            { value: 'KW', label: 'Kuwait' },
            { value: 'KY', label: 'Cayman Islands' },
            { value: 'KZ', label: 'Kazakhstan' },
            { value: 'LA', label: 'Laos' },
            { value: 'LB', label: 'Lebanon' },
            { value: 'LC', label: 'Saint Lucia' },
            { value: 'LI', label: 'Liechtenstein' },
            { value: 'LK', label: 'Sri Lanka' },
            { value: 'LR', label: 'Liberia' },
            { value: 'LS', label: 'Lesotho' },
            { value: 'LT', label: 'Lithuania' },
            { value: 'LU', label: 'Luxembourg' },
            { value: 'LV', label: 'Latvia' },
            { value: 'LY', label: 'Libya' },
            { value: 'MA', label: 'Morocco' },
            { value: 'MC', label: 'Monaco' },
            { value: 'MD', label: 'Moldova ' },
            { value: 'ME', label: 'Montenegro' },
            { value: 'MG', label: 'Madagascar' },
            { value: 'MH', label: 'Marshall Islands' },
            { value: 'MK', label: 'Republic of North Macedonia' },
            { value: 'ML', label: 'Mali' },
            { value: 'MM', label: 'Myanmar' },
            { value: 'MN', label: 'Mongolia' },
            { value: 'MO', label: 'Macao' },
            { value: 'MP', label: 'Northern Mariana Islands' },
            { value: 'MR', label: 'Mauritania' },
            { value: 'MS', label: 'Montserrat' },
            { value: 'MT', label: 'Malta' },
            { value: 'MU', label: 'Mauritius' },
            { value: 'MV', label: 'Maldives' },
            { value: 'MW', label: 'Malawi' },
            { value: 'MX', label: 'Mexico' },
            { value: 'MY', label: 'Malaysia' },
            { value: 'MZ', label: 'Mozambique' },
            { value: 'NA', label: 'Namibia' },
            { value: 'NE', label: 'Niger' },
            { value: 'NF', label: 'Norfolk Island' },
            { value: 'NG', label: 'Nigeria' },
            { value: 'NI', label: 'Nicaragua' },
            { value: 'NL', label: 'Netherlands ' },
            { value: 'NO', label: 'Norway' },
            { value: 'NP', label: 'Nepal' },
            { value: 'NR', label: 'Nauru' },
            { value: 'NU', label: 'Niue' },
            { value: 'NZ', label: 'New Zealand' },
            { value: 'OM', label: 'Oman' },
            { value: 'PA', label: 'Panama' },
            { value: 'PE', label: 'Peru' },
            { value: 'PG', label: 'Papua New Guinea' },
            { value: 'PH', label: 'Philippines' },
            { value: 'PK', label: 'Pakistan' },
            { value: 'PL', label: 'Poland' },
            { value: 'PN', label: 'Pitcairn' },
            { value: 'PR', label: 'Puerto Rico' },
            { value: 'PS', label: 'Palestine' },
            { value: 'PT', label: 'Portugal' },
            { value: 'PW', label: 'Palau' },
            { value: 'PY', label: 'Paraguay' },
            { value: 'QA', label: 'Qatar' },
            { value: 'RO', label: 'Romania' },
            { value: 'RS', label: 'Serbia' },
            { value: 'RU', label: 'Russia' },
            { value: 'RW', label: 'Rwanda' },
            { value: 'SA', label: 'Saudi Arabia' },
            { value: 'SB', label: 'Solomon Islands' },
            { value: 'SC', label: 'Seychelles' },
            { value: 'SD', label: 'Sudan ' },
            { value: 'SE', label: 'Sweden' },
            { value: 'SG', label: 'Singapore' },
            { value: 'SH', label: 'Saint Helena' },
            { value: 'SI', label: 'Slovenia' },
            { value: 'SJ', label: 'Svalbard and Jan Mayen' },
            { value: 'SK', label: 'Slovakia' },
            { value: 'SL', label: 'Sierra Leone' },
            { value: 'SM', label: 'San Marino' },
            { value: 'SN', label: 'Senegal' },
            { value: 'SO', label: 'Somalia' },
            { value: 'SR', label: 'Suriname' },
            { value: 'SS', label: 'South Sudan' },
            { value: 'ST', label: 'Sao Tome and Principe' },
            { value: 'SV', label: ' El Salvador' },
            { value: 'SX', label: 'Sint Maarten (Dutch part)' },
            { value: 'SY', label: 'Syrian Arab Republic' },
            { value: 'SZ', label: 'Eswatini' },
            { value: 'TC', label: 'Turks and Caicos Islands' },
            { value: 'TD', label: 'Chad' },
            { value: 'TF', label: 'French Southern Territories' },
            { value: 'TG', label: 'Togo' },
            { value: 'TH', label: 'Thailand' },
            { value: 'TJ', label: 'Tajikistan' },
            { value: 'TK', label: 'Tokelau' },
            { value: 'TL', label: 'Timor-Leste' },
            { value: 'TM', label: 'Turkmenistan' },
            { value: 'TN', label: 'Tunisia' },
            { value: 'TO', label: 'Tonga' },
            { value: 'TR', label: 'Turkey' },
            { value: 'TT', label: 'Trinidad and Tobago' },
            { value: 'TV', label: 'Tuvalu' },
            { value: 'TW', label: 'Taiwan ' },
            { value: 'TZ', label: 'Tanzania' },
            { value: 'UA', label: 'Ukraine' },
            { value: 'UG', label: 'Uganda' },
            { value: 'UM', label: 'United States Minor Outlying Islands' },
            { value: 'US', label: 'United States of America' },
            { value: 'UY', label: 'Uruguay' },
            { value: 'UZ', label: 'Uzbekistan' },
            { value: 'VA', label: 'Vanuatu)' },
            { value: 'VC', label: 'Saint Vincent and the Grenadines' },
            { value: 'VE', label: 'Venezuela ' },
            { value: 'VG', label: 'Virgin Islands (British)' },
            { value: 'VI', label: 'Virgin Islands (US)' },
            { value: 'VN', label: 'Viet Nam' },
            { value: 'VU', label: 'Vanuatu' },
            { value: 'WS', label: 'Samoa' },
            { value: 'YE', label: 'Yemen' },
            { value: 'YT', label: 'Mayotte' },
            { value: 'ZA', label: ' South Africa' },
            { value: 'ZM', label: 'Zambia' },
            { value: 'ZW', label: 'Zimbabwe' },
            { value: 'XX', label: 'Not specified' },
        ];
        break;
        case 'es':
            allCountries = [{ value: 'AF', label: 'Afganistán' },
            { value: 'AL', label: 'Albania' },
            { value: 'DZ', label: 'Argelia' },
            { value: 'AS', label: 'Andorra' },
            { value: 'AO', label: 'angola' },
            { value: 'AI', label: 'anguila' },
            { value: 'AQ', label: 'Antártida' },
            { value: 'AG', label: 'Antigua y Barbuda' },
            { value: 'AR', label: 'Argentina' },
            { value: 'AM', label: 'Armenia' },
            { value: 'AW', label: 'Aruba' },
            { value: 'AU', label: 'Australia' },
            { value: 'AT', label: 'Austria' },
            { value: 'AZ', label: 'Azerbaiyán' },
            { value: 'BS', label: 'bahamas' },
            { value: 'BH', label: 'Baréin' },
            { value: 'BD', label: 'bangladesh' },
            { value: 'BB', label: 'barbados' },
            { value: 'BY', label: 'Bielorrusia' },
            { value: 'BE', label: 'Bélgica' },
            { value: 'BZ', label: 'Belice' },
            { value: 'BJ', label: 'Benín' },
            { value: 'BM', label: 'Bermudas BMU' },
            { value: 'BT', label: 'Bután' },
            { value: 'BO', label: 'Bolivia (Estado Plurinacional de)' },
            { value: 'BQ', label: 'Bonaire, San Eustaquio y Saba' },
            { value: 'BA', label: 'Bosnia y Herzegovina' },
            { value: 'BW', label: 'Botsuana' },
            { value: 'BV', label: 'Isla Bouvet' },
            { value: 'BR', label: 'Brasil' },
            { value: 'IO', label: 'Territorio Británico del Océano Índico' },
            { value: 'BN', label: 'Brunei Darussalam' },
            { value: 'BG', label: 'Bulgaria' },
            { value: 'BF', label: 'Burkina Faso' },
            { value: 'BI', label: 'Burundi' },
            { value: 'CV', label: 'Cabo Verde' },
            { value: 'KH', label: 'Camboya' },
            { value: 'CM', label: 'Camerún' },
            { value: 'CA', label: 'Canadá' },
            { value: 'KY', label: 'Islas Caimán' },
            { value: 'CF', label: 'República Centroafricana' },
            { value: 'TD', label: 'Chad' },
            { value: 'CL', label: 'Chile' },
            { value: 'CN', label: 'Porcelana' },
            { value: 'CX', label: 'Isla de Navidad' },
            { value: 'CC', label: 'Islas Cocos (Keeling) (las)' },
            { value: 'CO', label: 'Colombia' },
            { value: 'KM', label: 'Comoras (las)' },
            { value: 'CD', label: 'Congo (República Democrática del)' },
            { value: 'CG', label: 'Congo (el)' },
            { value: 'CK', label: 'Islas Cook' },
            { value: 'CR', label: 'Costa Rica' },
            { value: 'HR', label: 'Croacia' },
            { value: 'CU', label:'Cuba' },
            { value: 'CW', label: 'curazao' },
            { value: 'CY', label: 'Chipre' },
            { value: 'CZ', label: 'Chequia' },
            { value: 'CI', label: 'costa de marfil' },
            { value: 'DK', label: 'Dinamarca' },
            { value: 'DJ', label: 'Yibuti' },
            { value: 'DM', label: 'Dominica' },
            { value: 'DO', label: 'República Dominicana' },
            { value: 'EC', label: 'Ecuador' },
            { value: 'EG', label: 'Egipto' },
            { value: 'SV', label: 'El Salvador' },
            { value: 'GQ', label: 'Ecuatorial Guinea' },
            { value: 'ER', label: 'Eritrea' },
            { value: 'EE', label: 'Estonia' },
            { value: 'SZ', label: 'Esuatini' },
            { value: 'ET', label: 'Etiopía' },
            { value: 'FK', label: 'Islas Malvinas (las) [Malvinas]' },
            { value: 'FO', label: 'Islas Faroe' },
            { value: 'FJ', label: 'Fiyi' },
            { value: 'FI', label: 'Finlandia' },
            { value: 'FR', label: 'Francia' },
            { value: 'GF', label: 'Guayana Francesa' },
            { value: 'PF', label: 'Polinesia francés' },
            { value: 'TF', label: 'Territorios Australes Franceses (los)' },
            { value: 'GA', label: 'Gabón' },
            { value: 'GM', label: 'Gambia' },
            { value: 'GE', label: 'Georgia' },
            { value: 'DE', label: 'Alemania' },
            { value: 'GH', label: 'Ghana' },
            { value: 'GI', label: 'Gibraltar' },
            { value: 'GR', label: 'Grecia' },
            { value: 'GL', label: 'Groenlandia' },
            { value: 'GD', label: 'Granada' },
            { value: 'GP', label: 'Guadalupe' },
            { value: 'GU', label: 'Guam' },
            { value: 'GT', label: 'Guatemala' },
            { value: 'GG', label:'Guernesey' },
            { value: 'GN', label: 'Guinea' },
            { value: 'GW', label: 'Guinea-Bisáu' },
            { value: 'GY', label: 'Guayana' },
            { value: 'HT', label: 'Haití' },
            { value: 'HM', label: 'Islas Heard y McDonald' },
            { value: 'VA', label: 'Santa Sede' },
            { value: 'HN', label: 'Honduras' },
            { value: 'HK', label: 'Hong Kong' },
            { value: 'HU', label: 'Hungría' },
            { value: 'IS', label: 'Islandia' },
            { value: 'IN', label: 'India' },
            { value: 'ID', label: 'Indonesia' },
            { value: 'IR', label: 'Irán (República Islámica de)' },
            { value: 'IQ', label: 'Irak' },
            { value: 'IE', label: 'Irlanda' },
            { value: 'IM', label: 'Isla del hombre' },
            { value: 'IL', label: 'Israel' },
            { value: 'IT', label: 'Italia' },
            { value: 'JM', label: 'Jamaica' },
            { value: 'JP', label: 'Japón' },
            { value: 'JE', label: 'Jersey' },
            { value: 'JO', label: 'Jordán' },
            { value: 'KZ', label: 'Kazajistán' },
            { value: 'KE', label: 'Kenia' },
            { value: 'KI', label: 'Kiribati' },
            { value: 'KP', label: 'Corea (República Popular Democrática de)' },
            { value: 'KR', label: 'Corea (República de)' },
            { value: 'KW', label: 'Kuwait' },
            { value: 'KG', label: 'Kirguistán' },
            { value: 'LA', label: 'República Democrática Popular Lao (la)' },
            { value: 'LV', label: 'letonia' },
            { value: 'LB', label: 'Líbano' },
            { value: 'LS', label: 'Lesoto' },
            { value: 'LR', label: 'Liberia' },
            { value: 'LY', label: 'Libia' },
            { value: 'LI', label: 'Liechtenstein' },
            { value: 'LT', label: 'Lituania' },
            { value: 'LU', label: 'luxemburgo' },
            { value: 'MO', label: 'Macao' },
            { value: 'MG', label: 'Madagascar' },
            { value: 'MW', label: 'Malaui' },
            { value: 'MY', label: 'Malasia' },
            { value: 'MV', label: 'Maldivas' },
            { value: 'ML', label: 'Malí' },
            { value: 'MT', label: 'Malta' },
            { value: 'MH', label: 'Islas Marshall' },
            { value: 'MQ', label: 'Martinica' },
            { value: 'MR', label: 'Mauritania' },
            { value: 'MU', label: 'Mauricio' },
            { value: 'YT', label: 'Mayotte' },
            { value: 'MX', label: 'México' },
            { value: 'FM', label: 'Micronesia (Estados Federados de)' },
            { value: 'MD', label: 'Moldavia (la República de)' },
            { value: 'MC', label: 'Mónaco' },
            { value: 'MN', label: 'Mongolia' },
            { value: 'ME', label: 'montenegro' },
            { value: 'MS', label: 'Montserrat' },
            { value: 'MA', label: 'Marruecos' },
            { value: 'MZ', label: 'Mozambique' },
            { value: 'MM', label: 'Birmania' },
            { value: 'NA', label: 'Namibia' },
            { value: 'NR', label: 'Nauru' },
            { value: 'NP', label: 'Nepal' },
            { value: 'NL', label: 'Países Bajos (los)' },
            { value: 'NC', label: 'Nueva Caledonia' },
            { value: 'NZ', label: 'Nueva Zelanda' },
            { value: 'NI', label: 'Nicaragua' },
            { value: 'NE', label: 'Níger (el)' },
            { value: 'NG', label: 'Nigeria' },
            { value: 'NU', label: 'Niue' },
            { value: 'NF', label: 'Isla Norfolk' },
            { value: 'MP', label: 'Islas Marianas del Norte (las)' },
            { value: 'NO', label: 'Noruega' },
            { value: 'OM', label: 'Omán' },
            { value: 'PK', label: 'Pakistán' },
            { value: 'PW', label: 'palaos' },
            { value: 'PS', label: 'Palestina, Estado de' },
            { value: 'PA', label: 'Panamá' },
            { value: 'PG', label: 'Papúa Nueva Guinea' },
            { value: 'PY', label: 'Paraguay' },
            { value: 'PE', label: 'Perú' },
            { value: 'PH', label: 'Filipinas' },
            { value: 'PN', label: 'pitcairn' },
            { value: 'PL', label: 'Polonia' },
            { value: 'PT', label: 'Portugal' },
            { value: 'PR', label: 'Puerto Rico' },
            { value: 'QA', label: 'Katar' },
            { value: 'MK', label: 'República de Macedonia del Norte' },
            { value: 'RO', label: 'Rumania' },
            { value: 'RU', label: 'Federación Rusa' },
            { value: 'RW', label: 'Ruanda' },
            { value: 'RE', label: 'Reunión' },
            { value: 'BL', label: 'San Bartolomé' },
            { value: 'SH', label: 'Santa Elena, Ascensión y Tristan da Cunha' },
            { value: 'KN', label: 'San Cristóbal y Nieves' },
            { value: 'LC', label: 'Santa Lucía' },
            { value: 'MF', label: 'San Martín (parte francesa)' },
            { value: 'PM', label: 'San Pedro y Miquelón' },
            { value: 'VC', label: 'San Vicente y las Granadinas' },
            { value: 'WS', label: 'samoa' },
            { value: 'SM', label: 'San Marino' },
            { value: 'ST', label: 'Santo Tomé y Príncipe' },
            { value: 'SA', label: 'Arabia Saudita' },
            { value: 'SN', label: 'Senegal'  },
            { value: 'RS', label: 'Serbia RS' },
            { value: 'SC', label: 'Seychelles' },
            { value: 'SL', label: 'Sierra Leona' },
            { value: 'SG', label: 'Singapur' },
            { value: 'SX', label: 'Sint Maarten (parte holandesa)' },
            { value: 'SK', label: 'Eslovaquia' },
            { value: 'SI', label: 'Eslovenia' },
            { value: 'SO', label: 'Islas Salomón' },
            { value: 'SO', label: 'Somalia' },
            { value: 'ZA', label: 'Sudáfrica' },
            { value: 'GD', label: 'Georgia del sur y las islas Sandwich del sur' },
            { value: 'SS', label: 'Sudán del Sur' },
            { value: 'ES', label: 'España' },
            { value: 'LK', label: 'Sri Lanka' },
            { value: 'SD', label: 'Sudán (el)' },
            { value: 'SR', label: 'Surinam' },
            { value: 'SJ', label: 'Svalbard y Jan Mayen' },
            { value: 'SE', label: 'Suecia' },
            { value: 'CH', label: 'Suiza' },
            { value: 'SY', label: 'República Árabe Siria' },
            { value: 'TW', label: 'Taiwan, provincia de China' },
            { value: 'TK', label: 'Tayikistán' },
            { value: 'TZ', label: 'Tanzanía, República Unida de' },
            { value: 'TH', label: 'Tailandia' },
            { value: 'TL', label: 'Timor Oriental' },
            { value: 'TG', label: 'Para llevar' },
            { value: 'TK', label: 'Tokelau' },
            { value: 'TO', label: 'tonga' },
            { value: 'TT', label: 'Trinidad y Tobago' },
            { value: 'TN', label: 'Túnez' },
            { value: 'TR', label: 'Turkey' },
            { value: 'TM', label: 'turkmenistán' },
            { value: 'TC', label: 'Islas Turcas y Caicos (las)' },
            { value: 'TV', label: 'Tuvalu Uganda' },
            { value: 'UA', label: 'Ucrania' },
            { value: 'AE', label: 'Emiratos Árabes Unidos (los)' },
            { value: 'GB', label: 'Reino Unido de Gran Bretaña' },
            { value: 'UM', label: 'Islas menores alejadas de los Estados Unidos (las)' },
            { value: 'US', label: 'Estados Unidos de America' },
            { value: 'UY', label: 'Uruguay' },
            { value: 'UZ', label: 'Uzbekistán' },
            { value: 'VU', label: 'Vanuatu' },
            { value: 'VE', label: 'Venezuela (República Bolivariana de)' },
            { value: 'VN', label: 'Vietnam' },
            { value: 'VG', label: 'Islas Vírgenes (británicas)' },
            { value: 'VI', label: 'Islas Vírgenes (EE. UU.)' },
            { value: 'WF', label: 'Wallis y Futuna' },
            { value: 'EH', label: 'Sahara Occidental' },
            { value: 'YE', label: 'Yemen' },
            { value: 'ZM', label: 'Zambia' },
            { value: 'ZW', label: 'Zimbabue' },
            { value: 'XX', label: 'No especificado' },
            ];
            break;
}
    return allCountries;
  }