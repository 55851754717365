import { useState, useEffect, useRef } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export default function useSize(target) {
  const [size, setSize] = useState({});

  useEffect(() => {
    if (target.current == null) {
      return;
    }

    const { width, height } = target.current.getBoundingClientRect();
    setSize({ width, height });
  }, [target.current]);

  useResizeObserver(target, (entry) => {
    const { width, height } = entry.contentRect;
    setSize({ width, height });
  });

  return size;
}