import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bem } from '../../../helpers/styles';
import styles from './Image.css';
import Error from '../Error/Error';

const Image = ({ src, perserveHeight, perserveWidth, error }) => {
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);

  const handleLoad = useCallback(
    ({ target: img }) => {
      setHeight(img.naturalHeight);
      setWidth(img.naturalWidth);
    },
    [src],
  );

  const style = useMemo(
    () => ({
      height: perserveHeight && height,
      width: perserveWidth && width,
      'object-fit': !perserveHeight && !perserveWidth ? 'cover' : 'initial',
    }),
    [height, width, perserveHeight, perserveWidth],
  );

  if (error) return <Error>{error}</Error>;

  return (
    <img
      src={src}
      className={bem(styles, 'Image', {
        error,
      })}
      onLoad={handleLoad}
      style={style}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string,
  error: PropTypes.string,
  perserveHeight: PropTypes.bool,
  perserveWidth: PropTypes.bool,
};

Image.defaultProps = {
  perserveHeight: false,
  perserveWidth: false,
  src: '',
};

export default Image;
