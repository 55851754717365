import React from 'react';
import PropTypes from 'prop-types';
import styles from './DrawerDetailBody.css';

function DrawerDetailBody({ children }) {
  return <div className={styles.DrawerDetailBody}>{children}</div>;
}

DrawerDetailBody.propTypes = {
  children: PropTypes.node,
};

export default DrawerDetailBody;
