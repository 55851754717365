import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { focusedFieldSelector, currentFormSelector } from '../../../../../store/selectors/editForm';
import { useSelector } from 'react-redux';
import EditItemTemplate from '../../../../templates/EditItem/EditItem';
import { useActions } from '../../../../../hooks/useActions';
import { setFocusedField as setFocusedFieldAction } from '../../../../../store/actions/editForm';
import { updateField as updateFieldAction } from '../../../../../store/actions/form';
import ItemList from '../../../../templates/ItemList/ItemList';
import TYPOLOGIES from '../../../../../constants/typology/typologies';
import styles from './DrawerContentItems.css';
import { getTyplogyByPath } from '../../../../../helpers/typology';
import { PUBLISHED } from '../../../../../constants/formState';

function DrawerContentItemsPage({ match }) {
  const { path } = match;
  const formId = useMemo(() => match.params.formId, [match]);
  const form = useSelector(currentFormSelector);
  const [updateField, setFocusedField] = useActions([updateFieldAction, setFocusedFieldAction]);
  const focusedField = useSelector(focusedFieldSelector);
  const focusedHasOptions = useMemo(
    () => focusedField && !!TYPOLOGIES.find((typo) => typo.type === focusedField.type)?.options,
    [focusedField],
  );
  const props = useMemo(() => getTyplogyByPath(path), [path]);

  // Update field in store when form is blurred
  const handleFocusedFieldChange = useCallback(
    (values) => {
      updateField(formId, focusedField.id, values);
    },
    [formId, focusedField],
  );

  const unsetFocusedField = useCallback(() => {
    setFocusedField(null);
  }, []);

  return (
    <div className={styles.DrawerContentItems}>
      {focusedField && focusedHasOptions ? (
        <EditItemTemplate
          onNewField={unsetFocusedField}
          field={focusedField}
          onChange={handleFocusedFieldChange}
          {...props}
        />
      ) : (
        <ItemList {...props} draggable={form && form.state !== PUBLISHED} />
      )}
    </div>
  );
}

DrawerContentItemsPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      formId: PropTypes.string,
    }),
  }),
};

export default DrawerContentItemsPage;
