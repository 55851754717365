import React from 'react';
import PropTypes from 'prop-types';
import SwitchField from '../../molecules/Field/Switch/SwitchField';
import useTranslate from '../../../hooks/useTranslate';
import { useSelector, useDispatch } from 'react-redux';
import { Field, formValueSelector } from 'redux-form';
import ShortcutField from '../../molecules/Field/ShortcutField/ShortcutField';
import {
  focusedFieldSelector,
  selectFormIdSelector,
  focusedFieldShortcutSelector,
} from '../../../store/selectors/editForm';
import { FORM_FIELD_UPDATE } from '../../../store/actions/form';
function ShortcutFragment({ form: reduxFormId }) {
  const i18n = useTranslate();
  const dispatch = useDispatch();
  const field = useSelector(focusedFieldSelector);
  const formId = useSelector(selectFormIdSelector);

  const { shortcut } = useSelector((state) =>
    formValueSelector(reduxFormId)(state, 'id', 'shortcut.enabled', 'shortcut.value'),
  );

  const shortcutValue = useSelector(focusedFieldShortcutSelector);

  const handleChange = (value) => {
    if (value.length <= 1) {
      const payload = {
        form: formId,
        fieldId: field.id,
        data: {
          ...field,
          shortcut: {
            ...field.shortcut,
            value,
          },
        },
      };
      dispatch({
        type: FORM_FIELD_UPDATE,
        payload,
      });
    }
  };

  return (
    <>
      <SwitchField name="shortcut.enabled" label={i18n('ShortcutFragment.shortcut')} />
      {shortcut?.enabled ? (
        <div>
          <p>shift + {shortcutValue}</p>
          <ShortcutField type="text" name="shortcut.value" onChange={handleChange} />
        </div>
      ) : (
        <Field name="shortcut.field" component="input" type="hidden" />
      )}
    </>
  );
}
ShortcutFragment.propTypes = {
  form: PropTypes.string,
  isBlock: PropTypes.bool,
};
export default ShortcutFragment;
