import React, { useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import ContributeForm from '../../form/ContributeForm/ContributeForm';
import { DEFAULT_GRID_COLUMNS } from '../../../constants/grid';
import Grid from '../../organisms/Grid/Grid';
import {
  buildFormTree,
  getNodeInitialValues,
  buildFormOutput,
  buildAnswer,
  buildAnswers,
  getReviewTree,
  buildBlockAnswerChoices,
  clearHiddenValues,
} from '../../../helpers/formTree';
import ContributeFormNode from './../ContributeFormNode/ContributeFormNode';
import Button from '../../atoms/Button/Button';
import useTranslate from '../../../hooks/useTranslate';
import { flatten } from '../../../helpers/array';
import { DEFAULT_GRID_ROWHEIGHT, GRID_GUTTER } from '../../../constants/grid';
import styles from './ContributeFormTemplate.css';
import { useDispatch, useSelector } from 'react-redux';
import { submit, getFormValues, getFormSyncErrors, initialize } from 'redux-form';
import Timer from '../../atoms/Timer/Timer';
import { compileAllRules, evaluateAllRules } from '../../../helpers/formula';
import ContributeFormErrorModal from '../ContributeFormErrorModal/ContributeFormErrorModal';
import { createToast } from '../../../store/actions/toast';
import { ERROR } from '../../../constants/toastType';
import useDebouncedEffect from '../../../hooks/useDebouncedEffect';
import HiddenBoxesContext from './HiddenBoxesContext';
import KeyboardShortcutsProbe from '../../atoms/KeyboardShortcutsProbe/KeyboardShortcutsProbe';
import { formQualitySelector } from '../../../store/selectors/editForm';
import { getFirstInput } from '../../../helpers/form';
import FormStyle from '../../atoms/FormStyle';
import PDFOCRGeolocProbe from '../../atoms/PDFOCRGeolocProbe/PDFOCRGeolocProbe';
import { PdfOcrViewerProvider } from '../../organisms/PdfOcrViewer/PdfOcrViewerContext';

function ContributeFormTemplate({
  data,
  form,
  answers,
  answer,
  onSubmit,
  choicesOverrides,
  taskExpiresAt,
  minTimeElapsed = true,
}) {
  const i18n = useTranslate();
  const dispatch = useDispatch();
  const { allowError } = form;
  const [hiddenBoxes, setHiddenBoxes] = useState([]);
  const [flattenedSyncErrors, setFlattenedSyncErrors] = useState(null);
  const formSyncErrors = useSelector(getFormSyncErrors('contribute'));
  const values = useSelector(getFormValues('contribute'));
  const { strategy } = useSelector(formQualitySelector);

  const [validationErrors, setValidationErrors] = useState(null);

  const gridColumns = form?.gridSize || DEFAULT_GRID_COLUMNS;
  const gridRowHeight = (DEFAULT_GRID_COLUMNS * DEFAULT_GRID_ROWHEIGHT) / form?.gridSize;

  useEffect(() => {
    if (values === initialValues) {
      const name = getFirstInput(form);
      if (name) {
        const el = document.querySelectorAll(`[name='${name}'`);
        el[0]?.focus();
      }
    }
  }, [values]);

  // Build the form tree from blocks & fields
  const formTree = useMemo(
    () =>
      buildFormTree(form, {
        choicesOverrides,
      }),
    [form, choicesOverrides],
  );
  const validationRules = useMemo(() => compileAllRules(form.validationRules, formTree), [
    form,
    formTree,
  ]);
  const hideRules = useMemo(() => compileAllRules(formTree.hideRules, formTree), [formTree]);

  useDebouncedEffect(
    () => {
      const [errors, failedExecutions] = evaluateAllRules(hideRules, values);
      if (failedExecutions.length) {
        createToast(
          ERROR,
          i18n(`ContributeFormTemplate.hideFailedExecution`, { count: failedExecutions.length }),
        );
      }
      setHiddenBoxes(errors.reduce((boxes, error) => [error.fieldPath, ...boxes], []));
    },
    100,
    [hideRules, values],
  );

  // Keep track of total form height
  const [realHeight, setRealHeight] = useState(0);

  // Submit without validation checks
  const doSubmit = useCallback(
    (values) => {
      if (!minTimeElapsed) {
        alert("Le temps minimum n'est pas écoulé !");
        return;
      }

      if (hiddenBoxes.length) {
        const clearedValues = clearHiddenValues(values, hiddenBoxes);
        dispatch(initialize('contribute', clearedValues, true));
      }

      onSubmit(buildFormOutput(formTree, values, hiddenBoxes));
    },
    [onSubmit, formTree, hiddenBoxes, minTimeElapsed],
  );

  const processValidationRules = useCallback(
    (values) => {
      const finalValues = hiddenBoxes.length ? clearHiddenValues(values, hiddenBoxes) : values;
      const [errors, failedExecutions] = evaluateAllRules(
        validationRules,
        finalValues,
        hiddenBoxes,
      );

      if (failedExecutions.length) {
        createToast(
          ERROR,
          i18n(`ContributeFormTemplate.failedExecution`, { count: failedExecutions.length }),
        );
      }

      if (errors.length) {
        setValidationErrors(errors);
      }

      return { errors, failedExecutions, finalValues };
    },
    [validationRules, hiddenBoxes],
  );

  const handleSubmit = useCallback(() => {
    if (allowError && Object.keys(formSyncErrors).length) {
      processValidationRules(values);
      setFlattenedSyncErrors(Object.values(flatten(formSyncErrors)).filter((er) => er));
    }
    dispatch(submit('contribute'));
  }, [values, formTree, formSyncErrors, allowError, doSubmit]);

  // Submit WITH validation checks
  const onSubmitTransformHandler = useCallback(
    (values) => {
      const { finalValues, failedExecutions, errors } = processValidationRules(values);
      if (!errors.length && !failedExecutions.length) {
        doSubmit(finalValues);
      }
    },
    [doSubmit, processValidationRules],
  );

  // Generate confidence tree for multi-review
  const reviewTree = useMemo(() => (answer ? getReviewTree(answer, form.blocks) : null), [answer]);

  // Generate answer choices for [multi-]review
  const answersValues = useMemo(
    () => (answers && strategy === 'multi' ? buildAnswers(answers) : null),
    [answers],
  );
  const blocksValues = useMemo(
    () => (answer && strategy === 'multi' ? buildBlockAnswerChoices(answer) : null),
    [answer],
  );
  // Generates initial values according to form tree shape
  const initialValues = useMemo(() => {
    return {
      // TODO: if nested data is a thing, we'll need to do that in buildFormTree
      // This works for root-level data fields
      ...data,
      ...(answer ? buildAnswer(answer) : getNodeInitialValues(formTree)),
    };
  }, [formTree, answer, data]);

  useEffect(() => {
    dispatch(initialize('contribute', initialValues));
  }, [initialValues]);

  const preventEnterSubmit = useCallback(
    (event) => {
      if (event.keyCode === 13 && !form.enterToValidate) {
        // Prevent default enter behavior on form-level if enter to validate is not enabled
        event.preventDefault();
      }
    },
    [form.enterToValidate],
  );

  // Compute the total form height to position submit button
  const formHeight = useMemo(() => realHeight || formTree.totalHeight, [
    realHeight,
    formTree.height,
  ]);

  return (
    <PdfOcrViewerProvider>
      <FormStyle form={form}>
        {form.keyboardShortcuts && (
          <KeyboardShortcutsProbe shortcuts={form.keyboardShortcuts} form={form} />
        )}

        <PDFOCRGeolocProbe form={form} answer={answer} />

        {form.showFormTitle === true ? (
          <div className={styles.ContributeFormTemplate__title}>{form.name}</div>
        ) : null}

        <HiddenBoxesContext.Provider value={hiddenBoxes}>
          <ContributeFormErrorModal
            open={(validationErrors && !!validationErrors.length) || !!flattenedSyncErrors?.length}
            errors={validationErrors}
            syncErrors={flattenedSyncErrors}
            onClose={() => {
              setValidationErrors(null);
              setFlattenedSyncErrors(null);
            }}
            onForceSubmit={
              form.allowError &&
              (() => {
                setValidationErrors(null);
                setFlattenedSyncErrors(null);
                doSubmit(values);
              })
            }
          />
          <div className={styles.ContributeFormTemplate} id="form_root">
            {/* scale the grid container so parent overflow-y wirks properly */}
            <div
              style={{
                minHeight: formHeight * gridRowHeight + gridRowHeight * 2,
              }}
            >
              <ContributeForm
                /*initialValues={initialValues}*/
                onSubmit={onSubmitTransformHandler}
                onKeyDown={preventEnterSubmit}
              >
                {/* If enter to validate is enabled, we need to add this invisible submit button that "enables" the feature */}
                {form.enterToValidate && (
                  <button
                    type="submit"
                    className={styles.ContributeFormTemplate__hiddenSubmit}
                  ></button>
                )}
                <Grid
                  columns={gridColumns}
                  rowHeight={gridRowHeight}
                  gutter={GRID_GUTTER}
                  dotBackground={false}
                >
                  <ContributeFormNode
                    node={formTree}
                    answersValues={answersValues}
                    blocksValues={blocksValues}
                    reviewTree={reviewTree}
                    onHeightChange={setRealHeight}
                  />
                  <Grid.Box
                    x={gridColumns / 2 - 1}
                    y={formHeight}
                    width={2}
                    style={{ padding: '1rem' }}
                  ></Grid.Box>
                </Grid>
              </ContributeForm>
            </div>
            <div className={styles.ContributeFormTemplate__actions}>
              <div className={styles.ContributeFormTemplate__actions_meta} />
              <Button
                rounded
                onClick={handleSubmit}
                className={styles.ContributeFormTemplate__submit}
              >
                {form.submitLabel || i18n('ContributeFormTemplate.submit')}
              </Button>
              <div className={styles.ContributeFormTemplate__actions_meta}>
                <Timer expiresAt={taskExpiresAt}></Timer>
              </div>
            </div>
          </div>
        </HiddenBoxesContext.Provider>
      </FormStyle>
    </PdfOcrViewerProvider>
  );
}

ContributeFormTemplate.defaultProps = {
  answer: null,
  answers: null,
};

ContributeFormTemplate.propTypes = {
  form: PropTypes.object,
  onSubmit: PropTypes.func,
  data: PropTypes.object,
  answer: PropTypes.object,
  answers: PropTypes.object,
  yappersId: PropTypes.string,
  taskId: PropTypes.string,
  projectTitle: PropTypes.string,
  choicesOverrides: PropTypes.object,
  onTimerAlmostElapsed: PropTypes.func,
  onTimerElapsed: PropTypes.func,
  minTimeElapsed: PropTypes.bool,
  taskExpiresAt: PropTypes.number,
};

export default ContributeFormTemplate;
