import { BLOCK_NODE, FIELD_NODE } from '../constants/formNode';
import { isColliding, sortByPositionFactory, isWithin } from '../components/organisms/Grid/GridHelpers';
import { DEFAULT_GRID_COLUMNS } from '../constants/grid';
import { get, del } from 'object-path';
import { DATA_TYPE_LIST } from '../constants/typology/dataType';
import {
    ADVANCEDSELECT,
    GPSCOORDINATES,
    LATITUDE,
    LONGITUDE,
    MCQ,
    NUMBER,
} from '../constants/typology/fieldType';
import { isObject } from './compare';
import clone from './clone';

function selectRemoteValueToApi(tuple) {
    if (!tuple) {
        return null;
    }
    const { value, label } = tuple;
    if (value && value.value) return { id: value.value, label };
    return { id: value, label };
}
function selectRemoteValueFromApi(tuple) {
    if (!tuple) {
        return null;
    }
    const { id, label } = tuple;
    return { value: id, label };
}

//get all values of fields/data by id in row
export function getAnswerValues({ data = [], fields = [] }) {
    return data
        .concat(fields)
        .reduce((values, { id, value, items, itemApi, itemsApi, type, remoteOptionsEnabled }) => {
            if (type === `coordinates_${LATITUDE}` || type === `coordinates_${LONGITUDE}`) {
                id = id.replace(`_${LATITUDE}`, '').replace(`_${LONGITUDE}`, '');
                if (!values[id]) values[id] = {};

                if (type === `coordinates_${LATITUDE}`) values[id][LATITUDE] = value;
                if (type === `coordinates_${LONGITUDE}`) values[id][LONGITUDE] = value;
            }
            if (itemApi && itemApi.id === '') {
                itemApi['id'] = null;
            }
            return {
                ...values,
                [id]:
                    values[id] ||
                    value ||
                    items ||
                    (itemApi?.id !== null
                        ? selectRemoteValueFromApi(itemApi)
                        : null) ||
                    (itemsApi
                        ? itemsApi.map(selectRemoteValueFromApi).filter((value) => !!value)
                        : null),
            };
        }, {});
}

//get all values of fields/data by id in rows and build Tree
export function getAnswerRowsValues(rows = []) {
    return rows
        .filter((row) => !row.isHidden)
        .map((row) => ({
            ...getAnswerValues(row),
            ...(row?.blocks && buildAnswerTree(row.blocks)),
        }));
}

// Build the initial value tree a response tree
export function buildAnswerTree(answerTree = []) {
    let initialValue = null;

    for (let i = 0; i <= answerTree.length - 1; i++) {
        const childAnswer = answerTree[i];
        const values = getAnswerValues(childAnswer);

        if (!childAnswer?.id) return initialValue;
        if (!initialValue) initialValue = { [childAnswer.id]: {} };

        if (childAnswer.rows) {
            //build same level
            initialValue = {
                ...initialValue,
                [childAnswer.id]: {
                    ...initialValue[childAnswer.id],
                    ...values,
                    $items: [...getAnswerRowsValues(childAnswer.rows)],
                },
            };
        }

        if (childAnswer.blocks) {
            const tree = buildAnswerTree(childAnswer.blocks);

            //build other level
            initialValue = {
                ...initialValue,
                [childAnswer.id]: {
                    ...initialValue[childAnswer.id],
                    $items: [{ ...values, ...(tree && tree) }],
                },
            };
        }
    }

    return initialValue;
}

export function buildAnswer(answer = {}) {
    return {
        ...getAnswerValues(answer),
        ...buildAnswerTree(answer.blocks),
    };
}
// Get initial values for a single node
export function getNodeInitialValues({ children }, initialValues = {}) {
    for (let n = 0; n < children.length; n++) {
        const child = children[n];
        if (child.nodeType === BLOCK_NODE) {
            initialValues[child.id] = {
                $items: [getNodeInitialValues(child)],
            };
        }
    }
    return initialValues;
}

export function buildAnswers(answers = {}) {
    if (!isObject(answers)) return null;

    const keys = Object.keys(answers);
    if (!keys.length) return null;

    return keys.map((key) => {
        return buildAnswer(answers[key]);
    });
}

export function buildBlockAnswerChoices(answer) {
    const blocks = {};
    for (let i = 0; answer.blocks && i < answer.blocks.length; i++) {
        const block = answer.blocks[i];
        if (block.rows) {
            if (!block.rows.length) {
                continue;
            }

            let nbFields = block.rows.map(row => { return row.fields.length; });
            // check for subBlock answer Values  
            block.rows.map(row => {
                if (row.blocks && row.blocks.length > 0) {
                    row.blocks.map(elem => {
                        blocks[elem.id] = {
                            nbFields: (elem.rows.map(e => { return e.fields.length; })).reduce(function (a, b) { return a + b; }, 0),
                            confidence: row.color,
                            rows: elem.rows.map((e) => ({
                                value: {
                                    ...buildAnswer({
                                        fields: e.fields,
                                        blocks: e.blocks.map((blockRow) => ({
                                            ...blockRow,
                                            ...(blockRow.rows ? blockRow.rows[0] : {}),
                                        })),
                                    }),
                                },
                                confidence: e.color,
                                label: e.primaryKey,
                            }))
                        };
                    })
                }
            });

            blocks[block.id] = {
                nbFields: nbFields.reduce(function (a, b) { return a + b; }, 0),
                confidence: block.color,
                rows: block.rows.map((row) => (
                    {
                        value: {
                            ...buildAnswer({
                                fields: row.fields,
                                blocks: row.blocks.map((blockRow) => ({
                                    ...blockRow,
                                    ...(blockRow.rows ? blockRow.rows[0] : {}),
                                })),
                            }),
                        },
                        confidence: row.color,
                        label: row.primaryKey,
                        nbFields: row.fields.length,
                    }
                )),
            };
        }
    }
    return blocks;
}

export function getReviewTree(answer, blockArray) {
    const out = {};

    for (let i = 0; answer.fields && i < answer.fields.length; i++) {
        const field = answer.fields[i];
        if (!field.children) {
            out[field.id] = {
                confidence: field.color,
                answers: field?.itemsApiAnswers?.length
                    ? field.itemsApiAnswers
                    : field?.itemsAnswers?.length
                        ? field.itemsAnswers
                        : field.itemAnswers?.length
                            ? field.itemAnswers
                            : field.answers,
            };
        } else if (field.children) {
            out[field.id] = getReviewTree(field.children);
        }
    }

    for (let i = 0; answer.blocks && i < answer.blocks.length; i++) {
        const block = answer.blocks[i];
        out[block.id] = { $items: block.rows?.map((row) => getReviewTree(row)) || [] };
        out.blockArray = blockArray;
    }

    return out;
}

// Test if box is fully contained inside target
export function isInside(box, target) {
    return (
        target.x >= box.x &&
        target.y >= box.y &&
        target.x + target.width <= box.x + box.width &&
        target.y + target.height <= box.y + box.height
    );
}

// Move boxes down if it collides with something
// WARNING : this function mutates parameters
export function reflow(boxes) {
    for (let i = 0; i < boxes.length; i++) {
        const box = boxes[i];
        let colliding = false;

        if (box.height === 0) {
            continue;
        }

        do {
            colliding = false;
            for (let j = boxes.length - 1; j > -1; j--) {
                const otherBox = boxes[j];

                if (box === otherBox || otherBox.height === 0) {
                    continue;
                }
                if (isColliding(box, otherBox) || isInside(box, otherBox) || isInside(otherBox, box)) {
                    colliding = true;
                    otherBox.y += 1;
                    break;
                }
            }
        } while (colliding);
    }
    return boxes;
}

export function boxSize(box) {
    return box.width * box.height;
}

// Get the smallest possible block for a given box
// The box must fully fit inside the block
export function getSmallestBlock(node, blocksNodes, { duplicable } = {}) {
    let block = null;
    for (let b = 0; b < blocksNodes.length; b++) {
        const someBlock = blocksNodes[b];

        if (
            node !== someBlock &&
            (!node.size || node.size < someBlock.size) &&
            isInside(someBlock.originalBox || someBlock.box, node.originalBox || node.box) &&
            (!block ||
                ((block.size || boxSize(block.box)) > (someBlock.size || boxSize(someBlock.box)) &&
                    (!duplicable || someBlock.duplicable === duplicable)))
        ) {
            block = someBlock;
        }
    }

    return block;
}

export function getHighestBlock(node, blocksNodes) {
    let block = null;
    for (let b = 0; b < blocksNodes.length; b++) {
        const someBlock = blocksNodes[b];

        if (
            node !== someBlock &&
            (!node.size || node.size < someBlock.size) &&
            (!block || (block.size || boxSize(block.box)) < (someBlock.size || boxSize(someBlock.box))) &&
            isInside(someBlock.box, node.box)
        ) {
            block = someBlock;
        }
    }

    return block;
}

function xySort(a, b) {
    if (a.box.y < b.box.y) {
        return -1;
    } else if (a.box.y > b.box.y) {
        return 1;
    } else if (a.box.x < b.box.x) {
        return -1;
    } else if (a.box.x > b.box.x) {
        return 1;
    }

    return 0;
}

// Sort items by thier position index
function positionIndexSort(a, b) {
    return a.positionIndex - b.positionIndex;
}

// Recursively sort a form tree
function recursiveSort(node, compareFunc) {
    node.children.sort(compareFunc);

    if (node.children) {
        node.children.forEach((child) => child.children && recursiveSort(child, compareFunc));
    }
}

// This is where magic happens : convert a flat form structure to a tree with nested nodes
// The algorithm works as follow
// - Put all form fields in smallest possible block, if any
//   - Otherwise add it as root field node
// - Put all block in smallest possible block if any
//   - Other it is added as root block node
export function buildFormTree(form, { choicesOverrides } = {}) {
    const root = {
        nodeType: 'root',
        children: [],
        totalHeight: 0,
        hideRules: [],
        compareFields: (form.compareFields || [])
            .filter((compareFields) => !compareFields.blockScope)
            .map((compareFields) => compareFields.fields),
    };
    const blocksNodes = [];
    const rootFieldsNodes = [];
    // Build the tree : blocks node
    for (let b = 0; b < form.blocks.length; b++) {
        const block = form.blocks[b];
        const blockNode = {
            nodeType: BLOCK_NODE,
            ...block,
            // Make a copy of the box since we'll make it relative to parent block
            box: { ...block.box },
            // Keep a copy of the original box to do proper inclusion testing while building the tree
            originalBox: block.box,
            children: [],
            rowHeight: 0,
            order: block.order || 0,
            // Used to sort boxes by their position on the grid
            positionIndex: block.box.y * form.gridSize + block.box.x,
            // Compute area occupied by this block, we use this to determine smallest possible block
            size: block.box.width * block.box.height + (block.box.hasHeader ? block.box.width : 0),
        };
        blocksNodes.push(blockNode);

        // Add hideIf rule if defined
        if (block?.hideIf?.enabled) {
            root.hideRules.push({
                id: block.id,
                ...block.hideIf,
                formula: block.hideIf.formula || [],
            });
        }
    }

    blocksNodes.sort(sortByPositionFactory(form.gridSize));

    // Create field nodes and fit in smallest possible block
    for (let f = 0; f < form.fields.length; f++) {
        const field = form.fields[f];
        const fieldNode = {
            nodeType: FIELD_NODE,
            ...field,
            // Used to sort boxes by their position on the grid
            positionIndex: field.box.y * form.gridSize + field.box.x,
            box: {
                ...field.box,
            },
        };

        if (choicesOverrides && choicesOverrides[fieldNode.id]) {
            fieldNode.choices = choicesOverrides[fieldNode.id].replaceAll(
                fieldNode.injectSeparator || '&&',
                '\n',
            );
        }

        // Find the smallest possible block
        const block = getSmallestBlock(field, blocksNodes);

        if (block) {
            // Correct field offset to be relative to block offset (minus 1 for the header row)
            fieldNode.box.x = field.box.x - block.box.x;
            fieldNode.box.y = field.box.y - block.box.y - 1;

            block.children.push(fieldNode);

            // Updates row height if needed
            block.rowHeight = Math.max(block.rowHeight, fieldNode.box.y + fieldNode.box.height);
            // Updates total height of the tree
            root.totalHeight = Math.max(root.totalHeight, fieldNode.box.y + fieldNode.box.height);
        } else {
            // Field is not inside any block, add to root
            rootFieldsNodes.push(fieldNode);
        }

        // Add hideIf rule if defined
        if (field?.hideIf?.enabled) {
            root.hideRules.push({
                ...field.hideIf,
                id: field.id,
            });
        }
    }

    // Fit blocks in smallest possible block
    for (let n = 0; n < blocksNodes.length; n++) {
        const node = blocksNodes[n];
        if (node.nodeType !== BLOCK_NODE) {
            continue;
        }

        // Find smallest block
        const block = getSmallestBlock(node, blocksNodes);

        if (block) {
            block.children.push(node);

            // Correct block offset to be relative to parent block (minus 1 for the header row)
            node.box.x = node.originalBox.x - block.originalBox.x;
            node.box.y = node.originalBox.y - block.originalBox.y - 1;

            // Updates parent block row height
            block.rowHeight = Math.max(block.rowHeight, node.box.y + node.box.height);
            // Updates total height of the tree
            root.totalHeight = Math.max(root.totalHeight, node.box.y + node.box.height);
        } else {
            root.children.push(node);
        }

        // Add hideIf rule if defined
        if (block?.hideIf?.enabled) {
            root.hideRules.push({
                ...block.hideIf,
                id: block.id,
            });
        }
    }

    // Sort stuff according to top-left position so order in the DOM matches x/y coords
    // This is usefull to keep native tabulation behavior on forms
    for (let n = 0; n < blocksNodes.length; n++) {
        blocksNodes[n].children.sort(xySort);
        // We've finished position testing, original box is no longer needed
        delete blocksNodes[n].originalBox;
    }

    for (let r = 0; r < root.hideRules.length; r++) {
        const hideRule = root.hideRules[r];
        const path = [
            ...getAncestorsChain(root, hideRule.id)
                .slice(0, -1)
                .map((node) => node.id),
            hideRule.id,
        ];
        hideRule.path = path;
    }

    // Add root field nodes to the tree, before blocks
    root.children.splice(0, 0, ...rootFieldsNodes);

    // Sort the whole tree structure by thier position (top left first)
    // When reflowing we want an ordered list of nodes so that boxes
    // at the bottom are moved before nodes at the top
    // Otherwise top boxes may be moved below bottom boxes
    recursiveSort(root, positionIndexSort);

    return root;
}

export function mapFormTree(tree, mapper) {
    return {
        ...tree,
        children: tree.children.map((node) => {
            const mappedNode = mapper(node);
            return node.children ? mapFormTree(mappedNode, mapper) : mappedNode;
        }),
    };
}

export function apiSelectTypologieTypeMapper(node) {
    const nodeCopy = { ...node };
    if (node.remoteOptionsEnabled && node.type === ADVANCEDSELECT) {
        nodeCopy.type = node.multi ? `api_multi${node.type}` : `api_${node.type}`;
    }
    return nodeCopy;
}

export function getAllCompareRules(form) {
    return [
        ...form.compareFields,
        ...form.blocks.reduce(
            (mergedCompareFields, block) =>
                block.compareFields
                    ? mergedCompareFields.concat(
                        block.compareFields.map((fields) => ({
                            blockScope: block.id,
                            fields,
                        })),
                    )
                    : mergedCompareFields,
            [],
        ),
    ];
}

// Retrieve the ancestors chain of a node
export function getAncestorsChain(node, nodeId) {
    for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i];
        if (child.id === nodeId) {
            return [child];
        }

        if (child.children) {
            const chain = getAncestorsChain(child, nodeId);
            if (chain && chain.length) {
                return [child, ...chain];
            }
        }
    }

    return [];
}

// Retrieve a node by id in a deep tree structure
export function getNodeById(node, nodeId) {
    if (node.id === nodeId) {
        return node;
    }

    if (node.children) {
        for (let i = 0; i < node.children.length; i++) {
            const result = getNodeById(node.children[i], nodeId);

            if (result) {
                return result;
            }
        }
    }

    return null;
}

// Move a node and its children down
export function offsetNodeAndChildren(node) {
    node.box.y += 1;
    if (node.children) {
        for (let i = 0; i < node.children.length; i++) {
            offsetNodeAndChildren(node.children[i]);
        }
    }
    return node;
}

// Reflow childs of a single node
export function reflowNodeChilds(node, form) {
    node.children.sort(sortByPositionFactory(form.gridSize));

    for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i];
        if (node.box.y === child.box.y && node.box.hasHeader) {
            // If child is on header row, move it down
            child.box.y += 1;
        } else {
            // Else test blocks that comes before this one for collision
            // If this occurs, move block down
            for (let j = 0; j < i; j++) {
                const otherChild = node.children[j];
                if (isColliding(child.box, otherChild.box)) {
                    offsetNodeAndChildren(child);
                    // We wan't to do it only once per block
                    break;
                }
            }
        }

        if (!isWithin(node.box, child.box)) {
            // If the child is no longer within the block, that means
            // it was pushed out of bounds, we need to increment height
            node.box = {
                ...node.box,
                height: node.box.height + 1,
            };
        }
    }

    return node;
}

// Transform relative positioning to absolute positioning
export function computeAbsolutePositions(node, offset = { x: 0, y: 0 }) {
    if (node.children) {
        for (let i = 0; i < node.children.length; i++) {
            const child = node.children[i];
            child.box.x += offset.x;
            child.box.y += offset.y;
            if (child.children) {
                computeAbsolutePositions(child, {
                    x: child.box.x,
                    y: child.box.y + 1,
                });
            }
        }
    }
}

// Reflow the form layout when a header is added to a block
export function reflowAfterAddHeader(block, form) {
    // The tree structure is more convenient for reflowing
    const formTree = buildFormTree(form);
    // Set a box to the root for reflow constraints
    formTree.box = { x: 0, y: 0, width: form.gridSize, height: 1000 };
    // Transform relative => absolute positioning
    computeAbsolutePositions(formTree);

    // Find ancestors chain of updated block
    const ancestors = [formTree, ...getAncestorsChain(formTree, block.id)];

    // Reflow ancestors, starting from deepest chilmd
    for (let i = ancestors.length - 1; i > -1; i--) {
        reflowNodeChilds(ancestors[i], form);
    }

    // Once tree has been reflown, copy-back updated boxes to form
    for (let i = 0; i < form.fields.length; i++) {
        const treeNode = getNodeById(formTree, form.fields[i].id);
        form.fields[i] = {
            ...form.fields[i],
            box: {
                ...form.fields[i].box,
                ...treeNode.box,
            },
        };
    }

    // Same thing for blocks
    for (let i = 0; i < form.blocks.length; i++) {
        const treeNode = getNodeById(formTree, form.blocks[i].id);
        form.blocks[i] = {
            ...form.blocks[i],
            box: {
                ...form.blocks[i].box,
                ...treeNode.box,
            },
        };
    }

    return form;
}

export function lastItem(array) {
    return array[array.length - 1];
}

export function buildFormOutput(node, values, hiddenBoxes = []) {
    return buildNodeOutput(node, values, hiddenBoxes);
}

export function buildNodeOutput(node, values, hiddenPaths = []) {
    const out = {
        data: [],
        fields: [],
        blocks: [],
    };
    for (let i = 0; i < node.children.length; i++) {
        const child = node.children[i];

        if (hiddenPaths.includes(child.id)) {
            continue;
        }

        if (child.nodeType === FIELD_NODE) {
            if (child.type === GPSCOORDINATES) {
                // GPS Coordinates have a special output format that splits in two objects
                out.fields.push(
                    {
                        type: 'coordinates_latitude',
                        id: `${child.id}_latitude`,
                        value: get(values, `${child.id}.latitude`),
                    },
                    {
                        type: 'coordinates_longitude',
                        id: `${child.id}_longitude`,
                        value: get(values, `${child.id}.longitude`),
                    },
                );
            } else {
                const nodeOutput = {
                    type: child.type,
                    id: child.id,
                };

                if (child.multi) {
                    if (child?.remoteOptionsEnabled) {
                        // Select queryMode case
                        nodeOutput.type = `api_multi${nodeOutput.type}`;
                        nodeOutput.itemsApi = get(values, child.id)?.map(selectRemoteValueToApi);
                    } else {
                        // Prefix mcq / select with "multi" when in multi mode
                        nodeOutput.type = `multi${nodeOutput.type}`;
                        nodeOutput.items = get(values, child.id);
                    }
                } else if (child.type === NUMBER) {
                    const itemNumber = get(values, child.id, '');
                    nodeOutput.value = itemNumber ? itemNumber.replace(',', '.') : null;
                } else {
                    if (child?.remoteOptionsEnabled) {
                        nodeOutput.type = `api_select`;
                        const itemApi = get(values, child.id);
                        nodeOutput.itemApi = itemApi ? selectRemoteValueToApi(itemApi) : null;
                    } else {
                        nodeOutput.value = get(values, child.id);
                    }
                }
                if (DATA_TYPE_LIST.includes(child.type)) {
                    out.data.push(nodeOutput);
                } else {
                    out.fields.push(nodeOutput);
                }
            }
        } else if (child.nodeType === BLOCK_NODE) {
            const rows = values[child.id]?.$items || [];
            child.children.map(elem => DATA_TYPE_LIST.includes(elem.type) && out.data.push({ type: elem.type, id: elem.id, value: values[elem.id] }));
            const block = {
                type: 'block',
                id: child.id,
                rows: [
                    ...rows.map((row, i) => {
                        const rowPath = `${child.id}.$items[${i}].`;
                        return buildNodeOutput(
                            child,
                            row,
                            hiddenPaths
                                .filter((hiddenBox) => hiddenBox && hiddenBox.startsWith(rowPath))
                                .map((hiddenBox) => hiddenBox.replace(rowPath, '')),
                        );
                    }),
                ],
            };
            out.blocks.push(block);
        }
    }
    return out;
}

export function clearHiddenValues(values, hiddenFields) {
    const cloned = clone(values);

    var existingHiddenFields = hiddenFields.filter(word => word);

    existingHiddenFields.filter(word => word).forEach((hiddenPath) => {
        del(cloned, hiddenPath.replace(/(\[|\]\.)/g, '.'));
    });
    return cloned;
}

export function processDataColumns(columns) {
    if (!columns) {
        return {};
    }
    return columns
        .filter((column) => column.type?.startsWith('data_'))
        .reduce(
            (acc, { name, data }) => ({
                ...acc,
                [name]: data,
            }),
            {},
        );
}

export function processChoicesInjectionColumns(columns) {
    if (!columns) {
        return {};
    }

    return columns
        .filter((column) => column.type === MCQ || column.type === ADVANCEDSELECT)
        .reduce(
            (acc, { name, data }) => ({
                ...acc,
                [name]: data,
            }),
            {},
        );
}