import { createStore as createReduxStore, combineReducers, compose, applyMiddleware } from 'redux';
import * as reducers from './reducers';
import { reducer as formReducer } from 'redux-form';
import { getQueryToken, getConfigToken, clearQueryParameters, getQueryTenant } from '../helpers/token';
import middleware from './middleware';

export default function createStore(idSite) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })
    : compose;

  let queryToken = getQueryToken();
  let queryTenant = getQueryTenant();

  const tokenName = idSite ? `wfb_token_${idSite}` : 'wfb_token';
  // eslint-disable-next-line
  console.info(`Property used to store token: `, tokenName);
  if (queryToken) {
    // If a query token is passed, save it in localStorage for persistence
    localStorage.setItem(tokenName, queryToken);
  } else {
    // Try to fetch token from storage if provided
    queryToken = localStorage.getItem(tokenName);
  }

  if(queryTenant){
      // If a query token is passed, save it in localStorage for persistence
      localStorage.setItem('wfb_tenant', queryTenant);
    } else {
      // Try to fetch token from storage if provided
      queryTenant = localStorage.getItem('wfb_tenant');
    }

  const store = createReduxStore(
    combineReducers({
      ...reducers,
      form: formReducer,
      // Token is initialized on create store but redux yells if there's no reducer
      token: (state = null) => state,
      tenant: (state = null) => state,
    }),
    {
      // Use token provided in URL with higher priority
      token: queryToken || getConfigToken(),
      tenant: queryTenant
    },
    composeEnhancers(applyMiddleware(...middleware)),
  );
  clearQueryParameters();

  return store;
}
