import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './GridBox.css';
import GridContext from '../GridContext';
import { classnames } from '../../../../helpers/classnames';

// A GridBox uses GridContext to determine real position from grid coordinates
// It is responsible for style box container
const GridBox = React.forwardRef(function GridBox(
  { children, x, y, width = 1, height = 1, margin = 0, style, onClick, className },
  ref,
) {
  const grid = useContext(GridContext);
  const { columnWidth, rowHeight, gutter } = grid;

  const finalStyle = useMemo(
    () => ({
      // Used to debug boxes
      border: __DEBUG_GRID ? '2px dashed purple' : undefined,
      ...style,
      left: `${x * columnWidth + gutter + margin}px`,
      top: `${y * rowHeight + gutter + margin}px`,
      width: width * columnWidth - (gutter * 2 + margin * 2),
      height: height * rowHeight - (gutter * 2 + margin * 2),
    }),
    [x, y, width, height, rowHeight, columnWidth, style],
  );

  if (columnWidth === 0) {
    return null;
  }

  return (
    <div
      className={classnames([styles.GridBox, className])}
      style={finalStyle}
      onClick={onClick}
      ref={ref}
    >
      {children}
    </div>
  );
});

GridBox.propTypes = {
  children: PropTypes.node,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.width,
  height: PropTypes.height,
  margin: PropTypes.number,
  style: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default GridBox;
