import useTranslate from '../hooks/useTranslate';
import { PATH_EDITFIELDS_FORM, PATH_DATA_FORM } from '../constants/paths';
import { DATA_TYPE_LIST } from '../constants/typology/dataType';
import { DATA, DATA_TYPOLOGIES } from '../constants/typology/data';
import { FIELD_TYPE_LIST } from '../constants/typology/fieldType';
import { FIELD, FIELD_TYPOLOGIES } from '../constants/typology/field';
import TYPOLOGIES from '../constants/typology/typologies';

const i18n = useTranslate();

const data = {
  items: DATA_TYPOLOGIES,
  resource: DATA,
  button: () => i18n('DataPage.createButton'),
  title: () => i18n('DataPage.drawerTitle'),
  description: () => i18n('DataPage.drawerDescription'),
  path: PATH_DATA_FORM,
};

/** Props with all typology */
const field = {
  items: FIELD_TYPOLOGIES,
  resource: FIELD,
  button: () => i18n('EditPage.createButton'),
  title: () => i18n('EditPage.drawerTitle'),
  description: () => i18n('EditPage.drawerDescription'),
  path: PATH_EDITFIELDS_FORM,
};

export const getTyplogyByPath = (path = '') => {
  if (PATH_EDITFIELDS_FORM == path) return field;
  if (PATH_DATA_FORM == path) return data;
  return {};
};

export function getTypologyByType(type = '') {
  return TYPOLOGIES.find((typo) => typo?.type === type);
}

export function getTypologyKind(type = '') {
  return DATA_TYPE_LIST.includes(type) ? DATA : FIELD;
}

export const getTypologyInitialValues = (type = '') => {
  let items = [];
  if (FIELD_TYPE_LIST.includes(type)) items = FIELD_TYPOLOGIES;
  if (DATA_TYPE_LIST.includes(type)) items = DATA_TYPOLOGIES;

  const item = items.find((item) => item.type === type);

  return (item?.options || []).reduce(
    (acc, option) => {
      if (!Object.keys(option).includes('initialValue')) return acc;
      const { name, initialValue } = option;
      return { ...acc, [name]: initialValue };
    },
    {
      comparisonRules: getFieldsInitialValues(item.comparisonRules),
    },
  );
};

export const getFieldsInitialValues = (fields) =>
  fields
    ? fields
        .filter((field) => typeof field.initialValue !== 'undefined')
        .reduce((acc, field) => ({ ...acc, [field.name]: field.initialValue }), {})
    : null;

export const getTypologyValues = (values = {}) => {
  const { selectTypology } = values;
  const field = selectTypology && TYPOLOGIES.find((typo) => typo.type === selectTypology);
  if (!field) return;

  return (field?.options || []).reduce((acc, { name, initialValue }) => {
    return { ...acc, [name]: values[name] || initialValue };
  }, {});
};
