export const TEXT = 'data_text';
export const NUM = 'data_numeric';
export const VIEWER = 'data_embed';
export const LINK = 'data_url';
export const VIDEO = 'data_video';
export const AUDIO = 'data_audio';
export const IMAGE = 'data_image';
export const IFRAME = 'data_iframe'
export const PDF_OCR = 'data_pdf_ocr';

export const DATA_TYPE_LIST = [TEXT, NUM, VIEWER, LINK, VIDEO, AUDIO, IMAGE, IFRAME, PDF_OCR];
