import React, { useMemo, useContext } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { Field, isDirty, ReduxFormContext } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import MCQ from '../../../atoms/MCQ/MCQ';
import FieldError from '../../Error/FieldError';
import useTranslate from '../../../../hooks/useTranslate';
import { useSelector } from 'react-redux';

function MCQFieldRender({ input, options, id, meta: { error, touched }, ...props }) {
  return (
    <>
      <MCQ {...input} options={options} id={id} block {...props} />
      {touched && error && <FieldError error={error} />}
    </>
  );
}

MCQFieldRender.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  layout: PropTypes.string,
  options: PropTypes.array,
  exclusive: PropTypes.bool,
};

function MCQField({
  name,
  label,
  required,
  information,
  validation,
  fieldHoC: FieldHOC,
  options,
  verticalAlign,
  answersChoices,
  confidence,
  ...props
}) {
  const i18n = useTranslate();
  const id = useMemo(() => `name_${uuid()}`, [name]);
  const finalOptions = useMemo(() => {
    const tempOption =
      options ||
      (validation.choices &&
        validation.choices.split('\n').map((choice) => ({
          label: choice,
          value: choice,
        }))) ||
      [];
    if (validation.allowOther) tempOption.push({ label: i18n('MCQ.other'), value: 'other' });
    if (validation.choices && validation.injectLabelValue) {
      tempOption.map((elem) => {
        let splitString = elem.label.split(validation.injectKeyValueSeparator);
        elem.label = splitString[0];
        elem.value = splitString[1];
      });
    }
    return tempOption;
  }, [validation]);

  const formCtx = useContext(ReduxFormContext);
  const touched = useSelector((state) => isDirty(formCtx?.formName)(state, name));

  return (
    <FormField
      label={label}
      name={name}
      htmlFor={id}
      information={information}
      required={required}
      verticalAlign={verticalAlign}
      answersChoices={answersChoices}
      confidence={touched ? 0 : confidence}
    >
      <FieldHOC
        name={name}
        component={MCQFieldRender}
        options={finalOptions}
        allowOther={validation.allowOther}
        exclusive={!validation.multi}
        autoselect={validation.autoselect}
        layout={validation.horizontal ? 'horizontal' : 'vertical'}
        style={validation.style}
        id={id}
        {...props}
      />
    </FormField>
  );
}

MCQField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  information: PropTypes.string,
  validation: PropTypes.object,
  fieldHoC: PropTypes.func,
  options: PropTypes.array,
  verticalAlign: PropTypes.bool,
  answersChoices: PropTypes.array,
  confidence: PropTypes.number,
};

MCQField.defaultProps = {
  fieldHoC: Field,
  validation: { allowOther: false },
  verticalAlign: false,
};

export default MCQField;
