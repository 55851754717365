export default [
  { label: '1', code: 'Digit1', key: '&' },
  { label: '2', code: 'Digit2', key: 'é' },
  { label: '3', code: 'Digit3', key: '"' },
  { label: '4', code: 'Digit4', key: "'" },
  { label: '5', code: 'Digit5', key: '(' },
  { label: '6', code: 'Digit6', key: '-' },
  { label: '7', code: 'Digit7', key: 'è' },
  { label: '8', code: 'Digit8', key: '_' },
  { label: '9', code: 'Digit9', key: 'ç' },
  { label: '0', code: 'Digit0', key: 'à' },
  { label: 'Minus', code: 'Minus', key: ')' },
  { label: 'Equal', code: 'Equal', key: '=' },
  { label: 'Backspace', code: 'Backspace', key: 'Backspace' },
  { label: 'Backquote', code: 'Backquote', key: '<' },
  { label: 'Tab', code: 'Tab', key: 'Tab' },
  { label: 'Q', code: 'KeyQ', key: 'a' },
  { label: 'W', code: 'KeyW', key: 'z' },
  { label: 'E', code: 'KeyE', key: 'e' },
  { label: 'R', code: 'KeyR', key: 'r' },
  { label: 'T', code: 'KeyT', key: 't' },
  { label: 'Y', code: 'KeyY', key: 'y' },
  { label: 'U', code: 'KeyU', key: 'u' },
  { label: 'I', code: 'KeyI', key: 'i' },
  { label: 'O', code: 'KeyO', key: 'o' },
  { label: 'P', code: 'KeyP', key: 'p' },
  { label: 'BracketLeft', code: 'BracketLeft', key: 'Dead' },
  { label: 'BracketRight', code: 'BracketRight', key: '^$' },
  { label: 'CapsLock', code: 'CapsLock', key: 'CapsLock' },
  { label: 'A', code: 'KeyA', key: 'q' },
  { label: 'S', code: 'KeyS', key: 's' },
  { label: 'D', code: 'KeyD', key: 'd' },
  { label: 'F', code: 'KeyF', key: 'f' },
  { label: 'G', code: 'KeyG', key: 'g' },
  { label: 'H', code: 'KeyH', key: 'h' },
  { label: 'J', code: 'KeyJ', key: 'j' },
  { label: 'K', code: 'KeyK', key: 'k' },
  { label: 'L', code: 'KeyL', key: 'l' },
  { label: 'Semicolon', code: 'Semicolon', key: 'm' },
  { label: 'ShiftLeft', code: 'ShiftLeft', key: 'Shift' },
  { label: 'ShiftRight', code: 'ShiftRight', key: 'Shift' },
  { label: 'IntlBackslash', code: 'IntlBackslash', key: '²' },
  { label: 'Z', code: 'KeyZ', key: 'w' },
  { label: 'X', code: 'KeyX', key: 'x' },
  { label: 'C', code: 'KeyC', key: 'c' },
  { label: 'V', code: 'KeyV', key: 'v' },
  { label: 'B', code: 'KeyB', key: 'b' },
  { label: 'N', code: 'KeyN', key: 'n' },
  { label: 'M', code: 'KeyM', key: ',' },
  { label: 'Comma', code: 'Comma', key: ';' },
  { label: 'Period', code: 'Period', key: ':' },
  { label: 'Slash', code: 'Slash', key: '!' },
  { label: 'Enter', code: 'Enter', key: 'Enter' },
  { label: 'Home', code: 'Home', key: 'Home' },
  { label: 'End', code: 'End', key: 'End' },
  { label: 'PageUp', code: 'PageUp', key: 'PageUp' },
  { label: 'Delete', code: 'Delete', key: 'Delete' },
  { label: 'PageDown', code: 'PageDown', key: 'PageDown' },
  { label: 'ControlLeft', code: 'ControlLeft', key: 'Control' },
  { label: 'OSLeft', code: 'OSLeft', key: 'Meta' },
  { label: 'AltLeft', code: 'AltLeft', key: 'Alt' },
  { label: 'AltRight', code: 'AltRight', key: 'Alt' },
  { label: 'OSRight', code: 'OSRight', key: 'Meta' },
  { label: 'ContextMenu', code: 'ContextMenu', key: 'ContextMenu' },
  { label: 'ControlRight', code: 'ControlRight', key: 'Control' },
  { label: 'Numpad0', code: 'Numpad0', key: '0' },
  { label: 'NumpadDecimal', code: 'NumpadDecimal', key: ',' },
  { label: 'NumpadEnter', code: 'NumpadEnter', key: 'Enter' },
  { label: 'Numpad1', code: 'Numpad1', key: '1' },
  { label: 'Numpad2', code: 'Numpad2', key: '2' },
  { label: 'Numpad3', code: 'Numpad3', key: '3' },
  { label: 'Numpad4', code: 'Numpad4', key: '4' },
  { label: 'Numpad5', code: 'Numpad5', key: '5' },
  { label: 'Numpad6', code: 'Numpad6', key: '6' },
  { label: 'Numpad7', code: 'Numpad7', key: '7' },
  { label: 'Numpad8', code: 'Numpad8', key: '8' },
  { label: 'Numpad9', code: 'Numpad9', key: '9' },
  { label: 'NumpadAdd', code: 'NumpadAdd', key: '+' },
  { label: 'NumpadSubtract', code: 'NumpadSubtract', key: '-' },
  { label: 'NumpadMultiply', code: 'NumpadMultiply', key: '*' },
  { label: 'NumpadDivide', code: 'NumpadDivide', key: '/' },
  { label: 'NumLock', code: 'NumLock', key: 'Clear' },
];
