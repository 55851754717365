import React, { useRef } from 'react';
import Drawer from '../../organisms/Drawer/Drawer';
import Button from '../../atoms/Button/Button';
import { useActions } from '../../../hooks/useActions';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { populatePath } from '../../../helpers/path';
import { setFocusedField as setFocusedFieldAction } from '../../../store/actions/editForm';
import styles from './DrawerNavTemplate.css';
import Popover from '../../atoms/Popover/Popover';
import SVG from '../../atoms/SVG/SVG';
import {
  PATH_EDITFIELDS_FORM,
  PATH_DATA_FORM,
  PATH_SETTINGS_FORM,
  PATH_INFO_FORM,
  PATH_QUALITY_FORM,
  PATHS_QUALITY,
  PATH_BLOCKS_FORM,
  PATH_STRATEGY_FORM,
} from '../../../constants/paths';
import useFeatToogle from '../../../hooks/useFeatToggle';
import useTranslate from '../../../hooks/useTranslate';
import useQuery from '../../../hooks/useQuery';
function DrawerNavTemplate() {
  const { params, path } = useRouteMatch();
  const query = useQuery();
  const [setFocusedField] = useActions([setFocusedFieldAction]);
  const featQuality = useFeatToogle('qualitySection');

  const anchorEdit = React.createRef();
  const anchorReload = useRef(null);
  const anchorBlocks = useRef(null);
  const anchorInfo = useRef(null);
  const anchorStrategy = useRef(null);
  const anchorQuality = useRef(null);
  const anchorSettings = useRef(null);

  const i18n = useTranslate();
  const anchors = {
    anchorEdit,
    anchorReload,
    anchorBlocks,
    anchorInfo,
    anchorSettings,
  };

  const unsetFocusedField = () => {
    setFocusedField(null);
  };

  const handlerClick = (pathTarget) => {
    return path !== pathTarget ? unsetFocusedField : null;
  };

  return (
    <Drawer.VerticalNav>
      <Button
        ref={anchorEdit}
        className={styles.DrawerNavTemplate__button}
        as={NavLink}
        flat
        icon="edit"
        activeClassName="active"
        color="white"
        to={populatePath(PATH_EDITFIELDS_FORM, params, query)}
        onClick={handlerClick(PATH_EDITFIELDS_FORM)}
      />
      <Button
        ref={anchorReload}
        className={styles.DrawerNavTemplate__button}
        as={NavLink}
        flat
        icon="reload"
        activeClassName="active"
        color="white"
        to={populatePath(PATH_DATA_FORM, params, query)}
        onClick={handlerClick(PATH_DATA_FORM)}
      />
      <Button
        ref={anchorBlocks}
        className={styles.DrawerNavTemplate__button}
        as={NavLink}
        flat
        icon="layers"
        activeClassName="active"
        color="white"
        to={populatePath(PATH_BLOCKS_FORM, params, query)}
        onClick={handlerClick(PATH_BLOCKS_FORM)}
      />
      <Button
        ref={anchorInfo}
        className={styles.DrawerNavTemplate__button}
        as={NavLink}
        flat
        icon="info"
        color="white"
        activeClassName="active"
        to={populatePath(PATH_INFO_FORM, params, query)}
        onClick={handlerClick(PATH_INFO_FORM)}
      />
      {featQuality && (
        <>
          <Button
            ref={anchorStrategy}
            className={styles.DrawerNavTemplate__button}
            as={NavLink}
            flat
            icon="quality"
            color="white"
            activeClassName="active"
            to={populatePath(PATH_STRATEGY_FORM, params, query)}
            onClick={handlerClick(PATH_STRATEGY_FORM)}
          />
          <Popover anchor={anchorStrategy} key={anchorStrategy}>
            <SVG glyph={i18n(`popover.anchorStrategy.svg`)} />
            <div className={styles.DrawerNavTemplate__Popover}>
              <h2 className={styles.DrawerNavTemplate__PopoverTitle}>
                {i18n(`popover.anchorStrategy.title`)}
              </h2>
              {i18n(`popover.anchorStrategy.text`)}
            </div>
          </Popover>
        </>
      )}
      {featQuality && (
        <>
          <Button
            ref={anchorQuality}
            className={styles.DrawerNavTemplate__button}
            as={NavLink}
            flat
            icon="validation"
            color="white"
            activeClassName="active"
            isActive={() => PATHS_QUALITY.includes(path)}
            to={populatePath(PATH_QUALITY_FORM, params, query)}
            onClick={handlerClick(PATH_QUALITY_FORM)}
          />
          <Popover anchor={anchorQuality} key={anchorQuality}>
            <SVG glyph={i18n(`popover.anchorQuality.svg`)} />
            <div className={styles.DrawerNavTemplate__Popover}>
              <h2 className={styles.DrawerNavTemplate__PopoverTitle}>
                {i18n(`popover.anchorQuality.title`)}
              </h2>
              {i18n(`popover.anchorQuality.text`)}
            </div>
          </Popover>
        </>
      )}
      <Button
        ref={anchorSettings}
        as={NavLink}
        flat
        icon="gear"
        className={styles.DrawerNavTemplate__button}
        color="white"
        activeClassName="active"
        to={populatePath(PATH_SETTINGS_FORM, params, query)}
        onClick={handlerClick(PATH_SETTINGS_FORM)}
      />
      {/* REVIEW  */}
      {Object.keys(anchors).map((el) => {
        return (
          <Popover anchor={anchors[el]} key={el}>
            <SVG glyph={i18n(`popover.${el}.svg`)} />
            <div className={styles.DrawerNavTemplate__Popover}>
              <h2 className={styles.DrawerNavTemplate__PopoverTitle}>
                {i18n(`popover.${el}.title`)}
              </h2>
              {i18n(`popover.${el}.text`)}
            </div>
          </Popover>
        );
      })}
    </Drawer.VerticalNav>
  );
}

DrawerNavTemplate.propTypes = {};

export default DrawerNavTemplate;
