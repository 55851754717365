import React from 'react';
import PropTypes from 'prop-types';
import useTranslate from '../../../hooks/useTranslate';
import styles from './NoCompare.css';
import CompareTag from '../CompareTag/CompareTag';

function NoCompare({ handleRemove, fields }) {
  const i18n = useTranslate();

  return (
    <div className={styles.NoCompare}>
      {/* eslint-disable-next-line */}
      {!!(fields && fields.length) ? (
        fields.map((field, key) => (
          <CompareTag key={key} fieldId={field.id} onDelete={() => handleRemove(field.id)} />
        ))
      ) : (
        <span className={styles.NoCompare__placeholder}>{i18n('NoCompare.placeholder')}</span>
      )}
    </div>
  );
}

NoCompare.propTypes = {
  fields: PropTypes.array,
  handleRemove: PropTypes.func,
};

NoCompare.defaultProps = {
  handleRemove: () => {},
  fields: [],
};
export default NoCompare;
