import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './ConfidenceIcon.css';
import { getConfidenceColor } from '../../../helpers/form';

function ConfidenceIcon({ confidence }) {
  const style = useMemo(() => ({ backgroundColor: getConfidenceColor(confidence) }), [confidence]);

  return <span className={styles.ConfidenceIcon} style={style}></span>;
}

ConfidenceIcon.propTypes = {
  confidence: PropTypes.number,
};

export default ConfidenceIcon;
