import React, { useEffect, useCallback, useMemo, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '../../atoms/Button/Button';
import styles from './ContributeCloseButtonTemplate.css';
import useTranslate from '../../../hooks/useTranslate';
import useFetchAction from '../../../hooks/useFetchAction';
import { releaseTask as releaseTaskAction } from '../../../store/actions/task';
import { createToast } from '../../../store/actions/toast';
import { ERROR } from '../../../constants/toastType';
import * as config from '../../../helpers/config';
import { getSiteName } from '../../../helpers/url';
import { useSelector } from 'react-redux';
import { allTasksIdsSelector, currentTaskSelector } from '../../../store/selectors/task';
import { setTimeoutIfFuture } from '../../../helpers/timeount';
import { AlertContext } from '../../molecules/Alert/AlertContext';
import { formatDuration } from '../../../helpers/time';

// eslint-disable-next-line no-console
const log = (...args) => console.log('ContributeCloseButtonTemplate - ', ...args);

function ContributeCloseButtonTemplate({ taskId, isView, projectId, onInactivityEnd }) {
  const i18n = useTranslate();
  const storeTasksIds = useSelector(allTasksIdsSelector);
  const currentTask = useSelector(currentTaskSelector);
  const taskStartedAt = useMemo(() => new Date(), [currentTask]);
  const [redirectOnError, setRedirectOnError] = useState(false);
  const [tasksIds, setTasksIds] = useState();
  const { openAlert } = useContext(AlertContext);
  const [releaseTask, , releaseError, , releaseStatus] = useFetchAction(releaseTaskAction);
  const url = useMemo(
    () =>
      (isView ? config.getUrlRedirection(`urls.team.achievements`) : config.getUrlRedirection(`urls.team.projects`)).replace(
        '{{IdProject}}',
        projectId,
      ),
    [projectId],
  );
  const sitename = useMemo(() => getSiteName(url), [url]);

  const goBack = () => {
    log(`redirect to ${url}`);
    window.location.href = url.replace('{{IdProject}}', projectId);
  };

  useEffect(() => {
    if (releaseError) {
      createToast(
        ERROR,
        i18n('ContributeCloseButtonTemplate.error', { message: releaseError.message }),
      );
      if (redirectOnError) {
        goBack();
      }
    } else if (releaseStatus === 'success') {
      //  eslint-disable-next-line
      log(`Released`);
      if (tasksIds && tasksIds.length) {
        const taskId = tasksIds[0];
        setTasksIds(tasksIds.slice(1));
        releaseTask(taskId, { ignoreError: true });
        //  eslint-disable-next-line
        log(`Release next task ${taskId}`);
      } else {
        goBack();
      }
    }
  }, [goBack, releaseError, releaseStatus, tasksIds]);

  const handleClick = useCallback(() => {
    if (storeTasksIds.length && !isView) {
      setTasksIds(storeTasksIds.slice(1));
      //  eslint-disable-next-line
      console.log(`ContributeCloseButtonTemplate - Start releasing task ${storeTasksIds[0]}`);
      // Make the release task call
      releaseTask(storeTasksIds[0], { ignoreError: true });
    } else {
      // Immediatly go back
      goBack();
    }
  }, [goBack, taskId, isView]);

  // Watch task timer
  useEffect(() => {
    let warnTid = null;
    let redirectTid = null;
    const expiresAt = currentTask?.expiresAt || 0;

    if (expiresAt > Date.now()) {
      warnTid = setTimeoutIfFuture(() => {
        openAlert(
          i18n('ContributeCloseButtonTemplate.alert', {
            startedAt: formatDuration(Date.now() - taskStartedAt.getTime()),
          }),
          i18n('ContributeCloseButtonTemplate.alertLabel'),
          onInactivityEnd,
        );
      }, expiresAt - 30000 - Date.now());
      redirectTid = setTimeoutIfFuture(() => {
        setRedirectOnError(true);
        handleClick();
      }, expiresAt - Date.now());
    }

    return () => {
      clearTimeout(warnTid);
      clearTimeout(redirectTid);
    };
  }, [handleClick, currentTask?.expiresAt, onInactivityEnd]);

  return (
    <Button
      icon="arrow_left"
      className={styles.ContributeCloseButtonTemplate__back}
      onClick={handleClick}
    >
      {[i18n('ContributeCloseButtonTemplate.back'), sitename].join(' ').trim()}
    </Button>
  );
}

ContributeCloseButtonTemplate.propTypes = {
  taskId: PropTypes.number,
  redirectTo: PropTypes.string,
  isView: PropTypes.bool,
  projectId: PropTypes.number,
  onInactivityEnd: PropTypes.func,
};

ContributeCloseButtonTemplate.defaultProps = { redirectTo: 'team' };

export default ContributeCloseButtonTemplate;
