import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import TextField from '../../molecules/Field/Text/TextField';
import Form from '../../atoms/Form/Form';

function CreateForm({ handleSubmit, onBlur, disabled }) {
  return (
    <Form onSubmit={handleSubmit}>
      <TextField
        name="name"
        placeholder="Form Title"
        onBlur={onBlur}
        verticalAlign={false}
        disabled={disabled}
      />
    </Form>
  );
}

CreateForm.propTypes = {
  handleSubmit: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

export default reduxForm({
  form: 'create',
})(CreateForm);
