export const KEY_Z = {key: 'z', which: 90, code: 'KeyZ'};
export const KEY_SHIFT = {key: 'Shift', which: 16, code: 'ShiftLeft'};
export const KEY_C = {key: 'c', which: 67, code: 'KeyC'};
export const KEY_V = {key: 'v', which: 86, code: 'KeyV'};
export const KEY_S = {key: 's', which: 83, code: 'KeyS'};
export const KEY_P = {key: 'p', which: 80, code: 'KeyP'};
export const KEY_DELETE = {key: 'Delete', which: 46, code: 'NumpadDecimal'};





