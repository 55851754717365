import React from "react";
import PropTypes from "prop-types";

const PdfOcrViewerContext = React.createContext(null);

export function usePdfOcrViewerContext() {
  const context = React.useContext(PdfOcrViewerContext);

  if (context == null) {
    console.error('usePdfOcrViewerContext must be used within a PdfOcrViewerProvider');
  }

  return context;
}

export function PdfOcrViewerProvider({ children }) {
  const [viewers, setViewers] = React.useState({});

  const registerViewer = React.useCallback((id, viewer) => {
    setViewers((prevViewers) => ({
      ...prevViewers,
      [id]: viewer
    }));
  }, []);

  const unregisterViewer = React.useCallback((id) => {
    setViewers((prevViewers) => {
      const { [id]: _, ...rest } = prevViewers;
      return rest;
    });
  }, []);

  return (
    <PdfOcrViewerContext.Provider value={{ viewers, registerViewer, unregisterViewer }}>
      {children}
    </PdfOcrViewerContext.Provider>
  );
}

PdfOcrViewerProvider.propTypes = {
  children: PropTypes.node.isRequired
};