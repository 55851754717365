import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Textarea.css';
import { bem } from '../../../helpers/styles';

function Textarea({ rows, onChange, onBlur, onFocus, value, error, disabled, isFocusOnInit, placeholder }) {
  const ref = useRef()

  const props = {
    onChange: useCallback((e) => onChange(e), [onChange]),
    rows,
    disabled,
  };

  useEffect(() => {
    if (isFocusOnInit) {
      ref?.current?.focus()
      onFocus()
    }
  }, [])

  return (
    <textarea
      ref={ref}
      className={bem(styles, 'Textarea', { error })}
      {...props}
      onBlur={onBlur}
      value={value}
      onFocus={onFocus}
      placeholder={placeholder}
    />
  );
}

Textarea.propTypes = {
  isFocusOnInit: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  error: PropTypes.any,
  disabled: PropTypes.bool,
};

Textarea.defaultProps = {
  required: false,
  rows: 2,
  onChange: () => null,
  onFocus: () => null
};

export default Textarea;
