import React from 'react';
import { useSelector, useStore } from 'react-redux';
import { currentTaskSelector } from '../../../../store/selectors/task';
import { currentCertificationSelector } from '../../../../store/selectors/certification';

function TaskInfo() {
  const task = useSelector(currentTaskSelector);

  if (!task) {
    const certification= useSelector(currentCertificationSelector);
    if(!certification) return null;
    return (
      <div style={{ lineHeight: '1.5rem' }}>
        <div>Yappers : #{certification.supplierId}</div>
        <div>Question : #{certification.certificationQuestionId}</div>
        <div>
          Certification : #{certification.certificationId} - {certification.certificationTitle}
        </div>
      </div>
    );
  }

  return (
    <div style={{ lineHeight: '1.5rem' }}>
      <div>Yappers : #{task.supplierId}</div>
      <div>Tâche : #{task.taskId}</div>
      <div>
        Projet : #{task.projectId} - {task.projectTitle}
      </div>
    </div>
  );
}

export default TaskInfo;
