import React, { useRef, useLayoutEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';

const RENDER_DEBOUNCE_DELAY = 100; // Wait Xms before rendering after resize

function PdfOcrViewerDocumentLayer({ page, viewports }) {
  const ref = useRef();
  const isRendering = useRef(false);

  const render = useCallback(
    async ({ viewports }) => {
      if (ref.current == null) {
        return;
      }

      // Don't render if already rendering
      if (isRendering.current) {
        return;
      }

      const canvas = ref.current;
      const context = canvas.getContext('2d');

      canvas.height = viewports.scaled.height;
      canvas.width = viewports.scaled.width;

      isRendering.current = true;

      await page.render({
        canvasContext: context,
        viewport: viewports.scaled,
      }).promise;

      isRendering.current = false;
    },
    [ref.current, page],
  );

  const debouncedRender = useMemo(() => debounce(RENDER_DEBOUNCE_DELAY, render), [render]);

  // Trigger full re-renders when the container resizes
  useLayoutEffect(() => {
    debouncedRender({ viewports });
  }, [debouncedRender, viewports]);

  return (
    <canvas
      ref={ref}
      style={{
        width: viewports.scaled.width,
        height: viewports.scaled.height,
      }}
    />
  );
}

PdfOcrViewerDocumentLayer.propTypes = {
  page: PropTypes.object.isRequired,
  viewports: PropTypes.object.isRequired,
};

export default PdfOcrViewerDocumentLayer;
