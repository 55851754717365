import React from 'react';
import PropTypes from 'prop-types';
import styles from './CompareTag.css';
import SVG from '../SVG/SVG';
import { useSelector } from 'react-redux';
import { formFieldSelector } from '../../../store/selectors/editForm';

function CompareTag({ fieldId, onDelete }) {
  const fieldData = useSelector(formFieldSelector(fieldId));
  return (
    <div className={styles.CompareTag}>
      {fieldData.label || '...'}
      {
        <button type="button" className={styles.CompareTag__button} onClick={onDelete}>
          <SVG className={styles.CompareTag__icon} glyph="circled_remove" />
        </button>
      }
    </div>
  );
}

CompareTag.propTypes = {
  fieldId: PropTypes.string,
  onDelete: PropTypes.func,
};

export default CompareTag;
