import React from 'react';
import styles from './Spinner.css';
import BRAND_IMG_URL from '../../../assets/images/brand-mini.png';

function Spinner() {
  return (
    <div className={styles.Spinner}>
      <img src={BRAND_IMG_URL} />
    </div>
  );
}

Spinner.propTypes = {};

export default Spinner;
