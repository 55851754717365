import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from './Grid';
import DraggableBox from './DraggableBox/DraggableBox';
import { useDrop } from 'react-dnd';
import styles from './DraggableGrid.css';
import DraggableGridLayer from './DraggableGridLayer/DraggableGridLayer';
import DraggableBoxStub from './DraggableBoxStub/DraggableBoxStub';
import { isOverlap, isWithin, moveGridItem, resizeGridItem } from './GridHelpers';
import { classnames } from '../../../helpers/classnames';

// Add drag interactivity to a regular grid
function DraggableGrid({
  children,
  accept,
  onDropItem,
  renderPreview,
  items: givenItems,
  columns,
  rowHeight,
  gutter = 4,
  className,
  scrollOffset,
  ...props
}) {
  // Since Grid is a child of DraggableGrid, we'll need a dedicated
  // state prop to store grid settings
  const [grid, setGrid] = useState();

  // Extract box items from children
  const items = givenItems || React.Children.map(children, (child) => child.props.item);

  // Track sub-boxes when dragging a block
  const [draggedChilds, setDraggedChilds] = useState(null);

  // Drop zone
  const [, dropRef] = useDrop({
    accept: Array.isArray(accept) ? accept.concat(['resize-handle']) : [accept, 'resize-handle'],
    drop: (item, monitor) => {
      if (!grid || !onDropItem) {
        return;
      }
      setDraggedChilds(null);
      const offset = monitor.getClientOffset();
      const offsetWithScroll = {
        x: offset.x + scrollOffset.x,
        y: offset.y + scrollOffset.y,
      };
      let dropCoords = null;
      let dropItem = null;
      if (item.type !== 'resize-handle') {
        // Box drop case
        dropItem = item;
        dropCoords = moveGridItem(item, grid, offsetWithScroll);
      } else {
        // Box resize handle case
        const boxItem = items.find((i) => i.id === item.boxId);
        if (boxItem) {
          dropItem = boxItem;
          dropCoords = resizeGridItem(boxItem, {
            ...item,
            ...moveGridItem(item, grid, offsetWithScroll),
          });
        }
      }
      if (dropItem && !isOverlap({ ...dropItem, ...dropCoords }, items, grid, draggedChilds)) {
        onDropItem(dropItem, dropCoords);
      }
    },
    collect(monitor) {
      // When a drop operation starts we collect the eventual childs of the dragged item
      // So we can ignore these childs when comparing boxes for collision

      const dragItem = monitor.getItem();
      if (draggedChilds !== null || !dragItem) {
        return;
      }

      const childItemsIds = items
        .filter((item) => item.id !== dragItem.id && isWithin(dragItem, item))
        .map((item) => item.id);
      setDraggedChilds(childItemsIds);
    },
  });

  // Update local state whenever grid settings change
  const handleGridChange = useCallback((grid) => {
    setGrid(grid);
  }, []);

  return (
    <div ref={dropRef} className={classnames([styles.DraggableGrid, className])}>
      <Grid
        {...props}
        columns={columns}
        rowHeight={rowHeight}
        gutter={gutter}
        onGridChange={handleGridChange}
      >
        {children}
      </Grid>
      <DraggableGridLayer
        items={items}
        renderPreview={renderPreview}
        draggedChilds={draggedChilds}
        scrollOffset={scrollOffset}
        parentGrid={grid}
      />
    </div>
  );
}

DraggableGrid.Box = DraggableBox;
DraggableGrid.BoxStub = DraggableBoxStub;

DraggableGrid.propTypes = {
  scrollOffset: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  renderPreview: PropTypes.func,
  children: PropTypes.node,
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  className: PropTypes.string,
  onDropItem: PropTypes.func,
  items: PropTypes.array,
  columns: PropTypes.number,
  rowHeight: PropTypes.number,
  gutter: PropTypes.number,
};

export default DraggableGrid;
