import React from 'react';
import Button from '../../atoms/Button/Button';
import PropTypes from 'prop-types';
import styles from './Paragraph.css';

function Paragraph({ title, content, icon }) {
  return (
    <div className={styles.Paragraph}>
      <h3>{title}</h3>
      <p>
        {content}
        {!!icon && (
          <Button flat icon={icon} activeClassName="active" className={styles.Paragraph__btn} />
        )}
      </p>
    </div>
  );
}

Paragraph.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

Paragraph.defaultProps = {
  icon: null,
};

export default Paragraph;
