import React from 'react';
import PropTypes from 'prop-types';
import Grid from '../../Grid/Grid';
import BlockControls from '../../BlockControls/BlockControls';
import DraggableGrid from '../../Grid/DraggableGrid';
import styles from './BlockBox.css';
import { COLOR_TRANS } from '../../../../constants/colors';

function BlockBox({ blockBox, focused, draggable = false, onClick, ...props }) {
  const RenderBox = draggable ? DraggableGrid.Box : Grid.Box;
  return (
    <RenderBox
      x={blockBox.item.x}
      y={blockBox.item.y}
      width={blockBox.item.width}
      height={blockBox.item.height}
      item={blockBox.item}
      className={styles.BlockBox}
      style={{
        backgroundColor: blockBox.color,
        boxShadow: focused
          ? `0px 0px 0px 2px ${
              blockBox.color === COLOR_TRANS ? 'rgba(0, 0, 0, 0.1)' : blockBox.darkerColor
            }`
          : undefined,
        // Bigger is the block, lower is the z-index
        zIndex: 1100 - (blockBox.item.width, blockBox.item.height),
      }}
      onClick={onClick}
      {...props}
    >
      <BlockControls duplicable block={blockBox} />
    </RenderBox>
  );
}

BlockBox.propTypes = {
  blockBox: PropTypes.object,
  draggable: PropTypes.bool,
  focused: PropTypes.bool,
  onClick: PropTypes.func,
};

export default BlockBox;
