import { createSelector } from 'reselect';

export const tasksSelector = (state) => state.task;

export const currentTaskSelector = createSelector(tasksSelector, (state) => state?.currentTask);

export const taskBufferCountSelector = createSelector(
  tasksSelector,
  (state) => (state?.currentTask ? 1 : 0) + state.buffer.length,
);

export const tasksDepletedSelector = createSelector(
  tasksSelector,
  (state) => !state?.currentTask && state.hasReceivedNullTask,
);

export const hasReceivedNullTaskSelector = createSelector(
  tasksSelector,
  (state) => state.hasReceivedNullTask,
);

export const lastTaskSentSelector = createSelector(tasksSelector, (state) => state?.lastTaskSent);

export const allTasksIdsSelector = createSelector(tasksSelector, (state) =>
  [state?.currentTask?.taskId, ...state.buffer.map((task) => task.taskId)].filter(
    (taskId) => !!taskId,
  ),
);
