import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import RadioBoxInput from '../../RadioBoxInput/RadioBoxInput';

function RadioBoxFieldRender({ input, ...props }) {
  return <RadioBoxInput {...input} {...props} />;
}

RadioBoxFieldRender.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
};

function RadioBoxField({ name, label, required, information, ...props }) {
  const id = useMemo(() => `name_${uuid()}`, [name]);
  return (
    <FormField label={label} name={name} htmlFor={id} information={information} required={required}>
      <Field name={name} component={RadioBoxFieldRender} id={id} {...props} />
    </FormField>
  );
}

RadioBoxField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  information: PropTypes.string,
};

export default RadioBoxField;
