import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { classnames } from '../../../helpers/classnames';
import { bem } from '../../../helpers/styles';

import styles from './Select.css';

function Select({ options, value, onChange, placeholder, className, disabled, ...restProps }) {
  const handleChange = useCallback(
    ({ target: { value } }) => {
      onChange(value);
    },
    [value, onChange],
  );
  const props = {
    value,
    onChange: handleChange,
    className: classnames([bem(styles, 'Select'), className]),
    placeholder,
  };

  return (
    <select disabled={disabled} {...props} {...restProps}>
      {placeholder && (
        <option disabled selected>
          {placeholder}
        </option>
      )}
      {options &&
        options.map((opt, key) => (
          <option key={key} value={opt.value} selected={value === opt.value}>
            {opt.label}
          </option>
        ))}
    </select>
  );
}

Select.defaultProps = {
  onChange: () => null,
};

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Select;
