import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

function getGlyph(glyph) {
  // eslint-disable-next-line
  return require(`../../../assets/svg/${glyph}.svg`);
}
function SVG({ glyph, className, style }) {
  const [symbol, error] = useMemo(() => {
    if (!glyph) {
      return [null, new Error('You must provide a glyph.')];
    }
    try {
      return [getGlyph(glyph), null];
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
      return [null, err];
    }
  }, [glyph]);

  if (!symbol || error) {
    // eslint-disable-next-line no-undef
    return process.env.NODE_ENV === 'development' ? (
      <span className={className} style={style}>
        Error : No glyph &quot;{glyph}&quot;
      </span>
    ) : null;
  }

  return (
    <svg viewbox={symbol.viewBox} style={style} className={className}>
      <use xlinkHref={`#${symbol.id}`} />
    </svg>
  );
}

SVG.propTypes = {
  glyph: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default SVG;
