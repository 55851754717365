import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './BlockItemControls.css';
import Button from '../../atoms/Button/Button';
import GridContext from '../Grid/GridContext';
import { bem } from '../../../helpers/styles';

import { getBlockDarkerColor } from '../../../helpers/color';

function BlockItemControls({
  onDelete,
  onAddClick,
  row,
  color,
  readonly,
  folded,
  hideSeparator = false,
}) {
  const grid = useContext(GridContext);

  const style = useMemo(
    () => ({
      zIndex: 10,
      height: grid && !hideSeparator ? grid.rowHeight : undefined,
      top: grid ? grid.rowHeight * row : undefined,
      width: grid ? grid.columns * grid.columnWidth : undefined,
      position: 'absolute',
    }),
    [grid, row],
  );

  return (
    <div className={styles.BlockItemControls} style={style}>
      {!hideSeparator && (
        <hr
          className={styles.BlockItemControls__separator}
          style={`border-top: 2px solid ${getBlockDarkerColor(color)}`}
        />
      )}
      <div className={styles.BlockItemControls__flex} />
      <Button
        type="button"
        icon="plus"
        rounded
        tabIndex={-1}
        className={styles.BlockItemControls__button}
        onClick={onAddClick}
        disabled={readonly}
      />
      <Button
        type="button"
        icon="trash"
        rounded
        tabIndex={-1}
        className={styles.BlockItemControls__button}
        onClick={onDelete}
        disabled={readonly}
      />
    </div>
  );
}

BlockItemControls.propTypes = {
  block: PropTypes.object,
  onDelete: PropTypes.func,
  onAddClick: PropTypes.func,
  row: PropTypes.number,
  color: PropTypes.string,
  readonly: PropTypes.bool,
  folded: PropTypes.bool,
  hideSeparator: PropTypes.bool,
};

export default BlockItemControls;
