import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import IframeViewer from '../../../atoms/IframeViewer/IframeViewer';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import getDummyData from '../../../../helpers/dummy';

function IframeDataRender({ input, id, meta: { initial }, ...props }) {
  if ((input.value || initial) === "") {
    return null;
  }

  return <IframeViewer id={id} link={input.value || initial} {...props} />;
}

IframeDataRender.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  src: PropTypes.src,
  id: PropTypes.string,
  meta: {
    initial: PropTypes.string,
  },
};

function IframeData({ name, label, fieldHoC: FieldHOC, validation, isPreview, ...props }) {
  const id = useMemo(() => `name_${uuid()}`, [name]);
  const initial = useMemo(() => isPreview ? getDummyData(validation.type) : "", [validation]);

  return (
    <FormField label={label}>
      <FieldHOC
        name={name}
        id={id}
        component={IframeDataRender}
        fixedMode={validation.fixedMode}
        meta={{ initial }}
        validation={validation}
        {...props}
      />
    </FormField>
  );
}

IframeData.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  information: PropTypes.string,
  fieldHoC: PropTypes.func,
  validation: PropTypes.object,
  isPreview: PropTypes.bool,
};

IframeData.defaultProps = {
  fieldHoC: Field,
};

export default IframeData;
