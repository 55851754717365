import { PATH_REGEX, SCHEME_REGEX } from '../constants/regex';

export function isPDFUrl(str) {
  try {
    if (PATH_REGEX.test(str)) {
      return true;
    }

    // If path is relative, prepend with location.origin
    const url = new URL(SCHEME_REGEX.test(str) ? str : `${window.location.origin}${str}`);
    return /\.pdf$/i.test(url.pathname);
  } catch (err) {
    return false;
  }
}

export const getSiteName = (url) => {
  try {
    return new URL(url).host;
  } catch (err) {
    return '';
  }
};
