import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './AudioPlayer.css';
import SVG from '../SVG/SVG';
import Error from '../Error/Error';
import useTranslate from '../../../hooks/useTranslate';

function AudioPlayer({ url, controls }) {
  const i18n = useTranslate();
  const [error, setError] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleIsPlaying = () => {
    setPlaying(true);
  };
  const handleIsStoping = () => {
    setPlaying(false);
  };
  const handleError = (error) => {
    setError(error);
  };

  const handleProgress = () => {
    setProgress(audioRef.current.currentTime / audioRef.current.duration);
  };

  const AudioInit = useCallback(() => {
    const audio = new Audio(url);
    audio.controls = controls;
    audio.addEventListener('play', handleIsPlaying);
    audio.addEventListener('pause', handleIsStoping);
    audio.addEventListener('timeupdate', handleProgress);
    audio.addEventListener('error', handleError);
    return audio;
  }, [url]);

  const audioRef = useRef(AudioInit());

  useEffect(() => {
    return () => {
      audioRef.current.removeEventListener('play', handleIsPlaying);
      audioRef.current.removeEventListener('pause', handleIsStoping);
      audioRef.current.removeEventListener('timeupdate', handleProgress);
      audioRef.current.removeEventListener('error', handleError);
      audioRef.current.pause();
    };
  }, []);

  const handlePlay = useCallback(() => {
    if (audioRef.current.paused) {
      audioRef.current.play();
    } else {
      audioRef.current.currentTime = 0;
      audioRef.current.pause();
      setProgress(0);
    }
  }, [audioRef?.current, url]);

  const handleBarClick = useCallback(
    (event) => {
      if (!audioRef.current.paused) {
        const rect = event.target.getBoundingClientRect();
        audioRef.current.currentTime =
          audioRef.current.duration * ((event.clientX - rect.left) / rect.width);
      }
    },
    [audioRef?.current],
  );

  if (error) {
    return <Error>{i18n('AudioPlayer.error.description')}</Error>;
  }

  return (
    <div className={styles.AudioPlayer} url={url} ref={() => audioRef}>
      <button
        className={styles.AudioPlayer__button}
        type="button"
        onClick={handlePlay}
        disabled={!controls}
        tabIndex={-1}
      >
        <SVG className={styles.AudioPlayer__buttonIcon} glyph={playing ? 'stop' : 'audio_play'} />
      </button>
      <div className={styles.AudioPlayer__progressBar} onClick={handleBarClick}>
        <div
          className={styles.AudioPlayer__progressBarIndicator}
          style={{ width: `${progress * 100}%` }}
        />
        <div
          onClick={(event) => event.stopPropagation()}
          className={styles.AudioPlayer__progressHandle}
          style={{ left: `${progress * 100}%` }}
        />
      </div>
    </div>
  );
}

AudioPlayer.defaultProps = {
  controls: true,
};

AudioPlayer.propTypes = {
  url: PropTypes.string,
  controls: PropTypes.bool,
};

export default AudioPlayer;
