import React from 'react';
import PropTypes from 'prop-types';
import styles from './DrawerDetailHeader.css';

function DrawerDetailHeader({ children }) {
  return <div className={styles.DrawerDetailHeader}>{children}</div>;
}

DrawerDetailHeader.propTypes = {
  children: PropTypes.node,
};

export default DrawerDetailHeader;
