// max requests to track
const BUFFER_SIZE = 1000;

export const initialState = {
  buffer: [],
  reqs: {},
  active: 0,
};

function getActionType(action) {
  if (!action?.meta?.requestId) {
    return 'none';
  }

  if (action.type.endsWith('_PENDING')) {
    return 'pending';
  }

  if (action.type.endsWith('_SUCCESS')) {
    return 'success';
  }

  if (action.type.endsWith('_ERROR')) {
    return 'error';
  }

  return 'none';
}

// Ensure that no more than BUFFER_SIZE requests are keeped in memory
function ensureBuffer(state) {
  if (state.buffer.length < BUFFER_SIZE) {
    return state;
  }

  const bufferCopy = [...state.buffer];
  const reqsCopy = { ...state.reqs };
  delete reqsCopy[bufferCopy[0]];
  bufferCopy.shift();
  return {
    buffer: bufferCopy,
    reqs: reqsCopy,
  };
}

export default function requestsReducer(state = initialState, action) {
  const type = getActionType(action);
  switch (type) {
    case 'pending':
      return ensureBuffer({
        buffer: [...state.buffer, action.meta.requestId],
        reqs: {
          ...state.reqs,
          [action.meta.requestId]: {
            status: 'pending',
          },
        },
        active: state.active + 1,
      });
    case 'error':
      return {
        ...state,
        reqs: {
          ...state.reqs,
          [action.meta.requestId]: {
            status: 'error',
            error: action.payload,
          },
        },
        active: state.active - 1,
      };
    case 'success':
      return {
        ...state,
        reqs: {
          ...state.reqs,
          [action.meta.requestId]: {
            status: 'success',
            payload: action.payload,
          },
        },
        active: state.active - 1,
      };
    default:
      return state;
  }
}
