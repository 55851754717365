import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import useFetchAction from '../../../hooks/useFetchAction';
import { getAnswerTask as getAnswerTaskAction } from '../../../store/actions/answer';
import { useParams } from 'react-router';
import Spinner from '../../atoms/Spinner/Spinner';
import ContributeAnswerFormTemplate from '../../templates/ContributeAnswerFormTemplate/ContributeAnswerFormTemplate';
import ErrorContributeTemplate from '../../templates/ErrorContributeTemplate/ErrorContributeTemplate';
import ContributeAnswerDetailTemplate from '../../templates/ContributeAnswerDetailTemplate/ContributeAnswerDetailTemplate';
import ContributeAnswer from '../../organisms/ContributeAnswer/ContributeAnswer';
import ContributeCloseButtonTemplate from '../../templates/ContributeCloseButtonTemplate/ContributeCloseButtonTemplate';
import { getFormTask as getFormTaskAction } from '../../../store/actions/task';

function ContributeViewTaskPage({ withMarking }) {
  const params = useParams();
  const [getAnswerTask, answerTaskLoading, answerTaskError, answerTask] = useFetchAction(getAnswerTaskAction);

  const [getForm, formLoading, formError, form] = useFetchAction(getFormTaskAction);

  // Creates a key-value pair of task data
  const formData = useMemo(() => {
    const formData = { ...answerTask?.json };

    answerTask?.columns.forEach((col) => {
      if (formData.data.find((elem) => elem.id === col.name) == null) {
        formData.data.push({
          id: col.name,
          type: col.type,
          value: col.data,
        });
      }
    });

    return formData;
  }, [answerTask]);

  const columnAnswer =
    answerTask && answerTask.Columns
      ? answerTask.Columns.map((elem) => {
          const tempObj = {
            id: elem.Name,
            type: elem.Type,
            value: elem.Data,
          };
          return tempObj;
        })
      : [];

  // First we load the task
  useEffect(() => {
    getAnswerTask(params.taskId);
  }, []);

  // Once task is loaded, load the form
  useEffect(() => {
    if (answerTask?.formId && !form && !formLoading) {
      getForm(answerTask.formId);
    }
  }, [answerTask, form, formLoading]);

  if (!answerTaskError && !formError) {
    if (!form || formLoading || answerTaskLoading) {
      return <Spinner />;
    }
  }

  const emptyAnswer = { blocks: [], data: columnAnswer, fields: [] };

  return (
    <ContributeAnswer>
      <ContributeCloseButtonTemplate isView projectId={answerTask.projectId} />
      {withMarking && (
        <ContributeAnswerDetailTemplate
          answer={answerTask}
          form={form}
        ></ContributeAnswerDetailTemplate>
      )}
      <ErrorContributeTemplate answerError={answerTaskError} formError={formError}>
        <ContributeAnswerFormTemplate answer={formData ? formData : emptyAnswer} form={form} />
      </ErrorContributeTemplate>
    </ContributeAnswer>
  );
}

ContributeViewTaskPage.propTypes = {
  withMarking: PropTypes.bool,
};

export default ContributeViewTaskPage;
