import React, { useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useFetchAction from '../../../hooks/useFetchAction';
import {
  getTask as getTaskAction,
  createTaskAnswer as createTaskAnswerAction,
  getFormTask as getFormTaskAction,
  keepTask as keepTaskAction,
} from '../../../store/actions/task';
import useTranslate from '../../../hooks/useTranslate';
import { useParams } from 'react-router';
import Spinner from '../../atoms/Spinner/Spinner';
import ContributeFormTemplate from '../../templates/ContributeFormTemplate/ContributeFormTemplate';
import { useDispatch, useSelector } from 'react-redux';
import { initialize } from 'redux-form';
import ErrorContributeTemplate from '../../templates/ErrorContributeTemplate/ErrorContributeTemplate';
import ContributeCloseButtonTemplate from '../../templates/ContributeCloseButtonTemplate/ContributeCloseButtonTemplate';
import {
  allTasksIdsSelector,
  currentTaskSelector,
  lastTaskSentSelector,
  taskBufferCountSelector,
  tasksDepletedSelector,
  hasReceivedNullTaskSelector,
} from '../../../store/selectors/task';
import { createToast } from '../../../store/actions/toast';
import { ERROR } from '../../../constants/toastType';
import { processChoicesInjectionColumns, processDataColumns } from '../../../helpers/formTree';
import { parseDuration } from '../../../helpers/time';
import { API_GOOGLE_KEY } from '../../../constants/googleMapApiKey';
// eslint-disable-next-line no-console
const log = (...args) => console.log('ContributePage - ', ...args);

function ContributePage({ isReview }) {
  const params = useParams();
  const i18n = useTranslate();
  const tasksIds = useSelector(allTasksIdsSelector);
  const [getTask, taskLoading, taskError, task, taskStatus] = useFetchAction(getTaskAction);
  const [timerElapsed, setTimerElapsed] = useState(false);
  const [isFillingBuffer, setIsFillingBuffer] = useState(true);
  const [
    createTaskAnswer,
    ,
    taskAnswerError,
    ,
    taskAnswerStatus,
    resetTaskAnswerStatus,
  ] = useFetchAction(createTaskAnswerAction);
  const [keepTask] = useFetchAction(keepTaskAction);
  const [taskOrBufferCount, setTaskOrBufferCount] = useState(0);
  const [minTimeElapsed, setMinTimeElapsed] = useState(false);
  const [getForm, formLoading, formError, form] = useFetchAction(getFormTaskAction);
  const currentTask = useSelector(currentTaskSelector);
  const currentTasksCount = useSelector(taskBufferCountSelector);
  const lastTaskId = useSelector(lastTaskSentSelector);
  const subdivide = useMemo(() => (form?.hasSubdivide && !isReview ? form.subdivide : 0), [
    form,
    isReview,
  ]);
  const tasksDepleted = useSelector(tasksDepletedSelector);
  const hasReceivedNullTask = useSelector(hasReceivedNullTaskSelector);
  const dispatch = useDispatch();

  const getTaskWithParams = useCallback(() => {
    // eslint-disable-next-line
    log(`Fetch new task`);
    getTask(params.projectId, isReview, { ignoreError: true });
  }, [getTask, params.projectId, isReview]);

  // Creates a key-value pair of task data
  const formData = useMemo(() => processDataColumns(currentTask?.columns), [currentTask?.taskId]);

  // Generate choice inject overrides
  const taskChoicesData = useMemo(() => processChoicesInjectionColumns(currentTask?.columns), [
    currentTask?.taskId,
  ]);

  // init google map api for autocomplete adress field
  const initApiGoogleMap = () => {
    var script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_GOOGLE_KEY}&libraries=places&callback=initMap`;
    script.async = true;

    window.initMap = function () {};
    document.head.appendChild(script);
  };
  useEffect(() => {
    initApiGoogleMap();
  }, []);

  // First we load the  initial task
  useEffect(() => {
    getTaskWithParams();
  }, []);

  // Once task is loaded, load the form
  useEffect(() => {
    if (task && !form && !formLoading) {
      getForm(task.formId);
    }
  }, [task, form, formLoading]);

  // PUT task to backend
  const handleSubmit = useCallback(
    (values) => {
      if (currentTask.isUploading) {
        createToast(ERROR, i18n('UploadInput.pending', {}));
      } else {
        const endTaskDate = new Date();
        var d1 = currentTask.SupportDateTime.getTime();
        var d2 = endTaskDate.getTime();
        currentTask.duration = (d2 - d1) * 10000;
        createTaskAnswer(currentTask, values, { ignoreError: true });
      }
    },
    [currentTask?.taskId],
  );

  // Logging only
  useEffect(() => {
    if (currentTask) {
      // eslint-disable-next-line
      log(`Current task: ${currentTask.taskId}`);
      currentTask.SupportDateTime = new Date();
    }
  }, [currentTask?.taskId]);

  // Logging only
  useEffect(() => {
    if (subdivide > 1) {
      // eslint-disable-next-line
      log(`Tasks in buffer: ${currentTasksCount}`);
    }
  }, [currentTasksCount]);

  // Fill the buffer in subdivide mode
  useEffect(() => {
    if (!form || subdivide < 2) {
      return;
    }

    if (hasReceivedNullTask) {
      // eslint-disable-next-line
      log(`Tasks are depleted, stop loading`);

      if (isFillingBuffer && currentTask) {
        currentTask.SupportDateTime = new Date();
      }
      setIsFillingBuffer(false);
      return;
    }

    if (isFillingBuffer && currentTasksCount >= subdivide) {
      // eslint-disable-next-line
      log(`Buffer is full, stop loading`);
      currentTask.SupportDateTime = new Date();
      setIsFillingBuffer(false);
      return;
    }

    if (!isFillingBuffer || currentTasksCount >= subdivide || taskLoading) {
      return;
    }

    getTaskWithParams();
  }, [subdivide, isFillingBuffer, taskLoading, currentTasksCount, form, hasReceivedNullTask]);

  // Immediatly load a new task when an answer is succesfully submited
  useEffect(() => {
    if (taskAnswerStatus === 'success') {
      // eslint-disable-next-line
      log(`Task sent: ${lastTaskId}`);

      // Clear form state
      dispatch(initialize('contribute'));

      if (subdivide <= 1 && !hasReceivedNullTask) {
        setTaskOrBufferCount(taskOrBufferCount + 1);
        // Retrieved a new task in simple mode
        getTaskWithParams();
      } else if (!hasReceivedNullTask || currentTasksCount == 0) {
        setTaskOrBufferCount(taskOrBufferCount + 1);
        // Enable fill buffer in subdivide mode
        setIsFillingBuffer(true);
        // eslint-disable-next-line
        log(`Buffer is empty, start new batch`);
      } else if (currentTask && subdivide > 1) {
        currentTask.SupportDateTime = new Date();
      }

      resetTaskAnswerStatus();
    }
  }, [taskAnswerStatus, subdivide, hasReceivedNullTask]);

  useEffect(() => {
    if (!form) {
      return;
    }
    setMinTimeElapsed(false);
    let minTid;
    if (form.minimumDuration) {
      minTid = setTimeout(() => setMinTimeElapsed(true), parseDuration(form.minimumDuration));
    } else {
      setMinTimeElapsed(true);
    }

    return () => {
      clearTimeout(minTid);
    };
  }, [currentTask?.taskId, form?.id]);

  const keepCurrentTasks = useCallback(() => {
    log('Keep current tasks', tasksIds.join(', '));
    tasksIds.forEach((taskId) => {
      keepTask(taskId);
    });
  }, [tasksIds]);

  if (!tasksDepleted && !taskError && !formError && !taskAnswerError) {
    if (
      (taskStatus === 'pending' && !currentTask) ||
      !form ||
      taskAnswerStatus === 'pending' ||
      taskAnswerStatus === 'success'
    ) {
      return <Spinner />;
    }
  }

  return (
    <>
      {currentTask && (
        <ContributeCloseButtonTemplate
          taskId={currentTask.taskId}
          projectId={currentTask.projectId}
          onInactivityEnd={keepCurrentTasks}
        />
      )}
      <ErrorContributeTemplate
        tasksDepleted={tasksDepleted}
        taskError={taskError}
        taskAnswerError={taskAnswerError}
        formError={formError}
        timerElapsed={timerElapsed}
      >
        {form && (
          <ContributeFormTemplate
            data={formData}
            form={form}
            answer={currentTask?.answer}
            answers={currentTask?.answers}
            onSubmit={handleSubmit}
            choicesOverrides={taskChoicesData}
            taskExpiresAt={currentTask?.expiresAt}
            onTimerElapsed={() => setTimerElapsed(true)}
            onTimerAlmostElapsed={keepCurrentTasks}
            taskOrBufferCount={taskOrBufferCount}
            minTimeElapsed={minTimeElapsed}
          />
        )}
      </ErrorContributeTemplate>
    </>
  );
}

ContributePage.propTypes = {
  isReview: PropTypes.bool,
};

export default ContributePage;
