import React, { useEffect, useMemo } from 'react';
import { getFormValues } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateForm } from '../../../../../store/actions/editForm';
import InformationForm from '../../../../form/InformationForm/InformationForm';
import useCurrentForm from '../../../../../hooks/useCurrentForm';
import styles from './DrawerContentInfo.css';
import { isFormPublishedSelector } from '../../../../../store/selectors/editForm';

function DrawerContentInfo() {
  const dispatch = useDispatch();
  const isPublished = useSelector(isFormPublishedSelector);
  const values = useSelector(getFormValues('instructions'));
  const [form] = useCurrentForm();

  const initialValues = useMemo(
    () => ({
      instructions: form ? form.instructions : null,
    }),
    [form],
  );

  // On submit push data to the redux store
  useEffect(() => {
    if (values && typeof values.instructions === 'string') {
      dispatch(updateForm(values));
    }
  }, [values]);

  if (!form) {
    return null;
  }

  return (
    <div className={styles.DrawerContentInfo}>
      <InformationForm disabled={isPublished} initialValues={initialValues} />
    </div>
  );
}

export default DrawerContentInfo;
