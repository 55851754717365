import React from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import styles from './DraggableBoxStub.css';

// A "stub" is a template of a box.
function DraggableBoxStub({ item, children, className = '', style }) {
  const [, dragRef] = useDrag({
    item: { ...item, x: 0, y: 0 },
    options: { dropEffect: 'copy' },
  });
  return (
    <div ref={dragRef} className={[styles.DraggableBoxStub, className].join(' ')} style={style}>
      {children}
    </div>
  );
}

DraggableBoxStub.propTypes = {
  item: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.string,
};

export default DraggableBoxStub;
