export function swap(array, i1, i2) {
  const copy = [...array];

  copy[i1] = array[i2];
  copy[i2] = array[i1];

  return copy;
}

/* eslint-disable */
export function flatten(ob) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;
    if (typeof ob[i] == 'object' && ob[i] !== null) {
      if (ob[i].label && ob[i].type) {
        toReturn[i] = ob[i];
      } else {
        var flatObject = flatten(ob[i]);
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[i + '.' + x] = flatObject[x];
        }
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}
/* eslint-enable */
