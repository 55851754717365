import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Form from '../../atoms/Form/Form';

function ValidationRuleForm({ handleSubmit, children }) {
  return <Form onSubmit={handleSubmit}>{children}</Form>;
}

ValidationRuleForm.propTypes = {
  handleSubmit: PropTypes.func,
  children: PropTypes.node,
};

export default reduxForm({
  form: 'validationRule',
})(ValidationRuleForm);
