import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router';
import { PATH_PREVIEW_FORM } from '../../../constants/paths';
import {
  SHORTTEXT,
  NUMBER,
  SELECT,
  RANGE,
  SWITCH,
  SWITCHTOGGLE,
  RICHTEXT,
  EMAIL,
  LINK,
  MCQ,
  GPSCOORDINATES,
  PHONENUMBER,
  URL,
  ADVANCEDSELECT,
  DATETIMERANGE,
  DATETIME,
  ADDRESS,
  FILEUPLOAD,
  RIB,
} from '../../../constants/typology/fieldType';
import {
  TEXT as DATA_TEXT,
  NUM as DATA_NUM,
  VIEWER,
  LINK as DATA_LINK,
  VIDEO as DATA_VIDEO,
  IFRAME as DATA_IFRAME,
  PDF_OCR as DATA_PDF_OCR,
  AUDIO,
  IMAGE,
} from '../../../constants/typology/dataType';
import TextField from '../Field/Text/TextField';
import NumberField from '../Field/Number/NumberField';
import SelectField from '../Field/Select/SelectField';
import RangeField from '../Field/Range/RangeField';
import SwitchField from '../Field/Switch/SwitchField';
import SwitchToggleField from '../Field/SwitchToggle/SwitchToggleField';
import RichTextField from '../Field/RichText/RichTextField';
import EmailField from '../Field/Email/EmailField';
import LinkField from '../Field/Link/LinkField';
import GPSCoordinatesField from '../Field/GPSCoordinates/GPSCoordinatesField';
import TextData from '../Data/Text/TextData';
import PhoneField from '../Field/Phone/PhoneField';
import UrlField from '../Field/Url/UrlField';
import EmptyComponent from '../../atoms/Empty/EmptyComponent';
import LinkData from '../Data/Link/LinkData';
import ViewerData from '../Data/Viewer/ViewerData';
import VideoPlayerData from '../Data/VideoPlayer/VideoPlayerData';
import MCQField from '../Field/MCQ/MCQField';
import createValidator from '../../../helpers/validate';
import AdvancedSelectField from '../AdvancedSelectField/AdvancedSelectField';
import AudioPlayerData from '../Data/AudioPlayer/AudioPlayerData';
import ImageData from '../Data/Image/ImageData';
import DatetimeRangeField from '../Field/DatetimeRange/DatetimeRangeField';
import DatetimeField from '../Field/Datetime/DatetimeField';
import AddressField from '../Field/Address/AddressField';
import UploadField from '../Field/Upload/UploadField';
import IframeData from '../Data/Iframe/IframeData';
import RibField from '../Field/Rib/RibField';
import PdfOcrData from '../Data/PdfOcr/PdfOcrData';

function getRenderField(type) {
  switch (type) {
    case SHORTTEXT:
      return TextField;
    case NUMBER:
      return NumberField;
    case SELECT:
      return SelectField;
    case RANGE:
      return RangeField;
    case SWITCH:
      return SwitchField;
    case SWITCHTOGGLE:
      return SwitchToggleField;
    case RICHTEXT:
      return RichTextField;
    case EMAIL:
      return EmailField;
    case MCQ:
      return MCQField;
    case LINK:
      return LinkField;
    case DATA_TEXT:
      return TextData;
    case DATA_NUM:
      return TextData;
    case VIEWER:
      return ViewerData;
    case DATA_LINK:
      return LinkData;
    case DATA_VIDEO:
      return VideoPlayerData;
    case DATA_IFRAME:
      return IframeData;
    case DATA_PDF_OCR:
      return PdfOcrData;
    case GPSCOORDINATES:
      return GPSCoordinatesField;
    case PHONENUMBER:
      return PhoneField;
    case URL:
      return UrlField;
    case ADVANCEDSELECT:
      return AdvancedSelectField;
    case AUDIO:
      return AudioPlayerData;
    case IMAGE:
      return ImageData;
    case DATETIMERANGE:
      return DatetimeRangeField;
    case DATETIME:
      return DatetimeField;
    case ADDRESS:
      return AddressField;
    case FILEUPLOAD:
      return UploadField;
    case RIB:
      return RibField;
    default:
      return EmptyComponent;
  }
}
function AdaptableField({ fieldType, validation, label, name, ...props }) {
  const validate = useMemo(() => createValidator(validation, label || name), [
    validation,
    label,
    name,
  ]);

  const isPreview = useRouteMatch(PATH_PREVIEW_FORM) != null;

  const RenderField = getRenderField(fieldType);
  return (
    <RenderField
      {...props}
      name={name}
      label={label}
      required={validation && validation.required}
      information={validation ? validation.information : ''}
      validate={validate}
      validation={validation}
      remoteOptions={validation?.remoteOptions}
      isPreview={isPreview}
    />
  );
}

AdaptableField.propTypes = {
  fieldType: PropTypes.string,
  validation: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
};

export default AdaptableField;
