import React, { useEffect, useRef } from 'react';
import styles from './InfoPage.css';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  instructionsFormSelector,
  informationOpenSelector,
  shortcutFieldsSelector,
  keyboardShortcutsTextFieldsSelector,
  keyboardShortcutsMultiFieldsSelector,
} from '../../../store/selectors/editForm';
import { bem } from '../../../helpers/styles';
import { Route, useRouteMatch } from 'react-router';
import {
  PATH_CONTRIBUTE_VIEW_TASK,
  PATH_CONTRIBUTE_FORM,
  PATH_CONTRIBUTE_REVIEW_FORM,
  PATH_CONTRIBUTE_VIEW_HIT,
  PATH_INFO_FORM,
  PATH_CERTIFICATION_FORM,
} from '../../../constants/paths';
import useTranslate from '../../../hooks/useTranslate';
import AudioPlayer from '../../atoms/AudioPlayer/AudioPlayer';
import VideoPlayer from '../../atoms/VideoPlayer/VideoPlayer';
import TaskInfo from './TaskInfo/TaskInfo';

function InfoPage() {
  const i18n = useTranslate();
  const instructions = useSelector(instructionsFormSelector);
  const isOpen = useSelector(informationOpenSelector);

  const keyboardShortcutsFields = useSelector(shortcutFieldsSelector);
  const keyboardShortcutsTextFields = useSelector(keyboardShortcutsTextFieldsSelector);
  const keyboardShortcutsMultiFields = useSelector(keyboardShortcutsMultiFieldsSelector);

  const match = useRouteMatch();
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      const nodesAudio = ref.current.querySelectorAll('div.ql-audio');
      const nodesVideo = ref.current.querySelectorAll('div.ql-video');

      nodesAudio.forEach((nodeAudio) => {
        const url = nodeAudio.querySelector('div')?.getAttribute('url');
        if (url) {
          const child = <AudioPlayer url={url} controls />;
          React.render(child, nodeAudio);
        }
      });

      nodesVideo.forEach((nodeVideo) => {
        const url = nodeVideo.querySelector('source')?.getAttribute('src');
        if (url) {
          const child = <VideoPlayer url={url} className="ql-video" controls />;
          React.render(child, nodeVideo);
        }
      });
    }
  }, [ref?.current, instructions]);

  return (
    <div className={bem(styles, 'InfoPage', { isOpen: isOpen || match.path === PATH_INFO_FORM })}>
      <Route
        path={[
          PATH_CONTRIBUTE_FORM,
          PATH_CONTRIBUTE_VIEW_TASK,
          PATH_CONTRIBUTE_REVIEW_FORM,
          PATH_CONTRIBUTE_VIEW_HIT,
          PATH_CERTIFICATION_FORM,
        ]}
        component={TaskInfo}
      />
      {instructions && instructions !== '<p><br></p>' && (
        <h2 className={styles.InfoPage__title}>{i18n('InfoPage.title')}</h2>
      )}
      {typeof instructions === 'string' && (
        <div
          ref={ref}
          dangerouslySetInnerHTML={{
            __html: `${instructions} 
             ${
               keyboardShortcutsFields?.length ||
               keyboardShortcutsMultiFields?.length ||
               keyboardShortcutsTextFields?.length
                 ? `<h3>Les raccourcis claviers:</h3>`
                 : ``
             }
             <ul> 
                ${keyboardShortcutsFields?.reduce(
                  (acc, item) => acc.concat(`<li>${item}</li>`),
                  '',
                )}
             </ul>
             <ul>
                ${keyboardShortcutsMultiFields?.reduce(
                  (acc, item) => acc.concat(`<li>${item}</li>`),
                  '',
                )}
             </ul>   
             <ul> 
                ${keyboardShortcutsTextFields?.reduce(
                  (acc, item) => acc.concat(`<li>${item}</li>`),
                  '',
                )}
             </ul>
        `,
          }}
        ></div>
      )}
    </div>
  );
}

InfoPage.propTypes = {
  instructions: PropTypes.string,
};

export default InfoPage;
