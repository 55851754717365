import i18next from 'i18next';
import fr from './fr/default.yaml';
import en from './en/default.yaml';
import es from './es/default.yaml';
import * as config from '../helpers/config';
import { getOneOfCookie } from '../helpers/cookies';
import { getLang } from '../helpers/navigator';

i18next.init({
  // Get cookie settings or try to use navigator settings
  lng: getOneOfCookie(config.get('i18n.cookie')) || getLang(),
  fallbackLng: config.get('i18n.default'),
  // eslint-disable-next-line
  debug: config.get('i18n.debug') === 'true',
  resources: {
    fr: { translation: fr },
    en: { translation: en },
    es: { translation: es },
  },
});
