import React from 'react';
import PropTypes from 'prop-types';
import styles from './RadioColorInput.css';
import { bem } from '../../../helpers/styles';
import { COLORS, COLOR_TRANS } from '../../../constants/colors';

function RadioColorInput({ colors, value, onChange, disabled }) {
  return (
    <div className={styles.RadioColorInput}>
      {colors.map((color, i) => (
        <div
          key={i}
          className={bem(styles, 'RadioColorInput__bullet', { selected: value === color })}
          style={
            color !== COLOR_TRANS
              ? { background: color }
              : {
                  background: 'white',
                  backgroundSize: '10px 10px',
                  backgroundPosition: '0 0, 0 5px, 5px -5px, -5px 0px',
                  backgroundImage:
                    'linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%)',
                }
          }
          onClick={() => !disabled && onChange(color)}
        ></div>
      ))}
    </div>
  );
}

RadioColorInput.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

RadioColorInput.defaultProps = {
  colors: COLORS,
};

export default RadioColorInput;
