import React from 'react';
import PropTypes from 'prop-types';
import styles from './FormField.css';
import { bem } from '../../../../helpers/styles';
import FieldInfo from '../../../molecules/Field/Info/FieldInfo';
import FieldDropdown from '../../../molecules/FieldDropdown/FieldDropdown';
import ConfidenceIcon from '../../ConfidenceIcon/ConfidenceIcon';

function FormField({
  label,
  htmlFor,
  children,
  inline,
  reverse,
  information,
  required,
  verticalAlign,
  multi,
  answersChoices,
  name,
  confidence,
  onFirstChoicesOpen,
  forceAnswersChoicesCaret,
}) {
  let _style = {};
  if (verticalAlign) {
    _style = multi ? { verticalAlign, multi } : { verticalAlign };
  }
  return (
    <div className={bem(styles, 'FormField', _style)}>
      <div className={bem(styles, 'FormField__field', { inline, reverse })}>
        <div className={styles.FormField__header}>
          {label && (
            <label className={bem(styles, 'FormField__header--label')} htmlFor={htmlFor}>
              {confidence && <ConfidenceIcon confidence={confidence} />}
              {label}
              {required && '*'}
            </label>
          )}
          {((answersChoices?.length || 0) !== 0 || forceAnswersChoicesCaret) && (
            <FieldDropdown
              onFirstOpen={onFirstChoicesOpen}
              className={bem(styles, 'FormField__header--dropdown')}
              icon="arrow_dropdown_down"
              options={answersChoices}
              name={name}
            />
          )}
        </div>
        {children}
      </div>

      {information && <FieldInfo content={information} />}
    </div>
  );
}

FormField.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  label: PropTypes.string,
  htmlFor: PropTypes.string,
  inline: PropTypes.bool,
  reverse: PropTypes.bool,
  required: PropTypes.bool,
  information: PropTypes.string,
  verticalAlign: PropTypes.bool,
  multi: PropTypes.bool,
  answersChoices: PropTypes.array,
  regex: PropTypes.array,
  confidence: PropTypes.number,
  onFirstChoicesOpen: PropTypes.func,
  forceAnswersChoicesCaret: PropTypes.bool,
};

FormField.defaultProps = {
  required: false,
  verticalAlign: false,
  answersChoices: null,
};

export default FormField;
