import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, formValueSelector, change, touch } from 'redux-form';
import Form from '../../atoms/Form/Form';
import { FORM_QUALITY_STRATEGY } from '../../../constants/form';
import useTranslate from '../../../hooks/useTranslate';
import SelectField from '../../molecules/Field/Select/SelectField';
import MCQField from '../../molecules/Field/MCQ/MCQField';
import NumberField from '../../molecules/Field/Number/NumberField';
import SwitchField from '../../molecules/Field/Switch/SwitchField';
import { useDispatch, useSelector } from 'react-redux';
import RichTextField from '../../molecules/Field/RichText/RichTextField';
import { createOptionsTuples } from '../../../helpers/options';
import RadioBoxField from '../../molecules/Field/RadioBox/RadioBoxField';
import SwitchToggleField from '../../molecules/Field/SwitchToggle/SwitchToggleField';
import AdaptableField from '../../molecules/Adaptable/AdaptableField';
import { NUMBER } from '../../../constants/typology/fieldType';

const valuesSelector = formValueSelector(FORM_QUALITY_STRATEGY);

const validation = {
  respondentPerTask: { valueRange: { min: 2 } },
  matchingCount: { valueRange: { min: 2 } },
};

function QualityStrategyForm({ form, handleSubmit, disabled }) {
  const i18n = useTranslate();
  const dispatch = useDispatch();

  // Transform options values into nice tuples for select / mcq
  const topOptions = useMemo(
    () =>
      createOptionsTuples(['panel', 'proofreading', 'multi'], {
        i18nKey: 'QualityStrategyForm.topOptions',
      }),
    [i18n],
  );
  const sampleOptions = useMemo(
    () =>
      createOptionsTuples(['multi', 'sample'], {
        i18nKey: 'QualityStrategyForm.sampleOptions',
      }),
    [i18n],
  );

  const undeterminateOptions = useMemo(
    () =>
      createOptionsTuples(['bestAnswer', 'manual'], {
        i18nKey: 'QualityStrategyForm.undeterminateOptions',
      }),
    [i18n],
  );

  const answerMultiOptions = useMemo(
    () =>
      createOptionsTuples([true, false], {
        i18nKey: 'QualityStrategyForm.answerMultiOptions',
      }),
    [i18n],
  );

  const answerProofReadingOptions = useMemo(
    () =>
      createOptionsTuples(['lastAnswers', 'allAnswer'], {
        i18nKey: 'QualityStrategyForm.answerProofReadingOptions',
      }),
    [i18n],
  );

  // Retrieve values that changes form layout
  const {
    strategy,
    hasReaderInstructions,
    sampleMode,
    isComplex,
    respondentPerTask,
    matchingCount,
    indeterminateMode,
  } =
    useSelector((state) =>
      valuesSelector(
        state,
        'strategy',
        'hasReaderInstructions',
        'sampleMode',
        'indeterminateMode',
        'isComplex',
        'respondentPerTask',
        'matchingCount',
      ),
    ) || {};

  const iterationCountValidation = useMemo(
    () => ({
      valueRange: { min: matchingCount },
    }),
    [matchingCount],
  );

  // Touch fields immediatly so that errors are show on mount
  useEffect(() => {
    dispatch(touch(form, 'respondentPerTask', 'matchingCount', 'iterationCount'));
  }, []);

  // Some fields values are only valid in some contexts
  useEffect(() => {
    // Updates strategy, if needed, when isComplex is toggled
    if (isComplex && strategy === 'simple') {
      dispatch(change(form, 'strategy', 'panel'));
    }
    if (!isComplex && strategy !== 'simple') {
      dispatch(change(form, 'strategy', 'simple'));
      dispatch(change(form, 'respondentPerTask', 1));
    }

    switch (strategy) {
      case 'simple':
        dispatch(change(form, 'respondentPerTask', 1));
        break;
      case 'panel':
        if (respondentPerTask < 2) {
          dispatch(change(form, 'respondentPerTask', 2));
        }
        break;
      case 'proofreading':
      case 'multi':
        dispatch(change(form, 'respondentPerTask', 2));
        break;
      default:
        break;
    }
  }, [isComplex, strategy]);

  return (
    <Form onSubmit={handleSubmit}>
      <SwitchToggleField
        name="isComplex"
        labelLeft={i18n('QualityStrategyForm.isComplex.right')}
        labelRight={i18n('QualityStrategyForm.isComplex.left')}
      />
      {isComplex && (
        <>
          <RadioBoxField flex name="strategy" options={topOptions} disabled={disabled} />
          {strategy === 'panel' && (
            <AdaptableField
              fieldType={NUMBER}
              name="respondentPerTask"
              label={i18n('QualityStrategyForm.respondentPerTask')}
              disabled={disabled}
              validation={validation.respondentPerTask}
            />
          )}
          {strategy === 'multi' && (
            <>
              <MCQField
                name="sampleMode"
                options={sampleOptions}
                layout="horizontal"
                verticalAlign={false}
                style={true}
                disabled={disabled}
              />
              {sampleMode === 'sample' && (
                <NumberField
                  name="samplePercent"
                  label={i18n('QualityStrategyForm.samplePercent')}
                  validation={{ optionalUnit: '%' }}
                  disabled={disabled}
                />
              )}
              <AdaptableField
                fieldType={NUMBER}
                name="matchingCount"
                label={i18n('QualityStrategyForm.matchingCount')}
                verticalAlign={false}
                disabled={disabled}
                validation={validation.matchingCount}
              />
              <AdaptableField
                fieldType={NUMBER}
                name="iterationCount"
                label={i18n('QualityStrategyForm.iterationCount')}
                verticalAlign={false}
                disabled={disabled}
                validation={iterationCountValidation}
              />
              <SelectField
                label={i18n('QualityStrategyForm.indeterminateMode')}
                name="indeterminateMode"
                options={undeterminateOptions}
                disabled={disabled}
              />
              {indeterminateMode === 'bestAnswer' && (
                <MCQField
                  name="bestAnswerOnly"
                  options={answerMultiOptions}
                  layout="vertical"
                  style={true}
                  disabled={disabled}
                />
              )}
            </>
          )}
          {strategy === 'proofreading' && (
            <>
              <MCQField
                name="sampleMode"
                options={sampleOptions}
                layout="horizontal"
                style={true}
                disabled={disabled}
              />
              {sampleMode === 'sample' && (
                <NumberField
                  name="samplePercent"
                  label={i18n('QualityStrategyForm.samplePercent')}
                  validation={{ optionalUnit: '%' }}
                  disabled={disabled}
                />
              )}
              <NumberField
                name="proofreadingCount"
                label={i18n('QualityStrategyForm.proofreadingCount')}
                disabled={disabled}
              />
              <MCQField
                name="answerOptions"
                label={i18n('QualityStrategyForm.sendingAnswer')}
                options={answerProofReadingOptions}
                layout="vertical"
                style={true}
                disabled={disabled}
              />
              <SwitchField
                label={i18n('QualityStrategyForm.readerInstructions')}
                name="hasReaderInstructions"
                disabled={disabled}
              />
              {hasReaderInstructions && (
                <RichTextField
                  name="readerInstructions"
                  verticalAlign={false}
                  disabled={disabled}
                />
              )}
            </>
          )}
        </>
      )}
    </Form>
  );
}

QualityStrategyForm.propTypes = {
  handleSubmit: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  form: PropTypes.string,
};

export default reduxForm({
  form: FORM_QUALITY_STRATEGY,
})(QualityStrategyForm);
