import { v4 as uuid } from 'uuid';
import { formatForm } from '../../api/form';

export const FORM_CREATE = 'FORM_CREATE';
export const FORM_CREATE_SUCCESS = 'FORM_CREATE_SUCCESS';
export const FORM_UPDATE = 'FORM_UPDATE';
export const FORM_UPDATE_SUCCESS = 'FORM_UPDATE_SUCCESS';
export const FORM_GET = 'FORM_GET';
export const FORM_GET_SUCCESS = 'FORM_GET_SUCCESS';
export const FORM_FIELD_ADD = 'FORM_FIELD_ADD';
export const FORM_FIELD_UPDATE = 'FORM_FIELD_UPDATE';
export const FORM_FIELD_DELETE = 'FORM_FIELD_DELETE';
export const FORM_BLOCK_ADD = 'FORM_BLOCK_ADD';
export const FORM_BLOCK_UPDATE = 'FORM_BLOCK_UPDATE';
export const FORM_BLOCK_MOVE = 'FORM_BLOCK_MOVE';
export const FORM_BLOCK_DELETE = 'FORM_BLOCK_DELETE';
export const FORM_VALIDATIONRULE_ADD = 'FORM_VALIDATIONRULE_ADD';
export const FORM_VALIDATIONRULE_UPDATE = 'FORM_VALIDATIONRULE_UPDATE';
export const FORM_VALIDATIONRULE_DELETE = 'FORM_VALIDATIONRULE_DELETE';
export const FORM_ITERATIONOVERLOAD_ADD = 'FORM_ITERATIONOVERLOAD_ADD';
export const FORM_ITERATIONOVERLOAD_UPDATE = 'FORM_ITERATIONOVERLOAD_UPDATE';
export const FORM_ITERATIONOVERLOAD_DELETE = 'FORM_ITERATIONOVERLOAD_DELETE';

export const createForm = ({ name }) => ({
  type: FORM_CREATE,
  fetch: {
    method: 'POST',
    url: '{{backendUrl}}/FormService/Form/CreateTemplate',
    auth: true,
    parse: 'parseForm',
    json: {
      Json: JSON.stringify({ name }),
    },
  },
  meta: { requestId: uuid() },
});

export const getForm = (formId) => ({
  type: FORM_GET,
  fetch: {
    method: 'GET',
    url: `{{backendUrl}}/FormService/Form/${formId}/GetTemplate`,
    auth: true,
    parse: 'parseForm',
  },
  meta: { requestId: uuid() },
});

export const updateForm = (values) => ({
  type: FORM_UPDATE,
  fetch: {
    method: 'PUT',
    url: `{{backendUrl}}/FormService/Form/UpdateTemplate`,
    auth: true,
    parse: 'parseForm',
    json: formatForm(values),
  },
  meta: { requestId: uuid() },
});

export const addField = (form, { type, box, data }) => ({
  type: FORM_FIELD_ADD,
  payload: { form, field: { id: uuid(), type, box, ...data } },
});

export const updateField = (form, fieldId, data) => ({
  type: FORM_FIELD_UPDATE,
  payload: { form, fieldId, data },
});

export const deleteField = (form, fieldId) => ({
  type: FORM_FIELD_DELETE,
  payload: { form, fieldId },
});

export const addBlock = (form, { color, box, name }) => ({
  type: FORM_BLOCK_ADD,
  payload: { form, block: { id: uuid(), color, name, box } },
});

export const updateBlock = (form, blockId, data) => ({
  type: FORM_BLOCK_UPDATE,
  payload: { form, blockId, data },
});
export const moveBlock = (form, blockId, data) => ({
  type: FORM_BLOCK_MOVE,
  payload: { form, blockId, data },
});

export const deleteBlock = (form, blockId) => ({
  type: FORM_BLOCK_DELETE,
  payload: { form, blockId },
});

export const addValidationRule = (payload = {}) => ({
  type: FORM_VALIDATIONRULE_ADD,
  payload,
});

export const updateValidationRule = (ruleId, data) => ({
  type: FORM_VALIDATIONRULE_UPDATE,
  payload: { ruleId, data },
});

export const deleteValidationRule = (ruleId) => ({
  type: FORM_VALIDATIONRULE_DELETE,
  payload: { ruleId },
});

export const addIterationOverloadRule = (payload = {}) => ({
  type: FORM_ITERATIONOVERLOAD_ADD,
  payload,
});

export const updateIterationOverloadRule = (ruleId, data) => ({
  type: FORM_ITERATIONOVERLOAD_UPDATE,
  payload: { ruleId, data },
});

export const deleteIterationOverloadRule = (ruleId) => ({
  type: FORM_ITERATIONOVERLOAD_DELETE,
  payload: { ruleId },
});
