import * as config from '../../helpers/config';

const errorMiddleware = () => (next) => (action) => {
  next(action);

  if (action.type.endsWith('_ERROR') && !action.meta?.ignoreError) {
    if (action.payload?.code === 401) {
      const idSiteCookie = getCookie('IdSite');
      window.location.href = config.get('url.team.signout');
    }
      // TODO: we need something better here but at least we have something
      alert(`An error occured:\n${JSON.stringify(action, null, 2)}`);
    }
};

export default errorMiddleware;
