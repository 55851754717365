import React from 'react';
import PropTypes from 'prop-types';

import DrawerContentItemsPage from './Items/DrawerContentItems';

function DrawerContentPage({ match }) {
  return <DrawerContentItemsPage match={match} />;
}

DrawerContentPage.propTypes = {
  match: PropTypes.shape({
    path: PropTypes.string,
    params: PropTypes.shape({
      formId: PropTypes.string,
    }),
  }),
};

export default DrawerContentPage;
