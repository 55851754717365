import { get } from 'object-path';
import { OPTIONS_GET, OPTIONS_SUCCESS } from '../actions/options';
import { optionsStateSelector } from '../selectors/options';
import { getResponsePayload } from './fetch';

export async function fetchRemoteOptionsFromSettings(settings) {
  const {
    url,
    query,
    pathOptions,
    pathValue = 'value',
    pathLabel = 'label',
    authorization,
  } = settings;

  const fetchOptions = {
    headers: new Headers(),
  };
  if (authorization) {
    fetchOptions.headers.set('Authorization', authorization);
  }
  const response = await fetch(url.replace('{query}', query), fetchOptions);
  const json = await getResponsePayload(response);
  const items = pathOptions ? get(json, pathOptions) : json;
  const options = items.map((item) => ({
    value: get(item, pathValue),
    label: get(item, pathLabel),
  }));
  return options;
}
export default function optionsMiddleware({ getState, dispatch }) {
  return (next) => async (action) => {
    if (action.type !== OPTIONS_GET) {
      return next(action);
    }

    const alreadyInState = optionsStateSelector(action.payload)(getState());
    next(action);

    if (alreadyInState) {
      return;
    }

    try {
      const {
        url,
        query,
        pathOptions,
        pathValue = 'value',
        pathLabel = 'label',
        authorization,
      } = action.payload;

      const fetchOptions = {
        headers: new Headers(),
      };
      if (authorization) {
        fetchOptions.headers.set('Authorization', authorization);
      }
      const response = await fetch(url.replace('{query}', query), fetchOptions);
      const json = await getResponsePayload(response);
      const items = pathOptions ? get(json, pathOptions) : json;
      const options = items.map((item) => ({
        value: get(item, pathValue),
        label: get(item, pathLabel),
      }));
      dispatch({
        type: OPTIONS_SUCCESS,
        payload: { fetchOptions: action.payload, options },
      });
    } catch (error) {
      dispatch({
        type: OPTIONS_SUCCESS,
        payload: { fetchOptions: action.payload, error },
      });
    }
  };
}
