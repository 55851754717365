import { useLocation } from 'react-router';

function paramsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}

export default function useQuery() {
  const location = useLocation();
  return location.search ? paramsToObject(new URLSearchParams(location.search)) : null;
}
