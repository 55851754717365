import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  focusedBlockIdSelector,
  formBlocksSelector,
} from '../../../../../../store/selectors/editForm';
import useTranslate from '../../../../../../hooks/useTranslate';
import Spoiler from '../../../../../molecules/Spoiler/Spoiler';
import BlockHeaderTemplate from '../../../../../templates/BlockHeaderTemplate/BlockHeaderTemplate';
import { setFocusedBlock as setFocusedBlockAction } from '../../../../../../store/actions/editForm';
import { useActions } from '../../../../../../hooks/useActions';
import styles from './DrawerContentBlocksSorting.css';
import BlockSortingRulesTemplate from '../../../../../templates/BlockSortingRulesTemplate/BlockSortingRulesTemplate';
import QualitySelectTemplate from '../../../../../templates/QualitySelectTemplate/QualitySelectTemplate';

function DrawerContentBlocksSortingPage() {
  const i18n = useTranslate();
  const blocks = useSelector(formBlocksSelector);
  const focusedBlockId = useSelector(focusedBlockIdSelector);
  const [setFocusedBlock] = useActions([setFocusedBlockAction]);

  // Focus first block, if any, on mount
  useEffect(() => {
    if (blocks.length && !focusedBlockId) {
      setFocusedBlock(blocks[0].id);
    }
  }, [blocks.length, focusedBlockId]);

  return (
    <div>
      <QualitySelectTemplate icon="compare_blocks" titleKey="QualityNavTemplate.blocksSorting" />
      <h3 className={styles.DrawerContentBlocksSorting__title}>
        {i18n('DrawerContentBlocksSortingPage.title')}
      </h3>
      <p className={styles.DrawerContentBlocksSorting__description}>
        {i18n('DrawerContentBlocksSortingPage.description')}
      </p>
      {blocks.length ? (
        <>
          {blocks.map((block, i) =>
            block.duplicable ? (
              <Spoiler
                key={i}
                renderHeader={() => <BlockHeaderTemplate block={block} />}
                open={block.id === focusedBlockId}
                onClick={() => setFocusedBlock(block.id)}
                render={() => <BlockSortingRulesTemplate />}
              />
            ) : null,
          )}
        </>
      ) : (
        <p className={styles.DrawerContentBlocksSorting__empty}>
          {i18n('DrawerContentBlocksSortingPage.empty')}
        </p>
      )}
    </div>
  );
}

DrawerContentBlocksSortingPage.propTypes = {};

export default DrawerContentBlocksSortingPage;
