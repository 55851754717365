import React, { useState, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import Button from '../../atoms/Button/Button';
import styles from './Dropdown.css';
import { classnames } from '../../../helpers/classnames';
import ConfidenceIcon from '../../atoms/ConfidenceIcon/ConfidenceIcon';
import useTranslate from '../../../hooks/useTranslate';

function Dropdown({ className, options, onSelect, onOpen, ...props }) {
  const [open, setOpen] = useState(false);
  const [buttonEl, setButtonEl] = useState(null);
  const [scrollOffset, setScrollOffset] = useState(0);
  const i18n = useTranslate();
  // Recompute menu style when ref changes
  const menuStyle = useMemo(() => {
    if (!buttonEl) {
      return {};
    }
    const rect = buttonEl.getBoundingClientRect();
    return {
      top: rect.top,
      left: rect.left,
    };
  }, [buttonEl, open, scrollOffset]);

  useEffect(() => {
    const formRoot = document.getElementById('form_root');
    if (formRoot) {
      setScrollOffset(formRoot.scrollTop);
    }
  }, [open]);

  // When the menu is open, clicking anywhere closes it
  useEffect(() => {
    const handleClickAnywhere = () => setOpen(false);
    const formRoot = document.getElementById('form_root');
    const handleScroll = () => {
      setScrollOffset(formRoot.scrollTop);
    };
    if (open) {
      document.addEventListener('click', handleClickAnywhere);
      formRoot && formRoot.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (open) {
        document.removeEventListener('click', handleClickAnywhere);
        formRoot && formRoot.removeEventListener('scroll', handleScroll);
      }
    };
  }, [open]);

  return (
    <>
      <Button
        {...props}
        className={classnames([styles.Dropdown__button, className])}
        ref={setButtonEl}
        type="button"
        onClick={() => {
          setOpen(true);
          onOpen && onOpen();
        }}
      />
      {/* Use a portal to avoid messing up with overflow: hidden stuff */}
      {open &&
        createPortal(
          <div className={styles.Dropdown__menu} style={menuStyle}>
            {options.length ? (
              options.map(({ label, value, confidence }, i) => (
                <button
                  key={i}
                  type="button"
                  className={styles.Dropdown__option}
                  onClick={() => onSelect(value)}
                >
                  {confidence ? <ConfidenceIcon confidence={confidence} /> : null}
                  {label}
                </button>
              ))
            ) : (
              <div className={styles.Dropdown__empty}>{i18n('Dropdown.empty')}</div>
            )}
          </div>,
          document.body,
        )}
    </>
  );
}

Dropdown.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  onSelect: PropTypes.func,
  onOpen: PropTypes.func,
};

export default Dropdown;
