import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { bem } from '../../../helpers/styles';
import SVG from '../SVG/SVG';
import styles from './Rating.css';

const Rating = ({ size, value, onClick, titleRight, titleLeft }) => {
  const [clickRate, setClickRating] = useState(value);
  const [rate, setRating] = useState(value);

  const handleSetRating = useCallback(
    (index) => {
      if (index === rate) setClickRating(0);

      setClickRating(index);
      setRating(index);
      onClick(index);
    },
    [clickRate, rate],
  );

  const handleSetPendingRating = useCallback(
    (index) => {
      setRating(index || clickRate);
    },
    [clickRate, rate],
  );

  return (
    <div className={styles.Rating} size={size} value={rate}>
      {titleLeft && <span>{titleLeft}</span>}

      {[...Array(size).keys()].map((value, index) => (
        <div
          isRating={rate >= index + 1}
          key={index + 1}
          onClick={() => handleSetRating(index + 1)}
          onMouseOver={() => handleSetPendingRating(index + 1)}
          onMouseLeave={() => handleSetPendingRating()}
        >
          <SVG
            glyph={'star'}
            className={bem(styles, 'Rating__star', { active: rate >= index + 1 })}
          />
        </div>
      ))}
      {titleRight && <span>{titleRight}</span>}
    </div>
  );
};

Rating.propTypes = {
  size: PropTypes.number,
  value: PropTypes.number,
  onClick: PropTypes.func,
  titleRight: PropTypes.string,
  titleLeft: PropTypes.string,
};

Rating.defaultProps = {
  size: 5,
  value: 0,
  onClick: () => null,
};
export default Rating;
