import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

function PdfOcrViewerPageGeolocLayer({ page, viewports, goToField, containerRef, metadata }) {
  const ref = useRef();

  useLayoutEffect(() => {
    if (ref.current == null) {
      return;
    }

    const geometry = goToField.geometry;

    const canvas = ref.current;
    const context = canvas.getContext('2d');

    canvas.height = viewports.scaled.height;
    canvas.width = viewports.scaled.width;

    const scale = viewports.scaled.width / metadata.dimensions.len_x;

    const x = Math.min(...geometry.Polygon.map((p) => p.X)) * scale;
    const y = Math.min(...geometry.Polygon.map((p) => p.Y)) * scale;

    const width = Math.max(...geometry.Polygon.map((p) => p.X)) * scale - x;
    const height = Math.max(...geometry.Polygon.map((p) => p.Y)) * scale - y;

    context.fillStyle = 'rgba(14, 241, 188, 0.5)';
    context.fillRect(x, y, width, height);

    // Draw a tooltip if the field has a value
    if (goToField.value != null) {
      context.font = '16px Arial';

      const text = `${goToField.value}`;
      const metrics = context.measureText(text);

      const textWidth =
        Math.abs(metrics.actualBoundingBoxLeft) + Math.abs(metrics.actualBoundingBoxRight);
      const textHeight =
        Math.abs(metrics.actualBoundingBoxAscent) + Math.abs(metrics.actualBoundingBoxDescent);

      const paddingFromField = 5;
      const padding = 5;
      const pagePadding = 10;
      const tooltipWidth = textWidth + padding * 2;
      const tooltipHeight = textHeight + padding * 2;

      // Calculate the position of the tooltip (center horizontally, below the field)
      let tooltipX = x + width / 2 - tooltipWidth / 2;
      let tooltipY = y + height + paddingFromField;

      // Make sure the tooltip is not outside the page by clamping it
      if (tooltipX < 0) {
        tooltipX = pagePadding;
      } else if (tooltipX + tooltipWidth > viewports.scaled.width) {
        tooltipX = viewports.scaled.width - tooltipWidth - pagePadding;
      }

      if (tooltipY < 0) {
        tooltipY = pagePadding;
      } else if (tooltipY + tooltipHeight > viewports.scaled.height) {
        tooltipY = viewports.scaled.height - tooltipHeight - pagePadding;
      }

      // Draw the tooltip background
      context.fillStyle = 'rgba(255, 255, 255, 0.8)';
      context.fillRect(tooltipX, tooltipY, tooltipWidth, tooltipHeight);

      // Draw the tooltip text
      context.fillStyle = 'hsl(199, 89%, 22%)';
      context.fillText(text, tooltipX + padding, tooltipY + padding + textHeight);
    }

    // Scroll the field into view at the center of the page
    containerRef.current.scrollTo({
      top: y + ref.current.offsetTop - containerRef.current.offsetHeight / 2,
      left: x - containerRef.current.offsetWidth / 2,
      behavior: 'smooth',
    });
  }, [ref.current, page, viewports, goToField]);

  return (
    <canvas
      ref={ref}
      style={{
        width: viewports.scaled.width,
        height: viewports.scaled.height,
        pointerEvents: 'none',
      }}
    />
  );
}

PdfOcrViewerPageGeolocLayer.propTypes = {
  page: PropTypes.object.isRequired,
  viewports: PropTypes.object.isRequired,
  goToField: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
};

export default PdfOcrViewerPageGeolocLayer;
