/**
 * @description Créé la liste des classes à appliquer à un composant.
 * @example
 *   const className = classnames([
 *    'class1', // Nom de classe sous forme de string
 *     // Objet contenant un nom de classe comme propriété et un boolean comme valeur
 *     // La condition permettra de d'ajouter ou non la classe à la liste
 *    {'class2': condition}
 *  ]);
 *
 * @param {array} classnames - Tableau de string ou d'objet.
 * @returns {string} Liste des classes.
 */
export function classnames(classnames) {
  return classnames
    .reduce((acc, classname) => {
      if (!classname) return acc;
      const name =
        typeof classname === 'string'
          ? classname
          : Object.entries(classname)[0][1]
          ? Object.entries(classname)[0][0]
          : '';
      return `${acc}${name ? ` ${name}` : ''}`;
    }, '')
    .trim();
}
