import { v4 as id } from 'uuid';
import React, { useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Spoiler from '../Spoiler/Spoiler';
import TextField from '../Field/Text/TextField';
import ValidationRuleForm from '../../form/ValidationRuleForm/ValidationRuleForm';
import Button from '../../atoms/Button/Button';
import styles from './ValidationRule.css';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import { useActions } from '../../../hooks/useActions';
import {
  deleteValidationRule as deleteValidationRuleAction,
  updateValidationRule as updateValidationRuleAction,
} from '../../../store/actions/form';
import compare from '../../../helpers/compare';
import FormulaField from '../Field/Formula/FormulaField';
import useTranslate from '../../../hooks/useTranslate';
import { FIELD_REFERENCE } from '../../../constants/formula';
import { setSelectFields as setSelectFieldsAction } from '../../../store/actions/editForm';
import { formBlocksSelector, selectFieldsSelector } from '../../../store/selectors/editForm';
import { getSmallestBlock } from '../../../helpers/formTree';

function ValidationRule({ rule, open, onFocus }) {
  const i18n = useTranslate();
  const [updateValidationRule, deleteValidationRule, setSelectFields] = useActions([
    updateValidationRuleAction,
    deleteValidationRuleAction,
    setSelectFieldsAction,
  ]);
  const dispatch = useDispatch();
  const formName = `validationRule_${rule.id}`;
  const values = useSelector(getFormValues(formName));
  const selectFields = useSelector(selectFieldsSelector);
  const blocks = useSelector(formBlocksSelector);

  const handleClickDelete = useCallback(
    (event) => {
      event.stopPropagation();
      deleteValidationRule(rule.id);
    },
    [rule],
  );

  // Push fields to the formula as they are added
  useEffect(() => {
    // An undefined values means the form is not mounted yet
    if (!open || typeof values === 'undefined') {
      return;
    }

    if (selectFields.length) {
      const addedField = selectFields[0];
      const block = getSmallestBlock(addedField, blocks, { duplicable: true });

      dispatch(
        change(formName, 'formula', [
          ...(values.formula || []),
          {
            type: FIELD_REFERENCE,
            field: addedField.id,
            path: addedField.id,
            typology: addedField.type,
            blockScope: block ? block.id : null,
            // Default to "sum" for duplicable blocks, "count" for non-duplicable ones
            duplicableResolution: block ? 'foreachIter' : null,
            // duplicableResolution: block ? 'sum' : null,
            id: id(),
          },
        ]),
      );
      setSelectFields([]);
    }
  }, [open, selectFields, values, formName, blocks]);

  const renderHeader = useMemo(
    () =>
      function ValidationRuleHeader() {
        return (
          <div className={styles.ValidationRule__header}>
            {open ? <TextField name="name" /> : <div>{rule.name}</div>}
            <Button
              icon="trash"
              flat
              className={styles.ValidationRule__deleteButton}
              onClick={handleClickDelete}
            />
          </div>
        );
      },
    [open, rule.name, rule.id],
  );

  useEffect(() => {
    if (values && !compare(rule, values)) {
      updateValidationRule(rule.id, values);
    }
  }, [rule, values]);

  const handleClickHeader = useCallback(() => {
    onFocus(rule.id);
  }, [rule]);

  return (
    <>
      {/* Wrapping the spoiler itself in a form so we can use *Field components */}
      <ValidationRuleForm initialValues={rule} form={formName}>
        <Spoiler
          open={open}
          title={rule.name}
          onClick={handleClickHeader}
          renderHeader={renderHeader}
          render={() => (
            <div style={{ padding: '1rem' }}>
              <FormulaField
                name="formula"
                placeholder={i18n('ValidationRule.formulaPlaceholder')}
              />
              <TextField
                name="errorMessage"
                placeholder={i18n('ValidationRule.errorMessagePlaceholder')}
                label={i18n('ValidationRule.errorMessage')}
                rows={4}
              />
            </div>
          )}
        />
      </ValidationRuleForm>
    </>
  );
}

ValidationRule.propTypes = {
  rule: PropTypes.object,
  open: PropTypes.bool,
  onFocus: PropTypes.func,
};

export default ValidationRule;
