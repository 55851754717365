import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Alert from './Alert';

// Grid context used to expose to all tree
export const AlertContext = React.createContext({
  openAlert: () => {},
});

function AlertProvider({ children }) {
  const [{ open, message, buttonLabel, callback }, setMessage] = useState({
    open: false,
    message: '',
    buttonLabel: '',
    callback,
  });

  return (
    <AlertContext.Provider
      value={{
        openAlert: (message, buttonLabel, callback) =>
          setMessage({ message, buttonLabel, open: true, callback }),
      }}
    >
      {open && (
        <Alert
          message={message}
          buttonLabel={buttonLabel}
          onClose={() => {
            setMessage({ open: false });
            callback && callback();
          }}
        />
      )}
      {children}
    </AlertContext.Provider>
  );
}

AlertProvider.propTypes = {
  children: PropTypes.node,
};

export default AlertProvider;
