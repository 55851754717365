import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { formFieldsSelector, selectShortcutSelector } from '../../../store/selectors/editForm';
import { contributeFormSelector } from '../../../store/selectors/form';
import { setFocusedField } from '../../../store/actions/editForm';
import {
  GPSCOORDINATES,
  PHONENUMBER,
  ADVANCEDSELECT,
  MCQ,
} from '../../../constants/typology/fieldType';
import { slugify } from '../../../helpers/slugify';

function getSelector(humanName, field) {
  switch (field.type) {
    case PHONENUMBER:
      return `[name$="${humanName}"] > input`;

    case ADVANCEDSELECT:
      return `[name$="${humanName}_select_proxy"]`;

    case MCQ:
      return `#${humanName}_MCQ_0`;
  }

  return `[name$="${humanName}"]`;
}

function ShortcutKeyboard() {
  const dispatch = useDispatch();

  const fields = useSelector(formFieldsSelector);
  const contribute = useSelector(contributeFormSelector);
  const keyMap = useSelector(selectShortcutSelector);
  const keys = Object.keys(keyMap);

  const handleShortcurt = (key, e) => {
    const field = fields.find((field) => {
      if (field.id === keyMap[key]) return field.id;
    });

    const humanName = `${
      field.label ? slugify(field.label.toLowerCase()) : 'field'
    }_${fields.indexOf(field)}${field.type === GPSCOORDINATES ? '.latitude' : ''}`;
    if (contribute) {
      const selector = getSelector(humanName, field);
      const element = document.querySelector(selector);

      if (element != null) {
        element.focus();
      }
    } else dispatch(setFocusedField(field.id));
    e.preventDefault();
  };
  return <KeyboardEventHandler handleKeys={keys} onKeyEvent={handleShortcurt} />;
}

export default ShortcutKeyboard;
