import moment from 'moment';

import {
  ERROR_REQUIRED,
  ERROR_MIN_CHARS,
  ERROR_MAX_CHARS,
  ERROR_INTEGER,
  ERROR_DOMAINS,
  ERROR_EMAIL,
  ERROR_PHONE,
  ERROR_BELOW_MIN,
  ERROR_ABOVE_MAX,
  ERROR_MIN_WORDS,
  ERROR_MAX_WORDS,
  ERROR_URL,
  ERROR_TIME_BELOW_MIN,
  ERROR_TIME_ABOVE_MAX,
  ERROR_DATE_BELOW_MIN,
  ERROR_DATE_ABOVE_MAX,
  ERROR_ADDRESS,
  ERROR_REGEX,
  ERROR_CAPITALIZED,
  ERROR_BELOW_MINCHOICES,
  ERROR_ABOVE_MAXCHOICES,
  ERROR_RIB,
  ERROR_DATE,
} from '../constants/errorType';
import {
  EMAIL_REGEX,
  EMAIL_DOMAIN_REGEX,
  SEPARATOR_REGEX,
  PHONE_REGEX,
  TIME_REGEX,
  DATE_REGEX,
  URL_REGEX,
  TEXT_REGEX,
  CAPITALIZED_REGEX,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT,
} from '../constants/regex';
import {
  EMAIL,
  GPSCOORDINATES,
  LONGITUDE,
  LATITUDE,
  URL,
  PHONENUMBER,
  DATETIME,
  DATE,
  ADDRESS,
  SHORTTEXT,
  RIB,
} from '../constants/typology/fieldType';
import { parseDuration } from './date';
import { smaller, larger } from 'mathjs';
import { bigint } from '../helpers/parse';
import INDICATIFS from '../constants/indicatif';
import { isValidIBANNumber } from '../components/molecules/Field/Rib/RibField';

const isValidDate = (date, format = DATE_FORMAT) => {
  return (
    moment(date, format, true).isValid() &&
    moment(date).year() >= 1000 &&
    moment(date).year() <= 9999
  );
};

/**
 * The order of validation is important, do not change it
 */
const createValidator = (validation, label) => (value) => {
  if (!validation) {
    return null;
  }

  /**
   * "Validation of invalid dates for the 'Date' and 'Date & Time' fields
   */
  if ([DATE, DATETIME].includes(validation.datetimeFormat)) {
    if (validation.required) {
      if (!value) return { label, type: ERROR_REQUIRED };

      if (validation.datetimeFormat === DATETIME && !isValidDate(value, DATE_TIME_FORMAT)) {
        return { label, type: ERROR_REQUIRED };
      }
    }

    if (value) {
      let isValid = true;
      if (validation.datetimeFormat === DATE) {
        isValid = isValidDate(value, DATE_FORMAT);
      } else if (validation.datetimeFormat === DATETIME) {
        const isValid1 = isValidDate(value, DATE_FORMAT);
        const isValid2 = isValidDate(value, DATE_TIME_FORMAT);
        const isValid3 = moment(value, TIME_FORMAT, true).isValid();
        isValid = isValid1 || isValid2 || isValid3;
      }
      if (!isValid) {
        return { label, type: ERROR_DATE };
      }
    }
  }

  if (validation.required) {
    if (!value) return { label, type: ERROR_REQUIRED };

    if (validation.type === GPSCOORDINATES && (!value?.latitude || !value?.longitude)) {
      return { label, type: ERROR_REQUIRED };
    }
  }

  if (validation.charsRange) {
    const elem = document.createElement('div');
    elem.innerHTML = value || '';
    const _value = elem.textContent || elem.innerText;
    const { min, max } = validation.charsRange;

    if (typeof min === 'number' && [..._value].length < min) {
      return { label, type: ERROR_MIN_CHARS, value: min };
    } else if (typeof max === 'number' && [..._value].length > max) {
      return { label, type: ERROR_MAX_CHARS, value: max };
    }
  }

  if (validation.allowedDecimals === false && value && (value % 1 != 0 || value.indexOf('.') > 0)) {
    return {
      label,
      type: ERROR_INTEGER,
      value: value,
    };
  }

  if (
    validation.valueRange &&
    value !== undefined &&
    value !== null &&
    value !== '' &&
    typeof value !== 'object'
  ) {
    const { min, max } = validation.valueRange;
    if (typeof min === 'number' && smaller(bigint(value), min)) {
      return { label, type: ERROR_BELOW_MIN, value: min };
    } else if (typeof max === 'number' && larger(bigint(value), max)) {
      return { label, type: ERROR_ABOVE_MAX, value: max };
    }
  }

  if (validation.wordsRange) {
    const words = value ? value.split(/\s+/g) : [];
    const { min, max } = validation.wordsRange;

    if (typeof min === 'number' && words.length < min) {
      return { label, type: ERROR_MIN_WORDS, value: min };
    } else if (typeof max === 'number' && words.length > max) {
      return { label, type: ERROR_MAX_WORDS, value: max };
    }
  }

  if (validation.type === GPSCOORDINATES) {
    if (validation.longitudeRange && value?.longitude) {
      const { longitude } = value;
      const { min, max } = validation.longitudeRange;
      if (typeof min === 'number' && bigint(longitude) < min) {
        return { label, type: ERROR_BELOW_MIN, fieldType: LONGITUDE, value: min };
      } else if (typeof max === 'number' && bigint(longitude) > max) {
        return { label, type: ERROR_ABOVE_MAX, fieldType: LONGITUDE, value: max };
      }
    }

    if (validation.latitudeRange && value?.latitude) {
      const { latitude } = value;
      const { min, max } = validation.latitudeRange;

      if (typeof min === 'number' && bigint(latitude) < min) {
        return { label, type: ERROR_BELOW_MIN, fieldType: LATITUDE, value: min };
      } else if (typeof max === 'number' && bigint(latitude) > max) {
        return { label, type: ERROR_ABOVE_MAX, fieldType: LATITUDE, value: max };
      }
    }
  }

  if (validation.type === DATETIME) {
    if (validation.timeRange) {
      const match = value && value.match(TIME_REGEX);
      const { min, max } = validation.timeRange;
      if (match) {
        const timeValue = parseDuration(match[0]);
        if (timeValue < parseDuration(min)) {
          return { label, type: ERROR_TIME_BELOW_MIN, value: min };
        } else if (parseDuration(match[0]) > parseDuration(max)) {
          return { label, type: ERROR_TIME_ABOVE_MAX, value: max };
        }
      }
    }

    if (validation.dateRange) {
      const match = value && value.match(DATE_REGEX);
      const { min, max } = validation.dateRange;
      if (match) {
        const dateValue = Date.parse(match[0]);
        if (dateValue < Date.parse(min)) {
          return { label, type: ERROR_DATE_BELOW_MIN, value: min };
        } else if (Date.parse(match[0]) > Date.parse(max)) {
          return { label, type: ERROR_DATE_ABOVE_MAX, value: max };
        }
      }
    }
  }

  if (validation.type === EMAIL && (value || validation.required)) {
    if (!EMAIL_REGEX.test(value)) {
      return {
        label,
        type: ERROR_EMAIL,
        value: value,
      };
    }
  }

  if (validation.type === PHONENUMBER && (value || validation.required)) {
    const indicatifArray = INDICATIFS.map((elem) => elem.value);
    const findIndif = indicatifArray.find((elem) => elem + ' ' === value);
    const checkEmptyNumber = findIndif !== undefined && !validation.required;
    if (!PHONE_REGEX.test(value) && !checkEmptyNumber) {
      return {
        label,
        type: ERROR_PHONE,
        value: value,
      };
    }
  }

  if (
    Object.keys(validation).includes('domains_accepted') &&
    (value || validation.required) &&
    !!validation.domains_accepted
  ) {
    const domain = value?.split('@')[1];
    const acceptedDomains = validation.domains_accepted
      .split(SEPARATOR_REGEX)
      .filter((acceptedDomain) => acceptedDomain.match(EMAIL_DOMAIN_REGEX));

    if (acceptedDomains.length && !acceptedDomains.includes(domain)) {
      return {
        label,
        type: ERROR_DOMAINS,
        value: domain || '',
      };
    }
  }

  if (
    Object.keys(validation).includes('domains_rejected') &&
    (value || validation.required) &&
    !!validation.domains_rejected
  ) {
    const domain = value?.split('@')[1];
    const rejectedDomains = validation.domains_rejected
      .split(SEPARATOR_REGEX)
      .filter((domain) => domain.match(EMAIL_DOMAIN_REGEX));

    if (rejectedDomains.length && rejectedDomains.includes(domain)) {
      return {
        label,
        type: ERROR_DOMAINS,
        value: domain || '',
      };
    }
  }

  if (validation.type === URL) {
    if (!value) return null;
    const values = value.split(',');

    if (validation.whitelist) {
      const trimmedWhitelist = validation.whitelist.split(',').map((str) => str.trim());
      const nok = values.find(
        (val) =>
          !trimmedWhitelist.find((url) => val.trim().includes(url)) ||
          val.match(URL_REGEX) === null,
      );
      if (nok) {
        return { label, type: ERROR_URL, value: null };
      }
    } else if (validation.blacklist) {
      const trimmedBlacklist = validation.blacklist.split(',').map((str) => str.trim());
      const nok = values.find(
        (val) =>
          trimmedBlacklist.find((url) => val.trim().includes(url)) ||
          val.trim().match(URL_REGEX) === null,
      );
      if (nok) return { label, type: ERROR_URL, value: null };
    } else {
      const nok = values.filter((val) => val.trim().match(URL_REGEX) === null);
      if (nok.length !== 0) return { label, type: ERROR_URL, value: null };
    }
  }

  if (validation.type === RIB) {
    if (!validation.rib) {
      if (!isValidIBANNumber(value) || isValidIBANNumber(value) > 1) {
        return {
          label,
          type: ERROR_RIB,
          value: value,
        };
      }
    }
  }
  if (validation.type === ADDRESS && !validation.onlyCountry && (value || validation.required)) {
    if (!TEXT_REGEX.test(value)) {
      return {
        label,
        type: ERROR_ADDRESS,
        value: value,
      };
    }
  }

  if (validation.type === SHORTTEXT && validation.regex?.enabled) {
    if (!value) return null;
    const regex = new RegExp(validation.regex.value);
    if (!regex.test(value)) {
      return {
        label,
        type: ERROR_REGEX,
        value: validation.regex.errorMessage,
      };
    }
  }

  if (typeof value === 'string' && validation.capitalized) {
    if (!CAPITALIZED_REGEX.test(value)) {
      return {
        label,
        type: ERROR_CAPITALIZED,
      };
    }
  }

  if (validation.choicesRange && Array.isArray(value)) {
    if (
      typeof validation.choicesRange.min === 'number' &&
      value.length < validation.choicesRange.min
    ) {
      return {
        label,
        type: ERROR_BELOW_MINCHOICES,
        value: validation.choicesRange.min,
      };
    } else if (
      typeof validation.choicesRange.max === 'number' &&
      value.length > validation.choicesRange.max
    ) {
      return {
        label,
        type: ERROR_ABOVE_MAXCHOICES,
        value: validation.choicesRange.max,
      };
    }
  }

  return null;
};

export default createValidator;
