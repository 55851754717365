import React, { useRef, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import Button from '../../atoms/Button/Button';
import styles from './UploadInput.css';
import useTranslate from '../../../hooks/useTranslate';
import * as config from '../../../helpers/config';
import SVG from '../../atoms/SVG/SVG';
import { createToast } from '../../../store/actions/toast';
import { ERROR } from '../../../constants/toastType';
import FieldError from '../Error/FieldError';
import { tokenSelector } from '../../../store/selectors/token';
import { useSelector } from 'react-redux';
import { currentTaskSelector } from '../../../store/selectors/task';

const uploadFile = async (file, token, taskId) => {
  const formData = new FormData();
  formData.append('MultipartFile', file);
  formData.append('IdTask', taskId);
  const response = await fetch(config.get('endpoints.upload'), {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
  if (response.status === 500) {
    throw new TypeError('download failed');
  }

  if (response.headers.get('Content-Type')?.includes('application/json')) {
    return await response.json();
  }

  throw new TypeError('Expected text response.');
};

// Validation uses megabytes (binary)
const MEGABYTES_BYTES = 1048576;

function UploadInput({ mimetypes, value, onChange, sizeRange, readonly }) {
  const i18n = useTranslate();
  const dropzoneRef = useRef(null);
  const [error, setError] = useState(null);
  // Initialize with value, if any
  const [currentFile, setCurrentFile] = useState(value ? { name: value } : null);
  const [uploadState, setUploadState] = useState('pending');
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: mimetypes?.length ? mimetypes : null,
    disabled: readonly,
  });

  const token = useSelector(tokenSelector);
  const currentTask = useSelector(currentTaskSelector);

  useEffect(() => {
    setCurrentFile(acceptedFiles[acceptedFiles.length - 1]);
  }, [acceptedFiles]);

  // Upload file to server handler
  useEffect(async () => {
    if (!currentFile) {
      setUploadState('pending');
      currentTask.isUploading=false;
      return;
    }

    // Validation part
    // Since we send the value in state only when upload succeded
    if (sizeRange) {
      const fileSize = currentFile.size;

      if (fileSize < sizeRange.min * MEGABYTES_BYTES) {
        setError(i18n('UploadInput.errors.belowMin', { size: sizeRange.min }));
        currentTask.isUploading=false;
        return;
      } else if (fileSize > sizeRange.max * MEGABYTES_BYTES) {
        setError(i18n('UploadInput.errors.aboveMax', { size: sizeRange.max }));
        currentTask.isUploading=false;
        return;
      }
    }

    // Upload part
    if (currentFile) {
      setUploadState('uploading');
      currentTask.isUploading=true;
      try {
        const fileUrl = await uploadFile(currentFile, token, currentTask?.taskId);
        onChange(fileUrl);
        setUploadState('done');
        currentTask.isUploading=false;
      } catch (err) {
        setUploadState('pending');
        currentTask.isUploading=false;
        setCurrentFile(null);
        createToast(ERROR, i18n('UploadInput.uploadFailed', { message: err.message }));
      }
    }
  }, [currentFile, token, currentTask]);

  const clearFile = () => {
    setCurrentFile(null);
    setError(null);
    onChange(null);
  };

  return (
    <div className={styles.UploadInput}>
      <div className={styles.UploadInput__input}>
        <div
          {...getRootProps({ className: 'dropzone' })}
          className={styles.UploadInput__dropzone}
          ref={dropzoneRef}
        >
          <input {...getInputProps()} />
          <Button
            icon="field_upload"
            type="button"
            className={styles.UploadInput__button}
            disabled={readonly}
          >
            {i18n('UploadInput.openDialog')}
          </Button>
        </div>
        <div className={styles.UploadInput__preview}>
          {currentFile &&
            (uploadState === 'uploading' ? (
              <>
                <SVG glyph="spinner" className={styles.UploadInput__spinner} />
                <span className={styles.UploadInput__fileMeta}>{i18n('UploadInput.pending')}</span>
              </>
            ) : (
              <>
                <Button
                  icon="close"
                  type="button"
                  flat
                  onClick={clearFile}
                  className={styles.UploadInput__clearButton}
                />
                <span className={styles.UploadInput__fileMeta}>{currentFile.name}</span>
              </>
            ))}
        </div>
      </div>
      {error && <FieldError message={error} />}
    </div>
  );
}

UploadInput.propTypes = {
  mimetypes: PropTypes.array,
  value: PropTypes.string,
  onChange: PropTypes.func,
  sizeRange: PropTypes.object,
  readonly: PropTypes.bool,
};

export default UploadInput;
