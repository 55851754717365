import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './SwitchToggle.css';
import Switch from '../../atoms/Switch/Switch';

function SwitchToggle({ id, checked, labelRight, labelLeft, onChange, disabled, ...props }) {
  const [value, setValue] = useState(checked);

  useEffect(() => {
    setValue(checked);
  }, [checked]);

  return (
    <div className={styles.SwitchToggle}>
      {labelRight && (
        <label
          htmlFor={id}
          onClick={() => !disabled && setValue(false)}
          className={styles.SwitchToggle__label_right}
        >
          {labelRight}
        </label>
      )}
      <Switch
        checked={value}
        onChange={(val) => {
          setValue(val);
          onChange(val);
        }}
        disabled={disabled}
        {...props}
      />
      {labelLeft && (
        <label
          htmlFor={id}
          onClick={() => !disabled && setValue(true)}
          className={styles.SwitchToggle__label_left}
        >
          {labelLeft}
        </label>
      )}
    </div>
  );
}

SwitchToggle.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  labelRight: PropTypes.string,
  labelLeft: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

SwitchToggle.defaultProps = {
  onChange: () => null,
};

export default SwitchToggle;
