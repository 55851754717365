import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import FieldError from '../../Error/FieldError';
import styles from './TextData.css';
import getDummyData from '../../../../helpers/dummy';
function TextDataRender({ input, label, placeholder, id, meta: { error, touched, initial } }) {
  if ((input.value || initial) === "") {
    return null;
  }

  const props = {
    label,
    placeholder,
    id,
  };
  const HtmlTextvalue =
    input.value.search(/\\n/g) >= 0
      ? input.value.replace(/\\n/g, '<br />')
      : input.value.search(/\n/g) >= 0
      ? input.value.replace(/\n/g, '<br />')
      : input.value;

  const initialTextValue =
    initial?.search(/\\n/g) >= 0
      ? initial?.replace(/\\n/g, '<br />')
      : initial?.search(/\n/g) >= 0
      ? initial?.replace(/\n/g, '<br />')
      : initial;

  return (
    <div className={styles.TextData}>
      <p {...input} id={id} error={touched && error} {...props} block>
        {input.value || initial ? (
          <div
            dangerouslySetInnerHTML={{ __html: input.value ? HtmlTextvalue : initialTextValue }}
          />
        ) : (
          placeholder
        )}
      </p>
      {touched && error && <FieldError error={error} />}
    </div>
  );
}

TextDataRender.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  content: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
    initial: PropTypes.string,
  }),
  id: PropTypes.string,
};

function TextData({ name, label, fieldHoC: FieldHOC, validation, isPreview, ...props }) {
  const id = useMemo(() => `name_${uuid()}`, [name]);
  const initial = useMemo(() => isPreview ? getDummyData(validation.type) : "", [validation]);

  return (
    <FormField label={label} name={name} htmlFor={id}>
      <FieldHOC
        name={name}
        id={id}
        component={TextDataRender}
        meta={{ initial }}
        validation={validation}
        {...props}
      />
    </FormField>
  );
}

TextData.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  fieldHoC: PropTypes.func,
  validation: PropTypes.object,
  isPreview: PropTypes.bool
};

TextData.defaultProps = {
  fieldHoC: Field,
};

export default TextData;
