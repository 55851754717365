/**
 * A set of formula evaluation toolset aimed for form validation rules
 *
 * Basic components includes :
 * - evaluate() and validate() that takes a string formula as input
 * - compile() compile a set of formula nodes into a string formula
 * - generateVariablesPath() use the field id of variable node to generate the path in the form tree structure
 * - createFormulaScope() create the scope of variables from nodes and form values
 *
 * Here is how all that stuff should be used :
 * - validate(): used to validate syntax of the formula
 * - generateVariablesPath(): when the form is mounted in preview / contribute node this is the sweet spot to generate variables paths
 * - createFormulaScope() then evaluate(): when we need to run validate we'll first generate a scope from values then run the formula against it
 */
import { create, all, factory, bignumber } from 'mathjs';
import { BLOCK_NODE } from '../constants/formNode';
import {
  FIELD_REFERENCE,
  FUNCTION_CEIL,
  FUNCTION_FLOOR,
  FUNCTION_ROUND,
  LITERAL_NUMBER,
  LITERAL_STRING,
  OPERATOR_ADD,
  OPERATOR_DIVIDE,
  OPERATOR_EQ,
  OPERATOR_GT,
  OPERATOR_GTE,
  OPERATOR_IN,
  OPERATOR_LEFT_PARENTHESIS,
  OPERATOR_LT,
  OPERATOR_LTE,
  OPERATOR_MODULO,
  OPERATOR_MULTIPLY,
  OPERATOR_NEQ,
  OPERATOR_NOT,
  OPERATOR_OR,
  OPERATOR_XOR,
  OPERATOR_AND,
  OPERATOR_NOTIN,
  OPERATOR_RIGHT_PARENTHESIS,
  OPERATOR_SUBSTRACT,
} from '../constants/formula';
import { getAncestorsChain } from './formTree';
import { get, set } from 'object-path';
import { bigint } from './parse';

function getStringValue(value) {
  return typeof value === 'string' ? value : value.value;
}

// factory function which defines a new data type StringValue
const createStringValue = factory('StringValue', ['typed'], ({ typed }) => {
  // create a new data type
  function StringValue(value) {
    this.value = getStringValue(value);
  }
  StringValue.prototype.isCustomValue = true;
  StringValue.prototype.toString = function () {
    return 'StringValue:' + this.value;
  };

  // define a new data type with typed-function
  typed.addType({
    name: 'StringValue',
    test: function (x) {
      // test whether x is of type StringValue
      return typeof x === 'string' || x instanceof StringValue;
    },
  });

  typed.conversions = typed.conversions.map((c) =>
    c.from !== 'string' || c.to !== 'BigNumber'
      ? c
      : {
        from: 'string',
        to: 'BigNumber',
        convert: function (x) {
          try {
            return bignumber(x);
          } catch (err) {
            // eslint-disable-next-line
            console.warn('Cannot convert "' + x + '" to BigNumber');
            return bignumber('0');
          }
        },
      },
  );

  return StringValue;
});

// function add which can add the StringValue data type
// When imported in math.js, the existing function `add` with support for
// StringValue, because both implementations are typed-functions and do not
// have conflicting signatures.
const createAddStringValue = factory('add', ['typed', 'StringValue'], ({ typed }) => {
  return typed('add', {
    'StringValue, StringValue': function (a, b) {
      return a + b;
    },
  });
});

const createEqualScalarStringValue = factory(
  'equalScalar',
  ['typed', 'StringValue'],
  ({ typed }) => {
    return typed('equalScalar', {
      'StringValue, StringValue': function (a, b) {
        return b === a;
      },
    });
  },
);

const createEqualStringValue = factory('equal', ['typed', 'StringValue'], ({ typed }) => {
  return typed('equal', {
    'StringValue, StringValue': function (a, b) {
      return b === a;
    },
    'BigNumber, StringValue': function () {
      return false;
    },
  });
});

const createNotEqualStringValue = factory(
  'notEqual',
  ['typed', 'StringValue', 'BigNumber'],
  ({ typed }) => {
    return typed('notEqual', {
      'StringValue, StringValue': function (a, b) {
        return b !== a;
      },
    });
  },
);

const createInStringValue = factory('leftShift', ['typed', 'StringValue'], ({ typed }) => {
  return typed('leftShift', {
    'StringValue, StringValue': function (a, b) {
      return !b.includes(a);
    },
  });
});

const createNotInStringValue = factory('rightArithShift', ['typed', 'StringValue'], ({ typed }) => {
  return typed('rightArithShift', {
    'StringValue, StringValue': function (a, b) {
      return b.includes(a);
    },
  });
});

const config = {};
const mathjs = create(all, config);

mathjs.import([
  createStringValue,
  createAddStringValue,
  createInStringValue,
  createNotInStringValue,
  createEqualStringValue,
  createEqualScalarStringValue,
  createNotEqualStringValue,
]);

// Evaluate a formula
export function evaluate(formula, vars = {}) {
  const result = mathjs.evaluate(formula, vars);
  if (typeof result === 'object' && result !== null && typeof result.value === 'string') {
    return result.value;
  }

  return result;
}

// Validate formula syntax
export function validate(formula) {
  try {
    mathjs.parse(formula);
    return true;
  } catch (err) {
    return false;
  }
}

export function getTokenSymbol(node) {
  switch (node.type) {
    case LITERAL_NUMBER:
      return node.value;
    case LITERAL_STRING:
      return `"${node.value}"`;
    case FIELD_REFERENCE:
      return node?.path?.replace(/\[\]/g, '').replace(/\./g, '$') || 'fieldRef';
    case OPERATOR_ADD:
      return '+';
    case OPERATOR_SUBSTRACT:
      return '-';
    case OPERATOR_MULTIPLY:
      return '*';
    case OPERATOR_DIVIDE:
      return '/';
    case OPERATOR_MODULO:
      return '%';
    case OPERATOR_EQ:
      return '==';
    case OPERATOR_NEQ:
      return '!=';
    case OPERATOR_GT:
      return '>';
    case OPERATOR_GTE:
      return '>=';
    case OPERATOR_LT:
      return '<';
    case OPERATOR_LTE:
      return '<=';
    case OPERATOR_LEFT_PARENTHESIS:
      return '(';
    case OPERATOR_RIGHT_PARENTHESIS:
      return ')';
    case OPERATOR_IN:
      return '>>';
    case OPERATOR_NOTIN:
      return '<<';
    case OPERATOR_OR:
      return 'or';
    case OPERATOR_XOR:
      return 'xor';
    case OPERATOR_AND:
      return 'and';
    case OPERATOR_NOT:
      return 'not';
    case FUNCTION_CEIL:
      return 'ceil(';
    case FUNCTION_FLOOR:
      return 'floor(';
    case FUNCTION_ROUND:
      return 'round(';
    default:
      return `<ukn ${node.type}>`;
  }
}

// Generates a string formula from an array of nodes
// A node has the following structure :
// {
//   type: NODE_TYPE,
//   // For literals
//   value?: 123,
//   // Id of the field for variables
//   field?: 'formField',
//   // Path of a field in form tree, for variables
//   path?: 'path.to.field.in.formField',
//   // How duplicable field should be handled "sum"
//   duplicableResolution?: 'sum'
// }
export function compile(nodes) {
  return nodes.map(getTokenSymbol).join(' ');
}

// Add a "path" attribute to each variable node from the form tree
export function generateVariablePaths(nodes, formTree) {
  return nodes.map((node) => {
    if (node.type !== FIELD_REFERENCE) {
      return node;
    }

    return {
      ...node,
      path: getAncestorsChain(formTree, node.field)
        .reduce((path, n) => [...path, n.nodeType === BLOCK_NODE ? `${n.id}[]` : n.id], [])
        .join('.'),
    };
  });
}

export function getParseValue(value, type, mode = '') {
  if (
    typeof value === 'string' &&
    type !== '' &&
    !type?.startsWith('data_') &&
    value.trim().match(/^([1-9][0-9]*([.,][0-9]+)?|0)$/)
  ) {
    return bigint(value.trim());
  }
  if (type === 'mcq' && mode === 'foreach' && Array.isArray(value)) {
    return value.join();
  }
  if (type === 'select' && value && value.value) {
    return value.value;
  }

  if (type === 'number') {
    return bigint(value || "0");
  }

  return value || '';
}

export function updateFormBlockscopesForField(form, movedFieldId, newBlockScope) {
  return {
    ...form,
    validationRules: (form.validationRules || []).map((formula) =>
      updateFormulaBlockscopesForField(formula, movedFieldId, newBlockScope),
    ),
    compareFields: (form.compareFields || []).map((formula) =>
      updateCompareFieldsForField(formula, movedFieldId, newBlockScope),
    ),
    iterationOverloads: (form.iterationOverloads || []).map((overload) => ({
      ...overload,
      formula: updateFormulaBlockscopesForField(overload.formula, movedFieldId, newBlockScope),
    })),
    fields: form.fields.map((field) => ({
      ...field,
      hideIf: updateFormulaBlockscopesForField(field.hideIf, movedFieldId, newBlockScope),
    })),
    blocks: form.blocks.map((block) => ({
      ...block,
      hideIf: updateFormulaBlockscopesForField(block.hideIf, movedFieldId, newBlockScope),
    })),
  };
}

export function updateFormFieldTokens(form, predicate) {
  return {
    ...form,
    validationRules: (form.validationRules || []).map((formula) =>
      updateFormulaFieldTokens(formula, predicate),
    ),
    iterationOverloads: (form.iterationOverloads || []).map((overload) => ({
      ...overload,
      formula: updateFormulaFieldTokens(overload.formula, predicate),
    })),
    fields: form.fields.map((field) => ({
      ...field,
      hideIf: updateFormulaFieldTokens(field.hideIf, predicate),
    })),
    blocks: form.blocks.map((block) => ({
      ...block,
      hideIf: updateFormulaFieldTokens(block.hideIf, predicate),
    })),
  };
}

export function updateFormulaFieldTokens(formulaData, predicate) {
  if (!formulaData?.formula) {
    return formulaData;
  }
  return {
    ...formulaData,
    formula: formulaData.formula.map((token) =>
      token.type === FIELD_REFERENCE ? predicate(token) : token,
    ),
  };
}

export function updateCompareFieldsForField(formulaData, movedFieldId, newBlockScope) {
  if (!formulaData?.fields) {
    return formulaData;
  }

  const fieldTok = formulaData.fields.find((field) => field === movedFieldId);

  if (!fieldTok) {
    return formulaData;
  }

  return {
    ...formulaData,
    blockScope: newBlockScope,
  };
}

export function updateFormulaBlockscopesForField(formulaData, movedFieldId, newBlockScope) {
  if (!formulaData?.formula) {
    return formulaData;
  }

  const fieldTok = formulaData.formula.find((tok) => tok.field === movedFieldId);

  if (!fieldTok) {
    return formulaData;
  }

  return {
    ...formulaData,
    blockScope:
      formulaData.blockScope === fieldTok.blockScope ? newBlockScope : formulaData.blockScope,
    formula: formulaData.formula.map((token) =>
      token.type === FIELD_REFERENCE && token.field === movedFieldId
        ? {
          ...token,
          blockScope: newBlockScope,
          duplicableResolution: newBlockScope === null ? null : (token.duplicableResolution || 'foreachIter'),
        }
        : token,
    ),
  };
}

// Retrieve a duplicable value in nested form values.
// Inputs path examples :
// - phonefield1
// - block1[].field1
// There is three modes :
// - "sum": makes the sum if there is multiple rows
// - "avg": makes the average if there is multiple rows
// - "foreach" : build an array with all rows values
export function getDuplicableValue(values, path, mode = 'sum', typology = '') {
  const pathParts = path.split('.');
  let scope = values;
  for (let u = 0; u < pathParts.length; u++) {
    const part = pathParts[u];
    if (part.endsWith('[]')) {
      const rows = get(scope, part.replace('[]', '.$items'));
      let result;
      if (!rows) {
        return typology === 'number' ? bignumber('0') : '';
      } else if (mode === 'foreach') {
        // Foreach case : we build an array of all rows values
        result = rows.reduce(
          (result, current) =>
            result.concat(
              getDuplicableValue(
                current,
                pathParts.slice(u + 1, pathParts.length - u, mode).join('.'),
                mode,
                typology,
              ),
            ),
          [],
        );
      } else if (mode === 'foreachIter') {
        result = rows.reduce(
          (result, current) =>
            result.concat(
              getDuplicableValue(
                current,
                pathParts.slice(u + 1, pathParts.length - u, mode).join('.'),
                mode,
                typology,
              ),
            ),
          [],
        );
      }
      else if (mode === 'count') {
        result = rows.reduce((result, current) => {
          //   current[`${pathParts.slice(u + 1, pathParts.length - u, mode).join('.')}`] = 1;
          const dv = getDuplicableValue(
            current,
            pathParts.slice(u + 1, pathParts.length - u, mode).join('.'),
            'sum',
            typology,
          );
          dv.d = [1];
          return dv.add(result);
        }, 0);
      }
      else if (mode === 'sum') {
        // Sum / Count case : we reduce the value to a number
        result = rows.reduce((result, current) => {
          const dv = getDuplicableValue(
            current,
            pathParts.slice(u + 1, pathParts.length - u, mode).join('.'),
            mode,
            typology,
          );
          return dv.add(result);
        }, 0);
      }
      return getParseValue(result, typology, mode);
    } else {
      scope = mode === 'count' ? 1 : get(scope, part, 0);
    }
  }

  return getParseValue(scope, typology, mode);
}

export function getRowsPaths(path, values) {
  const base = path[0];
  const value = get(values, `${base}.$items`, []);
  let rows = [];
  if (path.length > 1) {
    for (let i = 0; i < value.length; i++) {
      const subValue = value[i];
      const b = `${base}.$items[${i}]`;

      const subRows = getRowsPaths(path.slice(1), subValue);
      rows = rows.concat(subRows.map((r) => `${b}.${r}`));
    }
  } else {
    return [base];
  }
  return rows;
}

// Create formula variables scope from form values
// It takes care of computing duplicable fields sum / avg if needed
export function createFormulaScope(nodes, values, path) {
  const scope = {
    $path: path ? [path[0]] : null,
  };

  nodes.forEach((node) => {
    if (node.type !== FIELD_REFERENCE) {
      return;
    }
    const cleanPath = node.path.replace(/\[\]/g, '').replace(/\./g, '$');
    if (node.path.includes('[]')) {
      set(
        scope,
        cleanPath,
        getDuplicableValue(values, node.path, node.duplicableResolution || 'foreachIter', node.typology),
      );
    } else {
      const value = get(values, cleanPath);
      set(scope, cleanPath, getParseValue(value, node.typology));
    }
  });

  if (path && path.length > 1) {
    scope.$path = getRowsPaths(path, values);
  } else if (path) {
    scope.$path = path[0];
  }

  return scope;
}

// If a scope variable is an array (foreach case) we create an individual scope
// for each array value
export function explodeMultiScope(scope) {
  const rows = Object.values(scope).reduce(
    (rows, variable) => Math.max(rows, Array.isArray(variable) ? variable.length : 1),
    1,
  );

  const scopeKeys = Object.keys(scope);
  const scopes = [];

  for (let i = 0; i < rows; i++) {
    const rowScope = scopeKeys.reduce((_scope, key) => {
      const val = scope[key];
      return {
        ..._scope,
        [key]: Array.isArray(val) ? (i < val.length ? val[i] : val[val.length - 1]) : val,
      };
    }, {});

    scopes.push(rowScope);
  }

  return scopes;
}

export function compileAllRules(rules, tree) {
  return (rules || []).map((rule) => {
    const nodes = generateVariablePaths(rule.formula, tree);
    return {
      ...rule,
      formula: nodes,
      compiled: compile(nodes),
    };
  });
}

export function evaluateAllRules(rules, values, hiddenBoxes) {
  const errors = [];
  const failedExecutions = [];

  for (let i = 0; i < rules.length; i++) {
    const rule = rules[i];
    const globalScope = createFormulaScope(rule.formula, values, rule.path);

    // A single rule may result in multiple scopes if it contains a
    // "foreach" field refenrence : one scope per row
    const scopes = explodeMultiScope(globalScope);
    for (let j = 0; j < scopes.length; j++) {
      const scope = scopes[j];
      try {
        const result = evaluate(rule.compiled, scope);
        let checkHiddenField = false;
        hiddenBoxes && hiddenBoxes.map((elem) => {
          if (rule.compiled.search(elem) >= 0) {
            checkHiddenField = true;
          }
        });
        if (!result && !checkHiddenField) {
          errors.push({
            id: rule.id,
            name: rule.name,
            errorMessage: rule.errorMessage,
            isForeach: !!scopes.length,
            fieldPath: scope.$path,
            row: j + 1,
          });
        }
      } catch (err) {
        if (err.message.startsWith('Syntax')) {
          // Conversion issues are considered falsy results
          errors.push({
            id: rule.id,
            name: rule.name,
            errorMessage: 'Rule failed to execute.',
            isForeach: !!scopes.length,
            fieldPath: scope.$path,
            row: j + 1,
          });
        } else if (err.message.startsWith('Cannot convert')) {
          // eslint-disable-next-line
          console.error('Conversion issue');
        } else {
          failedExecutions.push(err);
        }
        // eslint-disable-next-line
        console.error(err);
      }
    }
  }

  return [errors, failedExecutions];
}
