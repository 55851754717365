import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Tag from '../../atoms/Tag/Tag';
import Button from '../../atoms/Button/Button';
import styles from './BlockHeaderTemplate.css';

function BlockHeaderTemplate({ block, onDelete }) {
  const handleDelete = useCallback(
    (event) => {
      event.stopPropagation();
      onDelete();
    },
    [onDelete],
  );
  return (
    <div className={styles.BlockHeaderTemplate}>
      <div className={styles.BlockHeaderTemplate__title}>
        <Tag color={block.color}>{block.name}</Tag>
      </div>
      {onDelete && <Button icon="trash" flat onClick={handleDelete} />}
    </div>
  );
}

BlockHeaderTemplate.propTypes = {
  block: PropTypes.object,
  onDelete: PropTypes.func,
};

export default BlockHeaderTemplate;
