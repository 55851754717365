import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../../atoms/Layout/Layout';
import styles from './DrawerTitle.css';

function DrawerTitle({ children }) {
  return (
    <Layout align="center" className={styles.DrawerTitle}>
      <Layout flex={1}>{children}</Layout>
    </Layout>
  );
}

DrawerTitle.propTypes = {
  children: PropTypes.node,
};

export default DrawerTitle;
