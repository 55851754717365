import React, { useCallback } from 'react';
import SettingsForm from '../../../../form/SettingsForm/SettingsForm';
import styles from './DrawerContentSettings.css';
import useTranslate from '../../../../../hooks/useTranslate';
import { useSelector, useDispatch } from 'react-redux';
import {
  currentFormSelector,
  isFormPublishedSelector,
} from '../../../../../store/selectors/editForm';
import { submit, getFormValues } from 'redux-form';
import { updateForm } from '../../../../../store/actions/editForm';
function DrawerContentSettingsPage() {
  const i18n = useTranslate();
  const dispatch = useDispatch();
  const form = useSelector(currentFormSelector);
  const isPublished = useSelector(isFormPublishedSelector);
  const valuesForm = useSelector(getFormValues('settings'));

  // Trigger submission on blur
  const handleFormBlur = useCallback(() => {
    dispatch(submit('settings'));
  }, [valuesForm]);

  // On submit push data to the redux store
  const handleSubmit = useCallback(
    (values) => {
      // Avoid erasing form title
      // eslint-disable-next-line no-unused-vars
      const { name, ...rest } = values;
      dispatch(updateForm(rest));
    },
    [valuesForm, form],
  );

  if (!form) {
    return null;
  }

  return (
    <div className={styles.DrawerContentSettings}>
      <h3 className={styles.DrawerContentSettings__title}>{i18n('DrawerContentSettings.title')}</h3>
      <p className={styles.DrawerContentSettings__description}>
        {i18n('DrawerContentSettings.description')}
      </p>
      <SettingsForm
        disabled={isPublished}
        initialValues={form}
        onBlur={handleFormBlur}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

DrawerContentSettingsPage.propTypes = {};

export default DrawerContentSettingsPage;
