import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ContributeForm from '../../form/ContributeForm/ContributeForm';
import { DEFAULT_GRID_COLUMNS } from '../../../constants/grid';
import Grid from '../../organisms/Grid/Grid';
import { buildFormTree, buildAnswer } from '../../../helpers/formTree';
import ContributeFormNode from './../ContributeFormNode/ContributeFormNode';
import { DEFAULT_GRID_ROWHEIGHT, GRID_GUTTER } from '../../../constants/grid';
import styles from './ContributeAnswerFormTemplate.css';
function ContributeAnswerFormTemplate({ answer, form }) {
  // Build the form tree from blocks & fields
  const formTree = useMemo(() => buildFormTree(form), [form]);

  // Keep track of total form height
  const [realHeight, setRealHeight] = useState(0);
  // Generates initial values according to form tree shape
  const initialValues = useMemo(() => buildAnswer(answer), [formTree]);

  // Compute the total form height to position submit button
  const formHeight = useMemo(() => realHeight || formTree.totalHeight, [
    realHeight,
    formTree.height,
  ]);

  const gridColumns = form?.gridSize || DEFAULT_GRID_COLUMNS;
  const gridRowHeight = (DEFAULT_GRID_COLUMNS * DEFAULT_GRID_ROWHEIGHT) / form?.gridSize;

  return (
    <>
      <div className={styles.ContributeAnswerFormTemplate}>
        {/* scale the grid container so parent overflow-y wirks properly */}
        <div style={{ minHeight: formHeight * gridRowHeight + gridRowHeight * 2 }}>
          <ContributeForm initialValues={initialValues} readonly>
            {/* If enter to validate is enabled, we need to add this invisible submit button that "enables" the feature */}
            <Grid
              columns={form.gridSize}
              rowHeight={gridRowHeight}
              gutter={GRID_GUTTER}
              dotBackground={false}
            >
              <ContributeFormNode node={formTree} onHeightChange={setRealHeight} readonly />
              <Grid.Box
                x={gridColumns / 2 - 1}
                y={formHeight}
                width={2}
                style={{ padding: '1rem' }}
              ></Grid.Box>
            </Grid>
          </ContributeForm>
        </div>
      </div>
    </>
  );
}

ContributeAnswerFormTemplate.propTypes = {
  answer: PropTypes.object,
  form: PropTypes.object,
};

export default ContributeAnswerFormTemplate;
