import React from 'react';
import FormPreviewTemplate from '../../templates/FormPreview/FormPreviewTemplate';

function ViewPage() {
  return (
    <div>
      <FormPreviewTemplate />
    </div>
  );
}

export default ViewPage;
