import { FIELD, BLOCK, FIELD_STUB, BLOCK_STUB } from '../../../constants/draggableType';

// Move item by given pixel offset according to grid cell size
export function moveGridItem(item, grid, offset) {
  const { left: x, offsetTop: y } = grid.boundingRect;
  return {
    x: Math.floor((offset.x - x) / grid.columnWidth),
    y: Math.floor((offset.y - y) / grid.rowHeight),
  };
}

// Resize a grid item using root item + resize handle
export function resizeGridItem(item, handle) {
  const mx = item.x + (item.width || 1);
  const my = item.y + (item.height || 1);

  switch (handle.direction) {
    case 'left': {
      const x = Math.min(item.x + ((item.width || 1) - 1), item.x + (handle.x - item.x));
      return {
        x,
        width: mx - x,
      };
    }
    case 'up': {
      const y = Math.min(item.y + ((item.height || 1) - 1), item.y + (handle.y - item.y));
      return {
        y,
        height: my - y,
      };
    }
    case 'down':
      return {
        y: item.y,
        height: Math.max(Math.floor(handle.y - item.y + 1), 1),
      };
    case 'right':
      return {
        x: item.x,
        width: Math.max(Math.floor(handle.x - item.x + 1), 1),
      };
  }
  return {};
}

// Check if two rectangles are colliding
// See : https://stackoverflow.com/a/7301852
export function isColliding(rect1, rect2) {
  // We emulate infinite height if box is in fixedMode to trigger collision with items below
  const r1h = rect1.fixedMode ? Number.MAX_SAFE_INTEGER : rect1.height;
  const r2h = rect2.fixedMode ? Number.MAX_SAFE_INTEGER : rect2.height;
  return (
    rect1.x < rect2.x + (rect2.width || 1) &&
    rect1.x + (rect1.width || 1) > rect2.x &&
    rect1.y < rect2.y + (r2h || 1) &&
    rect1.y + (r1h || 1) > rect2.y
  );
}

export function hasChildsBelow(box, boxes) {
  for (let i = 0; i < boxes.length; i++) {
    const otherBox = boxes[i];

    if (
      box.id !== otherBox.id &&
      box.x < otherBox.x + (otherBox.width || 1) &&
      box.x + (box.width || 1) > otherBox.x &&
      otherBox.y > box.y
    ) {
      return true;
    }
  }

  return false;
}

// Test if a rectangle is within another aka. totally inside
export function isWithin(parentRect1, childRect2) {
  // If parent has a header row, we adjust dimensions so children
  // are NOT considered within parent if they overlap header
  const correctParentY = parentRect1.hasHeader ? parentRect1.y + 1 : parentRect1.y;
  const parentHeight = parentRect1.height || 1;
  const correctParentHeight = parentRect1.hasHeader ? parentHeight - 1 : parentHeight;
  return (
    childRect2.x >= parentRect1.x &&
    childRect2.y >= correctParentY &&
    childRect2.x + (childRect2.width || 1) <= parentRect1.x + (parentRect1.width || 1) &&
    childRect2.y + (childRect2.height || 1) <= correctParentY + correctParentHeight
  );
}

// Testif bounding boxes are exactly the same
export function rectEquals(rect1, rect2) {
  return (
    rect1.x === rect2.x &&
    rect1.y === rect2.y &&
    rect1.width === rect2.width &&
    rect1.height === rect2.height
  );
}

// Check if given item is overlaping something in items array or out of grid bounds
// This function will return collision if
// - Current item is crossing another
// - Current item is within another item and this item isn't a block
// - Another item is within current item and current item is not a block
// - Collision is ignored if other item is part of block childs
export function isOverlap(item, items, grid, childrenIds) {
  if (item.x < 0 || item.y < 0 || item.x + item.width > grid.columns) {
    // Out of grid bounds case. We do not check y overflow since grid is vertically infinite
    return true;
  }

  for (let index = 0; index < items.length; index++) {
    const otherItem = items[index];

    if (childrenIds && childrenIds.includes(otherItem.id)) {
      continue;
    }

    // Check each item on grid against the current one
    if (item.id !== otherItem.id && isColliding(item, otherItem)) {
      const itemWithinOther = isWithin(otherItem, item);
      const otherWithinItem = isWithin(item, otherItem);

      if (!itemWithinOther && !otherWithinItem) {
        // Objects are crossing. This is always a collision
        return true;
      }

      if (
        itemWithinOther &&
        (otherItem.type === FIELD || otherItem.type === FIELD_STUB) &&
        ((item.type !== BLOCK && item.type !== BLOCK_STUB) || !rectEquals(item, otherItem))
      ) {
        // Current item is within something but its a field, collision
        // One exception is : when the overlaping item is a bloc that
        // Has the exact same size of the field. It can be considered within
        // that field but this is a valid case since that bloc will be placed
        // behind the field on wysiwyg / contribute views
        return true;
      }

      if (
        otherWithinItem &&
        (item.type === FIELD || item.type === FIELD_STUB) &&
        ((otherItem.type !== BLOCK && otherItem.type !== BLOCK_STUB) ||
          !rectEquals(item, otherItem))
      ) {
        // Same as above but inverse
        return true;
      }
    }
  }
  return false;
}

export const sortByPositionFactory = (gridColumns) => (a, b) => {
  const aIndex = a.box.y * gridColumns + a.box.x;
  const bIndex = b.box.y * gridColumns + b.box.x;

  if (aIndex === bIndex && typeof b.order !== 'undefined' && typeof a.order !== 'undefined') {
    return b.order - a.order;
  }

  return aIndex - bIndex;
}