export const EDITFORM_FOCUS_FIELD = 'EDITFORM_FOCUS_FIELD';
export const EDITFORM_FOCUS_BLOCK = 'EDITFORM_FOCUS_BLOCK';
export const EDITFORM_FOCUS_VALIDATIONRULE = 'EDITFORM_FOCUS_VALIDATIONRULE';
export const EDITFORM_FOCUS_COMPARERULE = 'EDITFORM_FOCUS_COMPARERULE';
export const EDITFORM_FOCUS_ITERATIONRULE = 'EDITFORM_FOCUS_ITERATIONRULE';
export const EDITFORM_UPDATE = 'EDITFORM_UPDATE';
export const EDITFORM_TOGGLE_INFORMATION = 'EDITFORM_TOGGLE_INFORMATION';
export const SELECTMODE_SET = 'SELECTMODE_SET';
export const SELECTMODE_ADD_FIELDS = 'SELECTMODE_ADD_FIELDS';
export const SELECTMODE_DELETE_FIELDS = 'SELECTMODE_DELETE_FIELDS';
export const SELECTMODE_SET_FIELDS = 'SELECTMODE_SET_FIELDS';

export const setFocusedField = (field) => ({
  type: EDITFORM_FOCUS_FIELD,
  payload: { field },
});

export const setFocusedBlock = (block) => ({
  type: EDITFORM_FOCUS_BLOCK,
  payload: { block },
});

export const setFocusedValidationRule = (ruleId) => ({
  type: EDITFORM_FOCUS_VALIDATIONRULE,
  payload: { ruleId },
});

export const setFocusedCompareRule = (ruleId) => ({
  type: EDITFORM_FOCUS_COMPARERULE,
  payload: { ruleId },
});

export const setFocusedIterationRule = (ruleId) => ({
  type: EDITFORM_FOCUS_ITERATIONRULE,
  payload: { ruleId },
});

export const updateForm = (data) => ({
  type: EDITFORM_UPDATE,
  payload: data,
});

export const toggleInformationPanel = () => ({
  type: EDITFORM_TOGGLE_INFORMATION,
});

export const setSelectMode = (selectMode) => ({
  type: SELECTMODE_SET,
  payload: selectMode,
});

export const addSelectFields = (field) => ({
  type: SELECTMODE_ADD_FIELDS,
  payload: field,
});

export const deleteSelectFields = (id) => ({
  type: SELECTMODE_DELETE_FIELDS,
  payload: { id },
});

export const setSelectFields = (selectFields) => ({
  type: SELECTMODE_SET_FIELDS,
  payload: selectFields,
});
