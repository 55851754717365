import React, { useRef, useState, useEffect, useLayoutEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import styles from './PdfOcrViewer.css';

import PdfOcrViewerDocumentLayer from './PdfOcrViewerPageDocumentLayer';
import PdfOcrViewerPageMetadataLayer from './PdfOcrViewerPageMetadataLayer';
import PdfOcrViewerPageGeolocLayer from './PdfOcrViewerPageGeolocLayer';

function PdfOcrViewerPage({ pdf, pageNum, viewerWidth, metadata, goToField, containerRef }) {
  const ref = useRef(null);
  const [page, setPage] = useState(null);

  useEffect(() => {
    if (pdf == null) {
      return;
    }

    pdf.getPage(pageNum + 1).then((page) => {
      setPage(page);
    });
  }, [pdf]);

  const [viewports, setViewports] = useState(null);
  useEffect(() => {
    if (page == null) {
      return;
    }

    // Scale to fit width
    const document = page.getViewport({ scale: 1 });

    const scale = viewerWidth / (document.width + 20);
    const scaled = page.getViewport({ scale });

    setViewports({
      document,
      scaled,
      scale,
    });
  }, [page, viewerWidth]);

  return (
    <div
      ref={ref}
      className={styles.PdfOcrViewerPage}
      style={{
        width: viewports?.scaled?.width,
        height: viewports?.scaled?.height,
      }}
    >
      {page != null && viewports != null ? (
        <>
          {/* Document itself */}
          <PdfOcrViewerDocumentLayer page={page} viewports={viewports} />

          {/* OCR metadata */}
          {metadata && (
            <PdfOcrViewerPageMetadataLayer page={page} viewports={viewports} metadata={metadata} />
          )}

          {/* Geoloc */}
          {goToField?.geometry && goToField?.geometry.IndexPage === pageNum ? (
            <PdfOcrViewerPageGeolocLayer
              page={page}
              viewports={viewports}
              goToField={goToField}
              containerRef={containerRef}
              metadata={metadata}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
}

PdfOcrViewerPage.propTypes = {
  pdf: PropTypes.object,
  pageNum: PropTypes.number,
  viewerWidth: PropTypes.number,
  metadata: PropTypes.object,
  goTo: PropTypes.object,
};

export default PdfOcrViewerPage;
