import React from 'react';
import PropTypes from 'prop-types';
import SwitchField from '../../molecules/Field/Switch/SwitchField';
import RegexField from '../../molecules/Field/RegexField/RegexField';
import { Field, formValueSelector } from 'redux-form';
import { useSelector } from 'react-redux';
import useTranslate from '../../../hooks/useTranslate';
import innerStyles from './RegexRuleFragment.css';
import TextField from '../../molecules/Field/Text/TextField';
function RegexRuleFragment({ form: reduxFormId }) {
  const i18n = useTranslate();

  const { regex } = useSelector((state) =>
    formValueSelector(reduxFormId)(state, 'id', 'regex.enabled', 'regex.formula'),
  );
  return (
    <>
      <SwitchField name="regex.enabled" label={i18n('regex.enabled')} />
      {regex?.enabled ? (
        <>
          <RegexField name="regex.value" label={i18n('RegexRuleFragment.regex')} />
          <div className={innerStyles.RegexRuleFragment__help}>
            {i18n('RegexRuleFragment.regexHelp')}&nbsp;
            <a
              href="https://regex101.com/?flavor=javascript"
              target="_blank"
              rel="noreferrer nooponer"
            >
              {i18n('RegexRuleFragment.regexHelpLink')}
            </a>
          </div>
          <div className={innerStyles.RegexRuleFragment}>
            <TextField
              name="regex.errorMessage"
              type="text"
              label={i18n('RegexRuleFragment.errorMessage')}
            />
          </div>
        </>
      ) : (
        <Field name="regex.formula" component="input" type="hidden" />
      )}
    </>
  );
}
RegexRuleFragment.propTypes = {
  form: PropTypes.string,
};
export default RegexRuleFragment;
