import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './ContributeAnswerDetailTemplate.css';
import DrawerDetail from '../../organisms/DrawerDetail/DrawerDetail';
import Rating from '../../atoms/Rating/Rating';
import Button from '../../atoms/Button/Button';
import { get } from '../../../helpers/config';
import useTranslate from '../../../hooks/useTranslate';
import useFetchAction from '../../../hooks/useFetchAction';
import { updateMarkTask as updateMarkTaskAction } from '../../../store/actions/task';
import { createToast } from '../../../store/actions/toast';
import { ERROR } from '../../../constants/toastType';
import { ToastContainer } from 'react-toastify';

function ContributeAnswerDetailTemplate({ answer }) {
  const i18n = useTranslate();
  const [updateMarkTask, taskLoading, taskError] = useFetchAction(updateMarkTaskAction);
  const [mark, setMark] = useState(0);
  const [taskUpdate, setTaskUpdate] = useState();

  useEffect(() => {
    if (taskUpdate) {
      if (taskLoading) return;
      setTaskUpdate(false);

      if (taskError) {
        createToast(ERROR, i18n('ContributeAnswerDetailTemplate.taskError'));
      } else {
        window.location.href = getUrlRedirection('urls.desk.backnav');
      }
    }
  }, [taskUpdate, taskLoading, taskError]);

  const handleClick = useCallback(() => {
    if (answer?.taskId) {
      updateMarkTask({ taskId: answer.taskId, mark });
      setTaskUpdate(true);
    }
  }, [answer, mark]);

  return (
    <div className={styles.ContributeAnswerDetailTemplate}>
      <DrawerDetail>
        <DrawerDetail.Header>
          <h3>
            {i18n('ContributeAnswerDetailTemplate.task')}
            {answer?.taskId}
          </h3>
          <div className={styles.ContributeAnswerDetailTemplate__info__column}>
            {answer?.projectId && (
              <div className={styles.ContributeAnswerDetailTemplate__info__row}>
                <span>{i18n('ContributeAnswerDetailTemplate.project').toUpperCase()}</span>
                <p>#{answer?.projectId}</p>
              </div>
            )}
            {answer?.taskState && (
              <div className={styles.ContributeAnswerDetailTemplate__info__row}>
                <span>{i18n('ContributeAnswerDetailTemplate.priority').toUpperCase()}</span>
                <p>#{answer?.taskState}</p>
              </div>
            )}
          </div>
        </DrawerDetail.Header>
        <DrawerDetail.Footer>
          <h3> {i18n('ContributeAnswerDetailTemplate.rate')}</h3>
          <Rating
            titleLeft={i18n('ContributeAnswerDetailTemplate.quality')}
            size={4}
            onClick={setMark}
          />
          <Button
            type="button"
            rounded
            tabIndex={-1}
            onClick={handleClick}
            disabled={taskUpdate || mark === 0}
          >
            {i18n('ContributeAnswerDetailTemplate.validate')}
          </Button>
          <ToastContainer
            autoClose={6000}
            position={'bottom-right'}
            hideProgressBar={true}
            pauseOnHover={false}
          />
        </DrawerDetail.Footer>
      </DrawerDetail>
    </div>
  );
}

ContributeAnswerDetailTemplate.propTypes = {
  form: PropTypes.object,
  answer: PropTypes.object,
};

export default ContributeAnswerDetailTemplate;
