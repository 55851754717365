import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useTranslate from '../../../hooks/useTranslate';
import Error from '../../atoms/Error/Error';
import * as config from '../../../helpers/config';
import Button from '../../atoms/Button/Button';

function ErrorContributeTemplate({
  children,
  formError,
  answerError,
  taskAnswerError,
  taskError,
  tasksDepleted,
  timerElapsed,
}) {
  const i18n = useTranslate();
  const urlRedirect = useMemo(() => {
    if (answerError) return config.getUrlRedirection('urls.desk.backnav');
    if (taskError || formError) return config.getUrlRedirection('urls.team.signout');
    if (taskAnswerError) return config.getUrlRedirection('urls.team.projects');
  }, [formError, answerError, taskAnswerError, taskError]);

  // Task null body handling
  if (tasksDepleted) {
    return (
      <Error
        fill
        action={() => (
          <Button as="a" href={config.getUrlRedirection('urls.team.projects')}>
            {i18n('ContributePage.noTaskError.action')}
          </Button>
        )}
      >
        {i18n('ContributePage.noTaskError.description')}
      </Error>
    );
  }

  // Redirect to team logout page if 401 is received
  if (answerError?.code === 401 || taskError?.code === 401 || formError?.code === 401) {
    window.location.href = urlRedirect;
  }

  if (timerElapsed) {
    alert('Le temps alloué à la tâche est écoulé. Vous allez être redirigé.');
    window.location.href = urlRedirect;
  }

  // Task answer error handling
  if (answerError?.code === 500 || taskAnswerError?.code === 500) {
    return (
      <Error
        fill
        action={() => (
          <Button as="a" href={urlRedirect}>
            {i18n('ContributePage.answerError.action')}
          </Button>
        )}
      >
        {i18n('ContributePage.answerError.description')}
      </Error>
    );
  }

  // Generic error handling
  if (formError || answerError || taskAnswerError || taskError) {
    return (
      <Error title={i18n('error.default.title')} fill>
        {i18n('error.default.body')}
      </Error>
    );
  }

  return <>{children}</>;
}

ErrorContributeTemplate.propTypes = {
  children: PropTypes.node,
  formError: PropTypes.shape({
    code: PropTypes.number,
  }),
  answerError: PropTypes.shape({
    code: PropTypes.number,
  }),
  taskAnswerError: PropTypes.shape({
    code: PropTypes.number,
  }),
  taskError: PropTypes.shape({
    code: PropTypes.number,
  }),
  tasksDepleted: PropTypes.bool,
  timerElapsed: PropTypes.bool,
};

export default ErrorContributeTemplate;
