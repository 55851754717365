import {
    CERTIFICATION_ANSWER,
    CERTIFICATION_ANSWER_SUCCESS,
    CERTIFICATION_GET_SUCCESS,
    CERTIFICATION_GET_QUESTION_SUCCESS
  } from '../actions/certification';
  
  export const initialState = {
    hasReceivedNullCertification: false,
    currentCertification: null,
    currentCertificationQuestion: null,
    lastCertificationSent: null,
    buffer: [],
  };
  
  export default function certificationReducer(state = initialState, action) {
    switch (action.type) {
      case CERTIFICATION_GET_SUCCESS:
        if (action.payload === null) {
          return {
            ...state,
            hasReceivedNullCertification: true,
          };
        }
        return {
          ...state,
          [action.payload.projectId]: action.payload,
          currentCertification: state.currentCertification ? state.currentCertification : action.payload,
          buffer: state.currentCertification ? [...state.buffer, action.payload] : state.buffer,
        };
      case CERTIFICATION_GET_QUESTION_SUCCESS:
        if (action.payload === null) {
          return {
            ...state,
            hasReceivedNullCertification: true,
          };
        }
        return {
          ...state,
          [action.payload.projectId]: action.payload,
          currentCertificationQuestion: state.currentCertificationQuestion ? state.currentCertificationQuestion : action.payload,
          buffer: state.currentCertification ? [...state.buffer, action.payload] : state.buffer,
        };
      case CERTIFICATION_ANSWER: {
        return {
          ...state,
          lastCertificationSent: action.fetch.json.IdCertificationQuestion,
        };
      }
      case CERTIFICATION_ANSWER_SUCCESS: {
        const bufferCopy = state.buffer.slice(0);
        return {
          ...state,
          currentCertification: bufferCopy.length ? bufferCopy[0] : null,
          buffer: bufferCopy.slice(1),
        };
      }      
      default:
        return state;
    }
  }
  