export function parseTask(json) {
  return {
    taskId: json.idHit,
    formId: json.idForm,
    projectId: json.idProject,
    projectTitle: json.projectTitle,
    taskLineId: json.idTask,
    columns: (json?.columns || []).map((column) => ({
      name: column.name,
      data: column.data,
      type: column.type,
    })),
    answers: Object.keys(json?.answers || {}).reduce(
      (answers, keyAnswer) => ({
        ...answers,
        [keyAnswer]: json?.answers[keyAnswer],
      }),
      {},
    ),
    supplierId: json.user.idUser,
    TaskState: json.taskState,
    answer: json?.inputJson,
    isReview: json.isReview,
    expiresAt: Date.parse(
      // Append Z to date if not present so that it is parsed as UTC
      json.endDate.endsWith("Z") ?
        json.endDate :
        json.endDate + "Z"
    ),
  };
}

export function formatMarkTask(json) {
  return {
    IdHit: json.taskId,
    Mark: json.mark,
  };
}
