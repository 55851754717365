export const PATH_CREATE_FORM = '/';
export const PATH_DEMO = '/form/demo/fields';
export const PATH_EDITFIELDS_FORM = '/form/:formId/fields';
export const PATH_DATA_FORM = '/form/:formId/data';
export const PATH_BLOCKS_FORM = '/form/:formId/blocks';
export const PATH_VIEW_FORM = '/form/:formId/view';
export const PATH_SETTINGS_FORM = '/form/:formId/settings';
export const PATH_PREVIEW_FORM = '/form/:formId/preview';
export const PATH_CONTRIBUTE_FORM = '/contribute/project/:projectId';
export const PATH_CERTIFICATION_FORM = '/contribute/certification/:certificationId';
export const PATH_CERTIFICATION_PUBLISH_QUESTION = '/contribute/certification/:certificationId/question/:questionId'
export const PATH_CERTIFICATION_VIEW = '/contribute/certificationUserTest/:certificationUserTestId/question/:questionId';
export const PATH_CONTRIBUTE_REVIEW_FORM = '/contribute/review/:projectId';
export const PATH_CONTRIBUTE_VIEW_HIT = '/contribute/view/:hitId';
export const PATH_CONTRIBUTE_VIEW_TASK = '/contribute/answer/:taskId';
export const PATH_INFO_FORM = '/form/:formId/information';
export const PATH_QUALITY_FORM = '/form/:formId/quality';
export const PATH_VALIDATION_FORM = '/form/:formId/quality/validation';
export const PATH_RESPONDANTS_FORM = '/form/:formId/quality/respondants';
export const PATH_STRATEGY_FORM = '/form/:formId/strategy';
export const PATH_FIELD_NO_COMPARE = '/form/:formId/quality/fields-no-compare';
export const PATH_FIELD_TO_COMPARE = '/form/:formId/quality/fields-to-compare';
export const PATH_BLOCKS_SORTING = '/form/:formId/quality/blocks-sorting';
export const PATH_KEYBOARD_SHORTCUTS = '/form/:formId/quality/keyboard-shortcuts';
export const PATH_ADVANCED_FIELD_TO_COMPARE = '/form/:formId/quality/advanced-fields-to-compare';
export const PATH_ITERATION_OVERLOAD = '/form/:formId/quality/iteration-overload';

export const PATHS_QUALITY = [
  PATH_QUALITY_FORM,
  PATH_RESPONDANTS_FORM,
  PATH_VALIDATION_FORM,
  PATH_FIELD_NO_COMPARE,
  PATH_FIELD_TO_COMPARE,
  PATH_BLOCKS_SORTING,
];
