import React, { useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SVG from '../../../atoms/SVG/SVG';
import styles from './FormulaToken.css';
import {
  FIELD_REFERENCE,
  LITERAL_NUMBER,
  LITERAL_STRING,
  OPERATOR_ADD,
  OPERATOR_DIVIDE,
  OPERATOR_EQ,
  OPERATOR_GT,
  OPERATOR_GTE,
  OPERATOR_IN,
  OPERATOR_LEFT_PARENTHESIS,
  OPERATOR_LT,
  OPERATOR_LTE,
  OPERATOR_MODULO,
  OPERATOR_MULTIPLY,
  OPERATOR_NEQ,
  OPERATOR_NOTIN,
  OPERATOR_RIGHT_PARENTHESIS,
  OPERATOR_SUBSTRACT,
  OPERATOR_OR,
  OPERATOR_XOR,
  OPERATOR_AND,
  OPERATOR_NOT,
} from '../../../../constants/formula';
import { useDrag, useDrop } from 'react-dnd';
import { FORMULA_TOKEN } from '../../../../constants/draggableType';
import { useSelector } from 'react-redux';
import {
  formBlockSelector,
  formFieldSelector,
  focusedFieldSelector,
} from '../../../../store/selectors/editForm';
import { bem } from '../../../../helpers/styles';
import i18next from 'i18next';
import { isInside } from '../../../../helpers/formTree';
import { formBlocksSelector } from '../../../../store/selectors/editForm';

function getFieldRefLabel(fieldData, blockData, duplicableResolution) {
  let prefix = '';

  if (blockData?.duplicable) {
    switch (duplicableResolution) {
      case 'count':
        prefix = i18next.t('FormulaToken.countOf');
        break;
      case 'sum':
        prefix = i18next.t('FormulaToken.sumOf');
        break;
      case 'foreach':
        prefix = i18next.t('FormulaToken.forEach');
        break;
      case 'foreachIter':
        prefix = i18next.t('FormulaToken.forEachIter');
        break;
      case null:
        prefix = i18next.t('FormulaToken.forEachIter');
        break;
      default:
        break;
    }
  }

  const fieldLabel = fieldData?.label || '...';
  const suffix =
    blockData && blockData.duplicable
      ? `${i18next.t('FormulaToken.forEach')} ${blockData?.name || '...'}`
      : '';

  return [prefix, fieldLabel, suffix].join(' ').trim();
}
function getFieldRefAllDuplicableLabel(fieldData, blockData, duplicableResolution) {
  let prefix = '';
  switch (duplicableResolution) {
    case 'count':
      prefix = i18next.t('FormulaToken.countOf');
      break;
    case 'sum':
      prefix = i18next.t('FormulaToken.sumOf');
      break;
    case 'foreach':
      prefix = i18next.t('FormulaToken.forEach');
      break;
    case 'foreachIter':
      prefix = i18next.t('FormulaToken.forEachIter');
      break;
    case null:
      prefix = i18next.t('FormulaToken.forEachIter');
      break;
    default:
      break;
  }

  const fieldLabel = fieldData?.label || '...';
  const suffix = `${i18next.t('FormulaToken.forAll')}`;
  return [prefix, fieldLabel, suffix].join(' ').trim();
}

function FormulaToken({
  id,
  field,
  blockScope,
  duplicableResolution,
  onChange,
  value,
  type,
  onDelete,
  onSwap,
}) {
  const [menuOpen, setMenuOpen] = useState(false);

  const fieldData = useSelector(formFieldSelector(field));
  const blockData = useSelector(formBlockSelector(blockScope));
  const focusedField = useSelector(focusedFieldSelector);
  const blocks = useSelector(formBlocksSelector);
  const blockInsideField =
    blocks && fieldData && blocks.map((elem) => isInside(elem.box, fieldData.box));

  //blocks Up or equivalent of the select field
  const blockUpandEqual =
    blockInsideField &&
    blockInsideField
      .map((elem, index) => {
        return elem === true && blocks[index];
      })
      .filter((el) => el != false);

  // only blocks duplicable Up or equivalent of the select field
  const blockUpEqualDuplicable = blockUpandEqual && blockUpandEqual.filter((el) => el.duplicable);

  //Check if there is a non duplicable groupe between two duplicable group
  const checkInsideNoDuplicable =
    blockUpandEqual &&
    blockUpandEqual.length > 2 &&
    blockUpandEqual[blockUpandEqual.length - 1].duplicable &&
    !blockUpandEqual[blockUpandEqual.length - 2].duplicable &&
    blockUpandEqual[blockUpandEqual.length - 3].duplicable;

  // Token preview
  const displayText = useMemo(() => {
    switch (type) {
      case LITERAL_NUMBER:
        return value;
      case LITERAL_STRING:
        return `"${value}"`;
      case OPERATOR_ADD:
        return '+';
      case OPERATOR_SUBSTRACT:
        return '-';
      case OPERATOR_DIVIDE:
        return '/';
      case OPERATOR_MULTIPLY:
        return '*';
      case OPERATOR_MODULO:
        return '%';
      case OPERATOR_EQ:
        return '=';
      case OPERATOR_NEQ:
        return '<>';
      case OPERATOR_GT:
        return '>';
      case OPERATOR_GTE:
        return '>=';
      case OPERATOR_LT:
        return '<';
      case OPERATOR_LTE:
        return '<=';
      case OPERATOR_IN:
        return 'in';
      case OPERATOR_NOT:
        return 'not';
      case OPERATOR_NOTIN:
        return 'not in';
      case OPERATOR_OR:
        return 'OR';
      case OPERATOR_AND:
        return 'AND';
      case OPERATOR_XOR:
        return 'XOR';
      case OPERATOR_LEFT_PARENTHESIS:
        return '(';
      case OPERATOR_RIGHT_PARENTHESIS:
        return ')';
      case FIELD_REFERENCE:
        if (
          (blockData === undefined && duplicableResolution) ||
          (blockUpEqualDuplicable && blockUpEqualDuplicable.length === 1)
        ) {
          return getFieldRefAllDuplicableLabel(fieldData, blockData, duplicableResolution);
        } else {
          return getFieldRefLabel(fieldData, blockData, duplicableResolution);
        }

      default:
        return '<ukn>';
    }
  }, [type, fieldData, blockData, duplicableResolution, blockUpEqualDuplicable]);

  // Dragging handler
  const [{ isDragging }, drag] = useDrag({
    item: { type: FORMULA_TOKEN, id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // Drop handler for swapping elements as dragging is on progress
  const [, drop] = useDrop({
    accept: FORMULA_TOKEN,
    // We use this for hover() hook, not as dropable target
    canDrop: () => false,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        onSwap(draggedId, id);
      }
    },
  });

  const handleDelete = useCallback(
    (event) => {
      event.stopPropagation();
      onDelete(id);
    },
    [onDelete, id],
  );

  // Menu for duplicable resolution
  const handleCaretClick = useCallback(
    (event) => {
      event.stopPropagation();
      setMenuOpen(!menuOpen);
    },
    [menuOpen],
  );

  const handleMenuItemClick = useCallback(
    (event, changedDuplicableResolution) => {
      onChange && onChange(id, { duplicableResolution: changedDuplicableResolution });
    },
    [id, onChange],
  );
  const handleDuplicableMenuItemClick = useCallback(
    (event, changedDuplicableResolution, blockScope) => {
      onChange &&
        onChange(id, { duplicableResolution: changedDuplicableResolution, blockScope: blockScope });
    },
    [id, onChange],
  );

  // Handle click outside menu
  useEffect(() => {
    function handleClickOutside() {
      setMenuOpen(false);
    }

    if (open) {
      document.body.addEventListener('click', handleClickOutside);
    }

    return () => {
      if (open) {
        document.body.removeEventListener('click', handleClickOutside);
      }
    };
  }, [menuOpen]);

  const ruleButtons = () => {
    if (focusedField && focusedField.hideStatus === 'onlyforeach') return null;
    if (focusedField && focusedField.hideStatus === 'sum_number') {
      return (
        <>
          <button
            type="button"
            className={styles.FormulaToken__menuItem}
            onClick={(event) => handleMenuItemClick(event, 'sum')}
          >
            {getFieldRefAllDuplicableLabel(fieldData, blockData, 'sum')}
          </button>
          <button
            type="button"
            className={styles.FormulaToken__menuItem}
            onClick={(event) => handleMenuItemClick(event, 'count')}
          >
            {getFieldRefAllDuplicableLabel(fieldData, blockData, 'count')}
          </button>
        </>
      );
    }
    if (blockUpandEqual && focusedField === null && blockUpEqualDuplicable.length === 1) {
      return (
        <>
          <button
            type="button"
            className={styles.FormulaToken__menuItem}
            onClick={(event) => handleDuplicableMenuItemClick(event, 'sum', null)}
          >
            {getFieldRefAllDuplicableLabel(fieldData, blockData, 'sum')}
          </button>

          <button
            type="button"
            className={styles.FormulaToken__menuItem}
            onClick={(event) => handleDuplicableMenuItemClick(event, 'count', null)}
          >
            {getFieldRefAllDuplicableLabel(fieldData, blockData, 'count')}
          </button>
          <button
            type="button"
            className={styles.FormulaToken__menuItem}
            onClick={(event) => handleDuplicableMenuItemClick(event, 'foreach', null)}
          >
            {getFieldRefAllDuplicableLabel(fieldData, blockData, 'foreach')}
          </button>
        </>
      );
    }
    //////////////////////////////////////////////
    if (
      blockUpandEqual &&
      focusedField === null &&
      blockUpandEqual.length > 2 &&
      blockUpandEqual[blockUpandEqual.length - 1].duplicable &&
      blockUpandEqual[blockUpandEqual.length - 3].duplicable
    ) {
      return (
        <>
          <button
            type="button"
            className={styles.FormulaToken__menuItem}
            onClick={(event) => handleDuplicableMenuItemClick(event, 'sum', null)}
          >
            {getFieldRefAllDuplicableLabel(fieldData, blockData, 'sum')}
          </button>
          {!checkInsideNoDuplicable && (
            <button
              type="button"
              className={styles.FormulaToken__menuItem}
              onClick={(event) =>
                handleDuplicableMenuItemClick(
                  event,
                  'sum',
                  blockUpandEqual[blockUpandEqual.length - 2].id,
                )
              }
            >
              {getFieldRefLabel(fieldData, blockUpandEqual[blockUpandEqual.length - 2], 'sum')}
            </button>
          )}

          <button
            type="button"
            className={styles.FormulaToken__menuItem}
            onClick={(event) =>
              handleDuplicableMenuItemClick(
                event,
                'sum',
                blockUpandEqual[blockUpandEqual.length - 3].id,
              )
            }
          >
            {getFieldRefLabel(fieldData, blockUpandEqual[blockUpandEqual.length - 3], 'sum')}
          </button>
          <button
            type="button"
            className={styles.FormulaToken__menuItem}
            onClick={(event) => handleDuplicableMenuItemClick(event, 'count', null)}
          >
            {getFieldRefAllDuplicableLabel(fieldData, blockData, 'count')}
          </button>
          {!checkInsideNoDuplicable && (
            <button
              type="button"
              className={styles.FormulaToken__menuItem}
              onClick={(event) =>
                handleDuplicableMenuItemClick(
                  event,
                  'count',
                  blockUpandEqual[blockUpandEqual.length - 2].id,
                )
              }
            >
              {getFieldRefLabel(fieldData, blockUpandEqual[blockUpandEqual.length - 2], 'count')}
            </button>
          )}

          <button
            type="button"
            className={styles.FormulaToken__menuItem}
            onClick={(event) =>
              handleDuplicableMenuItemClick(
                event,
                'count',
                blockUpandEqual[blockUpandEqual.length - 3].id,
              )
            }
          >
            {getFieldRefLabel(fieldData, blockUpandEqual[blockUpandEqual.length - 3], 'count')}
          </button>
          <button
            type="button"
            className={styles.FormulaToken__menuItem}
            onClick={(event) => handleMenuItemClick(event, 'foreach')}
          >
            {getFieldRefAllDuplicableLabel(fieldData, blockData, 'foreach')}
          </button>
        </>
      );
    }
    //////////////////////////////////////////////////
    if (
      blockUpandEqual &&
      focusedField === null &&
      blockUpandEqual.length > 1 &&
      blockUpandEqual[blockUpandEqual.length - 1].duplicable &&
      blockUpandEqual[blockUpandEqual.length - 2].duplicable
    ) {
      return (
        <>
          <button
            type="button"
            className={styles.FormulaToken__menuItem}
            onClick={(event) => handleDuplicableMenuItemClick(event, 'sum', null)}
          >
            {getFieldRefAllDuplicableLabel(fieldData, blockData, 'sum')}
          </button>
          <button
            type="button"
            className={styles.FormulaToken__menuItem}
            onClick={(event) =>
              handleDuplicableMenuItemClick(
                event,
                'sum',
                blockUpandEqual[blockUpandEqual.length - 2].id,
              )
            }
          >
            {getFieldRefLabel(fieldData, blockUpandEqual[blockUpandEqual.length - 2], 'sum')}
          </button>
          <button
            type="button"
            className={styles.FormulaToken__menuItem}
            onClick={(event) => handleDuplicableMenuItemClick(event, 'count', null)}
          >
            {getFieldRefAllDuplicableLabel(fieldData, blockData, 'count')}
          </button>
          <button
            type="button"
            className={styles.FormulaToken__menuItem}
            onClick={(event) =>
              handleDuplicableMenuItemClick(
                event,
                'count',
                blockUpandEqual[blockUpandEqual.length - 2].id,
              )
            }
          >
            {getFieldRefLabel(fieldData, blockUpandEqual[blockUpandEqual.length - 2], 'count')}
          </button>
          <button
            type="button"
            className={styles.FormulaToken__menuItem}
            onClick={(event) => handleMenuItemClick(event, 'foreach')}
          >
            {getFieldRefAllDuplicableLabel(fieldData, blockData, 'foreach')}
          </button>
        </>
      );
    }

    return (
      <>
        <button
          type="button"
          className={styles.FormulaToken__menuItem}
          onClick={(event) => handleMenuItemClick(event, 'sum')}
        >
          {getFieldRefLabel(fieldData, blockData, 'sum')}
        </button>
        <button
          type="button"
          className={styles.FormulaToken__menuItem}
          onClick={(event) => handleMenuItemClick(event, 'count')}
        >
          {getFieldRefLabel(fieldData, blockData, 'count')}
        </button>
        <button
          type="button"
          className={styles.FormulaToken__menuItem}
          onClick={(event) => handleMenuItemClick(event, 'foreach')}
        >
          {getFieldRefLabel(fieldData, blockData, 'foreach')}
        </button>
        <button
          type="button"
          className={styles.FormulaToken__menuItem}
          onClick={(event) => handleMenuItemClick(event, 'foreachIter')}
        >
          {getFieldRefLabel(fieldData, blockData, 'foreachIter')}
        </button>
      </>
    );
  };

  return (
    <div
      className={styles.FormulaToken}
      ref={(node) => drag(drop(node))}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      {duplicableResolution !== null &&
        blockUpEqualDuplicable &&
        blockUpEqualDuplicable.length > 0 && (
          <button
            type="button"
            className={bem(styles, 'FormulaToken__button', { right: true, menuOpen })}
            onClick={handleCaretClick}
          >
            <SVG className={styles.FormulaToken__icon} glyph="caret" />
          </button>
        )}
      {displayText}
      <button
        type="button"
        className={bem(styles, 'FormulaToken__button', { left: true })}
        onClick={handleDelete}
      >
        <SVG className={styles.FormulaToken__icon} glyph="remove_circled" />
      </button>
      {duplicableResolution !== null &&
        blockUpEqualDuplicable &&
        blockUpEqualDuplicable.length > 0 &&
        menuOpen && <div className={styles.FormulaToken__menu}>{ruleButtons()}</div>}
    </div>
  );
}

FormulaToken.propTypes = {
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  onDelete: PropTypes.func,
  onSwap: PropTypes.func,
  field: PropTypes.string,
  blockScope: PropTypes.string,
  duplicableResolution: PropTypes.string,
  onChange: PropTypes.func,
};

export default FormulaToken;
