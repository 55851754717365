import React, { useEffect, useState } from 'react';
import NoCompare from '../../atoms/noCompare/NoCompare';
import Form from '../../atoms/Form/Form';
import { setSelectFields, setSelectMode, updateForm } from '../../../store/actions/editForm';
import { useDispatch, useSelector } from 'react-redux';
import { currentFormSelector, selectFieldsSelector } from '../../../store/selectors/editForm';
import { useActions } from '../../../hooks/useActions';
import { deleteSelectFields as deleteCompareFieldsAction } from '../../../store/actions/editForm';

function QualityNoCompareTemplate() {
  const [mounted, setMounted] = useState(false);
  const form = useSelector(currentFormSelector);
  const compareFields = useSelector(selectFieldsSelector);
  const [deleteCompareFields] = useActions([deleteCompareFieldsAction]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectMode(true));
    return () => dispatch(setSelectMode(false));
  }, []);

  // Initialize compare field with form values
  useEffect(() => {
    if (form && !mounted) {
      if (form.noCompareFields) {
        dispatch(setSelectFields(form.noCompareFields.map((fieldId) => ({ id: fieldId }))));
      }
      setMounted(true);
    }
  }, [form, mounted]);

  // Once component is mounted we can push back changes from selectFields to the form object
  useEffect(() => {
    if (mounted) {
      dispatch(
        updateForm({
          noCompareFields: compareFields.map((field) => field.id),
        }),
      );
    }
  }, [compareFields, mounted]);

  return (
    <Form>
      <NoCompare fields={compareFields || []} handleRemove={deleteCompareFields} />
    </Form>
  );
}

export default QualityNoCompareTemplate;
