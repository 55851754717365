import { v4 as uuid } from 'uuid';

export const CERTIFICATION_GET = 'CERTIFICATION_GET';
export const CERTIFICATION_GET_SUCCESS = 'CERTIFICATION_GET_SUCCESS';
export const CERTIFICATION_GET_QUESTION = 'CERTIFICATION_GET_QUESTION';
export const CERTIFICATION_GET_QUESTION_SUCCESS = 'CERTIFICATION_GET_QUESTION_SUCCESS';
export const CERTIFICATION_UPDATE = 'CERTIFICATION_UPDATE';
export const CERTIFICATION_UPDATE_SUCCESS = 'CERTIFICATION_UPDATE_SUCCESS';
export const CERTIFICATION_UPDATE_QUESTION = 'CERTIFICATION_QUESTION_UPDATE';
export const CERTIFICATION_UPDATE_QUESTION_SUCCESS = 'CERTIFICATION_QUESTION_UPDATE_SUCCESS';
export const CERTIFICATION_FORM_GET = 'CERTIFICATION_FORM_GET';
export const CERTIFICATION_FORM_GET_SUCCESS = 'CERTIFICATION_FORM_GET_SUCCESS';
export const CERTIFICATION_ANSWER = 'CERTIFICATION_ANSWER';
export const CERTIFICATION_ANSWER_SUCCESS = 'CERTIFICATION_ANSWER_SUCCESS';

export const getCertification = (certificationId, meta = {}) => ({
  type: CERTIFICATION_GET,
  fetch: {
    method: 'POST',
    url: `{{backendUrl}}/CertificationService/CertificationUserTest/${certificationId}/Question`,
    auth: true,
    parse: 'parseCertification',
  },
  meta: { requestId: uuid(), ...meta },
});

export const getCertificationQuestion = (certificationId, questionId, meta = {}) => ({
  type: CERTIFICATION_GET_QUESTION,
  fetch: {
    method: 'GET',
    url: `{{backendUrl}}/CertificationService/Certification/${certificationId}/Question/${questionId}`,
    auth: true,
    parse: 'parseCertificationQuestion',
  },
  meta: { requestId: uuid(), ...meta },
});

export const getFormCertification = (formId) => ({
  type: CERTIFICATION_FORM_GET,
  fetch: {
    method: 'GET',
    url: `{{backendUrl}}/FormService/Form/${formId}/GetTemplate`,
    auth: true,
    parse: 'parseForm',
  },
  meta: { requestId: uuid() },
});

export const createCertificationAnswer = (certificationId,certificationUserId, certificationQuestionId,certificationUserQuestionId, json, meta = {}) => ({
  type: CERTIFICATION_ANSWER,
  fetch: {
    method: 'PUT',
    url: `{{backendUrl}}/CertificationService/CertificationUserTest/SubmitQuestion`,
    auth: true,
    json: {
      IdCertification: certificationId,
      IdCertificationUserTest :  certificationUserId,
      IdCertificationQuestion : certificationQuestionId,
      IdCertificationUserQuestion : certificationUserQuestionId,
      Answer: json,
    },
  },
  meta: { requestId: uuid(), ...meta },
});

export const updateCertificationQuestionAnswer = (certificationId,questionId, JsonAnswer, meta = {}) => ({
  type: CERTIFICATION_UPDATE_QUESTION,
  fetch: {
    method: 'PUT',
    url: `{{backendUrl}}/CertificationService/Certification/Questions/UpdateAnswer`,
    auth: true,
    json: {
      IdCertification: certificationId,
      IdCertificationQuestion : questionId,
      Answer: JsonAnswer,
    },
  },
  meta: { requestId: uuid(), ...meta },
});

