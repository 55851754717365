import React, { useMemo, useCallback } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { FieldArray, reduxForm } from 'redux-form';
import TextField from '../../molecules/Field/Text/TextField';
import Form from '../../atoms/Form/Form';
import Button from '../../atoms/Button/Button';
import useTranslate from '../../../hooks/useTranslate';
import KEYS from '../../../constants/keyboard';
import AdvancedSelectField from '../../molecules/AdvancedSelectField/AdvancedSelectField';
import styles from './KeyboardShortcutsForm.css';
import { useSelector } from 'react-redux';
import { formFieldsSelector } from '../../../store/selectors/editForm';
import { MCQ } from '../../../constants/typology/fieldType';
import { ADVANCEDSELECT } from '../../../constants/typology/fieldType';
import { keyboardShortcutsSelector } from '../../../store/selectors/editForm';

const getKeysOptions = () =>
  KEYS.map((keyData) => ({
    value: keyData.code,
    label: i18n.t('KeyboardShortcutsForm.key', { key: keyData.code }),
  }));

const getMultiModeOptions = (fieldType) =>
  fieldType != null
    ? ['set', 'unset', 'toggle'].map((mode) => ({
        value: mode,
        label: i18n.t(`KeyboardShortcutsForm.${fieldType}.multiMode.${mode}`),
      }))
    : [];

function KeyboardShortcutsField({ index, field, onRemoveField }) {
  const i18n = useTranslate();
  const options = useMemo(() => getKeysOptions(), [i18n]);

  const fields = useSelector(formFieldsSelector);
  const fieldOptions = useMemo(
    () =>
      fields
        .map((f, i) => ({
          label: f.label || i18n('KeyboardShortcutsForm.noName', { i }),
          value: i, // We use the index as the ids aren't set yet
          type: f.type,
        }))
        .filter((f) => [ADVANCEDSELECT, MCQ].includes(f.type)),
    [fields],
  );

  const shortcut = useSelector(keyboardShortcutsSelector)[index];
  const selectedField = shortcut != null ? fields[shortcut?.field] : null;

  const multiModeOptions = useMemo(() => getMultiModeOptions(selectedField?.type), [selectedField]);

  const valueOptions = useMemo(
    () =>
      selectedField?.choices.split('\n').map((f) => ({
        label: f,
        value: f,
      })) || [],
    [shortcut?.field],
  );

  const handleOnClickRemove = useCallback(() => {
    onRemoveField(index);
  }, [index]);

  if (shortcut == null) {
    return null;
  }

  return (
    <div className={styles.KeyboardShortcutsForm__shortcut}>
      <AdvancedSelectField name={`${field}.key`} validation={{ options }} />

      {shortcut.type == null || shortcut.type === 'text' ? (
        <TextField name={`${field}.value`} placeholder={i18n('KeyboardShortcutsForm.value')} />
      ) : null}

      {shortcut.type === 'multi' ? (
        <>
          <AdvancedSelectField name={`${field}.field`} validation={{ options: fieldOptions }} />

          {shortcut.field != null ? (
            <AdvancedSelectField
              key={`value-${shortcut?.field}`} // Unique key to refresh the options based on selected field
              name={`${field}.value`}
              validation={{ options: valueOptions }}
            />
          ) : null}

          {selectedField?.multi ? (
            <AdvancedSelectField
              key={`value-${shortcut?.field}-multiMode`} // Unique key to refresh the options based on selected field
              name={`${field}.multiMode`}
              validation={{ options: multiModeOptions }}
            />
          ) : null}
        </>
      ) : null}

      <Button
        type="button"
        icon="trash"
        flat
        onClick={handleOnClickRemove}
        className={styles.KeyboardShortcutsForm__deleteButton}
      />
    </div>
  );
}

KeyboardShortcutsField.propTypes = {
  field: PropTypes.string,
  index: PropTypes.number,
  onRemoveField: PropTypes.func,
};

function KeyboardShortcutsFields({ fields }) {
  const i18n = useTranslate();

  const handleOnRemoveField = useCallback((index) => {
    fields.remove(index);
  }, []);

  return (
    <div>
      <div className={styles.KeyboardShortcutsForm__actions}>
        <Button type="button" onClick={() => fields.push({ type: 'text' })} icon="plus" flat>
          {i18n('KeyboardShortcutsForm.addShortcut')}
        </Button>

        <Button type="button" onClick={() => fields.push({ type: 'multi' })} icon="plus" flat>
          {i18n('KeyboardShortcutsForm.addMultiShortcut')}
        </Button>
      </div>
      <div>
        {fields.map((field, index) => (
          <KeyboardShortcutsField
            key={index}
            index={index}
            field={field}
            onRemoveField={handleOnRemoveField}
          />
        ))}
      </div>
    </div>
  );
}

KeyboardShortcutsFields.propTypes = {
  fields: PropTypes.object,
};

function KeyboardShortcutsForm({ handleSubmit }) {
  return (
    <Form onSubmit={handleSubmit}>
      <FieldArray name="shortcuts" component={KeyboardShortcutsFields} />
    </Form>
  );
}

KeyboardShortcutsForm.propTypes = {
  handleSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};

export default reduxForm({
  form: 'keyboardShortcuts',
})(KeyboardShortcutsForm);
