import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

function DraggableBoxHandle({ className, direction, boxId, onDragStart, onDragEnd }) {
  const [, dragRef, preview] = useDrag({
    item: { type: 'resize-handle', direction, boxId },
    begin() {
      onDragStart && onDragStart();
    },
    end() {
      onDragEnd && onDragEnd();
    },
  });

  // Disable drag n drop preview
  useEffect(() => {
    preview(getEmptyImage());
  }, []);

  return <div ref={dragRef} className={className} />;
}

DraggableBoxHandle.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.string,
  boxId: PropTypes.any,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
};

export default DraggableBoxHandle;
