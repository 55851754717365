import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { classnames } from '../../../helpers/classnames';
import { bem } from '../../../helpers/styles';

import styles from './Popover.css';

function Popover({ anchor, direction, children, className, ...restProps }) {
  const [PopoverClass, setClass] = useState('hidden');
  const [style, setStyle] = useState(direction);

  const getPositions = ({ width, height, top, left }, { width: widthN, height: heightN }) => {
    return {
      x: left - (widthN / 2 - width / 2),
      y: top - (heightN / 2 - height / 2),
      margeX: widthN / 2 + width / 2,
      margeY: heightN / 2 + height / 2,
    };
  };

  const initStyle = (rect) => {
    const { node } = this;

    const rectN = node && node.getBoundingClientRect();

    const { x, y, margeX, margeY } = getPositions(rect, rectN);
    const margeFix = 30;

    switch (direction) {
      case 'top':
        return `top: ${y - margeY - margeFix}px; left: ${x}px;`;
      case 'bottom':
        return `top: ${y + margeY + margeFix}px; left: ${x}px;`;
      case 'left':
        return `top: ${y}px; left: ${x - margeX - margeFix}px`;

      case 'right':
        return `top: ${y}px; left: ${x + margeX + margeFix}px`;

      default:
        return `top: ${y - margeY - margeFix}px; left: ${x}px;`;
    }
  };

  const handleHover = useCallback((classname) => setClass(classname), [setClass]);
  const handleStyle = useCallback((rect) => setStyle(initStyle(rect)), [setStyle]);

  const props = {
    anchor: anchor ? anchor.current : anchor,
    direction,
    children,
    className: classnames([bem(styles, 'Popover', { [PopoverClass]: true }), className]),
    style,
  };

  useEffect(() => {
    if (anchor) {
      const { current } = anchor;

      current.addEventListener('mouseover', () => {
        const rect = current.getBoundingClientRect();
        handleHover('');
        handleStyle(rect);
      });

      current.addEventListener('mouseout', () => handleHover('hidden'));

      return () => {
        current.removeEventListener('mouseover', () => handleHover(''));
        current.removeEventListener('mouseout', () => handleHover('hidden'));
      };
    }
  }, [anchor.current]);

  return (
    <div {...props} {...restProps} ref={(node) => (this.node = node)}>
      {props.children}
    </div>
  );
}

Popover.defaultProps = {
  direction: 'right',
};

Popover.propTypes = {
  direction: PropTypes.oneOf[('top', 'bottom', 'left', 'right')],
  anchor: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Popover;
