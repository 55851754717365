import React from 'react';
import ReactDOM from 'react-dom';
import ReactQuill from 'react-quill';

const BlockEmbed = ReactQuill.Quill.import('blots/block/embed');
const Link = ReactQuill.Quill.import('formats/link');

import VideoPlayer from '../components/atoms/VideoPlayer/VideoPlayer';
import AudioPlayer from '../components/atoms/AudioPlayer/AudioPlayer';

const ATTRIBUTES_VIDEO = ['src'];
const ATTRIBUTES_AUDIO = ['url', 'ref'];

/**
 * Overload of the lib react-quill to add the following features Video and Audio
 * Video is replaced to use our component.
 * Audio didn't exist.
 */

class Video extends BlockEmbed {
  constructor(domNode) {
    super(domNode);
  }

  static setUrl(url) {
    this.url = url;
  }

  static value() {
    return this.url;
  }

  static create(value) {
    this.setUrl(value);
    const child = <VideoPlayer url={value} className="ql-video" controls={false} />;
    let node = super.create(child);

    ReactDOM.render(child, node);

    return node;
  }

  static sanitize(url) {
    return Link.sanitize(url);
  }

  static formats(domNode) {
    const nodeSource = domNode.querySelector('source');
    ATTRIBUTES_VIDEO.reduce((formats, attribute) => {
      if (nodeSource?.hasAttribute(attribute)) {
        formats[attribute] = nodeSource.getAttribute(attribute);
      }
      return formats;
    }, {});
  }

  format(name, value) {
    const nodeSource = this.domNode.querySelector('source');
    if (nodeSource) {
      if (ATTRIBUTES_VIDEO.indexOf(name) > -1) {
        if (value) {
          nodeSource.setAttribute(name, value);
        } else {
          nodeSource.removeAttribute(name);
        }
      } else {
        super.format(name, value);
      }
    }
  }

  html() {
    return this.domNode;
  }
}

Video.blotName = 'video';
Video.className = 'ql-video';
Video.tagName = 'div';

class Audio extends BlockEmbed {
  constructor(domNode) {
    super(domNode);
  }

  static setUrl(url) {
    this.url = url;
  }

  static value() {
    return this.url;
  }

  static sanitize(url) {
    return Link.sanitize(url);
  }

  static create(value) {
    this.setUrl(value);
    const node = super.create(value);
    const child = <AudioPlayer url={value} controls={false} />;
    ReactDOM.render(child, node);
    return node;
  }

  static formats(domNode) {
    const nodeAudio = domNode.firstChild;

    return ATTRIBUTES_AUDIO.reduce((formats, attribute) => {
      if (nodeAudio?.hasAttribute(attribute)) {
        formats[attribute] = nodeAudio.getAttribute(attribute);
      }

      return formats;
    }, {});
  }

  format(name, value) {
    const nodeAudio = this.domNode.firstChild;
    if (nodeAudio) {
      if (ATTRIBUTES_AUDIO.indexOf(name) > -1) {
        if (value) {
          nodeAudio.setAttribute(name, value);
        } else {
          nodeAudio.removeAttribute(name);
        }
      } else {
        super.format(name, value);
      }
    }
  }

  html() {
    return this.domNode;
  }
}
Audio.blotName = 'audio';
Audio.className = 'ql-audio';
Audio.tagName = 'div';

ReactQuill.Quill.register(
  {
    'formats/video': Video,
    'formats/audio': Audio,
  },
  true,
);

export default ReactQuill;
