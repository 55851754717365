import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './InputDateTime.css';
import { DATE_FORMAT } from '../../../constants/regex';

const RIGHT_KEY_CODE = 39;
const LEFT_KEY_CODE = 37;
const UP_KEY_CODE = 38;
const DOWN_KEY_CODE = 40;

export const formatToTwoDigits = (number) => {
  let numStr = number.toString();
  if (numStr.length < 2) {
    numStr = '0' + numStr;
  }
  if (numStr.length > 2 && numStr.charAt(0) === '0') {
    return numStr.substring(1);
  }
  return numStr;
};

export const formatToFourDigits = (number) => {
  let numStr = number.toString();
  numStr = numStr.replace(/^0+/, '');
  while (numStr.length < 4) {
    numStr = '0' + numStr;
  }
  return numStr;
};

export const dayTransform = ({ value }) => {
  try {
    const number = Number(value);
    if (number >= 1 && number <= 31) {
      return formatToTwoDigits(number);
    }
    if (number > 31) {
      return 31;
    }
    return value;
  } catch (e) {
    return null;
  }
};

export const monthTransform = ({ value }) => {
  try {
    const number = Number(value);
    if (number >= 1 && number <= 12) {
      return formatToTwoDigits(number);
    }
    if (number > 12) {
      return 12;
    }
    return value;
  } catch (e) {
    return null;
  }
};

export const yearTransform = ({ value }) => {
  if (value >= 1 && value <= 9999) {
    return formatToFourDigits(value);
  } else {
    return null;
  }
};

export const getDayMonthYear = (date, format = DATE_FORMAT) => {
  const dateMoment = moment(date, format);

  let day;
  let month;
  let year;

  if (dateMoment.isValid()) {
    day = formatToTwoDigits(dateMoment.date());
    month = formatToTwoDigits(dateMoment.month() + 1);
    year = dateMoment.year();
  }

  return {
    day,
    month,
    year,
  };
};

const focusNextElement = (value) => {
  const regex = /^0\d{1}$/;
  return regex.test(value);
};

function InputDateTime({
  id,
  name,
  value,
  onChange,
  onBlur,
  onFocus,
  error,
  style,
  isFocusOnInit,
  disabled,
}) {
  const [daySelected, setDaySelected] = useState(false);
  const [monthSelected, setMonthSelected] = useState(false);
  const [yearSelected, setYearSelected] = useState(false);

  const { day: dayInit, month: monthInit, year: yearInit } = getDayMonthYear(value);

  const [day, setDay] = useState(dayInit);
  const [month, setMonth] = useState(monthInit);
  const [year, setYear] = useState(yearInit);

  useEffect(() => {
    setDay(dayInit);
    setMonth(monthInit);
    setYear(yearInit);
  }, [dayInit, monthInit, yearInit]);

  const divRef = useRef(null);
  const [isFocusDiv, setIsFocusDiv] = useState(false);

  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);

  const dateFormat = ({ dayC = day, monthC = month, yearC = year }) => {
    if (dayC || monthC || yearC) {
      return `${yearC}-${monthC}-${dayC}`;
    }
    return null;
  };

  const dayHandleChange = (value) => {
    const dayC = dayTransform({ value });
    setDay(() => dayC);
    return dateFormat({ dayC });
  };

  const monthHandleChange = (value) => {
    const monthC = monthTransform({ value });
    setMonth(() => monthC);
    return dateFormat({ monthC });
  };

  const yearHandleChange = (value) => {
    const yearC = yearTransform({ value });
    setYear(() => yearC);
    return dateFormat({ yearC });
  };

  useEffect(() => {
    if (isFocusOnInit) {
      dayRef?.current?.focus();
      onFocus();
    }
  }, []);

  useEffect(() => {
    if (daySelected) {
      dayRef.current.select();
      setDaySelected(false);
    }
    if (monthSelected) {
      monthRef.current.select();
      setMonthSelected(false);
    }
    if (yearSelected) {
      yearRef.current.select();
      setYearSelected(false);
    }
  }, [monthSelected, daySelected, yearSelected]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (divRef.current && divRef.current.contains(event.target)) {
        setIsFocusDiv(true);
      } else {
        setIsFocusDiv(false);
      }
    }
    // Bind the event listener
    document.addEventListener('click', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div
      // TODO "Understanding the usefulness of passing IDs in the input"
      id={id}
      ref={divRef}
      className={styles.InputDateTime}
      style={{
        borderColor: error ? 'rgba(255, 91, 97, 1)' : isFocusDiv ? '#7373ff' : 'rgb(220, 223, 230)',
        ...style,
      }}
    >
      <div className="container">
        <div className="item_1">
          <input
            name={name}
            data-cy="input_day-InputDateTime"
            className="input_day"
            ref={dayRef}
            type="number"
            disabled={disabled}
            value={day}
            onFocus={(e) => {
              e.preventDefault();
              e.target?.select();
              setIsFocusDiv(true);
              onFocus();
            }}
            onChange={(e) => {
              const targetValue = e.target.value;
              const date = dayHandleChange(targetValue);
              onChange(date);
              if (dayTransform({ value: targetValue }) >= 4 || focusNextElement(targetValue)) {
                monthRef.current?.focus();
              }
            }}
            onBlur={(e) => {
              const date = dayHandleChange(e.target.value);
              onBlur(date);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === RIGHT_KEY_CODE) {
                monthRef.current?.focus();
                setMonthSelected(true);
              }
              if (e.keyCode === UP_KEY_CODE) {
                e.preventDefault();
                const newDay = Number(day) + 1;
                const date = dayHandleChange(newDay);
                onChange(date);
              }
              if (e.keyCode === DOWN_KEY_CODE) {
                e.preventDefault();
                const newDay = Number(day) - 1;
                const date = dayHandleChange(newDay);
                onChange(date);
              }
            }}
            min="1"
            max="31"
            placeholder="jj"
          />{' '}
          /{/*INPUT*/}
          <input
            data-cy="input_month-InputDateTime"
            className="input_month"
            ref={monthRef}
            type="number"
            value={month}
            disabled={disabled}
            onFocus={(e) => {
              e.preventDefault();
              e.target?.select();
              onFocus();
            }}
            onChange={(e) => {
              const targetValue = e.target.value;
              const date = monthHandleChange(targetValue);
              onChange(date);
              if (monthTransform({ value: targetValue }) >= 2 || focusNextElement(targetValue)) {
                yearRef.current?.focus();
              }
            }}
            onBlur={(e) => {
              const date = monthHandleChange(e.target.value);
              onBlur(date);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === RIGHT_KEY_CODE) {
                yearRef.current?.focus();
                setYearSelected(true);
              }
              if (e.keyCode === LEFT_KEY_CODE) {
                dayRef.current?.focus();
                setDaySelected(true);
              }
              if (e.keyCode === UP_KEY_CODE) {
                e.preventDefault();
                const newMonth = Number(month) + 1;
                const date = monthHandleChange(newMonth);
                onChange(date);
              }
              if (e.keyCode === DOWN_KEY_CODE) {
                e.preventDefault();
                const newMonth = Number(month) - 1;
                const date = monthHandleChange(newMonth);
                onChange(date);
              }
            }}
            min="1"
            max="31"
            placeholder="mm"
          />{' '}
          /
          <input
            data-cy="input_year-InputDateTime"
            className="input_year"
            ref={yearRef}
            type="number"
            value={year}
            disabled={disabled}
            onFocus={(e) => {
              e.preventDefault();
              e.target.select();
              onFocus();
            }}
            onChange={(e) => {
              const date = yearHandleChange(e.target.value);
              onChange(date);
            }}
            onBlur={(e) => {
              const date = yearHandleChange(e.target.value);
              onBlur(date);
            }}
            onKeyDown={(e) => {
              if (e.keyCode === LEFT_KEY_CODE) {
                monthRef.current?.focus();
                setMonthSelected(true);
              }
            }}
            min="1000"
            max="9999"
            placeholder="aaaa"
          />
          <div
            className="item_2"
            onClick={() => {
              dayRef.current?.focus();
              setDaySelected(true);
            }}
          />
        </div>
        <input
          data-cy="input_calander-InputDateTime"
          className="calander"
          onFocus={onFocus}
          disabled={disabled}
          value={`${year}-${month}-${day}`}
          onChange={(e) => {
            const value = e.target.value;
            if (value) {
              const { day: dayC, month: monthC, year: yearC } = getDayMonthYear(value);

              setYear(yearC);
              setMonth(monthC);
              setDay(dayC);

              const date = dateFormat({ dayC, monthC, yearC });
              onChange(date);
              onBlur(date);
            } else {
              setYear(null);
              setMonth(null);
              setDay(null);
              onChange();
              onBlur();
            }
          }}
          type="date"
        />
      </div>
    </div>
  );
}

InputDateTime.defaultProps = {
  onChange: () => null,
  onFocus: () => null,
  onBlur: () => null,
  id: '',
  name: '',
  value: null,
  error: false,
  style: null,
  disabled: false,
};

InputDateTime.propTypes = {
  isFocusOnInit: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  value: PropTypes.string,
};

export default InputDateTime;
