import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestStatusSelector } from '../store/selectors/requests';

export default function useFetchAction(fetchAction) {
  const [requestId, setRequestId] = useState(null);
  const status = useSelector(requestStatusSelector(requestId));
  const dispatch = useDispatch();

  const fetchCallback = useCallback(
    (...args) => {
      const action = fetchAction(...args);
      setRequestId(action.meta.requestId);
      dispatch(action);
    },
    [fetchAction],
  );

  return [
    fetchCallback,
    status?.status === 'pending',
    status?.error,
    status?.payload,
    status?.status,
    () => setRequestId(null),
  ];
}
