import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import SwitchToggle from '../../SwitchToggle/SwitchToggle';

function SwitchToggleFieldRender({
  input,
  labelRight,
  labelLeft,
  id,
  meta: { error, touched },
  disabled,
}) {
  return (
    <SwitchToggle
      {...input}
      error={touched && error}
      labelRight={labelRight}
      labelLeft={labelLeft}
      id={id}
      disabled={disabled}
      block
    />
  );
}

SwitchToggleFieldRender.propTypes = {
  input: PropTypes.object,
  labelRight: PropTypes.string,
  labelLeft: PropTypes.string,
  checked: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  disabled: PropTypes.bool,
};

function SwitchToggleField({ name, labelRight, labelLeft, ...props }) {
  const id = useMemo(() => `name_${uuid()}`, [name]);

  return (
    <FormField inline reverse>
      <Field
        name={name}
        component={SwitchToggleFieldRender}
        id={id}
        {...props}
        labelRight={labelRight}
        labelLeft={labelLeft}
        type="checkbox"
      />
    </FormField>
  );
}

SwitchToggleField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  labelRight: PropTypes.string,
  labelLeft: PropTypes.string,
};

export default SwitchToggleField;
