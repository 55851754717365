export function setCookie(name, value, expire) {
  var expireDate = new Date();
  expireDate.setTime(expireDate.getTime() + expire);
  document.cookie =
    name + '=' + escape(value) + '; expires=' + expireDate.toGMTString() + '; path=/';
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export function getOneOfCookie(names) {
  const array = Array.isArray(names) ? names : [names];
  for (let i = 0; i < array.length; i++) {
    const name = array[i];
    const value = getCookie(name);
    if (value) {
      return value;
    }
  }

  return undefined;
}
