import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import AudioPlayer from '../../../atoms/AudioPlayer/AudioPlayer';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import styles from './AudioPlayerData.css';
import getDummyData from '../../../../helpers/dummy';

function AudioPlayerRender({ input, id, meta: { initial } }) {
  if ((input.value || initial) === "") {
    return null;
  }

  return (
    <div className={styles.AudioPlayerData}>
      <AudioPlayer id={id} url={input.value || initial} controls />
    </div>
  );
}

AudioPlayerRender.propTypes = {
  input: PropTypes.object,
  id: PropTypes.string,
  meta: {
    initial: PropTypes.string,
  },
};

function AudioPlayerData({ name, label, fieldHoC: FieldHOC, information, validation, isPreview, ...props }) {
  const id = useMemo(() => `name_${uuid()}`, [name]);
  const initial = useMemo(() => isPreview ? getDummyData(validation.type) : "", [validation]);

  return (
    <FormField information={information} label={label}>
      <FieldHOC
        name={name}
        id={id}
        component={AudioPlayerRender}
        meta={{ initial }}
        validation={validation}
        {...props}
      />
    </FormField>
  );
}

AudioPlayerData.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  fieldHoC: PropTypes.func,
  information: PropTypes.string,
  validation: PropTypes.object,
  isPreview: PropTypes.bool
};

AudioPlayerData.defaultProps = {
  fieldHoC: Field,
};

export default AudioPlayerData;
