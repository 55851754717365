import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import Input from '../../../atoms/Input/Input';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import styles from './DatetimeRangeField.css';

function DatetimeFieldRender({
  input,
  label,
  placeholder,
  id,
  meta: { error, touched },
  rangeType,
}) {
  return (
    <Input
      type={rangeType}
      {...input}
      error={touched && error}
      label={label}
      id={id}
      block
      placeholder={placeholder}
    />
  );
}

DatetimeFieldRender.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  rangeType: PropTypes.string,
};

function DatetimeRangeField({ name, label, ...props }) {
  const id = useMemo(() => `${name}_${uuid()}`, [name]);

  return (
    <FormField label={label} name={name} htmlFor={id}>
      <div className={styles.DatetimeRangeField__inputs}>
        <Field name={`${name}.min`} component={DatetimeFieldRender} id={`${id}_min`} {...props} />
        <Field name={`${name}.max`} component={DatetimeFieldRender} id={`${id}_max`} {...props} />
      </div>
    </FormField>
  );
}

DatetimeRangeField.propTypes = { name: PropTypes.string, label: PropTypes.string };

export default DatetimeRangeField;
