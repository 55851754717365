import React, { useMemo, createPortal } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import Input from '../../../atoms/Input/Input';
import Textarea from '../../../atoms/Textarea/Textarea';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import FieldError from '../../Error/FieldError';
import { getConfidenceFieldStyle } from '../../../../helpers/form';
import styles from './TextField.css';

function TextFieldRender({
  input,
  label,
  placeholder,
  id,
  readonly,
  disabled,
  confidence,
  initialValue,
  isFocusOnInit,
  meta: { error, touched },
}) {
  const style = useMemo(() => !touched && getConfidenceFieldStyle(confidence), [
    confidence,
    touched,
  ]);

  const [isHover, setIsHover] = React.useState(false);
  const [isOverflowing, setIsOverflowing] = React.useState(false);

  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (inputRef.current == null) {
      return;
    }

    setIsOverflowing(inputRef.current.offsetWidth < inputRef.current.scrollWidth);
  }, [input.value]);

  return (
    <>
      <Input
        type="text"
        isFocusOnInit={isFocusOnInit}
        inputRef={inputRef}
        {...input}
        error={touched && error}
        label={label}
        id={id}
        block
        readonly={readonly}
        placeholder={placeholder}
        disabled={disabled}
        style={style}
        initialValue={initialValue}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      />
      {touched && error && <FieldError error={error} />}

      {isHover && isOverflowing && <Hover inputRef={inputRef} value={input.value} />}
    </>
  );
}

function Hover({ inputRef, value }) {
  if (inputRef.current == null) return null;
  if (value === null || value === undefined || value === '') return null;

  const { top, left, width, height } = inputRef.current.getBoundingClientRect();

  return createPortal(
    <div className={styles.TextFieldHoverContainer}>
      <div
        className={styles.TextFieldHover}
        style={{
          top: `${top + height + 4}px`,
          left: `${left}px`,
          minWidth: `${width}px`,
        }}
      >
        <span>{value}</span>
      </div>
    </div>,
    document.body,
  );
}
function TextareadRender({
  input,
  label,
  placeholder,
  rows,
  id,
  readonly,
  disabled,
  confidence,
  isFocusOnInit,
  meta: { error, touched },
}) {
  const style = useMemo(() => !touched && getConfidenceFieldStyle(confidence), [
    confidence,
    touched,
  ]);

  return (
    <>
      <Textarea
        isFocusOnInit={isFocusOnInit}
        {...input}
        rows={rows}
        error={touched && error}
        label={label}
        id={id}
        block
        readonly={readonly}
        placeholder={placeholder}
        disabled={disabled}
        style={style}
      />
      {touched && error && <FieldError error={error} />}
    </>
  );
}

TextFieldRender.propTypes = {
  isFocusOnInit: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  confidence: PropTypes.number,
  initialValue: PropTypes.string,
};

TextareadRender.propTypes = {
  isFocusOnInit: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  confidence: PropTypes.number,
};

function TextField({
  name,
  label,
  required,
  information,
  rows,
  verticalAlign,
  fieldHoC: FieldHOC,
  answersChoices,
  ...props
}) {
  const id = useMemo(() => `name_${uuid()}`, [name]);

  return (
    <FormField
      label={label}
      name={name}
      htmlFor={id}
      information={information}
      required={required}
      verticalAlign={verticalAlign}
      answersChoices={answersChoices}
    >
      <FieldHOC
        name={name}
        component={rows > 1 ? TextareadRender : TextFieldRender}
        id={id}
        rows={rows}
        {...props}
      />
    </FormField>
  );
}

TextField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  information: PropTypes.string,
  rows: PropTypes.number,
  fieldHoC: PropTypes.func,
  verticalAlign: PropTypes.bool,
  answersChoices: PropTypes.array,
  validate: PropTypes.func,
};

TextField.defaultProps = {
  rows: 0,
  verticalAlign: true,
  fieldHoC: Field,
  answersChoices: null,
};

export default TextField;
