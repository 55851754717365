import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import useTranslate from '../../../hooks/useTranslate';
import List from '../../atoms/list/List';
import cssvars from '../../../styles/variables';
import styles from './QualitySelectTemplate.css';
import QualityNavTemplate from '../QualityNavTemplate/QualityNavTemplate';

function QualitySelectTemplate({ icon, titleKey }) {
  const i18n = useTranslate();
  const [open, setOpen] = useState(false);

  // Close opened menu on click
  useEffect(() => {
    const handleClick = () => setOpen(false);
    if (open) {
      document.addEventListener('click', handleClick);
    }
    return () => {
      if (open) {
        document.removeEventListener('click', handleClick);
      }
    };
  }, [open]);
  return (
    <div className={styles.QualitySelectTemplate}>
      <List.Item
        className={styles.QualitySelectTemplate__button}
        color={cssvars['color-quality-icon']}
        icon={icon}
        iconRight="rectangle"
        onClick={() => setOpen(true)}
      >
        {i18n(titleKey)}
      </List.Item>
      {open && (
        <div className={styles.QualitySelectTemplate__menu}>
          <QualityNavTemplate itemClassname={styles.QualitySelectTemplate__menuItem} />
        </div>
      )}
    </div>
  );
}

QualitySelectTemplate.propTypes = {
  active: PropTypes.string,
  icon: PropTypes.string,
  titleKey: PropTypes.string,
};

export default QualitySelectTemplate;
