import { TEXT, NUM, VIEWER, LINK, VIDEO, AUDIO, IMAGE, IFRAME, PDF_OCR } from './dataType';
import { SHORTTEXT, SWITCH } from './fieldType';

export const DATA = 'data';

export const DATA_TEXT = {
  type: TEXT,
  boxDefaults: { width: 2 },
  options: [{ name: 'numeric', type: SWITCH, hideField: true }],
  meta: {
    name: 'Texte',
    color: '#FF5B61',
    icon: 'text',
  },
};

export const DATA_NUM = {
  type: NUM,
  boxDefaults: { width: 2 },
  options: [{ name: 'numeric', type: SWITCH, hideField: true }],
  meta: {
    name: 'Texte',
    color: '#FF5B61',
    icon: 'text',
  },
};
export const DATA_VIEWER = {
  type: VIEWER,
  boxDefaults: { width: 4, height: 4 },
  options: [{ name: 'fixedMode', type: SWITCH, hideField: true }],
  meta: {
    name: 'Viewer',
    color: '#FF5B61',
    icon: 'data_pdf',
  },
};

export const DATA_LINK = {
  type: LINK,
  boxDefaults: { width: 2, height: 1 },
  options: [{ name: 'information', type: SHORTTEXT, hideLabel: true }],
  meta: {
    name: 'Link',
    color: '#FF5B61',
    icon: 'data_link',
  },
};

export const DATA_VIDEO = {
  type: VIDEO,
  boxDefaults: { width: 2, height: 2 },
  options: [],
  meta: {
    name: 'Video',
    color: '#FF5B61',
    icon: 'play',
  },
};

export const DATA_AUDIO = {
  type: AUDIO,
  boxDefaults: { width: 2, height: 2 },
  options: [{ name: 'information', type: SHORTTEXT, hideLabel: true }],
  meta: {
    name: 'Audio',
    color: '#FF5B61',
    icon: 'data_audio',
  },
};

export const DATA_IMAGE = {
  type: IMAGE,
  boxDefaults: { width: 2, height: 2 },
  options: [
    { name: 'information', type: SHORTTEXT, hideLabel: true },
    { name: 'perserveWidth', type: SWITCH },
    { name: 'perserveHeight', type: SWITCH },
  ],
  meta: {
    name: 'Image',
    color: '#FF5B61',
    icon: 'data_image',
  },
};

export const DATA_IFRAME = {
  type: IFRAME,
  boxDefaults: { width: 4, height: 4 },
  options: [{ name: 'fixedMode', type: SWITCH, hideField: true }],
  meta: {
    name: 'Iframe',
    color: '#FF5B61',
    icon: 'data_iframe',
  },
};

export const DATA_PDF_OCR = {
  type: PDF_OCR,
  boxDefaults: { width: 4, height: 4 },
  options: [],
  meta: {
    name: 'PdfOcr',
    color: '#FF5B61',
    icon: 'data_iframe',
  },
}

export const DATA_TYPOLOGIES = [
  DATA_NUM,
  DATA_TEXT,
  DATA_LINK,
  DATA_VIEWER,
  DATA_VIDEO,
  DATA_AUDIO,
  DATA_IMAGE,
  DATA_IFRAME,
  DATA_PDF_OCR
];
