import React from 'react';
import PropTypes from 'prop-types';
import styles from './Form.css';
import { classnames } from '../../../helpers/classnames';

function Form({ onSubmit, className, children, onKeyDown }) {
  return (
    <form
      onSubmit={onSubmit}
      className={classnames([styles.Form, className])}
      onKeyDown={onKeyDown}
    >
      {children}
    </form>
  );
}

Form.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.children,
  className: PropTypes.string,
  onKeyDown: PropTypes.func,
};

export default Form;
