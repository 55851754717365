/* eslint-env node */
module.exports = {
  'width-drawer': '350px',
  'font-body':
    "'Caros Soft', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue'",
  'font-size-input': '12px',
  'font-size': '14px',
  'font-weight-bold': 'bold',
  'font-weight-strong': '700',
  'font-weight-light': '300',
  'emphasis-high': 'rgba(0, 0, 0, 0.87)',
  'emphasis-medium': 'rgba(0, 0, 0, 0.6)',
  'emphasis-disabled': 'rgba(0, 0, 0, 0.35)',
  'color-primary': 'rgba(217, 224, 255, 1)',
  'color-primary-dark': 'rgba(3, 21, 58, 1)',
  'color-primary-disabled': 'rgb(128, 127, 137)',
  'color-accent': 'rgba(255, 222, 167, 1)',
  'color-white': 'rgba(255, 255, 255, 1)',
  'color-black': 'rgba(0, 0, 0, 1)',
  'color-black-dark': 'rgba(68, 68, 68, 1)',
  'color-grey': 'rgba(220, 223, 230, 1)',
  'color-scrim': 'rgba(220, 223, 230, 0.6)',
  'color-grey-dark': 'rgba(144, 147, 153, 1)',
  'color-grey-even-darker': 'rgba(83, 80, 102, 1)',
  'color-red': 'rgba(230, 0, 0, 1)',
  'color-light-red': 'rgba(255, 91, 97, 0.15)',
  'color-orange': 'rgba(255, 153, 0, 1)',
  'color-yellow': 'rgba(255, 255, 0, 1)',
  'color-green': 'rgba(0, 138, 0, 1)',
  'color-light-green': 'rgba(0, 138, 0, 0.15)',
  'color-blue': 'rgba(0, 102, 204, 1)',
  'color-purple': 'rbga(153, 51, 255, 1)',
  'color-light-purple': 'rgba(112, 87, 255, 1)',
  'color-white-yellow': 'rgba(248, 248, 242, 1)',
  'color-blue-dark': 'rgba(3, 21, 58, 0.14)',
  'color-tooltip': 'rgba(84, 106, 205, 1)',
  'color-blue-light': 'rgba(175, 190, 255, 1)',
  'color-white-forward': 'rgba(241, 244, 250, 1)',
  'color-drawer': 'rgba(250, 252, 255, 1)',
  'color-drawer-border': 'rgba(229, 233, 240, 1)',
  'color-focus': 'rgba(112, 87, 255, 1)',
  'color-focus-data': 'rgba(255, 91, 97, 1)',
  'color-focus-semi-transparent': 'rgba(112, 87, 255, 0.5)',
  'color-focus-data-semi-transparent': 'rgba(255, 91, 97, 0.5)',
  'color-button-border-hover': 'rgba(129, 148, 255, 1)',
  'color-error': 'rgba(255, 91, 97, 1)',
  'color-font': 'rgba(81, 80, 97, 1)',
  'color-shadow': 'rgba(3, 21, 58, 0.19)',
  'color-box-placeholder': 'rgb(220, 220, 220, 0.7)',
  'color-box-placeholder-blocked': 'rgba(220, 22, 22, 0.2)',
  'color-box-icon-blocked': 'rgba(255, 0, 0, 1)',
  'radio-color-1': 'rgba(255, 236, 199, 1)',
  'radio-color-1-dark': 'rgb(255, 171, 0, 1)',
  'radio-color-2': 'rgba(217, 224, 255, 1)',
  'radio-color-3': 'rgba(202, 243, 236, 1)',
  'radio-color-4': 'rgba(204, 242, 255, 1)',
  'radio-color-5': 'rgba(37, 104, 160, 1)',
  'radio-color-6': 'rgba(30, 150, 127, 1)',
  'radio-color-7': 'rgba(255, 171, 0, 1)',
  'radio-color-8': 'rgba(255, 91, 97, 1)',
  'color-button-hover': 'rgba(183, 196, 255, 1)',
  'color-quality-icon': 'rgba(99, 197, 255, 1)',
  'color-star': 'rgba(227, 227, 227, 1)',
  'color-star-active': 'rgba(255, 204, 102, 1)',
  'color-confidence-10': '#ff8ea0',
  'color-confidence-20': '#ffb66c',
  'color-confidence-30': '#ffe369',
  'color-confidence-40': '#8ad5bb',
  gutter: '1rem',
  height: '28px',
  'semi-gutter': '0.5rem',
  'border-radius': '6px',
  'border-radius-semi-round': '10px',
  'z-layer-infodrawer': '10001',
};
