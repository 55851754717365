import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, submit, getFormValues } from 'redux-form';
import TextField from '../../molecules/Field/Text/TextField';
import Form from '../../atoms/Form/Form';
import { useDispatch } from 'react-redux';
import AdaptableField from '../../molecules/Adaptable/AdaptableField';
import useTranslate from '../../../hooks/useTranslate';
import { useSelector } from 'react-redux';
import HideRuleFragment from '../HideRuleFragment/HideRuleFragment';
import ShortcutFragment from '../ShortcutFragment/ShortcutFragment';
import RegexRuleFragment from '../RegexRuleFragment/RegexRuleFragment';
import useFeatToogle from '../../../hooks/useFeatToggle';
import { ADVANCEDSELECT, MCQ, SHORTTEXT } from '../../../constants/typology/fieldType';
import AdvancedSelectFragment from './AdvancedSelect/AdvancedSelectFragment';
import { VIEWER, TEXT, NUM, IFRAME } from '../../../constants/typology/dataType';
import ViewerFragment from './Viewer/ViewerFragment';
import DataTextFragment from './DataText/DataTextFragment';

import {
  fieldMulti as fieldMultiSelector,
  fieldInject as fieldInjectSelector,
  fieldLabelValue as FieldLabelValueSelector,
} from '../../../store/selectors/editForm';
import InjectFragment from './Inject/InjectFragment';

function FieldForm({
  handleSubmit,
  submitOnBlur,
  form,
  fields,
  resource,
  typology,
  shortcut,
  isPublished,
}) {
  const dispatch = useDispatch();
  const i18n = useTranslate();
  const valuesForm = useSelector(getFormValues(form));
  const fieldMulti = useSelector(fieldMultiSelector);
  const fieldInject = useSelector(fieldInjectSelector);
  const fieldLabelValue = useSelector(FieldLabelValueSelector);

  const featHideIf = useFeatToogle('hideIf');

  const displayRangeIteration = (field) => {
    if (field.name !== 'choicesRange') return true;
    else return fieldMulti;
  };
  const fieldsToShow = useMemo(
    () => fields.filter((field) => !field.hideField && displayRangeIteration(field)),
    [fields, fieldMulti],
  );
  const TypologyFragment = useMemo(() => {
    switch (typology) {
      case ADVANCEDSELECT:
        return AdvancedSelectFragment;
      case MCQ:
        return InjectFragment;
      case VIEWER:
        return ViewerFragment;
      case IFRAME:
        return ViewerFragment;
      case TEXT:
        return DataTextFragment;
      case NUM:
        return DataTextFragment;
      default:
        return null;
    }
  }, [typology]);

  const useRegex = useMemo(() => {
    return typology === SHORTTEXT;
  }, [typology]);

  const handleBlur = useCallback(() => {
    if (submitOnBlur) dispatch(submit(form));
  }, [valuesForm, submitOnBlur]);

  const hasShortcut = () => {
    return shortcut === '';
  };
  const checkCountryOnly = (array) => {
    if (valuesForm && valuesForm.type === 'address' && valuesForm.onlyCountry) {
      return array;
    }
    return array.filter((item) => item.name !== 'defaultFrance');
  };
  const FilterFields = checkCountryOnly(fieldsToShow);

  return (
    <Form onSubmit={handleSubmit}>
      {isPublished && (
        <TextField readonly onBlur={handleBlur} name="id" placeholder="ID" verticalAlign={false} />
      )}
      <TextField
        disabled={isPublished}
        onBlur={handleBlur}
        name="label"
        placeholder="Label"
        verticalAlign={false}
      />
      {FilterFields.map((field, i) => (
        <AdaptableField
          key={i}
          onBlur={handleBlur}
          verticalAlign={false}
          fieldType={field.type}
          label={!field.hideLabel && field?.name && i18n(`${resource}.options.${field.name}`)}
          // Put label as placeholder if hidden
          placeholder={field.hideLabel && i18n(`${resource}.options.${field.name}`)}
          {...field}
          labelRight={
            !field.hideLabel && field?.labelRight && i18n(`${resource}.options.${field.labelRight}`)
          }
          labelLeft={
            !field.hideLabel && field?.labelLeft && i18n(`${resource}.options.${field.labelLeft}`)
          }
          options={
            field.options &&
            field.options.map(({ label, ...option }) => ({
              ...option,
              label: i18n(`${resource}.options.options.${label}`),
            }))
          }
          disabled={isPublished}
        />
      ))}
      {!fieldInject && typology === MCQ && (
        <TextField
          name="choices"
          label={i18n('field.options.choices')}
          rows={10}
          placeholder={fieldLabelValue ? i18n('field.options.labelValuePlaceholder') : null}
        />
      )}
      {TypologyFragment && (
        <TypologyFragment form={form} fieldInject={fieldInject} fieldLabelValue={fieldLabelValue} />
      )}
      {useRegex && <RegexRuleFragment form={form} />}
      {featHideIf && <HideRuleFragment form={form} />}
      {hasShortcut() && <ShortcutFragment form={form} />}
    </Form>
  );
}

FieldForm.defaultProps = {
  form: 'field',
};

FieldForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitOnBlur: PropTypes.bool,
  form: PropTypes.string,
  fields: PropTypes.array,
  resource: PropTypes.string,
  typology: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.object),
  }),
  isPublished: PropTypes.bool,
  shortcut: PropTypes.string,
  regex: PropTypes.bool,
};
const i18n = useTranslate();

const validate = (values) => {
  const errors = {};
  if (
    values &&
    values.choices &&
    (values.type === 'select' || values.type === 'mcq') &&
    values.injectLabelValue &&
    values.injectKeyValueSeparator
  ) {
    let splitText = values.choices.split('\n');
    let checkTest = splitText.map((elem) => elem.search(values.injectKeyValueSeparator));
    checkTest.map((elem) => {
      if (elem < 0) errors.choices = i18n('field.options.Errorseparator');
    });
  }
  return errors;
};

export default reduxForm({
  form: 'field',
  validate,
})(FieldForm);
