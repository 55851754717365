import React, { useCallback } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styles from './Modal.css';

function Modal({ open, title, onClose, children }) {
  const handleScrimClick = useCallback(
    (event) => {
      event.stopPropagation();
      onClose();
    },
    [onClose],
  );

  if (!open) {
    return null;
  }

  return createPortal(
    <div className={styles.Modal__scrim} onClick={handleScrimClick}>
      <div className={styles.Modal}>
        {title && <h2 className={styles.Modal__title}>{title}</h2>}
        {children}
      </div>
    </div>,
    document.body,
  );
}

Modal.defaultProps = {
  onClose: () => null,
};

Modal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

export default Modal;
