import React, { useCallback, useEffect, useMemo } from 'react';
import { v4 as id } from 'uuid';
import PropTypes from 'prop-types';
import ValidationRuleForm from '../../form/ValidationRuleForm/ValidationRuleForm';
import useTranslate from '../../../hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import {
  formBlocksSelector,
  selectFieldsSelector,
  shouldDisplayDeliveryAnswerSelector,
} from '../../../store/selectors/editForm';
import { FIELD_REFERENCE } from '../../../constants/formula';
import { useActions } from '../../../hooks/useActions';
import {
  deleteIterationOverloadRule as deleteIterationOverloadRuleAction,
  updateIterationOverloadRule as updateIterationOverloadRuleAction,
} from '../../../store/actions/form';
import { setSelectFields as setSelectFieldsAction } from '../../../store/actions/editForm';
import Spoiler from '../Spoiler/Spoiler';
import FormulaField from '../Field/Formula/FormulaField';
import TextField from '../Field/Text/TextField';
import styles from './IterationOverloadRule.css';
import { getSmallestBlock } from '../../../helpers/formTree';
import Button from '../../atoms/Button/Button';
import compare from '../../../helpers/compare';
import { createToast } from '../../../store/actions/toast';
import { ERROR } from '../../../constants/toastType';
import SelectField from '../Field/Select/SelectField';
import { getDeliveryAnswerOptions } from '../../../helpers/form';
import { TYPOLOGIES_ITERATION_OVERLOAD } from '../../../constants/typology/typologies';

function IterationOverloadRule({ rule, onFocus, iterationOptions }) {
  const i18n = useTranslate();
  const [updateIterationOverloadRule, deleteIterationOverloadRule, setSelectFields] = useActions([
    updateIterationOverloadRuleAction,
    deleteIterationOverloadRuleAction,
    setSelectFieldsAction,
  ]);
  const dispatch = useDispatch();
  const formName = `iterationRule_${rule.id}`;
  const values = useSelector(getFormValues(formName));
  const selectFields = useSelector(selectFieldsSelector);
  const blocks = useSelector(formBlocksSelector);
  const showDeliveryAnswer = useSelector(shouldDisplayDeliveryAnswerSelector);

  const handleClickDelete = useCallback(
    (event) => {
      event.stopPropagation();
      deleteIterationOverloadRule(rule.id);
    },
    [rule],
  );

  // Push fields to the formula as they are added
  useEffect(() => {
    // An undefined values means the form is not mounted yet
    if (!open || typeof values === 'undefined') {
      return;
    }

    if (selectFields.length) {
      const addedField = selectFields[0];
      setSelectFields([]);

      if (!TYPOLOGIES_ITERATION_OVERLOAD.includes(addedField.type)) {
        return createToast(ERROR, i18n(`IterationOverloadRule.errors.wrongType`));
      }

      if (addedField.multi) {
        return createToast(ERROR, i18n(`IterationOverloadRule.errors.isMulti`));
      }

      if (values.formula && values.formula.find((token) => token.type === FIELD_REFERENCE)) {
        return createToast(ERROR, i18n(`IterationOverloadRule.errors.alreadyContained`));
      }

      const block = getSmallestBlock(addedField, blocks);
      dispatch(
        change(formName, 'formula', [
          ...(values.formula || []),
          {
            type: FIELD_REFERENCE,
            field: addedField.id,
            path: addedField.id,
            typology: addedField.type,
            blockScope: block ? block.id : null,
            // Default to "sum" for duplicable blocks, "count" for non-duplicable ones
            duplicableResolution: block ? 'sum' : null,
            id: id(),
          },
        ]),
      );
    }
  }, [open, selectFields, values, formName, blocks]);

  const renderHeader = useMemo(
    () =>
      function IterationOverloadRuleHeader() {
        return (
          <div className={styles.IterationOverloadRule__header}>
            {open ? <TextField name="name" /> : <div>{rule.name}</div>}
            <Button
              icon="trash"
              flat
              className={styles.IterationOverloadRule__deleteButton}
              onClick={handleClickDelete}
            />
          </div>
        );
      },
    [open, rule.name, rule.id],
  );

  useEffect(() => {
    if (values && !compare(rule, values)) {
      updateIterationOverloadRule(rule.id, values);
    }
  }, [rule, values]);

  const validateFormula = (formula) => {
    if (
      !formula ||
      formula.length !== 3 ||
      formula[0].type !== FIELD_REFERENCE ||
      !formula[1].type.startsWith('OPERATOR_') ||
      !formula[2].type.startsWith('LITERAL_')
    ) {
      return i18n('FieldError.iteration_rule_syntax_invalid');
    }

    return null;
  };

  const handleClickHeader = useCallback(() => {
    onFocus(rule.id);
  }, [rule]);

  return (
    <>
      {/* Wrapping the spoiler itself in a form so we can use *Field components */}
      <ValidationRuleForm initialValues={rule} form={formName}>
        <Spoiler
          open={open}
          title={rule.name}
          onClick={handleClickHeader}
          renderHeader={renderHeader}
          render={() => (
            <div style={{ padding: '1rem' }}>
              <FormulaField
                name="formula"
                placeholder={i18n('IterationOverloadRule.formulaPlaceholder')}
                withOperators={false}
                withComparison={true}
                withLiterals={true}
                withParenthesis={false}
                information={i18n('IterationOverloadRule.instructions')}
                validate={validateFormula}
                overload
              />
              <SelectField
                options={iterationOptions}
                name="minMatch"
                parse={parseFloat}
                label={i18n('IterationOverloadRule.minMatch')}
              />

              {showDeliveryAnswer && (
                <SelectField
                  options={getDeliveryAnswerOptions('iterationOverload')}
                  name="deliveryAnswer"
                  label={i18n('IterationOverloadRule.deliveryAnswer')}
                />
              )}
            </div>
          )}
        />
      </ValidationRuleForm>
    </>
  );
}

IterationOverloadRule.propTypes = {
  onFocus: PropTypes.func,
  rule: PropTypes.object,
  iterationOptions: PropTypes.array,
};

export default IterationOverloadRule;
