export default function compare(obj1, obj2) {
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false;

    for (let i = 0; i < obj1.length; i++) {
      if (obj1[i] && !obj2[i]) return false;
      if (!compare(obj1[i], obj2[i])) return false;
    }
  } else if (isObject(obj1) && isObject(obj2) && !Array.isArray(obj1) && !Array.isArray(obj2)) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      if (obj1[key] && !obj2[key]) return false;
      if (!compare(obj1[key], obj2[key])) return false;
    }
  } else {
    return obj1 === obj2;
  }

  return true;
}

export function isObject(object) {
  return object != null && typeof object === 'object';
}
