import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import Switch from '../../../atoms/Switch/Switch';

function SwitchFieldRender({ input, label, placeholder, id, meta: { error, touched }, disabled }) {
  return (
    <Switch
      {...input}
      error={touched && error}
      label={label}
      id={id}
      block
      placeholder={placeholder}
      disabled={disabled}
    />
  );
}

SwitchFieldRender.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  checked: PropTypes.bool,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  disabled: PropTypes.bool,
};

function SwitchField({ name, label, information, ...props }) {
  const id = useMemo(() => `name_${uuid()}`, [name]);
  return (
    <FormField label={label} name={name} htmlFor={id} information={information} inline reverse>
      <Field name={name} component={SwitchFieldRender} id={id} {...props} type="checkbox" />
    </FormField>
  );
}

SwitchField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  information: PropTypes.string,
};

export default SwitchField;
