import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  currentFormSelector,
  focusedBlockSelector,
  formBlocksSelector,
  selectFieldsSelector,
} from '../../../store/selectors/editForm';
import Button from '../../atoms/Button/Button';
import useTranslate from '../../../hooks/useTranslate';
import CompareBox from '../../molecules/CompareBox/CompareBox';
import { useActions } from '../../../hooks/useActions';
import { updateBlock as updateBlockAction } from '../../../store/actions/form';
import { swap } from '../../../helpers/array';
import {
  setSelectFields as setSelectFieldsAction,
  setSelectMode as setSelectModeAction,
} from '../../../store/actions/editForm';
import { createToast } from '../../../store/actions/toast';
import { ERROR } from '../../../constants/toastType';
import { getSmallestBlock } from '../../../helpers/formTree';
import styles from './BlockSortingRulesTemplate.css';

function BlockSortingRulesTemplate() {
  const i18n = useTranslate();
  const [selectedRule, setSelectedRule] = useState(0);
  const blocks = useSelector(formBlocksSelector);
  const block = useSelector(focusedBlockSelector);
  const form = useSelector(currentFormSelector);
  const selectedFields = useSelector(selectFieldsSelector);
  const rules = useMemo(() => block.sortingRules || [], [block.sortingRules]);
  const [dispatchUpdateBlock, setSelectMode, setSelectFields] = useActions([
    updateBlockAction,
    setSelectModeAction,
    setSelectFieldsAction,
  ]);

  // Shorthand for bloc updating
  const updateBlock = useCallback((changes) => dispatchUpdateBlock(form.id, block.id, changes), [
    block,
    form.id,
  ]);

  // Enable / disable select mode on mount / unmount
  useEffect(() => {
    setSelectFields([]);
    setSelectMode(true);

    return () => {
      setSelectMode(false);
    };
  }, []);

  // Focus first rule on open
  useEffect(() => {
    setSelectedRule(0);
  }, [block.id]);

  // Handle fields selection
  useEffect(() => {
    if (!selectedFields.length) {
      return;
    }

    // Clear selected fields
    setSelectFields([]);

    // Check that field isn't duplicate
    const rule = rules[selectedRule];

    // We may have selected fields in state but no rules created when switching groupes
    if (!rule) {
      return;
    }

    const field = selectedFields[0];

    if (rule.includes(field.id)) {
      createToast(ERROR, i18n('BlockSortingRulesTemplate.error.duplicate'));
      return;
    }

    // Check that the field is inside the current block
    const fieldBlock = getSmallestBlock(field, blocks, { duplicable: true });

    if (!fieldBlock || fieldBlock.id !== block.id) {
      createToast(
        ERROR,
        i18n('BlockSortingRulesTemplate.error.mustBeInBlock', {
          block: block.name,
        }),
      );
      return;
    }

    // Finally add the field to the rule
    updateBlock({
      sortingRules: rules.map((rule, index) =>
        index === selectedRule ? [...rule, field.id] : rule,
      ),
    });

    // We want to run this effect only when selectedFields changes
    // We can add all deps here but this is unecessary add may require additional
    // checks at the start of the effect
  }, [selectedFields]);

  // Push an empty fields array when creating a new rule
  const handleAddRule = useCallback(() => {
    updateBlock({
      // Adding a rule constits in adding an empty array
      sortingRules: [...(block.sortingRules || []), []],
    });
    setSelectedRule(block.sortingRules?.length || 0);
  }, [updateBlock]);

  // Rule priority handlers
  const handleMoveUp = useCallback(
    (index) => {
      if (index < 1) {
        return;
      }

      updateBlock({
        sortingRules: swap(rules, index, index - 1),
      });
      setSelectedRule(index - 1);
    },
    [selectedRule, updateBlock, rules],
  );

  const handleMoveDown = useCallback(
    (index) => {
      if (index >= rules.length - 1) {
        return;
      }

      updateBlock({
        sortingRules: swap(rules, index, index + 1),
      });
      setSelectedRule(index + 1);
    },
    [selectedRule, updateBlock, rules],
  );

  const handleDelete = useCallback(
    (index) => {
      updateBlock({
        sortingRules: rules.filter((rule, i) => i !== index),
      });
    },
    [rules],
  );

  const handleUpdateRule = useCallback(
    (ruleIndex, { fields }) => {
      updateBlock({
        sortingRules: rules.map((rule, index) => (index === ruleIndex ? fields : rule)),
      });
    },
    [rules],
  );

  return (
    <div className={styles.BlockSortingRulesTemplate}>
      <div className={styles.BlockSortingRulesTemplate__actions}>
        <Button type="button" flat onClick={handleAddRule}>
          {i18n('BlockSortingRulesTemplate.addRule')}
        </Button>
      </div>
      {rules.length ? (
        <div className={styles.BlockSortingRulesTemplate__rules}>
          {rules.map((fields, index) => (
            <CompareBox
              key={index}
              id={index}
              fields={fields}
              focused={index === selectedRule}
              onFocus={() => setSelectedRule(index)}
              onMoveUp={() => handleMoveUp(index)}
              onMoveDown={() => handleMoveDown(index)}
              onDelete={() => handleDelete(index)}
              onUpdate={handleUpdateRule}
            />
          ))}
        </div>
      ) : (
        <p className={styles.BlockSortingRulesTemplate__empty}>
          {i18n('BlockSortingRulesTemplate.empty')}
        </p>
      )}
    </div>
  );
}

BlockSortingRulesTemplate.propTypes = {};

export default BlockSortingRulesTemplate;
