import React, { createContext } from 'react';
import PropTypes from 'prop-types';

const FormStyleContext = createContext(null);
export const useFormStyle = () => React.useContext(FormStyleContext);

function FormStyle({ form, children }) {
  // Create a cssVars object with the form's custom style
  // Those variables will be usable for all children of FormStyle
  const cssVars = {
    '--font-size-input': `${form.fontSize || 12}px`,
  };

  return (
    <FormStyleContext.Provider value={cssVars}>
      <div style={cssVars}>{children}</div>
    </FormStyleContext.Provider>
  );
}

FormStyle.propTypes = {
  form: PropTypes.object,
  children: PropTypes.children,
};

export default FormStyle;
