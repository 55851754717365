import { get } from './config';

export function getConfigToken() {
  const { username, password } = get('backend.auth');
  return username && password ? btoa(username + ':' + password) : null;
}

export function getQueryToken() {
  const token = new URLSearchParams(window.location.search).get('token');
  return token ? decodeURIComponent(token) : null;
}

export function clearQueryParameters() {
  const query = new URLSearchParams(window.location.search);
  const suffix = query.get('IdSite') ? `?IdSite=${query.get('IdSite')}` : '';
  window.history.replaceState(null, null, `${window.location.pathname}${suffix}`);
}

export function getQueryTenant() {
  const tenant = new URLSearchParams(window.location.search).get('tenant');
  return tenant ? decodeURIComponent(tenant) : null;
}