import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { Field, formValueSelector } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import getDummyData from '../../../../helpers/dummy';
import PdfOcrViewer from '../../../atoms/PdfOcrViewer/PdfOcrViewer';
import { useSelector } from 'react-redux';

function PdfOcrDataRender({ input, id, meta: { initial, name }, ...props }) {
  if ((input.value || initial) === '') {
    return null;
  }

  const metadataAsText = useSelector((state) =>
    formValueSelector('contribute')(state, `${input.name}_metadata`),
  );

  const metadata = useMemo(() => {
    if (metadataAsText == null) {
      return null;
    }

    try {
      return JSON.parse(metadataAsText);
    } catch (err) {
      console.error('Failed to parse Metadata', err);

      // extract position from error
      const position = parseInt(err.message.match(/position ([0-9]+)/)[1]);

      console.log(
        metadataAsText.slice(position - 20, Math.min(position + 20, metadataAsText.length)),
      );

      return null;
    }
  }, [metadataAsText]);

  return (
    <PdfOcrViewer
      id={id}
      name={name}
      link={input.value || initial}
      metadata={metadata}
      {...props}
    />
  );
}

PdfOcrDataRender.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  src: PropTypes.src,
  id: PropTypes.string,
  meta: {
    initial: PropTypes.string,
  },
};

function PdfOcrData({ name, label, fieldHoC: FieldHOC, validation, isPreview, ...props }) {
  const id = useMemo(() => `name_${uuid()}`, [name]);
  const initial = useMemo(() => (isPreview ? getDummyData(validation.type) : ''), [validation]);

  return (
    <FormField label={label}>
      <FieldHOC
        name={name}
        id={id}
        component={PdfOcrDataRender}
        fixedMode={validation.fixedMode}
        meta={{ initial, name }}
        validation={validation}
        {...props}
      />
    </FormField>
  );
}

PdfOcrData.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  information: PropTypes.string,
  fieldHoC: PropTypes.func,
  validation: PropTypes.object,
  isPreview: PropTypes.bool,
};

PdfOcrData.defaultProps = {
  fieldHoC: Field,
};

export default PdfOcrData;
