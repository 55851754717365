import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BLOCK_NODE, FIELD_NODE } from '../../../constants/formNode';
import ContributeFormNodeRoot from './Root/ContributeFormNodeRoot';
import ContributeFormNodeField from './Field/ContributeFormNodeField';
import ContributeFormNodeBlock from './Block/ContributeFormNodeBlock';
import HiddenBoxesContext from '../ContributeFormTemplate/HiddenBoxesContext';

function ContributeFormNode({ node, ...props }) {
  const hiddenBoxes = useContext(HiddenBoxesContext);
  const path = `${props.parentFieldName || ''}${node.id}`;
  const isHidden = hiddenBoxes.includes(path);

  useEffect(() => {
    if (isHidden) {
      props.onHeightChange(0);
    } else if (node.box) {
      props.onHeightChange(node.box.height);
    }
  }, [isHidden]);

  if (isHidden) {
    return null;
  }

  switch (node.nodeType) {
    case 'root':
      return <ContributeFormNodeRoot node={node} {...props} />;
    case BLOCK_NODE:
      return <ContributeFormNodeBlock node={node} {...props} />;
    case FIELD_NODE:
      return <ContributeFormNodeField node={node} {...props} />;
    default:
      return null;
  }
}

ContributeFormNode.propTypes = {
  node: PropTypes.object,
  onHeightChange: PropTypes.func,
  parentFieldName: PropTypes.string,
};

export default ContributeFormNode;
