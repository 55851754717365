import React from 'react';
import PropTypes from 'prop-types';
import { bem } from '../../../helpers/styles';
import { classnames } from '../../../helpers/classnames';
import Item from './Item/Item';
import styles from './List.css';

function List({ className, ...restProps }) {
  const props = {
    className: classnames([bem(styles, 'List'), className]),
  };

  return <ul {...props} {...restProps}></ul>;
}
List.Item = Item;

List.propTypes = {
  className: PropTypes.string,
};

export default List;
