import React from 'react';
import PropTypes from 'prop-types';
import styles from './Alert.css';
import Button from '../../atoms/Button/Button';

function Alert({ message, buttonLabel, onClose }) {
  return (
    <div className={styles.Alert}>
      <div className={styles.Alert__modal}>
        {message}
        <div className={styles.Alert__actions}>
          <Button onClick={onClose}>{buttonLabel}</Button>
        </div>
      </div>
    </div>
  );
}

Alert.propTypes = {
  message: PropTypes.string,
  buttonLabel: PropTypes.string,
  onClose: PropTypes.func,
};

export default Alert;
