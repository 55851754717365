import { v4 as uuid } from 'uuid';
import { get } from '../../helpers/config';
import * as parsers from '../../api/parsers';
import { tokenSelector } from '../selectors/token';

export async function getResponsePayload(response, parseFunc = null) {
  if (response.status === 204) {
    return null;
  }

  const ct = response.headers.get('content-type');
  if (ct && ct.indexOf('application/json') > -1) {
    const parse = parseFunc ? parsers[parseFunc] : null;
    const json = await response.json();
    return parse && json ? parse(json) : json;
  }

  try {
    // Better to try than nothing
    return await response.text();
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return null;
  }
}

export default function fetchMiddleware({ getState, dispatch }) {
  return (next) => async (action) => {
    next(action);

    if (!action.fetch) {
      return;
    }

    const { url, json, auth, parse, ...params } = action.fetch;
    const { requestId = uuid(), ...metaRest } = action.meta;
    const meta = { requestId, ...metaRest };
    const headers = {};

    if (json) {
      headers['content-type'] = 'application/json';
    }

    if (auth) {
      const token = tokenSelector(getState());
      headers['authorization'] = `Bearer ${token}`;
    }
    dispatch({
      type: `${action.type}_PENDING`,
      meta,
    });
    try {
      const response = await fetch(
        url
          .replace('{{backendUrl}}', get('backend.backendUrl')),
        {
          ...params,
          headers,
          body: json ? JSON.stringify(json) : params.body,
          mode: 'cors',
        },
      );
      const data = await getResponsePayload(response, parse);

      if (response.ok) {
        dispatch({
          type: `${action.type}_SUCCESS`,
          meta,
          payload: data,
        });
      } else {
        dispatch({
          type: `${action.type}_ERROR`,
          meta,
          payload: {
            code: response.status,
            ...data,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: `${action.type}_ERROR`,
        meta,
        payload: {
          code: -1,
          message: err.message,
        },
      });
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };
}
