export function parseCertification(json) {
  return {
    certificationQuestionId: json.idCertificationQuestion,
    certificationUserId: json.idCertificationUserTest,
    certificationUserQuestionId: json.idCertificationUserQuestion,
    formId: json.idForm,
    supplierId: json.user.idUser,
    certificationId: json.idCertification,
    certificationTitle : json.certificationTitle,
    columns : (json?.columns || []).map((column) => ({
      name: column.name,
      data: column.data,
      type: column.type,
    })),
    answer: json?.answer??  json?.inputJson,
  };
} 

export function parseCertificationQuestion(json){
  return {
    certificationQuestionId: json.idCertificationQuestion,
    certificationId: json.idCertification,
    formId: json.certification.form.idForm,
    certificationTitle : json.certification.name,
    columns : (json?.inputs || []).map((column) => ({
      name: column.name,
      data: column.data,
      type: column.type,
    })),
    answer: json?.answer??  json?.inputJson ,
    json: json?.answer??  json?.inputJson 
  };
}

export function parseAnswerCertification(json){
  return {
    certificationQuestionId: json.idCertificationQuestion,
    certificationId: json.idCertification,
    formId: json.idForm,
    certificationTitle : json.certificationTitle,
    columns : (json?.columns || []).map((column) => ({
      name: column.name,
      data: column.data,
      type: column.type,
    })),
    answer: (json?.answer ?? json?.inputJson ?? { blocks: [], data: [], fields: [] }),
    json: (json?.answer ?? json?.inputJson ?? { blocks: [], data: [], fields: [] })
  };
}