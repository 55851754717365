import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import Phone from '../../../atoms/Phone/Phone';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import FieldError from '../../Error/FieldError';
import { getConfidenceFieldStyle } from '../../../../helpers/form';

function PhoneRender({
  input,
  label,
  placeholder,
  id,
  readonly,
  confidence,
  isFocusOnInit,
  meta: { error, touched },
}) {
  const style = useMemo(() => !touched && getConfidenceFieldStyle(confidence), [
    confidence,
    touched,
  ]);

  return (
    <>
      <Phone
        {...input}
        isFocusOnInit={isFocusOnInit}
        error={touched && error}
        label={label}
        id={id}
        block
        placeholder={placeholder}
        readonly={readonly}
        style={style}
      />
      {touched && error && <FieldError error={error} />}
    </>
  );
}

PhoneRender.propTypes = {
  isFocusOnInit: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  readonly: PropTypes.bool,
  confidence: PropTypes.number,
};

function PhoneField({
  name,
  label,
  required,
  information,
  fieldHoC: FieldHOC,
  answersChoices,
  ...props
}) {
  const id = useMemo(() => `name_${uuid()}`, [name]);

  return (
    <FormField
      label={label}
      name={name}
      htmlFor={id}
      information={information}
      required={required}
      verticalAlign={true}
      answersChoices={answersChoices}
    >
      <FieldHOC name={name} component={PhoneRender} id={id} {...props} />
    </FormField>
  );
}

PhoneField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  information: PropTypes.string,
  fieldHoC: PropTypes.func,
  answersChoices: PropTypes.array,
};

PhoneField.defaultProps = {
  fieldHoC: Field,
  answersChoices: null,
};

export default PhoneField;
