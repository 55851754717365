import { set } from 'object-path';
import { getCookie, setCookie } from './cookies';
const { NODE_CONFIG } = process.env;
import { tenantSelector } from '../store/selectors/tenant';
import { useSelector } from 'react-redux';

export const get = (keypath, conf = NODE_CONFIG, fullkeypath) => {
  const key = keypath.split('.')[0];
  const remainingpath = keypath.split('.').slice(1).join('.');
  const val = conf[key];
  if (typeof val === 'undefined') {
    throw new Error(`Property not set "${fullkeypath || keypath}"`);
  }

  if (val !== null && typeof val === 'object' && remainingpath) {
    return get(remainingpath, val, fullkeypath || keypath);
  }

  return val;
};

export const getUrlRedirection = (keypath, conf = NODE_CONFIG, fullkeypath) => {
  const key = keypath.split('.')[0];
  const remainingpath = keypath.split('.').slice(1).join('.');
  const val = conf[key];
  if (typeof val === 'undefined') {
    throw new Error(`Property not set "${fullkeypath || keypath}"`);
  }

  if (val !== null && typeof val === 'object' && remainingpath) {
    return getUrlRedirection(remainingpath, val, fullkeypath || keypath);
  }
  const tenant = useSelector(tenantSelector);
  return val.replace('{{tenant}}',tenant);
};

export const overrideConfigFromParams = (urlParams) => {

  const idSiteCookie = getCookie('IdSite');
  const idSite = urlParams.get('IdSite') || idSiteCookie;
  const backendUrl = get('backend.backendUrl');

  set(NODE_CONFIG, 'backend.backendUrl', backendUrl);
  // eslint-disable-next-line
  console.log('backend.backendUrl', backendUrl);

  if (idSite) {
    // If an id site is passed we'll try to override redirect host URLs depending on config mapping
    setCookie('IdSite', idSite, new Date(Date.now() + 1000 * 60 * 60));
    try {
      const [siteOverride, pathOverrides] = get(`idsitemap.${idSite}`);

      // eslint-disable-next-line
      console.log('backend.taskUrl (override)', `https://${siteOverride}`);

      const sites = get('urls');
      Object.keys(sites).map((key) => {
        const site = sites[key];

        if (site.overrides.includes(idSite)) {
          Object.keys(site).map((urlKey) => {
            // For each site url in config (overrides is the special key to define which override url is allowed)
            if (urlKey !== 'overrides') {
              const url = new URL(site[urlKey]);

              const pathOverride = pathOverrides ? pathOverrides[urlKey] : null;
              let finalUrl = site[urlKey].replace(url.host, siteOverride);

              if (pathOverride) {
                finalUrl = finalUrl.replace(url.pathname, pathOverride);
              }

              try {
                // This will throw if URL is not valid
                new URL(finalUrl);

                // Replace the host of the old URL with the override one
                set(NODE_CONFIG, `url.${key}.${urlKey}`, finalUrl);

                //eslint-disable-next-line no-console
                console.info(`url.${key}.${urlKey}`, finalUrl);
              } catch {
                //eslint-disable-next-line no-console
                console.error(
                  `Cannot override URL ${site}.${urlKey} with URL "${finalUrl}" because its not valid.`,
                );
              }
            }
          });
        }
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      // idsitemap with given id is not set
    }

    return idSite;
  }

  return null;
};
