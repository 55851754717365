import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import VideoPlayer from '../../../atoms/VideoPlayer/VideoPlayer';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import getDummyData from '../../../../helpers/dummy';
import styles from './VideoPlayerData.css';

function VideoPlayerRender({ input, id, meta: { initial } }) {
  // TODO: inject input.value here instead of dummy PDF

  if ((input.value || initial) === "") {
    return null;
  }

  return (
    <div className={styles.VideoPlayerData}>
      <VideoPlayer
        id={id}
        className={styles.VideoPlayerData__Player}
        url={input.value || initial}
        controls
      />
    </div>
  );
}

VideoPlayerRender.propTypes = {
  id: PropTypes.string,
  input: PropTypes.object,
  meta: {
    initial: PropTypes.string,
  },
};
function VideoPlayerData({ name, label, fieldHoC: FieldHOC, validation, isPreview, ...props }) {
  const id = useMemo(() => `name_${uuid()}`, [name]);
  const initial = useMemo(() => isPreview ? getDummyData(validation.type) : "", [validation]);

  return (
    <FormField label={label} name={name} htmlFor={id}>
      <FieldHOC
        name={name}
        id={id}
        component={VideoPlayerRender}
        meta={{ initial }}
        validation={validation}
        {...props}
      />
    </FormField>
  );
}

VideoPlayerData.defaultProps = {
  fieldHoC: Field,
};

VideoPlayerData.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  fieldHoC: PropTypes.func,
  validation: PropTypes.object,
  isPreview: PropTypes.bool
};

export default VideoPlayerData;
