import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router';
import { PUBLISHED } from '../../../../constants/formState'

import PropTypes from 'prop-types';
import Layout from '../../../atoms/Layout/Layout';
import Button from '../../../atoms/Button/Button';
import styles from './DrawerFormControls.css';
import useTranslate from '../../../../hooks/useTranslate';
import { updateForm as updateFormAction } from '../../../../store/actions/form';
import useFetchAction from '../../../../hooks/useFetchAction';
import { useSelector, useDispatch } from 'react-redux';
import {
  currentFormSelector,
  editFormDirtySelector,
  selectFieldsSelector,
} from '../../../../store/selectors/editForm';
import { Link } from 'react-router-dom';
import { initialize } from 'redux-form';
import { populatePath } from '../../../../helpers/path';
import { PATH_PREVIEW_FORM } from '../../../../constants/paths';
import {
  generateDataManifest,
  transformToHumanNames,
  validateForm,
} from '../../../../helpers/form';
import useQuery from '../../../../hooks/useQuery';
import Undo from '../../../molecules/Undo/Undo';
import { DEMO } from '../../../../constants/demo';
import * as config from '../../../../helpers/config';
import {
  buildFormOutput,
  buildFormTree,
  getNodeInitialValues,
} from '../../../../helpers/formTree';
import { KEY_P, KEY_S } from '../../../../constants/keyboardFormControl';
import { getTypologyKind } from '../../../../helpers/typology';
import getDummyData from '../../../../helpers/dummy';
import { DATA } from '../../../../constants/typology/data';

function DrawerFormControls({ onPreview }) {
  const { formId } = useParams();
  const i18n = useTranslate();
  const form = useSelector(currentFormSelector);
  const dirty = useSelector(editFormDirtySelector);
  const selectedFields = useSelector(selectFieldsSelector);
  const query = useQuery();
  const dispatch = useDispatch();
  const [updateForm] = useFetchAction(updateFormAction);
  const routerHistory = useHistory();

  const transformedForm = useMemo(() => (form ? transformToHumanNames(form) : form), [form]);
    const formTree = useMemo(
    () => {
      if (transformedForm) {
        return buildFormTree(transformedForm, { choicesOverrides: {} })
      }
    },
    [transformedForm],
  );

  // Generates mock data for form preview
  const data = useMemo(
    () =>
      transformedForm
        ? transformedForm.fields.reduce((dummyData, field) => {
            if (getTypologyKind(field.type) === DATA) {
              return {
                ...dummyData,
                [field.id]: getDummyData(field.type),
              };
            }
            return dummyData;
          }, {})
        : {},
    [transformedForm],
  );

  // Generates initial values according to form tree shape
  const initialValues = useMemo(() => {
    if (formTree && data) {
      return {
        ...data,
        ...getNodeInitialValues(formTree),
      };
    }
  }, [formTree, data]);

  useEffect(() => {
    const keyBoardSavePreview = (e) => {
      if (e.ctrlKey && e.key === KEY_S.key) {
        handleSave(form);
        e.preventDefault();
      }
      if (e.ctrlKey && e.key === KEY_P.key) {
        routerHistory.push(populatePath(PATH_PREVIEW_FORM, { formId: form && form.id }, query));
        e.preventDefault();
      }
    };
    window.addEventListener('keydown', keyBoardSavePreview);

    return () => {
      document.removeEventListener('keydown', keyBoardSavePreview);
    };
  }, [form]);

  const handleSave = useCallback(() => {
    if (formId === DEMO) {
      return null;
    }
    const formWithData = {
      ...form,
      data: generateDataManifest(form),
    };
    updateForm(formWithData);
    // Re-initialize forms to clean dirty state
    dispatch(initialize('settings', formWithData));
    dispatch(initialize('create', formWithData));
  }, [form, selectedFields]);

  const handlePublish = useCallback(() => {
    if (formId === DEMO) {
      window.location.href = config.get('url.desk.signup');
      return null;
    }
    const errors = validateForm(form);

    if (errors.length) {
      alert(
        `${i18n('DrawerFormControls.missingRequired')}\n\n${errors
          .map((val) => '- ' + val)
          .join('\n')}`,
      );
      return;
    }

    if (!confirm(i18n('DrawerFormControls.publishConfirm'))) {
      return;
    }

    const formWithIds = transformToHumanNames(form);
    updateForm({
      ...formWithIds,
      outputJsonSample: buildFormOutput(formTree, initialValues, []),
      data: generateDataManifest(formWithIds),
      state: PUBLISHED,
    });
  }, [JSON.stringify(form), JSON.stringify(initialValues), JSON.stringify(formTree)]);

  const isPublished = form && form.state ===  PUBLISHED;

  return (
    <Layout className={styles.DrawerFormControls}>
      <Layout>
        <Undo />
      </Layout>
      <Layout>
        <Button
          icon="preview"
          onClick={onPreview}
          as={Link}
          to={populatePath(PATH_PREVIEW_FORM, { formId: form && form.id }, query)}
          className={styles.previewFooter__button}
        />
        <Button
          onClick={handleSave}
          disabled={!dirty || formId === DEMO}
          icon={dirty ? 'save' : 'done'}
          className={styles.previewFooter__button}
        />
        <Button
          className={styles.DrawerFormControl__button}
          block
          onClick={handlePublish}
          disabled={isPublished}
          rounded
          color="white"
        >
          {i18n(isPublished ? 'DrawerFormControls.published' : 'DrawerFormControls.publish')}
        </Button>
      </Layout>
    </Layout>
  );
}

DrawerFormControls.propTypes = {
  onPreview: PropTypes.func,
};

export default DrawerFormControls;
