import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import Link from '../../Link/Link';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import FieldError from '../../Error/FieldError';

function LinkFieldRender({ input, label, placeholder, id, meta: { error, touched } }) {
  return (
    <>
      <Link
        {...input}
        error={touched && error}
        label={label}
        id={id}
        block
        placeholder={placeholder}
      />
      {touched && error && <FieldError error={error} />}
    </>
  );
}

LinkFieldRender.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
};

function LinkField({ name, label, required, information, ...props }) {
  const id = useMemo(() => `name_${uuid()}`, [name]);

  return (
    <FormField
      label={label}
      name={name}
      htmlFor={id}
      information={information}
      required={required}
      verticalAlign={true}
    >
      <Field name={name} component={LinkFieldRender} id={id} {...props} />
    </FormField>
  );
}

LinkField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  information: PropTypes.string,
};

export default LinkField;
