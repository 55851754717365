import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PDFViewer from '../../../atoms/PDFViewer/PDFViewer';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import getDummyData from '../../../../helpers/dummy';

function ViewerDataRender({ input, id, meta: { initial }, ...props }) {
  if ((input.value || initial) === "") {
    return null;
  }

  return <PDFViewer id={id} link={input.value || initial} {...props} />;
}

ViewerDataRender.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  link: PropTypes.link,
  id: PropTypes.string,
  meta: {
    initial: PropTypes.string,
  },
};

function ViewerData({ name, label, fieldHoC: FieldHOC, validation, isPreview, ...props }) {
  const id = useMemo(() => `name_${uuid()}`, [name]);
  const initial = useMemo(() =>isPreview ? getDummyData(validation.type) : "", [validation]);

  return (
    <FormField label={label}>
      <FieldHOC
        name={name}
        id={id}
        component={ViewerDataRender}
        fixedMode={validation.fixedMode}
        meta={{ initial }}
        validation={validation}
        {...props}
      />
    </FormField>
  );
}

ViewerData.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  information: PropTypes.string,
  fieldHoC: PropTypes.func,
  validation: PropTypes.object,
  isPreview: PropTypes.bool
};

ViewerData.defaultProps = {
  fieldHoC: Field,
};

export default ViewerData;
