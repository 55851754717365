import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { allBoxesSelector } from '../../../../store/selectors/editForm';
import AdaptableField from '../../../molecules/Adaptable/AdaptableField';
import { SWITCH } from '../../../../constants/typology/fieldType';
import useTranslate from '../../../../hooks/useTranslate';
import { hasChildsBelow } from '../../../organisms/Grid/GridHelpers';
import styles from './ViewerFragment.css';

function ViewerFragment({ form }) {
  const i18n = useTranslate();
  const { fixedMode, id } = useSelector((state) =>
    formValueSelector(form)(state, 'fixedMode', 'id'),
  );
  const items = useSelector(allBoxesSelector);
  const canToggle = useMemo(() => {
    if (fixedMode) {
      return true;
    }

    const currentItem = items.find((_item) => _item.id === id);

    if (!currentItem) {
      return false;
    }

    return !hasChildsBelow(currentItem, items);
  }, [fixedMode, id, items]);

  return (
    <>
      <AdaptableField
        verticalAlign={false}
        fieldType={SWITCH}
        label={i18n('data.options.fixedMode')}
        name="fixedMode"
        disabled={!canToggle}
      />
      {!canToggle && (
        <p className={styles.ViewerFragment__error}>
          {i18n('ViewerFragment.error.fixedModeItemsBelow')}
        </p>
      )}
    </>
  );
}

ViewerFragment.propTypes = {
  form: PropTypes.string,
};

export default ViewerFragment;
