import React, { useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { initialize } from 'redux-form';
import ContributeFormTemplate from '../../templates/ContributeFormTemplate/ContributeFormTemplate';
import ErrorContributeCertificationTemplate from '../../templates/ErrorContributeCertificationTemplate/ErrorContributeCertificationTemplate';
import ContributeCloseButtonTemplate from '../../templates/ContributeCloseButtonTemplate/ContributeCloseButtonTemplate';
import useFetchAction from '../../../hooks/useFetchAction';
import { 
    getFormCertification as getFormCertificationAction,
    updateCertificationQuestionAnswer as updateCertificationQuestionAnswerAction,
    getCertificationQuestion as getCertificationQuestionAction
 } from '../../../store/actions/certification';
 import { processChoicesInjectionColumns, processDataColumns } from '../../../helpers/formTree';
 import {
  currentCertificationQuestionSelector,
    certificationsDepletedSelector,
    hasReceivedNullCertificationSelector,
  } from '../../../store/selectors/certification';
import Spinner from '../../atoms/Spinner/Spinner';



function CertificationQuestionPage() {
    const params = useParams();
    const currentCertificationQuestion = useSelector(currentCertificationQuestionSelector);
    const certificationsDepleted = useSelector(certificationsDepletedSelector);
    const hasReceivedNullCertification = useSelector(hasReceivedNullCertificationSelector);
    const [getCertificationQuestion, ,certificationError, certificationQuestion, certificationStatus] = useFetchAction(getCertificationQuestionAction);

    const [getForm, formLoading, formError, form] = useFetchAction(getFormCertificationAction);
    const [
      updateCertificationQuestionAnswer,
        ,
        certificationAnswerError,
        ,
        certificationAnswerStatus,
        resetCertificationAnswerStatus,
      ] = useFetchAction(updateCertificationQuestionAnswerAction);
      const getCertificationQuestionWithParams = useCallback(() => {
        // eslint-disable-next-line
        getCertificationQuestion(params.certificationId, params.questionId,{ ignoreError: true });
      }, [getCertificationQuestion, params.certificationId, params.questionId]);
    const dispatch = useDispatch();


  // Creates a key-value pair of task data
  const formData = useMemo(() => processDataColumns(certificationQuestion?.columns), [certificationQuestion?.certificationId]);

  // Generate choice inject overrides
  const certificationChoicesData = useMemo(() => processChoicesInjectionColumns(currentCertificationQuestion?.columns), [
    currentCertificationQuestion?.certificationQuestionId,
  ]);

  // First we load the task
  useEffect(() => {
      getCertificationQuestionWithParams(params.certificationId, params.questionId);
  }, []);

  // Once task is loaded, load the form
  useEffect(() => {
    if (certificationQuestion?.formId && !form && !formLoading) {
      getForm(certificationQuestion.formId);
    }
  }, [certificationQuestion, form, formLoading]);

    // PUT task to backend
    const handleSubmit = useCallback(
        (values) => {
          updateCertificationQuestionAnswer(certificationQuestion.certificationId, certificationQuestion.certificationQuestionId,values, { ignoreError: true });
        },
        [certificationQuestion?.certificationId],
      );

  // Immediatly load a new task when an answer is succesfully submited
  useEffect(() => {
    if (certificationAnswerStatus === 'success') {

      // Clear form state
      dispatch(initialize('contribute'));
      if (!hasReceivedNullCertification) {
        // Retrieved a new task in simple mode
        getCertificationQuestionWithParams(params.certificationId);
      }
      resetCertificationAnswerStatus();
    }
  }, [certificationAnswerStatus, hasReceivedNullCertification]);
  if (!certificationsDepleted && !certificationError && !formError && !certificationAnswerError) {
    if (
      (certificationStatus === 'pending' && !currentCertificationQuestion) ||
      !form ||
      certificationAnswerStatus === 'pending' ||
      certificationAnswerStatus === 'success'
    ) {
      return <Spinner />;
    }
  }
    return (
        <>
      {currentCertificationQuestion && (
        <ContributeCloseButtonTemplate
          taskId={certificationQuestion.certificationQuestionId}
          projectId={currentCertificationQuestion.certificationId}
        />
      )}
      <ErrorContributeCertificationTemplate
        certificationsDepleted={certificationsDepleted}
        certificationError={certificationError}
        certificationAnswerError={certificationAnswerError}
        formError={formError}
      >
    {form && 
    <ContributeFormTemplate 
    data={formData} 
    form={form}
    answer={certificationQuestion?.answer}
    choicesOverrides={certificationChoicesData}
     onSubmit={handleSubmit} />}
     </ErrorContributeCertificationTemplate>
   </>)
}

export default CertificationQuestionPage;
