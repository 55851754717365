import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import styles from './LinkData.css';
import FormField from '../../../atoms/Form/Field/FormField';
import getDummyData from '../../../../helpers/dummy';

function LinkDataRender({ input, id, meta: { initial } }) {
  // TODO: inject input.value here instead of dummy PDF

  if ((input.value || initial) === "") {
    return null;
  }

  return (
    <>
      <a
        href={input.value || initial}
        id={id}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.Link}
        tabIndex={-1}
      >
        {input.value || initial}
      </a>
    </>
  );
}

LinkDataRender.propTypes = {
  input: PropTypes.object,
  id: PropTypes.string,
  meta: {
    initial: PropTypes.string,
  },
};

function LinkData({
  name,
  label,
  information,
  fieldHoC: FieldHOC,
  validation,
  isPreview,
  ...props
}) {
  const id = useMemo(() => `name_${uuid()}`, [name]);
  const initial = useMemo(() => isPreview ? getDummyData(validation.type) : "", [validation]);

  return (
    <FormField label={label} information={information}>
      <FieldHOC
        name={name}
        id={id}
        label={label}
        component={LinkDataRender}
        meta={{ initial }}
        validation={validation}
        {...props}
      />
    </FormField>
  );
}

LinkData.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  information: PropTypes.string,
  fieldHoC: PropTypes.func,
  validation: PropTypes.object,
  isPreview: PropTypes.bool,
};

LinkData.defaultProps = {
  fieldHoC: Field,
};

export default LinkData;
