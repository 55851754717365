import React from 'react';
import styles from './DrawerContentRespondants.css';
import useTranslate from '../../../../../../hooks/useTranslate';
import QualitySelectTemplate from '../../../../../templates/QualitySelectTemplate/QualitySelectTemplate';
import { RESPONDANTS } from '../../../../../../constants/respondants';

function DrawerContentRespondantsPage() {
  const i18n = useTranslate();

  return (
    <div className={styles.DrawerContentRespondants}>
      <h3 className={styles.DrawerContentRespondants__title}>
        {i18n('DrawerContentRespondants.title')}
      </h3>
      <p className={styles.DrawerContentRespondants__description}>
        {i18n('DrawerContentRespondants.description')}
      </p>
      <QualitySelectTemplate active={RESPONDANTS} />
    </div>
  );
}

DrawerContentRespondantsPage.propTypes = {};

export default DrawerContentRespondantsPage;
