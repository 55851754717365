import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './FormulaDropdown.css';
import SVG from '../../../atoms/SVG/SVG';

function FormulaDropdownItem({ children, onClick }) {
  return (
    <button type="button" className={styles.FormulaDropdown__item} onClick={onClick}>
      {children}
    </button>
  );
}

FormulaDropdownItem.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
};

function FormulaDropdown({ label, children, icon, onClick }) {
  const dropdownRef = useRef();
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    onClick && onClick();
    setOpen(true);
  }, [onClick]);

  useEffect(() => {
    function handleClickOutside() {
      setOpen(false);
    }

    if (open) {
      document.body.addEventListener('click', handleClickOutside);
    }

    return () => {
      if (open) {
        document.body.removeEventListener('click', handleClickOutside);
      }
    };
  }, [dropdownRef.current, open]);

  return (
    <div ref={dropdownRef} className={styles.FormulaDropdown}>
      <button onClick={handleOpen} type="button" className={styles.FormulaDropdown__button}>
        {label}
        {icon && <SVG glyph={icon} className={styles.FormulaDropdown__icon} />}
      </button>
      {open && children && <div className={styles.FormulaDropdown__menu}>{children}</div>}
    </div>
  );
}

FormulaDropdown.Item = FormulaDropdownItem;

FormulaDropdown.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

export default FormulaDropdown;
