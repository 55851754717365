import { parseForm } from "../api/form";
import { FORM_GET_SUCCESS } from "../store/actions/form";

export default function loadDebugTools(store) {
  window.debugTools = {

    // Replaces the current loaded form with the one passed in parameter as JSONObject
    loadForm: (formJSON) => {
      // Replace IdForm with current form Id so that we don't try to refetch the form
      formJSON.IdForm = store.getState().form.create.initial.id;
      formJSON.State = "Creating";

      const form = parseForm(formJSON);

      store.dispatch({
        type: FORM_GET_SUCCESS,
        payload: form,
        dirty: true
      });
    },

    // Returns a url to load the project in dev environment with the appropriate token
    createLocalUrl: () => {
      const params = new URLSearchParams(window.location.search);
      const IdSite = params.get('IdSite');
      const token = localStorage.getItem(`wfb_token_${IdSite}`);
      params.set('token', token);
      console.log(`http://localhost:5000${window.location.pathname}?${params.toString()}`)
    }

  };
}
