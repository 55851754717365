import '@babel/polyfill';
import 'whatwg-fetch';
import 'url-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import createStore from './store/createStore';
import App from './components/organisms/App/App';
import { overrideConfigFromParams } from './helpers/config';
import './i18n/setup';
import debugTools from './helpers/debugTools';

import './styles/theme.css';
import './styles/global.css';
import './styles/toast.css';

const idSite = overrideConfigFromParams(new URLSearchParams(window.location.search));

const store = createStore(idSite);

debugTools(store);

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

window.store = store;
