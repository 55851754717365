import React from 'react';
import PropTypes from 'prop-types';
import styles from './RadioBoxInput.css';
import { bem } from '../../../helpers/styles';

function RadioBoxInput({ flex, value, onChange, options }) {
  return (
    <div className={bem(styles, 'RadioBoxInput', { flex })}>
      {options.map((option, i) => (
        <button
          type="button"
          className={bem(styles, 'RadioBoxInput__option', {
            selected: value === option.value,
          })}
          key={i}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
}

RadioBoxInput.propTypes = {
  flex: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
};

export default RadioBoxInput;
