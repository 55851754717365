import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import Image from '../../../atoms/Image/Image';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import styles from './ImageData.css';
import getDummyData from '../../../../helpers/dummy';

function ImageRender({
  input,
  id,
  validation: { perserveHeight, perserveWidth },
  meta: { error, initial },
}) {
  if ((input.value || initial) === "") {
    return null;
  }

  return (
    <div className={styles.ImageData}>
      <Image
        id={id}
        src={input.value || initial}
        perserveHeight={perserveHeight}
        perserveWidth={perserveWidth}
        error={error}
      />
    </div>
  );
}

ImageRender.propTypes = {
  input: PropTypes.object,
  id: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    initial: PropTypes.string,
  }),
  validation: PropTypes.shape({
    perserveHeight: PropTypes.bool,
    perserveWidth: PropTypes.bool,
  }),
};

function ImageData({ name, label, fieldHoC: FieldHOC, information, validation, isPreview, ...props }) {
  const id = useMemo(() => `name_${uuid()}`, [name]);
  const initial = useMemo(() => isPreview ? getDummyData(validation.type) : "", [validation]);

  return (
    <FormField label={label} information={information} verticalAlign={false}>
      <FieldHOC
        name={name}
        id={id}
        component={ImageRender}
        validation={validation}
        meta={{ initial }}
        {...props}
      />
    </FormField>
  );
}

ImageData.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  fieldHoC: PropTypes.func,
  information: PropTypes.string,
  validation: PropTypes.object,
  isPreview: PropTypes.bool
};

ImageData.defaultProps = {
  fieldHoC: Field,
  validation: {},
};
export default ImageData;
