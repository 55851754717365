export function bem(styles, base, paramModifiers = {}) {
  const modifiers = Array.isArray(paramModifiers)
    ? paramModifiers
    : Object.keys(paramModifiers).filter((key) => !!paramModifiers[key]);

  if (modifiers.length) {
    return [styles[base], ...modifiers.map((modifier) => styles[`${base}--${modifier}`])]
      .join(' ')
      .trim();
  }
  return styles[base] ? styles[base] : '';
}
