export const DEFAULT_INDICATIF = '+33';

export default [
  {
    value: '+27',
    shortLabel: '(+27)',
    label: 'Afrique Du Sud (+27)',
    code: 'ZA',
  },
  {
    value: '+503',
    shortLabel: '(+503)',
    label: 'Salvador (+503)',
    code: 'SV',
  },
  {
    value: '+93',
    shortLabel: '(+93)',
    label: 'Afghanistan (+93)',
    code: 'AF',
  },
  {
    value: '+355',
    shortLabel: '(+355)',
    label: 'Albanie (+355)',
    code: 'AL',
  },
  {
    value: '+213',
    shortLabel: '(+213)',
    label: 'Algérie (+213)',
    code: 'DZ',
  },
  {
    value: '+49',
    shortLabel: '(+49)',
    label: 'Allemagne (+49)',
    code: 'DE',
  },
  {
    value: '+1 264',
    shortLabel: '(+1 264)',
    label: 'Anguilla (+1 264)',
    code: 'AI',
  },
  {
    value: '+1268',
    shortLabel: '(+1268)',
    label: 'Antigua-Et-Barbuda (+1268)',
    code: 'AG',
  },
  {
    value: '+966',
    shortLabel: '(+966)',
    label: 'Arabie Saoudite (+966)',
    code: 'SA',
  },
  {
    value: '+54',
    shortLabel: '(+54)',
    label: 'Argentine (+54)',
    code: 'AR',
  },
  {
    value: '+374',
    shortLabel: '(+374)',
    label: 'Arménie (+374)',
    code: 'AM',
  },
  {
    value: '+297',
    shortLabel: '(+297)',
    label: 'Aruba (+297)',
    code: 'AW',
  },
  {
    value: '+61',
    shortLabel: '(+61)',
    label: 'Australie (+61)',
    code: 'AU',
  },
  {
    value: '+43',
    shortLabel: '(+43)',
    label: 'Autriche (+43)',
    code: 'AT',
  },
  {
    value: '+994',
    shortLabel: '(+994)',
    label: 'Azerbaïdjan (+994)',
    code: 'AZ',
  },
  {
    value: '+1 242',
    shortLabel: '(+1 242)',
    label: 'Bahamas (+1 242)',
    code: 'BS',
  },
  {
    value: '+973',
    shortLabel: '(+973)',
    label: 'Bahreïn (+973)',
    code: 'BH',
  },
  {
    value: '+880',
    shortLabel: '(+880)',
    label: 'Bangladesh (+880)',
    code: 'BD',
  },
  {
    value: '+1 246',
    shortLabel: '(+1 246)',
    label: 'Barbade (+1 246)',
    code: 'BB',
  },
  {
    value: '+32',
    shortLabel: '(+32)',
    label: 'Belgique (+32)',
    code: 'BE',
  },
  {
    value: '+501',
    shortLabel: '(+501)',
    label: 'Belize (+501)',
    code: 'BZ',
  },
  {
    value: '+1 441',
    shortLabel: '(+1 441)',
    label: 'Bermudes (+1 441)',
    code: 'BM',
  },
  {
    value: '+975',
    shortLabel: '(+975)',
    label: 'Bhoutan (+975)',
    code: 'BT',
  },
  {
    value: '+95',
    shortLabel: '(+95)',
    label: 'Birmanie (+95)',
    code: 'MM',
  },
  {
    value: '+375',
    shortLabel: '(+375)',
    label: 'Biélorussie (+375)',
    code: 'BY',
  },
  {
    value: '+387',
    shortLabel: '(+387)',
    label: 'Bosnie-Herzégovine (+387)',
    code: 'BA',
  },
  {
    value: '+267',
    shortLabel: '(+267)',
    label: 'Botswana (+267)',
    code: 'BW',
  },
  {
    value: '+673',
    shortLabel: '(+673)',
    label: 'Brunei Darussalam (+673)',
    code: 'BN',
  },
  {
    value: '+55',
    shortLabel: '(+55)',
    label: 'Brésil (+55)',
    code: 'BR',
  },
  {
    value: '+359',
    shortLabel: '(+359)',
    label: 'Bulgarie (+359)',
    code: 'BG',
  },
  {
    value: '+226',
    shortLabel: '(+226)',
    label: 'Burkina Faso (+226)',
    code: 'BF',
  },
  {
    value: '+257',
    shortLabel: '(+257)',
    label: 'Burundi (+257)',
    code: 'BI',
  },
  {
    value: '+229',
    shortLabel: '(+229)',
    label: 'Bénin (+229)',
    code: 'BJ',
  },
  {
    value: '+855',
    shortLabel: '(+855)',
    label: 'Cambodge (+855)',
    code: 'CM',
  },
  {
    value: '+237',
    shortLabel: '(+237)',
    label: 'Cameroun (+237)',
    code: 'CM',
  },
  {
    value: '+1',
    shortLabel: '(+1)',
    label: 'Canada (+1)',
    code: 'CA',
  },
  {
    value: '+238',
    shortLabel: '(+238)',
    label: 'Cap-Vert (+238)',
    code: 'CV',
  },
  {
    value: '+56',
    shortLabel: '(+56)',
    label: 'Chili (+56)',
    code: 'CL',
  },
  {
    value: '+86',
    shortLabel: '(+86)',
    label: 'Chine (+86)',
    code: 'CN',
  },
  {
    value: '+537',
    shortLabel: '(+537)',
    label: 'Chypre (+537)',
    code: 'CY',
  },
  {
    value: '+57',
    shortLabel: '(+57)',
    label: 'Colombie (+57)',
    code: 'CO',
  },
  {
    value: '+269',
    shortLabel: '(+269)',
    label: 'Comores (+269)',
    code: 'KM',
  },
  {
    value: '+242',
    shortLabel: '(+242)',
    label: 'Congo (+242)',
    code: 'CG',
  },
  {
    value: '+850',
    shortLabel: '(+850)',
    label: 'Corée du nord (+850)',
    code: 'KP',
  },
  {
    value: '+82',
    shortLabel: '(+82)',
    label: 'Corée du sud (+82)',
    code: 'KR',
  },
  {
    value: '+506',
    shortLabel: '(+506)',
    label: 'Costa Rica (+506)',
    code: 'CR',
  },
  {
    value: '+385',
    shortLabel: '(+385)',
    label: 'Croatie (+385)',
    code: 'HR',
  },
  {
    value: '+53',
    shortLabel: '(+53)',
    label: 'Cuba (+53)',
    code: 'CU',
  },
  {
    value: '+225',
    shortLabel: '(+225)',
    label: "Côte D'Ivoire (+225)",
    code: 'CI',
  },
  {
    value: '+45',
    shortLabel: '(+45)',
    label: 'Denemark (+45)',
    code: 'DK',
  },
  {
    value: '+253',
    shortLabel: '(+253)',
    label: 'Djibouti (+253)',
    code: 'DJ',
  },
  {
    value: '+1 767',
    shortLabel: '(+1 767)',
    label: 'Dominique (+1 767)',
    code: 'DM',
  },
  {
    value: '+34',
    shortLabel: '(+34)',
    label: 'Espagne (+34)',
    code: 'ES',
  },
  {
    value: '+372',
    shortLabel: '(+372)',
    label: 'Estonie (+372)',
    code: 'EE',
  },
  {
    value: '+679',
    shortLabel: '(+679)',
    label: 'Fidji (+679)',
    code: 'FJ',
  },
  {
    value: '+358',
    shortLabel: '(+358)',
    label: 'Finlande (+358)',
    code: 'FI',
  },
  {
    value: '+33',
    shortLabel: '(+33)',
    label: 'France (+33)',
    code: 'FR',
  },
  {
    value: '+241',
    shortLabel: '(+241)',
    label: 'Gabon (+241)',
    code: 'GA',
  },
  {
    value: '+220',
    shortLabel: '(+220)',
    label: 'Gambie (+220)',
    code: 'GM',
  },
  {
    value: '+233',
    shortLabel: '(+233)',
    label: 'Ghana (+233)',
    code: 'GH',
  },
  {
    value: '+350',
    shortLabel: '(+350)',
    label: 'Gibraltar (+350)',
    code: 'GI',
  },
  {
    value: '+1 473',
    shortLabel: '(+1 473)',
    label: 'Grenade (+1 473)',
    code: 'GD',
  },
  {
    value: '+299',
    shortLabel: '(+299)',
    label: 'Groenland (+299)',
    code: 'GL',
  },
  {
    value: '+30',
    shortLabel: '(+30)',
    label: 'Grèce (+30)',
    code: 'GR',
  },
  {
    value: '+590',
    shortLabel: '(+590)',
    label: 'Guadeloupe (+590)',
    code: 'FR',
  },
  {
    value: '+1 671',
    shortLabel: '(+1 671)',
    label: 'Guam (+1 671)',
    code: 'GU',
  },
  {
    value: '+502',
    shortLabel: '(+502)',
    label: 'Guatemala (+502)',
    code: 'GT',
  },
  {
    value: '+44',
    shortLabel: '(+44)',
    label: 'Guernesey (+44)',
    code: 'GG',
  },
  {
    value: '+224',
    shortLabel: '(+224)',
    label: 'Guinée (+224)',
    code: 'GN',
  },
  {
    value: '+240',
    shortLabel: '(+240)',
    label: 'Guinée Équatoriale (+240)',
    code: 'GQ',
  },
  {
    value: '+245',
    shortLabel: '(+245)',
    label: 'Guinée-Bissau (+245)',
    code: 'GW',
  },
  {
    value: '+595',
    shortLabel: '(+595)',
    label: 'Guyana (+595)',
    code: 'GY',
  },
  {
    value: '+594',
    shortLabel: '(+594)',
    label: 'Guyane (+594)',
    code: 'FR',
  },
  {
    value: '+995',
    shortLabel: '(+995)',
    label: 'Géorgie (+995)',
    code: 'GE',
  },
  {
    value: '+500',
    shortLabel: '(+500)',
    label: 'Géorgie du Sud-et-les Îles Sandwich du Sud (+500)',
    code: 'GS',
  },
  {
    value: '+509',
    shortLabel: '(+509)',
    label: 'Haïti (+509)',
    code: 'HT',
  },
  {
    value: '+504',
    shortLabel: '(+504)',
    label: 'Honduras (+504)',
    code: 'HN',
  },
  {
    value: '+852',
    shortLabel: '(+852)',
    label: 'Hong Kong (+852)',
    code: 'HK',
  },
  {
    value: '+36',
    shortLabel: '(+36)',
    label: 'Hongrie (+36)',
    code: 'HU',
  },
  {
    value: '+91',
    shortLabel: '(+91)',
    label: 'Inde (+91)',
    code: 'IN',
  },
  {
    value: '+62',
    shortLabel: '(+62)',
    label: 'Indonésie (+62)',
    code: 'ID',
  },
  {
    value: '+964',
    shortLabel: '(+964)',
    label: 'Irak (+964)',
    code: 'IQ',
  },
  {
    value: '+98',
    shortLabel: '(+98)',
    label: 'Iran (+98)',
    code: 'IR',
  },
  {
    value: '+353',
    shortLabel: '(+353)',
    label: 'Irlande (+353)',
    code: 'IE',
  },
  {
    value: '+354',
    shortLabel: '(+354)',
    label: 'Islande (+354)',
    code: 'IS',
  },
  {
    value: '+972',
    shortLabel: '(+972)',
    label: 'Israël (+972)',
    code: 'IL',
  },
  {
    value: '+39',
    shortLabel: '(+39)',
    label: 'Italie (+39)',
    code: 'IT',
  },
  {
    value: '+1 876',
    shortLabel: '(+1 876)',
    label: 'Jamaïque (+1 876)',
    code: 'JM',
  },
  {
    value: '+81',
    shortLabel: '(+81)',
    label: 'Japon (+81)',
    code: 'JP',
  },
  {
    value: '+44',
    shortLabel: '(+44)',
    label: 'Jersey (+44)',
    code: 'JE',
  },
  {
    value: '+962',
    shortLabel: '(+962)',
    label: 'Jordanie (+962)',
    code: 'JO',
  },
  {
    value: '+7 7',
    shortLabel: '(+7 7)',
    label: 'Kazakhstan (+7 7)',
    code: 'KZ',
  },
  {
    value: '+254',
    shortLabel: '(+254)',
    label: 'Kenya (+254)',
    code: 'KE',
  },
  {
    value: '+996',
    shortLabel: '(+996)',
    label: 'Kirghizistan (+996)',
    code: 'KG',
  },
  {
    value: '+686',
    shortLabel: '(+686)',
    label: 'Kiribati (+686)',
    code: 'KI',
  },
  {
    value: '+965',
    shortLabel: '(+965)',
    label: 'Koweït (+965)',
    code: 'KW',
  },
  {
    value: '+262',
    shortLabel: '(+262)',
    label: 'La Réunion (+262)',
    code: 'RE',
  },
  {
    value: '+856',
    shortLabel: '(+856)',
    label: 'Laos (+856)',
    code: 'LA',
  },
  {
    value: '+266',
    shortLabel: '(+266)',
    label: 'Lesotho (+266)',
    code: 'LS',
  },
  {
    value: '+371',
    shortLabel: '(+371)',
    label: 'Lettonie (+371)',
    code: 'LV',
  },
  {
    value: '+961',
    shortLabel: '(+961)',
    label: 'Liban (+961)',
    code: 'LB',
  },
  {
    value: '+231',
    shortLabel: '(+231)',
    label: 'Liberia (+231)',
    code: 'LR',
  },
  {
    value: '+218',
    shortLabel: '(+218)',
    label: 'Libye (+218)',
    code: 'LY',
  },
  {
    value: '+423',
    shortLabel: '(+423)',
    label: 'Liechtenstein (+423)',
    code: 'LI',
  },
  {
    value: '+370',
    shortLabel: '(+370)',
    label: 'Lituanie (+370)',
    code: 'LT',
  },
  {
    value: '+352',
    shortLabel: '(+352)',
    label: 'Luxembourg (+352)',
    code: 'LU',
  },
  {
    value: '+853',
    shortLabel: '(+853)',
    label: 'Macao (+853)',
    code: 'MO',
  },
  {
    value: '+389',
    shortLabel: '(+389)',
    label: 'Macédoine (+389)',
    code: 'MK',
  },
  {
    value: '+261',
    shortLabel: '(+261)',
    label: 'Madagascar (+261)',
    code: 'MG',
  },
  {
    value: '+60',
    shortLabel: '(+60)',
    label: 'Malaisie (+60)',
    code: 'MY',
  },
  {
    value: '+265',
    shortLabel: '(+265)',
    label: 'Malawi (+265)',
    code: 'MW',
  },
  {
    value: '+960',
    shortLabel: '(+960)',
    label: 'Maldives (+960)',
    code: 'MV',
  },
  {
    value: '+223',
    shortLabel: '(+223)',
    label: 'Mali (+223)',
    code: 'ML',
  },
  {
    value: '+356',
    shortLabel: '(+356)',
    label: 'Malte (+356)',
    code: 'MT',
  },
  {
    value: '+212',
    shortLabel: '(+212)',
    label: 'Maroc (+212)',
    code: 'MA',
  },
  {
    value: '+596',
    shortLabel: '(+596)',
    label: 'Martinique (+596)',
    code: 'MQ',
  },
  {
    value: '+230',
    shortLabel: '(+230)',
    label: 'Maurice (+230)',
    code: 'MU',
  },
  {
    value: '+222',
    shortLabel: '(+222)',
    label: 'Mauritanie (+222)',
    code: 'MR',
  },
  {
    value: '+262',
    shortLabel: '(+262)',
    label: 'Mayotte (+262)',
    code: 'YT',
  },
  {
    value: '+377',
    shortLabel: '(+377)',
    label: 'Monaco (+377)',
    code: 'MC',
  },
  {
    value: '+976',
    shortLabel: '(+976)',
    label: 'Mongolie (+976)',
    code: 'MN',
  },
  {
    value: '+1664',
    shortLabel: '(+1664)',
    label: 'Montserrat (+1664)',
    code: 'MS',
  },
  {
    value: '+382',
    shortLabel: '(+382)',
    label: 'Monténégro (+382)',
    code: 'ME',
  },
  {
    value: '+258',
    shortLabel: '(+258)',
    label: 'Mozambique (+258)',
    code: 'MZ',
  },
  {
    value: '+264',
    shortLabel: '(+264)',
    label: 'Namibie (+264)',
    code: 'NA',
  },
  {
    value: '+674',
    shortLabel: '(+674)',
    label: 'Nauru (+674)',
    code: 'NR',
  },
  {
    value: '+505',
    shortLabel: '(+505)',
    label: 'Nicaragua (+505)',
    code: 'NI',
  },
  {
    value: '+227',
    shortLabel: '(+227)',
    label: 'Niger (+227)',
    code: 'NE',
  },
  {
    value: '+234',
    shortLabel: '(+234)',
    label: 'Nigéria (+234)',
    code: 'NG',
  },
  {
    value: '+683',
    shortLabel: '(+683)',
    label: 'Niue (+683)',
    code: 'NU',
  },
  {
    value: '+47',
    shortLabel: '(+47)',
    label: 'Norvège (+47)',
    code: 'NO',
  },
  {
    value: '+687',
    shortLabel: '(+687)',
    label: 'Nouvelle-Calédonie (+687)',
    code: 'NA',
  },
  {
    value: '+64',
    shortLabel: '(+64)',
    label: 'Nouvelle-Zélande (+64)',
    code: 'NZ',
  },
  {
    value: '+977',
    shortLabel: '(+977)',
    label: 'Népal (+977)',
    code: 'NP',
  },
  {
    value: '+968',
    shortLabel: '(+968)',
    label: 'Oman (+968)',
    code: 'OM',
  },
  {
    value: '+256',
    shortLabel: '(+256)',
    label: 'Ouganda (+256)',
    code: 'UG',
  },
  {
    value: '+998',
    shortLabel: '(+998)',
    label: 'Ouzbékistan (+998)',
    code: 'UZ',
  },
  {
    value: '+92',
    shortLabel: '(+92)',
    label: 'Pakistan (+92)',
    code: 'PK',
  },
  {
    value: '+680',
    shortLabel: '(+680)',
    label: 'Palaos (+680)',
    code: 'PW',
  },
  {
    value: '+970',
    shortLabel: '(+970)',
    label: 'Palestine (+970)',
    code: 'PS',
  },
  {
    value: '+507',
    shortLabel: '(+507)',
    label: 'Panama (+507)',
    code: 'PA',
  },
  {
    value: '+675',
    shortLabel: '(+675)',
    label: 'Papouasie-Nouvelle-Guinée (+675)',
    code: 'PG',
  },
  {
    value: '+595',
    shortLabel: '(+595)',
    label: 'Paraguay (+595)',
    code: 'PY',
  },
  {
    value: '+31',
    shortLabel: '(+31)',
    label: 'Pays-Bas (+31)',
    code: 'NL',
  },
  {
    value: '+63',
    shortLabel: '(+63)',
    label: 'Philippines (+63)',
    code: 'PH',
  },
  {
    value: '+872',
    shortLabel: '(+872)',
    label: 'Pitcairn (+872)',
    code: 'PN',
  },
  {
    value: '+48',
    shortLabel: '(+48)',
    label: 'Pologne (+48)',
    code: 'PL',
  },
  {
    value: '+689',
    shortLabel: '(+689)',
    label: 'Polynésie Française (+689)',
    code: 'PF',
  },
  {
    value: '+1 939',
    shortLabel: '(+1 939)',
    label: 'Porto Rico (+1 939)',
    code: 'PR',
  },
  {
    value: '+351',
    shortLabel: '(+351)',
    label: 'Portugal (+351)',
    code: 'PT',
  },
  {
    value: '+51',
    shortLabel: '(+51)',
    label: 'Pérou (+51)',
    code: 'PE',
  },
  {
    value: '+974',
    shortLabel: '(+974)',
    label: 'Qatar (+974)',
    code: 'QA',
  },
  {
    value: '+40',
    shortLabel: '(+40)',
    label: 'Roumanie (+40)',
    code: 'RO',
  },
  {
    value: '+44',
    shortLabel: '(+44)',
    label: 'Royaume-Uni (+44)',
    code: 'GB',
  },
  {
    value: '+7',
    shortLabel: '(+7)',
    label: 'Russie (+7)',
    code: 'RU',
  },
  {
    value: '+250',
    shortLabel: '(+250)',
    label: 'Rwanda (+250)',
    code: 'RW',
  },
  {
    value: '+236',
    shortLabel: '(+236)',
    label: 'République Centrafricaine (+236)',
    code: 'CF',
  },
  {
    value: '+373',
    shortLabel: '(+373)',
    label: 'République De Moldavie (+373)',
    code: 'MD',
  },
  {
    value: '+1 849',
    shortLabel: '(+1 849)',
    label: 'République Dominicaine (+1 849)',
    code: 'DO',
  },
  {
    value: '+243',
    shortLabel: '(+243)',
    label: 'République Démocratique Du Congo (+243)',
    code: 'CD',
  },
  {
    value: '+590',
    shortLabel: '(+590)',
    label: 'Saint-Barthélemy (+590)',
    code: 'BL',
  },
  {
    value: '+1 869',
    shortLabel: '(+1 869)',
    label: 'Saint-Christophe-et-Niévès (+1 869)',
    code: 'KN',
  },
  {
    value: '+378',
    shortLabel: '(+378)',
    label: 'Saint-Marin (+378)',
    code: 'SM',
  },
  {
    value: '+590',
    shortLabel: '(+590)',
    label: 'Saint-Martin (Partie Française) (+590)',
    code: 'MF',
  },
  {
    value: '+508',
    shortLabel: '(+508)',
    label: 'Saint-Pierre-Et-Miquelon (+508)',
    code: 'PM',
  },
  {
    value: '+379',
    shortLabel: '(+379)',
    label: 'Saint-Siège (État De La Cité Du Vatican) (+379)',
    code: 'VA',
  },
  {
    value: '+1 784',
    shortLabel: '(+1 784)',
    label: 'Saint-Vincent-Et-Les Grenadines (+1 784)',
    code: 'VC',
  },
  {
    value: '+290',
    shortLabel: '(+290)',
    label: 'Sainte-Hélène (+290)',
    code: 'SH',
  },
  {
    value: '+1 758',
    shortLabel: '(+1 758)',
    label: 'Sainte-Lucie (+1 758)',
    code: 'LC',
  },
  {
    value: '+685',
    shortLabel: '(+685)',
    label: 'Samoa (+685)',
    code: 'WS',
  },
  {
    value: '+1 684',
    shortLabel: '(+1 684)',
    label: 'Samoa Américaines (+1 684)',
    code: 'AS',
  },
  {
    value: '+239',
    shortLabel: '(+239)',
    label: 'Sao Tomé-Et-Principe (+239)',
    code: 'ST',
  },
  {
    value: '+381',
    shortLabel: '(+381)',
    label: 'Serbie (+381)',
    code: 'RS',
  },
  {
    value: '+248',
    shortLabel: '(+248)',
    label: 'Seychelles (+248)',
    code: 'SC',
  },
  {
    value: '+232',
    shortLabel: '(+232)',
    label: 'Sierra Leone (+232)',
    code: 'SL',
  },
  {
    value: '+65',
    shortLabel: '(+65)',
    label: 'Singapour (+65)',
    code: 'SG',
  },
  {
    value: '+421',
    shortLabel: '(+421)',
    label: 'Slovaquie (+421)',
    code: 'SK',
  },
  {
    value: '+386',
    shortLabel: '(+386)',
    label: 'Slovénie (+386)',
    code: 'SI',
  },
  {
    value: '+252',
    shortLabel: '(+252)',
    label: 'Somalie (+252)',
    code: 'SO',
  },
  {
    value: '+249',
    shortLabel: '(+249)',
    label: 'Soudan (+249)',
    code: 'SD',
  },
  {
    value: '+94',
    shortLabel: '(+94)',
    label: 'Sri Lanka (+94)',
    code: 'LK',
  },
  {
    value: '+41',
    shortLabel: '(+41)',
    label: 'Suisse (+41)',
    code: 'CH',
  },
  {
    value: '+597',
    shortLabel: '(+597)',
    label: 'Suriname (+597)',
    code: 'SR',
  },
  {
    value: '+46',
    shortLabel: '(+46)',
    label: 'Suède (+46)',
    code: 'SE',
  },
  {
    value: '+47',
    shortLabel: '(+47)',
    label: 'Svalbard Et Jan Mayen (+47)',
    code: 'SJ',
  },
  {
    value: '+268',
    shortLabel: '(+268)',
    label: 'Swaziland (+268)',
    code: 'SZ',
  },
  {
    value: '+963',
    shortLabel: '(+963)',
    label: 'Syrie (+963)',
    code: 'SY',
  },
  {
    value: '+221',
    shortLabel: '(+221)',
    label: 'Sénégal (+221)',
    code: 'SN',
  },
  {
    value: '+992',
    shortLabel: '(+992)',
    label: 'Tadjikistan (+992)',
    code: 'TJ',
  },
  {
    value: '+255',
    shortLabel: '(+255)',
    label: 'Tanzanie (+255)',
    code: 'TZ',
  },
  {
    value: '+886',
    shortLabel: '(+886)',
    label: 'Taïwan (+886)',
    code: 'TW',
  },
  {
    value: '+235',
    shortLabel: '(+235)',
    label: 'Tchad (+235)',
    code: 'TD',
  },
  {
    value: '+420',
    shortLabel: '(+420)',
    label: 'Tchéquie (+420)',
    code: 'CZ',
  },
  {
    value: '+246',
    shortLabel: '(+246)',
    label: "Territoire Britannique De L'océan Indien (+246)",
    code: 'IO',
  },
  {
    value: '+66',
    shortLabel: '(+66)',
    label: 'Thaïlande (+66)',
    code: 'TH',
  },
  {
    value: '+670',
    shortLabel: '(+670)',
    label: 'Timor-Leste (+670)',
    code: 'TL',
  },
  {
    value: '+228',
    shortLabel: '(+228)',
    label: 'Togo (+228)',
    code: 'TG',
  },
  {
    value: '+690',
    shortLabel: '(+690)',
    label: 'Tokelau (+690)',
    code: 'TK',
  },
  {
    value: '+676',
    shortLabel: '(+676)',
    label: 'Tonga (+676)',
    code: 'TO',
  },
  {
    value: '+1 868',
    shortLabel: '(+1 868)',
    label: 'Trinité-Et-Tobago (+1 868)',
    code: 'TT',
  },
  {
    value: '+216',
    shortLabel: '(+216)',
    label: 'Tunisie (+216)',
    code: 'TN',
  },
  {
    value: '+993',
    shortLabel: '(+993)',
    label: 'Turkménistan (+993)',
    code: 'TM',
  },
  {
    value: '+90',
    shortLabel: '(+90)',
    label: 'Turquie (+90)',
    code: 'TR',
  },
  {
    value: '+688',
    shortLabel: '(+688)',
    label: 'Tuvalu (+688)',
    code: 'TV',
  },
  {
    value: '+380',
    shortLabel: '(+380)',
    label: 'Ukraine (+380)',
    code: 'UA',
  },
  {
    value: '+598',
    shortLabel: '(+598)',
    label: 'Uruguay (+598)',
    code: 'UY',
  },
  {
    value: '+678',
    shortLabel: '(+678)',
    label: 'Vanuatu (+678)',
    code: 'VU',
  },
  {
    value: '+58',
    shortLabel: '(+58)',
    label: 'Venezuela (+58)',
    code: 'VE',
  },
  {
    value: '+84',
    shortLabel: '(+84)',
    label: 'Viet Nam (+84)',
    code: 'VN',
  },
  {
    value: '+681',
    shortLabel: '(+681)',
    label: 'Wallis Et Futuna (+681)',
    code: 'WF',
  },
  {
    value: '+967',
    shortLabel: '(+967)',
    label: 'Yémen (+967)',
    code: 'YE',
  },
  {
    value: '+260',
    shortLabel: '(+260)',
    label: 'Zambie (+260)',
    code: 'ZM',
  },
  {
    value: '+263',
    shortLabel: '(+263)',
    label: 'Zimbabwe (+263)',
    code: 'ZW',
  },
  {
    value: '+52',
    shortLabel: '(+52)',
    label: 'exique (+52)',
    code: 'MX',
  },
  {
    value: '+20',
    shortLabel: '(+20)',
    label: 'Égypte (+20)',
    code: 'EG',
  },
  {
    value: '+971',
    shortLabel: '(+971)',
    label: 'Émirats Arabes Unis (+971)',
    code: 'AE',
  },
  {
    value: '+593',
    shortLabel: '(+593)',
    label: 'Équateur (+593)',
    code: 'EC',
  },
  {
    value: '+291',
    shortLabel: '(+291)',
    label: 'Érythrée (+291)',
    code: 'ER',
  },
  {
    value: '+591',
    shortLabel: '(+591)',
    label: 'État Plurinational De Bolivie (+591)',
    code: 'BO',
  },
  {
    value: '+691',
    shortLabel: '(+691)',
    label: 'États Fédérés De Micronésie (+691)',
    code: 'FM',
  },
  {
    value: '+1',
    shortLabel: '(+1)',
    label: 'États-Unis (+1)',
    code: 'US',
  },
  {
    value: '+251',
    shortLabel: '(+251)',
    label: 'Éthiopie (+251)',
    code: 'ET',
  },
  {
    value: '+61',
    shortLabel: '(+61)',
    label: 'Île Christmas (+61)',
    code: 'CX',
  },
  {
    value: '+44',
    shortLabel: '(+44)',
    label: 'Île De Man (+44)',
    code: 'IM',
  },
  {
    value: '+672',
    shortLabel: '(+672)',
    label: 'Île Norfolk (+672)',
    code: 'NF',
  },
  {
    value: '+ 345',
    shortLabel: '(+ 345)',
    label: 'Îles Caïmans (+ 345)',
    code: 'KW',
  },
  {
    value: '+61',
    shortLabel: '(+61)',
    label: 'Îles Cocos (+61)',
    code: 'CC',
  },
  {
    value: '+682',
    shortLabel: '(+682)',
    label: 'Îles Cook (+682)',
    code: 'CK',
  },
  {
    value: '+298',
    shortLabel: '(+298)',
    label: 'Îles Féroé (+298)',
    code: 'FO',
  },
  {
    value: '+500',
    shortLabel: '(+500)',
    label: 'Îles Malouines (+500)',
    code: 'FK',
  },
  {
    value: '+1 670',
    shortLabel: '(+1 670)',
    label: 'Îles Mariannes Du Nord (+1 670)',
    code: 'MP',
  },
  {
    value: '+692',
    shortLabel: '(+692)',
    label: 'Îles Marshall (+692)',
    code: 'MH',
  },
  {
    value: '+677',
    shortLabel: '(+677)',
    label: 'Îles Salomon (+677)',
    code: 'SB',
  },
  {
    value: '+1 649',
    shortLabel: '(+1 649)',
    label: 'Îles Turques-et-Caïques (+1 649)',
    code: 'TC',
  },
  {
    value: '+1 284',
    shortLabel: '(+1 284)',
    label: 'Îles Vierges Britanniques (+1 284)',
    code: 'VG',
  },
  {
    value: '+1 340',
    shortLabel: '(+1 340)',
    label: 'Îles Vierges Des États-Unis (+1 340)',
    code: 'VI',
  },
];
