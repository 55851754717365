import React, { useCallback, useMemo, useEffect } from 'react';
import useCurrentForm from '../../../hooks/useCurrentForm';
import Spinner from '../../atoms/Spinner/Spinner';
import downloadJson from '../../../helpers/downloadJson';
import ContributeFormTemplate from '../../templates/ContributeFormTemplate/ContributeFormTemplate';
import { transformToHumanNames } from '../../../helpers/form';
import Button from '../../atoms/Button/Button';
import styles from './PreviewPage.css';
import useTranslate from '../../../hooks/useTranslate';
import { useHistory, useParams, matchPath } from 'react-router';
import { getTypologyKind } from '../../../helpers/typology';
import { DATA } from '../../../constants/typology/data';
import getDummyData from '../../../helpers/dummy';
import { populatePath } from '../../../helpers/path';
import { PATH_EDITFIELDS_FORM, PATH_PREVIEW_FORM } from '../../../constants/paths';
import { useLastLocation } from 'react-router-last-location';
import useQuery from '../../../hooks/useQuery';
import { API_GOOGLE_KEY } from '../../../constants/googleMapApiKey';
import FormStyle from '../../atoms/FormStyle';

function PreviewPage() {
  const t = useTranslate();
  const history = useHistory();
  const params = useParams();
  const [form] = useCurrentForm();
  const query = useQuery();
  const lastLocation = useLastLocation();
  const transformedForm = useMemo(() => (form ? transformToHumanNames(form) : form), [form]);
  // Generates mock data for form preview
  const data = useMemo(
    () =>
      transformedForm
        ? transformedForm.fields.reduce((dummyData, field) => {
            if (getTypologyKind(field.type) === DATA) {
              return {
                ...dummyData,
                [field.id]: getDummyData(field.type),
              };
            }
            return dummyData;
          }, {})
        : {},
    [transformedForm],
  );
  // init google map api for autocomplete adress field
  const initApiGoogleMap = () => {
    var script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_GOOGLE_KEY}&libraries=places&callback=initMap`;
    script.async = true;

    window.initMap = function () {};
    document.head.appendChild(script);
  };
  useEffect(() => {
    initApiGoogleMap();
  }, []);

  const handleSubmit = useCallback((values) => {
    downloadJson('response.json', values);
  }, []);

  const handleBack = useCallback(() => {
    if (lastLocation && !matchPath(lastLocation.pathname, { path: PATH_PREVIEW_FORM })) {
      history.goBack();
    } else {
      history.push(populatePath(PATH_EDITFIELDS_FORM, params, query));
    }
  }, [params]);

  if (!form) {
    return <Spinner />;
  }

  return (
    <>
      <Button icon="arrow_left" className={styles.PreviewPage__back} onClick={handleBack}>
        {t('PreviewPage.back')}
      </Button>

      <FormStyle form={form}>
        <ContributeFormTemplate
          data={data}
          form={transformedForm}
          onSubmit={handleSubmit}
          isPreview
        />
      </FormStyle>
    </>
  );
}

export default PreviewPage;
