import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import FieldError from '../../Error/FieldError';
import UploadInput from '../../UploadInput/UploadInput';

function UploadFieldRender({ input, meta: { error, touched }, ...props }) {
  return (
    <>
      <UploadInput name={name} {...input} {...props} />
      {touched && error && <FieldError error={error} />}
    </>
  );
}

UploadFieldRender.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  readonly: PropTypes.bool,
};

function UploadField({
  name,
  label,
  required,
  information,
  fieldHoC: FieldHOC,
  validation,
  ...props
}) {
  const id = useMemo(() => `name_${uuid()}`, [name]);
  const mimetypes = useMemo(
    () => [...(validation?.mimetypes || [])].concat(validation?.imagesOnly ? ['image/*'] : []),
    [validation],
  );

  return (
    <FormField
      label={label}
      name={name}
      htmlFor={id}
      information={information}
      required={required}
      verticalAlign={true}
    >
      <FieldHOC
        name={name}
        component={UploadFieldRender}
        id={id}
        sizeRange={validation?.sizeRange}
        mimetypes={mimetypes}
        {...props}
      />
    </FormField>
  );
}

UploadField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  information: PropTypes.string,
  fieldHoC: PropTypes.func,
  validation: PropTypes.object,
};

UploadField.defaultProps = {
  fieldHoC: Field,
};

export default UploadField;
