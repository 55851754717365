import {
  RULE_MULTILIKE_MONO,
  RULE_TEXTLIKE,
  RULE_LOCATIONLIKE,
  RULE_NUMBERLIKE,
  RULE_DATELIKE,
} from './comparisonRules';
import {
  SHORTTEXT,
  RANGE,
  SWITCH,
  SWITCHTOGGLE,
  NUMBER,
  SELECT,
  EMAIL,
  GPSCOORDINATES,
  MCQ,
  PHONENUMBER,
  RICHTEXT,
  URL,
  ADVANCEDSELECT,
  DATETIME,
  DATETIMERANGE,
  ADDRESS,
  FILEUPLOAD,
  RIB,
} from './fieldType';
export const FIELD = 'field';

export const FIELD_EMAIL = {
  type: EMAIL,
  boxDefaults: { width: 2 },
  options: [
    { name: 'information', type: SHORTTEXT, hideLabel: true },
    { name: 'required', type: SWITCH },
    {
      name: 'domains_accepted',
      type: SHORTTEXT,
      placeholder: 'ex : wirk.io, foulefactory.com…',
      initialValue: '',
    },
    {
      name: 'domains_rejected',
      type: SHORTTEXT,
      placeholder: 'ex : wirk.io, foulefactory.com…',
      initialValue: '',
    },
  ],
  comparisonRules: RULE_TEXTLIKE,
  meta: {
    name: 'Texte formaté',
    color: '#6bbfc5',
    icon: 'field_email',
  },
  shortcut: '',
};

export const FIELD_ADDRESS = {
  type: ADDRESS,
  boxDefaults: { width: 2 },
  options: [
    { name: 'required', type: SWITCH },
    { name: 'onlyCountry', type: SWITCH },
    { name: 'defaultFrance', type: SWITCH },
  ],
  comparisonRules: RULE_LOCATIONLIKE,
  meta: {
    name: 'Adresse',
    color: '#ffb66c',
    icon: 'field_address',
  },
  shortcut: '',
};

export const FIELD_RIB = {
  type: RIB,
  boxDefaults: { width: 3 },
  options: [
    { name: 'required', type: SWITCH },
    { name: 'rib', type: SWITCH },
  ],
  comparisonRules: RULE_TEXTLIKE,
  meta: {
    name: 'RIB',
    color: '#ffb66c',
    icon: 'field_number',
  },
  shortcut: '',
};

export const FIELD_GPSCOORDINATES = {
  type: GPSCOORDINATES,
  boxDefaults: { width: 2 },
  options: [
    { name: 'information', type: SHORTTEXT, hideLabel: true },
    { name: 'required', type: SWITCH },
    { name: 'longitudeRange', type: RANGE },
    { name: 'latitudeRange', type: RANGE },
  ],
  comparisonRules: RULE_LOCATIONLIKE,
  meta: {
    name: 'Texte formaté gps',
    color: '#86c1ff',
    icon: 'field_address',
  },
  shortcut: '',
};

export const FIELD_DATETIME = {
  type: DATETIME,
  boxDefaults: { width: 2 },
  options: [
    { name: 'information', type: SHORTTEXT, hideLabel: true },
    { name: 'required', type: SWITCH },
    {
      name: 'datetimeFormat',
      type: SELECT,
      options: [
        { value: 'date', label: 'date' },
        { value: 'time', label: 'time' },
        { value: 'datetime', label: 'datetime' },
      ],
      initialValue: 'date',
    },
    { name: 'timeRange', type: DATETIMERANGE, rangeType: 'time' },
    { name: 'dateRange', type: DATETIMERANGE, rangeType: 'date' },
  ],
  comparisonRules: RULE_DATELIKE,
  meta: {
    name: 'Date & Heure',
    color: '#ff8ea0',
    icon: 'field_date',
  },
  shortcut: '',
};

export const FIELD_URL = {
  type: URL,
  boxDefaults: { width: 2 },
  options: [
    { name: 'required', type: SWITCH },
    { name: 'whitelist', type: SHORTTEXT },
    { name: 'blacklist', type: SHORTTEXT },
  ],
  comparisonRules: RULE_TEXTLIKE,
  meta: {
    name: 'Url',
    color: '#63C5FF',
    icon: 'text_short',
  },
  shortcut: '',
};

export const FIELD_NUMBER = {
  type: NUMBER,
  boxDefaults: { width: 2 },
  options: [
    { name: 'information', type: SHORTTEXT, hideLabel: true },
    { name: 'required', type: SWITCH },
    {
      name: 'allowedDecimals',
      labelRight: 'integer',
      labelLeft: 'decimals',
      type: SWITCHTOGGLE,
      initialValue: false,
    },
    { name: 'optionalUnit', type: SHORTTEXT, placeholder: '€' },
    { name: 'valueRange', type: RANGE },
  ],
  comparisonRules: RULE_NUMBERLIKE,
  meta: {
    name: 'Champ numérique',
    color: '#ffd375',
    icon: 'field_number',
  },
  shortcut: '',
};

/** FIELDS OTHERS **/
export const FIELD_SHORTTEXT = {
  type: SHORTTEXT,
  boxDefaults: { width: 2 },
  options: [
    { name: 'information', type: SHORTTEXT, hideLabel: true },
    { name: 'required', type: SWITCH },
    { name: 'charsRange', type: RANGE },
    { name: 'wordsRange', type: RANGE },
    { name: 'capitalized', type: SWITCH },
  ],
  comparisonRules: RULE_TEXTLIKE,
  meta: {
    name: 'Texte Court',
    color: '#dcd6ff',
    icon: 'text_short',
  },
  shortcut: '',
};

export const FIELD_TEXT = {
  type: RICHTEXT,
  boxDefaults: { width: 4, height: 2 },
  options: [
    { name: 'information', type: SHORTTEXT },
    { name: 'required', type: SWITCH },
    { name: 'charsRange', type: RANGE },
    { name: 'wordsRange', type: RANGE },
    { name: 'hideRich', type: SWITCH },
  ],
  comparisonRules: RULE_TEXTLIKE,
  meta: {
    name: 'Texte Riche',
    color: '#a79edc',
    icon: 'field_richtext',
  },
};

export const FIELD_PHONENUMBER = {
  type: PHONENUMBER,
  boxDefaults: { width: 2 },
  options: [{ name: 'required', type: SWITCH }],
  comparisonRules: RULE_TEXTLIKE,
  meta: {
    name: 'Champ téléphone',
    color: '#8ad5bb',
    icon: 'field_phone',
  },
  shortcut: '',
};

export const FIELD_MCQ = {
  type: MCQ,
  boxDefaults: { width: 3, height: 2 },
  options: [
    { name: 'information', type: SHORTTEXT, hideLabel: true },
    { name: 'required', type: SWITCH },
    { name: 'multi', type: SWITCH },
    { name: 'allowOther', type: SWITCH },
    { name: 'autoselect', type: SWITCH },
    { name: 'horizontal', type: SWITCH },
    { name: 'choicesRange', type: RANGE },
  ],
  comparisonRules: RULE_MULTILIKE_MONO,
  meta: {
    name: 'Champ numérique',
    color: '#b0d5cc',
    icon: 'field_mcq',
  },
  shortcut: ''
};

export const FIELD_LIST = {
  type: ADVANCEDSELECT,
  boxDefaults: { width: 2, height: 2 },
  options: [
    { name: 'information', type: SHORTTEXT, hideLabel: true },
    { name: 'required', type: SWITCH },
    { name: 'multi', type: SWITCH },
    { name: 'autoselect', type: SWITCH },
    { name: 'choicesRange', type: RANGE },
  ],
  comparisonRules: RULE_MULTILIKE_MONO,
  meta: {
    name: 'Liste',
    color: '#ffe369',
    icon: 'field_list',
  },
  shortcut: '',
};

export const FIELD_UPLOAD = {
  type: FILEUPLOAD,
  boxDefaults: { width: 2, height: 2 },
  options: [
    { name: 'information', type: SHORTTEXT, hideLabel: true },
    { name: 'required', type: SWITCH },
    { name: 'allowedExtentions', type: SWITCH },
    { name: 'imagesOnly', type: SWITCH },
    { name: 'sizeRange', type: RANGE },
  ],
  meta: {
    name: 'Upload document',
    color: '#1E967F',
    icon: 'field_upload',
  },
};

export const FIELD_TYPOLOGIES = [
  FIELD_SHORTTEXT,
  FIELD_TEXT,
  FIELD_EMAIL,
  FIELD_GPSCOORDINATES,
  FIELD_NUMBER,
  FIELD_ADDRESS,
  FIELD_PHONENUMBER,
  FIELD_DATETIME,
  FIELD_LIST,
  FIELD_MCQ,
  FIELD_URL,
  FIELD_UPLOAD,
  FIELD_RIB,
];
