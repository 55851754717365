import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import FieldError from '../../Error/FieldError';
import FormulaInput from '../../FormulaInput/FormulaInput';
import { compile, validate } from '../../../../helpers/formula';

function FormulaFieldRender({ input, id, meta: { error, touched }, ...props }) {
  const isSyntaxValid = useMemo(() => {
    return !input.value || validate(compile(input.value));
  }, [input.value]);

  return (
    <>
      <FormulaInput
        {...input}
        id={id}
        block
        {...props}
        error={(touched && error) || !isSyntaxValid}
      />
      {error && <FieldError error={error} />}
      {!isSyntaxValid && <FieldError error={{ type: error || 'invalid_syntax' }} />}
    </>
  );
}

FormulaFieldRender.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  layout: PropTypes.string,
  options: PropTypes.array,
  exclusive: PropTypes.bool,
};

function FormulaField({ name, label, required, fieldHoC: FieldHOC, information, ...props }) {
  const id = useMemo(() => `name_${uuid()}`, [name]);

  return (
    <FormField label={label} name={name} htmlFor={id} required={required} information={information}>
      <FieldHOC name={name} component={FormulaFieldRender} id={id} {...props} />
    </FormField>
  );
}

FormulaField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  fieldHoC: PropTypes.func,
  information: PropTypes.string,
};

FormulaField.defaultProps = {
  fieldHoC: Field,
  validation: {},
};

export default FormulaField;
