import React from 'react';
import PropTypes from 'prop-types';
import styles from './FormPreviewTemplate.css';
import { useParams } from 'react-router-dom';

function FormPreviewTemplate() {
  const { formId } = useParams();
  return <div className={styles.FormPreviewTemplate}>{`Displaying form ${formId}`}</div>;
}
FormPreviewTemplate.propTypes = {
  drawer: PropTypes.bool,
  formId: PropTypes.string,
};

FormPreviewTemplate.defaultProps = {
  drawer: false,
  formId: null,
};

export default FormPreviewTemplate;
