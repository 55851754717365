import React, { useEffect, useState } from 'react';
import { ActionCreators as UndoActionCreators } from 'redux-undo';
import { connect } from 'react-redux';
import SVG from '../../atoms/SVG/SVG';
import styles from './Undo.css';
import { KEY_Z, KEY_SHIFT } from '../../../constants/keyboardFormControl';

let Undo = ({ canUndo, canRedo, onUndo, onRedo, state }) => {
  const [firstItem, setFirstItem] = useState(false);
  const [displayBorderLeft, setDisplayBorderLeft] = useState(false);
  const [displayBorderRight, setDisplayBorderRight] = useState(false);

  useEffect(() => {
    const controlEvent = (event) => {
      if (event.ctrlKey && event.key === KEY_Z.key) {
        const previewPage = window.location.href.indexOf('preview');
        if (previewPage < 0) {
          !canUndo && onUndo();
          setFirstItem(true);
          setDisplayBorderLeft(true);
          setTimeout(function () {
            setDisplayBorderLeft(false);
          }, 400);
        }
      }
      if (event.which === KEY_SHIFT.which && event.ctrlKey && event.shiftKey) {
        !canRedo && onRedo();
        setDisplayBorderRight(true);
        setTimeout(function () {
          setDisplayBorderRight(false);
        }, 400);
      }
    };
    document.addEventListener('keydown', controlEvent);
  }, [state]);

  useEffect(() => {
    if (state.past.length < 4) {
      onRedo();
    }
    if (firstItem) {
      firstItem && onUndo();
      let checkItem = state.past.map(
        (elem) =>
          (elem.focusedField || elem.focusedBlock) ===
          (state.present.focusedField || state.present.focusedBlock),
      );
      const count = {};
      checkItem.forEach((element) => {
        count[element] = (count[element] || 0) + 1;
      });
      if (count.true === 1) {
        onUndo();
      }
      setFirstItem(false);
    }
  }, [state, firstItem]);

  const onClickUndo = () => {
    onUndo();
    setFirstItem(true);
  };

  return (
    <>
      <button
        onClick={() => {
          onClickUndo();
        }}
        disabled={!canUndo}
        className={styles.buttonSvg}
        onMouseEnter={(e) => {
          setDisplayBorderLeft(true), e.preventDefault;
        }}
        onMouseLeave={(e) => {
          setDisplayBorderLeft(false), e.preventDefault;
        }}
      >
        <SVG
          glyph="arrow_cancel"
          style={{ width: '16px', height: '12px', opacity: displayBorderLeft ? '1' : '0.5' }}
        />
      </button>
      <button
        onClick={onRedo}
        className={styles.buttonSvg}
        style={{ transform: 'scaleX(-1)' }}
        onMouseEnter={(e) => {
          setDisplayBorderRight(true), e.preventDefault;
        }}
        onMouseLeave={(e) => {
          setDisplayBorderRight(false), e.preventDefault;
        }}
      >
        <SVG
          glyph="arrow_cancel"
          style={{ width: '16px', height: '12px', opacity: displayBorderRight ? '1' : '0.5' }}
        />
      </button>
    </>
  );
};
const mapStateToProps = (state) => ({
  canUndo: state.editForm.past.length > 0,
  canRedo: state.editForm.future.length > 0,
  state: state.editForm,
});

const mapDispatchToProps = {
  onUndo: UndoActionCreators.undo,
  onRedo: UndoActionCreators.redo,
};

Undo = connect(mapStateToProps, mapDispatchToProps)(Undo);

export default Undo;
