import React from 'react';
import PropTypes from 'prop-types';
import styles from './DrawerFooter.css';

function DrawerFooter({ children }) {
  return <div className={styles.DrawerFooter}>{children}</div>;
}

DrawerFooter.propTypes = {
  children: PropTypes.node,
};

export default DrawerFooter;
