export default function uniq(array) {
  const out = [];
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    if (out.includes(item)) {
      continue;
    }
    out.push(item);
  }
  return out;
}
