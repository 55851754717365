import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import GridBox from '../GridBox/GridBox';
import { getEmptyImage } from 'react-dnd-html5-backend';
import styles from './DraggableBox.css';
import DraggableBoxHandle from '../DraggableBoxHandle/DraggableBoxHandle';
import { classnames } from '../../../../helpers/classnames';

// Implements draggable behavior on top on grid box
function DraggableBox({ item, children, style, onMoveStart, onMoveEnd, ...props }) {
  const [handleDragging, setHandleDragging] = useState(false);
  const [{ isDragging }, dragRef, preview] = useDrag({
    item,
    options: {
      dropEffect: 'move',
    },
    collect(monitor) {
      return { isDragging: monitor.isDragging() };
    },
    begin() {
      onMoveStart && onMoveStart(item);
    },
    end() {
      onMoveEnd && onMoveEnd(item);
    },
  });

  const handleDragStart = useCallback(() => setHandleDragging(true), []);
  const handleDragEnd = useCallback(() => setHandleDragging(false), []);

  const finalStyle = useMemo(() => ({ ...style, opacity: isDragging || handleDragging ? 0 : 1 }), [
    isDragging,
    handleDragging,
    style,
  ]);

  // Disable drag n drop preview
  useEffect(() => {
    preview(getEmptyImage());
  }, []);

  return (
    <GridBox
      {...props}
      ref={dragRef}
      className={classnames([props.className, styles.DraggableBox])}
      style={finalStyle}
    >
      <DraggableBoxHandle
        boxId={item.id}
        direction="up"
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        className={styles.DraggableBox__handleTop}
      />
      <DraggableBoxHandle
        boxId={item.id}
        direction="down"
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        className={styles.DraggableBox__handleBottom}
      />
      <DraggableBoxHandle
        boxId={item.id}
        direction="left"
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        className={styles.DraggableBox__handleLeft}
      />
      <DraggableBoxHandle
        boxId={item.id}
        direction="right"
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        className={styles.DraggableBox__handleRight}
      />
      {children}
    </GridBox>
  );
}

DraggableBox.propTypes = {
  item: PropTypes.object,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  onMoveStart: PropTypes.func,
  onMoveEnd: PropTypes.func,
};

export default DraggableBox;
