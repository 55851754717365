import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './DraggableGridLayer.css';
import { useDragLayer } from 'react-dnd';
import GridContext from '../GridContext';
import { isOverlap, resizeGridItem, moveGridItem } from '../GridHelpers';

// This implements the draggable grid preview layer
function DraggableGridLayer({
  items,
  renderPreview: PreviewBox = () => null,
  parentGrid,
  scrollOffset,
  draggedChilds,
}) {
  const { item, isDragging, offset } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    offset: monitor.getClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  const previewItem = useMemo(() => {
    if (!parentGrid || !offset) {
      return null;
    }

    const offsetWithScroll = {
      x: offset.x + scrollOffset.x,
      y: offset.y + scrollOffset.y,
    };

    if (item.type === 'resize-handle') {
      // Find the item related to this resize handle
      const parentItem = items.find((parent) => parent.id === item.boxId);
      if (parentItem) {
        return {
          ...parentItem,
          ...resizeGridItem(parentItem, {
            ...item,
            ...moveGridItem(item, parentGrid, offsetWithScroll),
          }),
        };
      }
    }
    // Regular box item, juste move it
    return {
      ...item,
      ...moveGridItem(item, parentGrid, offsetWithScroll),
    };
  }, [item, parentGrid, offset, scrollOffset]);

  const canDrop = useMemo(
    () => previewItem && !isOverlap(previewItem, items, parentGrid, draggedChilds),
    [previewItem, items, draggedChilds],
  );

  if (!parentGrid || !isDragging) {
    return null;
  }

  return (
    <div className={styles.DraggableGridLayer} style={{ top: parentGrid.boundingRect.offsetTop }}>
      <GridContext.Provider value={parentGrid}>
        {previewItem && <PreviewBox item={previewItem} canDrop={canDrop} />}
      </GridContext.Provider>
    </div>
  );
}

DraggableGridLayer.propTypes = {
  scrollOffset: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  parentGrid: PropTypes.object,
  renderPreview: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.number,
  rowHeight: PropTypes.number,
  gutter: PropTypes.number,
  draggedChilds: PropTypes.array,
};

export default DraggableGridLayer;
