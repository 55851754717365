import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Form from '../../atoms/Form/Form';

function ContributeForm({ handleSubmit, children, onKeyDown }) {
  return (
    <Form onSubmit={handleSubmit} onKeyDown={onKeyDown}>
      {children}
    </Form>
  );
}

ContributeForm.propTypes = {
  handleSubmit: PropTypes.func,
  onBlur: PropTypes.func,
  children: PropTypes.node,
  onKeyDown: PropTypes.func,
};

export default reduxForm({
  form: 'contribute',
})(ContributeForm);
