import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Grid from '../../../organisms/Grid/Grid';
import AdaptableField from '../../../molecules/Adaptable/AdaptableField';
import styles from './ContributeFormNodeField.css';
import deepValueObject from '../../../../helpers/deepValueObject';
import { GPSCOORDINATES, NUMBER } from '../../../../constants/typology/fieldType';
import { DATA_TYPE_LIST } from '../../../../constants/typology/dataType';
import { get } from 'object-path';

export default function ContributeFormNodeField({
  node,
  boxOverride,
  parentFieldName = '',
  readonly,
  answersValues,
  reviewTree,
}) {
  // Take boxOverrides into account when computing the final box
  const realBox = useMemo(
    () => ({
      ...node.box,
      y: boxOverride ? boxOverride.y : node.box.y,
    }),
    [node, boxOverride && boxOverride.y],
  );

  const name = `${parentFieldName}${node.id}`;

  const reviewData = useMemo(() => get(reviewTree, name.replace(/\[([\d]+)\]\./g, '.$1.')), [
    reviewTree,
    name,
  ]);

  const answersChoices = useMemo(() => {
    if (reviewData?.answers) {
      return reviewData.answers.map((choice) => ({
        value: choice,
        label: choice ? choice.toString() : '',
      }));
    }
    return (
      answersValues &&
      answersValues.reduce((result, initialValues) => {
        const value = deepValueObject(initialValues, name);
        let label = value;

        if (node.type === GPSCOORDINATES && value) {
          label = Object.values(value).join(', ');
        }

        return value
          ? [...result, { value, label: Array.isArray(label) ? label.join(', ') : label }]
          : [];
      }, [])
    );
  }, [answersValues]);

  return (
    <Grid.Box {...realBox} className={styles.ContributeFormNodeField}>
      <AdaptableField
        isFocusOnInit={node?.isFocusOnInit}
        name={DATA_TYPE_LIST.includes(node.type) ? node.id : name}
        answersChoices={answersChoices}
        fieldType={node.type}
        label={node.label}
        validation={node}
        verticalAlign={true}
        isBignumber={node.type === NUMBER ? true : undefined}
        readonly={readonly}
        confidence={reviewData?.confidence}
      />
    </Grid.Box>
  );
}

ContributeFormNodeField.defaultProps = {
  answersValues: null,
  change: () => null,
};

ContributeFormNodeField.propTypes = {
  answersValues: PropTypes.array,
  node: PropTypes.object,
  boxOverride: PropTypes.object,
  parentFieldName: PropTypes.string,
  readonly: PropTypes.bool,
  reviewTree: PropTypes.object,
};
