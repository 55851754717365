import React from 'react';
import PropTypes from 'prop-types';
import styles from './SelectTag.css';
import SVG from '../SVG/SVG';

function SelectTag({ children, onRemove }) {
  return (
    <div className={styles.SelectTag}>
      {children}
      <button type="button" className={styles.SelectTag__remove} onClick={onRemove}>
        <SVG className={styles.SelectTag__removeIcon} glyph="circled_remove" />
      </button>
    </div>
  );
}

SelectTag.propTypes = {
  onRemove: PropTypes.func,
  children: PropTypes.node,
};

export default SelectTag;
