import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon/Icon';
import styles from './Item.css';
import { bem } from '../../../../helpers/styles';
import { classnames } from '../../../../helpers/classnames';
import SVG from '../../SVG/SVG';

function Item({ color, icon, children, className, iconRight, ...restProps }) {
  return (
    <li className={classnames([bem(styles, 'Item'), className])} {...restProps}>
      {icon && <Icon color={color} icon={icon} />}
      <span className={styles.Item__label} title={children}>
        {children}
      </span>
      {iconRight && <SVG className={styles.Item__iconRight} glyph={iconRight} />}
    </li>
  );
}

Item.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  iconRight: PropTypes.string,
};

export default Item;
