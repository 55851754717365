import React from 'react';
import PropTypes from 'prop-types';
import styles from './DrawerContent.css';

function DrawerContent({ children }) {
  return <div className={styles.DrawerContent}>{children}</div>;
}

DrawerContent.propTypes = {
  children: PropTypes.node,
};

export default DrawerContent;
