export const SHORTTEXT = 'text_short';
export const NUMBER = 'number';
export const SELECT = 'simpleselect';
export const ADVANCEDSELECT = 'select';
export const RANGE = 'range';
export const SWITCH = 'switch';
export const SWITCHTOGGLE = 'switch_toggle';
export const RICHTEXT = 'text';
export const EMAIL = 'email';
export const LATITUDE = 'latitude';
export const LONGITUDE = 'longitude';
export const GPSCOORDINATES = 'coordinates_gps';
export const LINK = 'link';
export const VIDEO = 'video';
export const MCQ = 'mcq';
export const TEXTAREA = 'textarea';
export const PHONENUMBER = 'phonenumber';
export const URL = 'url';
export const DATETIME = 'datetime';
export const DATETIMERANGE = 'datetimerange';
export const DATE = 'date';
export const TIME = 'time';
export const ADDRESS = 'address';
export const FILEUPLOAD = 'file';
export const RIB = 'rib';

export const FIELD_TYPE_LIST = [
  SHORTTEXT,
  NUMBER,
  SELECT,
  RANGE,
  SWITCH,
  SWITCHTOGGLE,
  RICHTEXT,
  EMAIL,
  GPSCOORDINATES,
  ADDRESS,
  RIB,
  LINK,
  VIDEO,
  MCQ,
  TEXTAREA,
  PHONENUMBER,
  URL,
  ADVANCEDSELECT,
  DATETIMERANGE,
  DATETIME,
  DATE,
  TIME,
  FILEUPLOAD,
];
