import {
  TASK_ANSWER,
  TASK_ANSWER_SUCCESS,
  TASK_FORM_KEEP_SUCCESS,
  TASK_GET_SUCCESS,
} from '../actions/task';

export const initialState = {
  hasReceivedNullTask: false,
  currentTask: null,
  lastTaskSent: null,
  buffer: [],
};

export default function taskReducer(state = initialState, action) {
  switch (action.type) {
    case TASK_GET_SUCCESS:
      if (action.payload === null) {
        return {
          ...state,
          hasReceivedNullTask: true,
        };
      }
      return {
        ...state,
        [action.payload.projectId]: action.payload,
        currentTask: state.currentTask ? state.currentTask : action.payload,
        buffer: state.currentTask ? [...state.buffer, action.payload] : state.buffer,
      };
    case TASK_ANSWER: {
      return {
        ...state,
        lastTaskSent: action.fetch.json.IdTask,
      };
    }
    case TASK_ANSWER_SUCCESS: {
      const bufferCopy = state.buffer.slice(0);
      return {
        ...state,
        currentTask: bufferCopy.length ? bufferCopy[0] : null,
        buffer: bufferCopy.slice(1),
      };
    }
    case TASK_FORM_KEEP_SUCCESS: {
      const { taskId } = action.meta;
      const expiresAt = Date.parse(action.payload.EndDate);
      return {
        ...state,
        currentTask:
          state.currentTask.taskId === taskId
            ? { ...state.currentTask, expiresAt }
            : state.currentTask,
        buffer: state.buffer.map((task) =>
          task.taskId === taskId ? { ...task, expiresAt } : task,
        ),
      };
    }
    default:
      return state;
  }
}
