import i18n from 'i18next';
import { DATA_TYPE_LIST, NUM, TEXT } from '../constants/typology/dataType';
import { slugify } from './slugify';
import i18next from 'i18next';
import { FIELD_REFERENCE } from '../constants/formula';
import cssvars from '../styles/variables';
import { getTokenSymbol } from './formula';
import {
  ADDRESS,
  ADVANCEDSELECT,
  DATE,
  EMAIL,
  GPSCOORDINATES,
  MCQ,
  NUMBER,
  PHONENUMBER,
  RICHTEXT,
  SHORTTEXT,
  URL,
  RIB,
} from '../constants/typology/fieldType';

// Generates manifest entry base on data fields
export function generateDataManifest(form) {
  return form.fields
    .filter((field) => field.injectChoices || DATA_TYPE_LIST.includes(field.type))
    .map((field) => ({
      field: field.id,
      type: field.type,
    }));
}

export function updateRuleReferences(rule, idMap) {
  return {
    ...rule,
    blockScope: rule.blockScope ? idMap.get(rule.blockScope) : null,
    formula: (rule?.formula || []).map((el) =>
      el.type === FIELD_REFERENCE
        ? {
            ...el,
            field: idMap.get(el.field),
            path: idMap.get(el.path) || el.path,
            blockScope: idMap.get(el.blockScope) || el.blockScope,
          }
        : el,
    ),
  };
}

// Transform field ids to something more readable by humans
export function transformToHumanNames(form) {
  const map = new Map();
  // Slugify all fields
  const outForm = {
    ...form,
    fields: form.fields.map((field, index) => {
      const baseName = field.label
        ? field.label
        : DATA_TYPE_LIST.includes(field.type)
        ? 'data'
        : 'field';
      const newId = `${slugify(baseName)}_${index}`;
      map.set(field.id, newId);
      return {
        ...field,
        // basename makes it human readable and index ensure collision avoidance
        id: newId,
        iterationOverloads: [],
      };
    }),
    blocks: form.blocks.map((block, index) => {
      const newId = `${block.name ? slugify(block.name) : 'block'}_${index}`;
      const returnValue = {
        ...block,
        id: newId,
        compareFields: [],
      };
      map.set(block.id, newId);
      return returnValue;
    }),
  };

  // Update references in validation rules
  outForm.validationRules = (outForm.validationRules || []).map((rule) =>
    updateRuleReferences(rule, map),
  );

  outForm.iterationOverloads = (outForm.iterationOverloads || []).map((rule) => {
    const updatedRule = updateRuleReferences(rule, map);

    return {
      ...updatedRule,
      // Extract formula data for wirk
      field: updatedRule.formula[0]?.field,
      operator: rule.formula[1] ? getTokenSymbol(rule.formula[1]) : null,
      value: rule.formula[2] ? getTokenSymbol(rule.formula[2]) : null,
    };
  });

  // Update references in fields & blocks
  outForm.fields = outForm.fields.map((field) =>
    field.hideIf
      ? {
          ...field,
          hideIf: updateRuleReferences(field.hideIf, map),
        }
      : field,
  );

  outForm.fields = outForm.fields.map((field) =>
  (field.numeric)
    ? {
        ...field,
        type: NUM,
      }
    : (!field.numeric && field.type === NUM ) ? {
      ...field,
      type: TEXT,
    } :{
      ...field,
      type: field.type,
    },
);

  // Also update sorting rules for blocks
  outForm.blocks = outForm.blocks.map((block) => ({
    ...block,
    hideIf: block.hideIf ? updateRuleReferences(block.hideIf, map) : null,
    sortingRules: block.sortingRules
      ? block.sortingRules.map((rule) => rule.map((fieldId) => map.get(fieldId)))
      : null,
  }));

  // Replace fields to compare / not to compare ids
  if (outForm?.compareFields) {
    // Map compare fields at block level
    outForm.compareFields.forEach((compareFieldsRule) => {
      const blockScope = map.get(compareFieldsRule?.blockScope) || compareFieldsRule?.blockScope;

      if (!blockScope) {
        return;
      }

      const block = outForm.blocks.find((block) => block.id === blockScope);
      if (block) {
        block.compareFields = [
          ...(block.compareFields || []),
          compareFieldsRule.fields.map((fieldId) => map.get(fieldId)),
        ];
      }
    });

    // Map root compareFields
    outForm.compareFields = outForm.compareFields.map((compareField) => {
      return {
        ...compareField,
        blockScope: compareField?.blockScope
          ? map.get(compareField?.blockScope) || compareField?.blockScope
          : null,
        fields: compareField?.fields.map((id) => map.get(id) || id),
      };
    });
    //outForm.compareFields = outForm.compareFields?.filter((field) => !field.blockScope);
  }

  // Same for not to compare fields
  if (outForm?.noCompareFields) {
    outForm.noCompareFields = outForm.noCompareFields.map((id) => map.get(id) || id);
  }

  // Attach iteration rules to the relevant fields
  if (form.iterationOverloads) {
    for (let i = 0; i < form.iterationOverloads.length; i++) {
      const iterationOverloadRule = form.iterationOverloads[i];
      if (!iterationOverloadRule?.formula[0]) {
        continue;
      }

      const fieldId = map.get(iterationOverloadRule.formula[0].field);
      const field = outForm.fields.find((field) => field.id === fieldId);

      if (!field) {
        // eslint-disable-next-line no-console
        console.warn(`Iteration overload field not found: "${fieldId}"`);
        continue;
      }

      iterationOverloadRule.formula[0].field = fieldId;
      iterationOverloadRule.operator = getTokenSymbol(iterationOverloadRule.formula[1]);
      iterationOverloadRule.value = JSON.parse(getTokenSymbol(iterationOverloadRule.formula[2]));
      field.iterationOverloads = [...(field.iterationOverloads || []), iterationOverloadRule];
    }
  }
  return outForm;
}

export function validateForm(form) {
  const errors = [];

  if (!form.name) {
    errors.push(i18next.t(`form.errors.title`));
  }

  if (!form.quality?.respondentPerTask) {
    errors.push(i18next.t(`form.errors.respondentPerTask`));
  }

  if (!form.minimumDuration) {
    errors.push(i18next.t(`form.errors.minimumDuration`));
  }

  if (!form.averageDuration) {
    errors.push(i18next.t(`form.errors.averageDuration`));
  }
  if (
    form.fields.map((elem) => elem.type === "select" || elem.type ==="mcq")
  ) {
    form.fields.map(elem1 => {
      if((elem1.type === "select" && elem1.choices && elem1.injectLabelValue) || (elem1.type ==="mcq" && elem1.choices && elem1.injectLabelValue)) {
        let splitText = elem1.choices.split('\n');
        let checkTest = splitText.map((elem2) => elem2.search(elem1.injectKeyValueSeparator));
        checkTest.map((elem3) => {
          if (elem3 < 0) errors.push(i18next.t(`form.errors.errorChoicesSelectorMcq`));
        });
      }
    });
  }

  // Check that duplicable blocks have at least one sorting rule
  for (let i = 0; i < form.blocks.length && form?.quality?.strategy === 'multi'; i++) {
    const block = form.blocks[i];
    if (
      block.duplicable &&
      (!block.sortingRules ||
        block.sortingRules.reduce((totalFields, fields) => totalFields + fields.length, 0) === 0)
    ) {
      errors.push(i18next.t(`form.errors.missingBlockSortingRules`, { block: block.name }));
    }
  }

  // Check that iteration overloads have a correct iteration parameter
  if (form?.quality?.strategy === 'multi') {
    const { iterationCount, respondentPerTask } = form.quality;
    if (iterationCount > respondentPerTask && form.iterationOverloads?.length) {
      const diff = iterationCount - respondentPerTask;
      for (let i = 0; i < form.iterationOverloads.length; i++) {
        const iterationOverload = form.iterationOverloads[i];
        if (
          iterationOverload.minMatch < respondentPerTask ||
          iterationOverload.minMatch > respondentPerTask + diff
        ) {
          errors.push(
            i18next.t(`form.errors.iterationOverloadOutOfRange`, { rule: iterationOverload.name }),
          );
        }
      }
    }
  }

  return errors;
}

export const getFirstInput = (form) => {
  if (!form.fields.length) {
    return null;
  }
  const firstX = form.fields.reduce((min, b) => Math.min(min, b.box.x), form.fields[0].box.x);
  const lefties = form.fields.filter((field) => field.box.x === firstX);
  if (lefties.length === 1) {
    return lefties[0].id;
  } else {
    const firstLefties = lefties.find(
      (field) =>
        field.box.y === lefties.reduce((min, b) => Math.min(min, b.box.y), lefties[0].box.y),
    );
    return firstLefties.id;
  }
};

export function getConfidenceColor(confidence) {
  switch (confidence) {
    case 10:
      return cssvars['color-confidence-10'];
    case 20:
      return cssvars['color-confidence-20'];
    case 30:
      return cssvars['color-confidence-30'];
    case 40:
      return cssvars['color-confidence-40'];
    default:
      return undefined;
  }
}

export function getConfidenceFieldStyle(confidence) {
  const borderColor = getConfidenceColor(confidence);
  return borderColor ? { borderColor } : undefined;
}

export function getDeliveryAnswerOptions(typologyType) {
  let base = [
    { value: 1 },
    { value: 2 },
    { value: 3 },
    { value: 4 },
    { value: 5 },
    { value: 6 },
    { value: 7 },
    { value: 8 },
    { value: 9 },
  ];

  if (typologyType) {
    switch (typologyType) {
      case SHORTTEXT:
      case RICHTEXT:
      case RIB:
      case EMAIL:
      case URL:
      case PHONENUMBER:
        base = base.filter((option) => [1, 7].includes(option.value));
        break;
      case MCQ:
      case ADVANCEDSELECT:
        base = base.filter((option) => [1, 3, 4, 5].includes(option.value));
        break;
      case NUMBER:
      case GPSCOORDINATES:
      case ADDRESS:
      case DATE:
        base = base.filter((option) => [1, 7, 6].includes(option.value));
        break;
      case 'iterationOverload':
        base = base.filter((option) => [1, 7, 8].includes(option.value));
        break;
      case 'block':
        base = base.filter((option) => [1, 3, 4, 5].includes(option.value));
        break;
      default:
        break;
    }
  }

  return base.map(({ value }) => ({
    value,
    label: i18n.t(`comparisonRules.options.options.${value}`),
  }));
}
