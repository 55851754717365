import React from 'react';
import PropTypes from 'prop-types';
import List from '../../atoms/list/List';
import DraggableFieldListItem from './Item/Item';

function DraggableFieldList({ children }) {
  return <List>{children}</List>;
}

DraggableFieldList.propTypes = {
  children: PropTypes.node,
};

DraggableFieldList.Item = DraggableFieldListItem;

export default DraggableFieldList;
