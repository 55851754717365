import React from 'react';
import styles from './DrawerContentNoCompare.css';
import useTranslate from '../../../../../../hooks/useTranslate';
import QualityNoCompareTemplate from '../../../../../templates/QualityNoCompareTemplate/QualityNoCompareTemplate';
import QualitySelectTemplate from '../../../../../templates/QualitySelectTemplate/QualitySelectTemplate';

function DrawerContentNoCompare() {
  const i18n = useTranslate();
  return (
    <>
      <QualitySelectTemplate icon="nocompare" titleKey="QualityNavTemplate.noCompare" />
      <div className={styles.DrawerContentNoCompare}>
        <h3 className={styles.DrawerContentNoCompare__title}>
          {i18n('DrawerContentNoCompare.title')}
        </h3>
        <p className={styles.DrawerContentNoCompare__description}>
          {i18n('DrawerContentNoCompare.description')}
        </p>
        <QualityNoCompareTemplate />
      </div>
    </>
  );
}

export default DrawerContentNoCompare;
