import shallowCompare from '../../helpers/shallowCompare';
import { OPTIONS_GET, OPTIONS_SUCCESS } from '../actions/options';

// Store options fetched remotly
export const initialState = [];

export default function optionsReducer(state = initialState, action) {
  switch (action.type) {
    case OPTIONS_GET:
      return [
        ...state,
        {
          fetchOptions: action.payload,
          pending: true,
          result: null,
        },
      ];
    case OPTIONS_SUCCESS: {
      const { fetchOptions, options } = action.payload;
      return state.map((optionState) =>
        shallowCompare(optionState.fetchOptions, fetchOptions)
          ? {
              ...optionState,
              pending: false,
              options,
            }
          : optionState,
      );
    }
    default:
      return state;
  }
}
