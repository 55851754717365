import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import Select from '../../../atoms/Select/Select';

function SelectFieldRender({
  input,
  label,
  placeholder,
  options,
  id,
  meta: { error, touched },
  disabled,
}) {
  return (
    <Select
      {...input}
      error={touched && error}
      label={label}
      id={id}
      block
      placeholder={placeholder}
      options={options}
      disabled={disabled}
    />
  );
}

SelectFieldRender.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  disabled: PropTypes.bool,
};

function SelectField({ name, label, ...props }) {
  const id = useMemo(() => `name_${uuid()}`, [name]);
  return (
    <FormField label={label} name={name} htmlFor={id}>
      <Field name={name} component={SelectFieldRender} id={id} {...props} />
    </FormField>
  );
}

SelectField.propTypes = { name: PropTypes.string, label: PropTypes.string };

export default SelectField;
