import React from 'react';
import Drawer from '../../Drawer/Drawer';
import DrawerNavTemplate from '../../../templates/DrawerNavTemplate/DrawerNavTemplate';
import DrawerHeaderFormPage from '../../../pages/Drawer/Header/Form/DrawerHeaderForm';
import DrawerFooterFormPage from '../../../pages/Drawer/Footer/DrawerFooter';

import { Switch, Route } from 'react-router';
import {
  PATH_SETTINGS_FORM,
  PATH_EDITFIELDS_FORM,
  PATH_DATA_FORM,
  PATH_INFO_FORM,
  PATH_BLOCKS_FORM,
  PATH_QUALITY_FORM,
  PATH_RESPONDANTS_FORM,
  PATH_STRATEGY_FORM,
  PATH_FIELD_NO_COMPARE,
  PATH_FIELD_TO_COMPARE,
  PATH_BLOCKS_SORTING,
  PATH_KEYBOARD_SHORTCUTS,
  PATH_VALIDATION_FORM,
  PATH_ADVANCED_FIELD_TO_COMPARE,
  PATH_ITERATION_OVERLOAD,
} from '../../../../constants/paths';
import DrawerContentPage from '../../../pages/Drawer/Content/DrawerContent';
import DrawerContentSettingsPage from '../../../pages/Drawer/Content/Settings/DrawerContentSettings';
import DrawerContentInfo from '../../../pages/Drawer/Content/Info/DrawerContentInfo';
import DrawerContentBlocks from '../../../pages/Drawer/Content/Blocks/DrawerContentBlocks';
import DrawerContentQualityPage from '../../../pages/Drawer/Content/Quality/DrawerContentQuality';
import DrawerContentRespondantsPage from '../../../pages/Drawer/Content/Quality/Respondants/DrawerContentRespondants';
import DrawerContentStrategyPage from '../../../pages/Drawer/Content/Quality/Strategy/DrawerContentStrategy';
import DrawerContentValidation from '../../../pages/Drawer/Content/Validation/DrawerContentValidation';
import DrawerContentNoComparePage from '../../../pages/Drawer/Content/Quality/NoCompare/DrawerContentNoComparePage';
import DrawerContentToComparePage from '../../../pages/Drawer/Content/Quality/ToCompare/DrawerContentToCompare';
import DrawerContentBlocksSortingPage from '../../../pages/Drawer/Content/Quality/BlocksSorting/DrawerContentBlocksSorting';
import DrawerContentKeyboardShortcuts from '../../../pages/Drawer/Content/KeyboardShortcuts/KeyboardShortcuts';
import AdvancedToComparePage from '../../../pages/Drawer/Content/Quality/AdvancedToCompare/AdvancedToCompare';
import DrawerIterationOverload from '../../../pages/Drawer/Content/Quality/IterationOverload/IterationOverload';

function AppDrawer() {
  return (
    <Drawer>
      <DrawerNavTemplate />
      <Drawer.Body>
        <Drawer.Header>
          <Switch>
            <Route component={DrawerHeaderFormPage} />
          </Switch>
        </Drawer.Header>
        <Drawer.Content>
          <Switch>
            <Route path={[PATH_EDITFIELDS_FORM, PATH_DATA_FORM]} component={DrawerContentPage} />
            <Route path={PATH_BLOCKS_FORM} component={DrawerContentBlocks} />
            <Route path={PATH_INFO_FORM} component={DrawerContentInfo} />
            <Route path={PATH_QUALITY_FORM} exact component={DrawerContentQualityPage} />
            <Route path={PATH_RESPONDANTS_FORM} component={DrawerContentRespondantsPage} />
            <Route path={PATH_STRATEGY_FORM} component={DrawerContentStrategyPage} />
            <Route path={PATH_SETTINGS_FORM} component={DrawerContentSettingsPage} />
            <Route path={PATH_VALIDATION_FORM} component={DrawerContentValidation} />
            <Route path={PATH_FIELD_NO_COMPARE} component={DrawerContentNoComparePage} />
            <Route path={PATH_FIELD_TO_COMPARE} component={DrawerContentToComparePage} />
            <Route path={PATH_BLOCKS_SORTING} component={DrawerContentBlocksSortingPage} />
            <Route path={PATH_KEYBOARD_SHORTCUTS} component={DrawerContentKeyboardShortcuts} />
            <Route path={PATH_ADVANCED_FIELD_TO_COMPARE} component={AdvancedToComparePage} />
            <Route path={PATH_ITERATION_OVERLOAD} component={DrawerIterationOverload} />
          </Switch>
        </Drawer.Content>
        <Drawer.Footer>
          <Switch>
            <Route component={DrawerFooterFormPage} />
          </Switch>
        </Drawer.Footer>
      </Drawer.Body>
    </Drawer>
  );
}

AppDrawer.propTypes = {};

export default AppDrawer;
