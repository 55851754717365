import React from 'react';
import PropTypes from 'prop-types';
import styles from './DrawerVerticalNav.css';
import BRAND_IMG_URL from '../../../../assets/images/brand-mini.png';
import Button from '../../../atoms/Button/Button';
import * as config from '../../../../helpers/config';

function DrawerVerticalNav({ children }) {
  return (
    <nav className={styles.DrawerVerticalNav}>
      <img src={BRAND_IMG_URL} className={styles.DrawerVerticalNav__brand} />
      <Button
        icon="arrow_left"
        flat
        color="white"
        className={styles.DrawerVerticalNav__back}
        as="a"
        href={config.getUrlRedirection('urls.desk.backnav')}
      />
      <div className={styles.DrawerVerticalNav__links}>{children}</div>
    </nav>
  );
}

DrawerVerticalNav.propTypes = {
  children: PropTypes.node,
};

export default DrawerVerticalNav;
