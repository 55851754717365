import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import SwitchField from '../../molecules/Field/Switch/SwitchField';
import FormulaField from '../../molecules/Field/Formula/FormulaField';
import { useDispatch, useSelector } from 'react-redux';
import { change, Field, formValueSelector } from 'redux-form';
import useTranslate from '../../../hooks/useTranslate';
import { createToast } from '../../../store/actions/toast';
import {
  formBlockSelector,
  formBlocksSelector,
  formFieldSelector,
  formFieldsSelector,
  selectFieldsSelector,
} from '../../../store/selectors/editForm';
import { ERROR } from '../../../constants/toastType';
import { FIELD_REFERENCE } from '../../../constants/formula';
import { getSmallestBlock, isInside } from '../../../helpers/formTree';
import { setSelectFields, setSelectMode } from '../../../store/actions/editForm';

function HideRuleFragment({ form: reduxFormId, isBlock }) {
  const i18n = useTranslate();

  const { id, hideIf } = useSelector((state) =>
    formValueSelector(reduxFormId)(
      state,
      'id',
      'hideIf.enabled',
      'hideIf.formula',
      'hideIf.blockScope',
    ),
  );
  const block = useSelector(formBlockSelector(id));
  const fields = useSelector(formFieldsSelector);
  const blocks = useSelector(formBlocksSelector);
  const field = useSelector(formFieldSelector(id));
  const selectedFields = useSelector(selectFieldsSelector);
  const dispatch = useDispatch();

  const handleAddField = useCallback(
    (addedField) => {
      // Clear fields select in any case
      dispatch(setSelectFields([]));

      if (!hideIf?.enabled) {
        // Hide if not enabled, or hideIf does not exists early exit condition
        return;
      }

      const addToFormula = (duplicableValue) => {
        const blockScope = getSmallestBlock(addedField, blocks, { duplicable: true })?.id;
        dispatch(
          change(reduxFormId, 'hideIf', {
            ...hideIf,
            blockScope,
            formula: [
              ...(hideIf.formula || []),
              {
                type: FIELD_REFERENCE,
                typology: addedField.type,
                field: addedField.id,
                duplicableResolution: duplicableValue,
                blockScope: blockScope,
              },
            ],
          }),
        );
        // Reset selection mode
        dispatch(setSelectMode(false));
      };

      //////////////////////////////////////////////
      if (isBlock) {
        if (isInside(block.box, addedField.box)) {
          createToast(ERROR, i18n('HideRuleFragment.error.subFieldReference'));
        } else {
          addToFormula();
        }
      } else {
        if (
          addedField ||
          (blocks && blocks.map((elem) => isInside(elem.box, addedField.box)).includes(true))
        ) {
          let blockInsideAddedField =
            blocks && blocks.map((elem) => isInside(elem.box, addedField.box));
          let blockUpandEqualAddedField =
            blockInsideAddedField &&
            blockInsideAddedField
              .map((elem, index) => {
                return elem === true && blocks[index];
              })
              .filter((el) => el != false);
          let blockUpEqualDuplicableAddedField =
            blockUpandEqualAddedField && blockUpandEqualAddedField.filter((el) => el.duplicable);
          if (blockUpEqualDuplicableAddedField.length > 0) {
            let blockInside = blocks && blocks.map((elem) => isInside(elem.box, field.box));
            let blockdependant =
              blockInside &&
              blockInside
                .map((elem, index) => {
                  return elem === true && blocks[index];
                })
                .filter((el) => el != false);
            let isBlockDuplicable = blockdependant && blockdependant.filter((el) => el.duplicable);
            if (isBlockDuplicable.length === 0) {
              field.hideStatus = 'sum_number';
              addToFormula('sum');
            }
          } else {
            if (!isBlock && addedField.id === id) {
              // Can't self-add as masked field
              createToast(ERROR, i18n('HideRuleFragment.error.selfReference'));
            } else if (isBlock) {
              if (isInside(block.box, addedField.box)) {
                createToast(ERROR, i18n('HideRuleFragment.error.subFieldReference'));
              } else {
                addToFormula();
              }
            } else {
              addToFormula();
            }
          }
        }
        ////////////////////////////////////////////

        // If field to hide is in a group
        if (field && blocks && blocks.map((elem) => isInside(elem.box, field.box)).includes(true)) {
          if (!isBlock && addedField.id === id) {
            createToast(ERROR, i18n('HideRuleFragment.error.selfReference'));
          } else {
            let blockInsideField = blocks && blocks.map((elem) => isInside(elem.box, field.box));
            let blockUpandEqual =
              blockInsideField &&
              blockInsideField
                .map((elem, index) => {
                  return elem === true && blocks[index];
                })
                .filter((el) => el != false);
            let blockUpEqualDuplicable =
              blockUpandEqual && blockUpandEqual.filter((el) => el.duplicable);
            let allDependantBlocks = blocks
              .filter((block) => isInside(blockUpandEqual[0].box, block.box))
              .map((block) => block);

            //if field to hide is in a duplicable group or children to duplicable groups :
            if (blockUpEqualDuplicable.length > 0) {
              let blockInsideSelectedField = blocks.map((elem) =>
                isInside(elem.box, addedField.box),
              );
              let blockUpandEqualSelectField =
                blockInsideSelectedField &&
                blockInsideSelectedField
                  .map((elem, index) => {
                    return elem === true && blocks[index];
                  })
                  .filter((el) => el != false);
              let blockSelectUpEqualDuplicable =
                blockUpandEqualSelectField &&
                blockUpandEqualSelectField.filter((el) => el.duplicable);

              let checkBlockUpandEqualSelectField =
                blockUpandEqualSelectField &&
                blockUpandEqualSelectField.map((elem) =>
                  blockUpandEqual.some((element) => {
                    if (element.id === elem.id) {
                      return true;
                    }
                    return false;
                  }),
                );
              //if field to hide isn't in duplicable group
              if (blockSelectUpEqualDuplicable.length === 0) {
                addToFormula();
              } else if (
                blockSelectUpEqualDuplicable.length > 0 &&
                blockUpandEqualSelectField[0] !== blockUpandEqual[0]
              ) {
                createToast(ERROR, i18n('HideRuleFragment.error.forbidden'));
              } else if (!checkBlockUpandEqualSelectField.includes(false)) {
                field.hideStatus = 'onlyforeach';
                addToFormula(null);
              } else if (
                blockUpandEqualSelectField.length > 0 &&
                isInside(
                  blockUpandEqual[blockUpandEqual.length - 1].box,
                  blockUpandEqualSelectField[blockUpandEqualSelectField.length - 1].box,
                )
              ) {
                field.hideStatus = 'sum_number';
                addToFormula('sum');
              } else {
                createToast(ERROR, i18n('HideRuleFragment.error.forbidden'));
              }
            } else {
              if (!isBlock && addedField.id === id) {
                // Can't self-add as masked field
                createToast(ERROR, i18n('HideRuleFragment.error.selfReference'));
              } else if (isBlock) {
                if (isInside(block.box, addedField.box)) {
                  createToast(ERROR, i18n('HideRuleFragment.error.subFieldReference'));
                } else {
                  addToFormula();
                }
              }
            }
          }
        }
      }
    },
    [id, hideIf, field, block, isBlock, fields],
  );

  useEffect(() => {
    // When a field is selected, try to add it to formula
    selectedFields.length && handleAddField(selectedFields[0]);
  }, [handleAddField, selectedFields]);

  return (
    <>
      <SwitchField name="hideIf.enabled" label={i18n('HideRuleFragment.enabled')} />
      {hideIf?.enabled ? (
        <FormulaField name="hideIf.formula" onAddField={() => dispatch(setSelectMode(true))} />
      ) : (
        <Field name="hideIf.formula" component="input" type="hidden" />
      )}
    </>
  );
}

HideRuleFragment.propTypes = {
  form: PropTypes.string,
  isBlock: PropTypes.bool,
};

export default HideRuleFragment;
