import React, { useMemo, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import FormField from '../../../atoms/Form/Field/FormField';
import { Field } from 'redux-form';
import Input from '../../../atoms/Input/Input';
import FieldError from '../../Error/FieldError';
import { LATITUDE, LONGITUDE } from '../../../../constants/typology/fieldType';
import { getConfidenceFieldStyle } from '../../../../helpers/form';

function GPSCoordinatesFieldRender({
  id,
  value,
  input,
  readonly,
  confidence,
  isFocusOnInit,
  meta: { error, touched },
  ...props
}) {
  const { onChange, name, onBlur } = input;
  const { latitude, longitude } = input.value || value;
  const nameLongitude = useMemo(() => `${name}.longitude`, [name]);
  const nameLatitude = useMemo(() => `${name}.latitude`, [name]);
  const parse = useCallback((val) => (val ? val : null), []);

  const handleChangeLatitude = (val) => {
    onChange({ latitude: parse(val), longitude });
    onBlur();
  };

  const handleChangeLongitude = (val) => {
    onChange({ latitude, longitude: parse(val) });
    onBlur();
  };

  const style = useMemo(() => (!touched && getConfidenceFieldStyle(confidence)) || {}, [
    confidence,
    touched,
  ]);

  return (
    <div
      {...props}
      value={input.value || value}
      id={id}
      name={name}
      className={style.GPSCoordinatesField}
    >
      <Input
        mode="number"
        step="any"
        isFocusOnInit={isFocusOnInit}
        {...input}
        className={style?.GPSCoordinatesField__input}
        readonly={readonly}
        onChange={handleChangeLatitude}
        value={latitude}
        name={nameLatitude}
        error={((error && !error?.fieldType) || error?.fieldType === LATITUDE) && touched}
        placeholder={LATITUDE}
        id={id}
        block
        style={style}
      />
      <Input
        mode="number"
        step="any"
        {...input}
        className={style?.GPSCoordinatesField__input}
        readonly={readonly}
        onChange={handleChangeLongitude}
        value={longitude}
        name={nameLongitude}
        error={((error && !error?.fieldType) || error?.fieldType === LONGITUDE) && touched}
        placeholder={LONGITUDE}
        id={id}
        block
        style={style}
      />
      {error && touched && <FieldError error={error} />}
    </div>
  );
}

GPSCoordinatesFieldRender.defaultProps = {
  value: {
    longitude: null,
    latitude: null,
  },
};

GPSCoordinatesFieldRender.propTypes = {
  isFocusOnInit: PropTypes.object,
  input: PropTypes.object,
  label: PropTypes.string,
  validation: PropTypes.object,
  id: PropTypes.string,
  value: PropTypes.shape({
    longitude: PropTypes.number,
    latitude: PropTypes.number,
  }),
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  confidence: PropTypes.number,
  readonly: PropTypes.bool,
};

function GPSCoordinatesField({
  name,
  label,
  required,
  information,
  fieldHoC: FieldHOC,
  answersChoices,
  ...props
}) {
  const id = useMemo(() => `name_${uuid()}`, [name]);

  return (
    <FormField
      label={label}
      name={name}
      htmlFor={id}
      information={information}
      required={required}
      answersChoices={answersChoices}
      verticalAlign={true}
    >
      <FieldHOC name={name} component={GPSCoordinatesFieldRender} id={id} {...props} />
    </FormField>
  );
}

GPSCoordinatesField.defaultProps = {
  fieldHoC: Field,
  answersChoices: null,
};

GPSCoordinatesField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  information: PropTypes.string,
  fieldHoC: PropTypes.func,
  answersChoices: PropTypes.array,
};

export default GPSCoordinatesField;
