import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './Tag.css';

function Tag({ children, color }) {
  const style = useMemo(
    () => ({
      backgroundColor: color,
      color: 'rgba(0,0,0,.3)',
    }),
    [color],
  );

  return (
    <span className={styles.Tag} style={style}>
      {children}
    </span>
  );
}

Tag.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
};

export default Tag;
