import React from 'react';
import propTypes from 'prop-types';
import { classnames } from '../../../helpers/classnames';
import { bem } from '../../../helpers/styles';
import SVG from '../SVG/SVG';

import styles from './Icon.css';

function Icon({ className, icon, flat, color, onClick }) {
  const RenderComponent = onClick ? 'button' : 'div';
  const style = color && !flat ? { backgroundColor: color } : null;

  const svgStyle = color && flat ? { fill: color } : null;

  const props = {
    className: classnames([bem(styles, 'Icon', { flat, button: !!onClick }), className]),
    style: style,
    onClick: onClick,
  };

  return (
    <RenderComponent {...props}>
      <SVG glyph={icon} className={bem(styles, 'Icon__SVG', { flat })} style={svgStyle} />
    </RenderComponent>
  );
}

Icon.propTypes = {
  className: propTypes.string,
  icon: propTypes.string,
  color: propTypes.string,
  flat: propTypes.bool,
  onClick: propTypes.func,
};

export default Icon;
