import React from 'react';
import PropTypes from 'prop-types';
import styles from './FieldInfo.css';

function FieldInfo({ content }) {
  return <div className={styles.FieldInfo}>{content}</div>;
}

FieldInfo.propTypes = {
  content: PropTypes.string,
};

export default FieldInfo;
