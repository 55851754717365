import { toast } from 'react-toastify';
import { SUCCESS, ERROR, WARNING } from '../../constants/toastType';

export const createToast = (options, text) => {
  switch (options) {
    case SUCCESS:
      toast.success(text);
      break;
    case ERROR:
      toast.error(text);
      break;
    case WARNING:
      toast.warn(text);
      break;
    default:
      toast(text);
  }
};
