import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Timer.css';
import SVG from '../SVG/SVG';
import { bem } from '../../../helpers/styles';
import { parseSecondsToDuration } from '../../../helpers/date';

function Timer({ expiresAt }) {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      const remainMs = expiresAt - Date.now();
      setSeconds(remainMs > 0 ? Math.floor(remainMs / 1000) : 0);
    }, 100);
    return () => clearInterval(interval);
  }, [expiresAt]);

  return (
    <div className={bem(styles, 'Timer')}>
      <SVG className={bem(styles, 'Timer__icon')} glyph="clock" />
      {parseSecondsToDuration(seconds)}
    </div>
  );
}

Timer.propTypes = {
  expiresAt: PropTypes.string,
};

export default Timer;
