import React, { useRef, useLayoutEffect, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import { contribtueFormActiveSelector } from '../../../store/selectors/form';

function drawGeometry(context, geometry, color, scale) {
  const x = Math.min(...geometry.map((p) => p.X));
  const y = Math.min(...geometry.map((p) => p.Y));

  const width = Math.max(...geometry.map((p) => p.X)) - x;
  const height = Math.max(...geometry.map((p) => p.Y)) - y;

  context.fillStyle = color;
  context.fillRect(x * scale, y * scale, width * scale, height * scale);
}

function geometryIntersects(geometry, x, y, width, height, scale) {
  const geometryX = Math.min(...geometry.map((p) => p.X));
  const geometryY = Math.min(...geometry.map((p) => p.Y));

  const geometryWidth = Math.max(...geometry.map((p) => p.X)) - geometryX;
  const geometryHeight = Math.max(...geometry.map((p) => p.Y)) - geometryY;

  return (
    geometryX * scale < x + width &&
    geometryY * scale < y + height &&
    geometryX * scale + geometryWidth * scale > x &&
    geometryY * scale + geometryHeight * scale > y
  );
}

function geometryWithin(geometry, x, y, width, height, scale) {
  const geometryX = Math.min(...geometry.map((p) => p.X));
  const geometryY = Math.min(...geometry.map((p) => p.Y));

  const geometryWidth = Math.max(...geometry.map((p) => p.X)) - geometryX;
  const geometryHeight = Math.max(...geometry.map((p) => p.Y)) - geometryY;

  return (
    geometryX * scale >= x &&
    geometryY * scale >= y &&
    geometryX * scale + geometryWidth * scale <= x + width &&
    geometryY * scale + geometryHeight * scale <= y + height
  );
}

function PdfOcrViewerPageMetadataLayer({ page, viewports, metadata }) {
  const ref = useRef();
  const [mouse, setMouse] = useState(null);

  const dispatch = useDispatch();
  const formValues = useSelector(getFormValues('contribute'));
  const contributeFormActive = useSelector(contribtueFormActiveSelector);

  const [selectedWords, setSelectedWords] = useState('');

  const handleOnSelectWords = useCallback(() => {
    if (contribtueFormActiveSelector == null) {
      return;
    }

    return dispatch(
      change(
        'contribute',
        contributeFormActive,
        selectedWords,
        // (formValues?.[contributeFormActive] || '') + selectedWords,
      ),
    );
  }, [contributeFormActive, selectedWords]);

  useLayoutEffect(() => {
    if (ref.current == null) {
      return;
    }

    const canvas = ref.current;
    const context = canvas.getContext('2d');

    canvas.height = viewports.scaled.height;
    canvas.width = viewports.scaled.width;

    // TODO: use actual dimensions
    const scale = viewports.scaled.width / metadata.dimensions.len_x;

    // Figure out and highlight the current selection
    if (mouse?.isDown) {
      const x = Math.min(mouse.startX, mouse.endX);
      const y = Math.min(mouse.startY, mouse.endY);
      const width = Math.abs(mouse.startX - mouse.endX);
      const height = Math.abs(mouse.startY - mouse.endY);

      // Find all blocks that intersects with mouse
      const blocks = metadata.blocks.filter((block) =>
        geometryIntersects(block.geometry.Polygon, x, y, width, height, scale),
      );

      // Find all lines that intersects with mouse
      const lines = blocks.flatMap((block) =>
        block.lines.filter((line) =>
          geometryIntersects(line.geometry.Polygon, x, y, width, height, scale),
        ),
      );

      // Find all words that are contained within mouse
      const words = lines.flatMap((line) =>
        line.words.filter((word) =>
          geometryWithin(word.geometry.Polygon, x, y, width, height, scale),
        ),
      );

      // Render
      // blocks.forEach((block) => {
      //   drawGeometry(context, block.geometry.Polygon, 'rgba(14, 241, 188, 0.5)', scale);
      // });

      // lines.forEach((block) => {
      //   drawGeometry(context, block.geometry.Polygon, 'rgba(184, 241, 14, 0.5)', scale);
      // });

      words.forEach((block) => {
        drawGeometry(context, block.geometry.Polygon, 'rgba(241, 120, 14, 0.5)', scale);
      });

      // Merge all words into a single string
      const text = words.map((word) => word.value).join(' ');
      setSelectedWords(text);
    }

    // Render the selection rectangle
    if (mouse?.isDown) {
      const x = Math.min(mouse.startX, mouse.endX);
      const y = Math.min(mouse.startY, mouse.endY);
      const width = Math.abs(mouse.startX - mouse.endX);
      const height = Math.abs(mouse.startY - mouse.endY);

      context.lineWidth = 1;
      context.strokeStyle = 'rgb(30, 155, 250)';
      context.fillStyle = 'rgba(30, 155, 250, 0.2)';
      context.strokeRect(x, y, width, height);
      context.fillRect(x, y, width, height);
    }
  }, [ref.current, page, viewports, metadata, mouse]);

  useEffect(() => {
    if (mouse == null && selectedWords !== '') {
      handleOnSelectWords();
      setSelectedWords('');
    }
  }, [mouse, selectedWords]);

  const handleOnMouseDown = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    setMouse({
      isDown: true,
      startX: event.offsetX,
      startY: event.offsetY,
      endX: event.offsetX,
      endY: event.offsetY,
    });
  }, []);

  const handleOnMouseMove = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();

    setMouse((prev) =>
      prev?.isDown
        ? {
            ...prev,
            endX: event.offsetX,
            endY: event.offsetY,
          }
        : prev,
    );
  }, []);

  const handleOnMouseUp = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setMouse(null);
  }, []);

  const handleOnMouseLeave = useCallback(() => {
    setMouse(null);
  }, []);

  return (
    <canvas
      ref={ref}
      style={{
        width: viewports.scaled.width,
        height: viewports.scaled.height,
        cursor: 'crosshair',
      }}
      onMouseDown={handleOnMouseDown}
      onMouseMove={handleOnMouseMove}
      onMouseUp={handleOnMouseUp}
      onMouseLeave={handleOnMouseLeave}
    />
  );
}

PdfOcrViewerPageMetadataLayer.propTypes = {
  page: PropTypes.object.isRequired,
  viewports: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
};

export default PdfOcrViewerPageMetadataLayer;
