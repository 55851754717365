export function parseAnswer(json) {
  return {
    taskId: json.idTask,
    formId: json.project.form.idForm,
    projectId: json.project.idProject,
    taskState: json.taskState,
    answer: (json?.answer ?? { blocks: [], data: [], fields: [] }),
    json: (json?.answer ?? { blocks: [], data: [], fields: [] }),
    columns: (json?.columns || []).map((column) => ({
      name: column.name,
      data: column.data,
      type: column.type,
    })),
  };
}


export function parseAnswerHit(json) {
  return {
    hitId: json.idHit,
    formId: json.idForm,
    projectId: json.task.project.idProject,
    taskId: json.idTask,
    supplierName: json.user?.name,
    supplierFirstName: json.user?.firstName,
    hitState: json.hitState,
    answer: (json?.answer ?? { blocks: [], data: [], fields: [] }),
    json: (json?.answer ?? { blocks: [], data: [], fields: [] }),
    columns: (json?.columns || []).map((column) => ({
      name: column.name,
      data: column.data,
      type: column.type,
    })),
  };
}
