import { v4 as uuid } from 'uuid';
import { formatMarkTask } from '../../api/task';

export const TASK_GET = 'TASK_GET';
export const TASK_GET_SUCCESS = 'TASK_GET_SUCCESS';
export const TASK_UPDATE = 'TASK_UPDATE';
export const TASK_UPDATE_SUCCESS = 'TASK_UPDATE_SUCCESS';
export const TASK_RELEASE = 'TASK_RELEASE';
export const TASK_RELEASE_SUCCESS = 'TASK_RELEASE_SUCCESS';
export const TASK_FORM_GET = 'TASK_FORM_GET';
export const TASK_FORM_GET_SUCCESS = 'TASK_FORM_GET_SUCCESS';
export const TASK_ANSWER = 'TASK_ANSWER';
export const TASK_ANSWER_SUCCESS = 'TASK_ANSWER_SUCCESS';
export const TASK_FORM_KEEP = 'TASK_FORM_KEEP';
export const TASK_FORM_KEEP_SUCCESS = 'TASK_FORM_KEEP_SUCCESS';
export const TASK_SUPPORTED_ALLOCATION = 'TASK_KEEP_ALLOCATION';
export const TASK_SUPPORTED_ALLOCATION_SUCCESS = 'TASK_SUPPORTED_ALLOCATION_SUCCESS';

export const getTask = (projectId, isReview, meta = {}) => ({
  type: TASK_GET,
  fetch: {
    method: 'POST',
    url: `{{backendUrl}}/TaskService/Hit`,
    auth: true,
    json: {
      IdProject: projectId,
      isReview,
    },
    parse: 'parseTask',
  },
  meta: { requestId: uuid(), ...meta },
});

export const getFormTask = (formId) => ({
  type: TASK_FORM_GET,
  fetch: {
    method: 'GET',
    url: `{{backendUrl}}/FormService/Form/${formId}/GetTemplate`,
    auth: true,
    parse: 'parseForm',
  },
  meta: { requestId: uuid() },
});

export const createTaskAnswer = (task, json, meta = {}) => ({
  type: TASK_ANSWER,
  fetch: {
    method: 'PUT',
    url: `{{backendUrl}}/TaskService/Hit`,
    auth: true,
    json: {
      IdHit: task.taskId,
      SupportDateTime : task.SupportDateTime,
      Duration : task.duration,
      Answer: json,
    },
  },
  meta: { requestId: uuid(), ...meta },
});

export const updateMarkTask = (values) => {
  return {
    type: TASK_UPDATE,
    fetch: {
      method: 'POST',
      url: `{{backendUrl}}/Mark`,
      auth: true,
      json: formatMarkTask(values),
    },
    meta: { requestId: uuid() },
  };
};

export const releaseTask = (taskId, meta = {}) => ({
  type: TASK_RELEASE,
  fetch: {
    method: 'DELETE',
    url: `{{backendUrl}}/TaskService/Hit/${taskId}`,
    auth: true,
  },
  meta: { requestId: uuid(), ...meta },
});

export const keepTask = (taskId, meta = { taskId }) => ({
  type: TASK_FORM_KEEP,
  fetch: {
    method: 'PATCH',
    url: `{{backendUrl}}/TaskService/Hit/${taskId}`,
    auth: true,
  },
  meta: { requestId: uuid(), ...meta },
});

