import React, { useEffect } from 'react';
import styles from './BlockFormTemplate.css';
import BlockForm from '../../form/BlockForm/BlockForm';
import {
  focusedBlockSelector,
  currentFormSelector,
  isFormPublishedSelector,
} from '../../../store/selectors/editForm';
import { useSelector, useDispatch } from 'react-redux';
import { getFormValues, initialize } from 'redux-form';
import { FORM_BLOCK } from '../../../constants/form';
import { useActions } from '../../../hooks/useActions';
import { updateBlock as updateBlockAction } from '../../../store/actions/form';

function BlockFormTemplate() {
  const block = useSelector(focusedBlockSelector);
  const form = useSelector(currentFormSelector);
  const isPublished = useSelector(isFormPublishedSelector);
  const values = useSelector(getFormValues(FORM_BLOCK));
  const dispatch = useDispatch();
  const [updateBlock] = useActions([updateBlockAction]);

  useEffect(() => {
    if (values) {
      const copy = { ...values };
      delete copy.box;
      updateBlock(form.id, values.id, copy);
    }
  }, [values]);

  // (Re-)Initialize the form when block id changes
  useEffect(() => {
    dispatch(initialize('block', block));
  }, [block && block.id]);

  return (
    <div className={styles.BlockFormTemplate}>
      {block && <BlockForm isPublished={isPublished} onSubmit={Function.prototype} />}
    </div>
  );
}

export default BlockFormTemplate;
