import React, { useEffect } from 'react';
import styles from './DrawerContentStrategy.css';
import useTranslate from '../../../../../../hooks/useTranslate';
import QualityStrategyForm from '../../../../../form/QualityStrategyForm/QualityStrategyForm';
import { useSelector, useDispatch } from 'react-redux';
import {
  currentFormSelector,
  isFormPublishedSelector,
} from '../../../../../../store/selectors/editForm';
import { getFormValues, isDirty } from 'redux-form';
import { FORM_QUALITY_STRATEGY } from '../../../../../../constants/form';
import { updateForm } from '../../../../../../store/actions/editForm';

const defaultInitialValues = {
  strategy: 'panel',
  sampleMode: 'multi',
  indeterminateMode: 'bestAnswer',
};

function DrawerContentStrategyPage() {
  const i18n = useTranslate();
  const form = useSelector(currentFormSelector);
  const qualityValues = useSelector(getFormValues(FORM_QUALITY_STRATEGY));
  const dirty = useSelector(isDirty(FORM_QUALITY_STRATEGY));
  const isPublished = useSelector(isFormPublishedSelector);
  const dispatch = useDispatch();

  // When values change, push updates to the store
  useEffect(() => {
    if (dirty) {
      dispatch(
        updateForm({
          ...form,
          quality: qualityValues,
        }),
      );
    }
  }, [qualityValues, dirty]);

  return (
    <div className={styles.DrawerContentStrategy}>
      <h3 className={styles.DrawerContentStrategy__title}>{i18n('DrawerContentStrategy.title')}</h3>
      <p className={styles.DrawerContentStrategy__description}>
        {i18n('DrawerContentStrategy.description')}
      </p>
      {form && (
        <QualityStrategyForm
          disabled={isPublished}
          initialValues={{ ...defaultInitialValues, ...form.quality }}
          onSubmit={Function.prototype}
        />
      )}
    </div>
  );
}

DrawerContentStrategyPage.propTypes = {};

export default DrawerContentStrategyPage;
