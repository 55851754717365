import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import { contribtueFormActiveSelector } from '../../../store/selectors/form';

function KeyboardShortcutsProbe({ shortcuts, form }) {
  const dispatch = useDispatch();
  const formValues = useSelector(getFormValues('contribute'));

  // Currently selected field
  const contributeFormActive = useSelector(contribtueFormActiveSelector);

  const shortcutsMap = useMemo(
    () => shortcuts.reduce((map, shortcut) => ({ ...map, [shortcut.key]: shortcut }), {}),
    [shortcuts],
  );

  useEffect(() => {
    function handleKeyup(event) {
      const shortcut = shortcutsMap[event.code];

      if (form.disableBrowserShortcuts || shortcut != null) {
        event.preventDefault();
        event.stopPropagation();
      }

      if (shortcut == null) {
        return;
      }

      // Default to type: 'text' for backwards compatibility
      const type = shortcut.type || 'text';

      switch (type) {
        case 'text': {
          // No field selected
          if (contribtueFormActiveSelector == null) {
            return;
          }

          return dispatch(
            change(
              'contribute',
              contributeFormActive,
              (formValues?.[contributeFormActive] || '') + shortcut.value,
            ),
          );
        }

        case 'multi': {
          const fieldId = form?.fields[shortcut.field]?.id;
          const field = form?.fields[shortcut.field];
          const value = formValues?.[fieldId];

          if (field.multi) {
            if (shortcut.multiMode === 'set') {
              return dispatch(
                change('contribute', fieldId, [
                  ...(value || []).filter((v) => v !== shortcut.value),
                  shortcut.value,
                ]),
              );
            } else if (shortcut.multiMode === 'unset') {
              return dispatch(
                change('contribute', fieldId, [
                  ...(value || []).filter((v) => v !== shortcut.value),
                ]),
              );
            } else if (shortcut.multiMode === 'toggle') {
              const isToggled = (value || []).includes(shortcut.value);

              return dispatch(
                change('contribute', fieldId, [
                  ...(value || []).filter((v) => v !== shortcut.value),
                  ...(isToggled ? [] : [shortcut.value]),
                ]),
              );
            } else {
              console.log('Unknown multi mode', shortcut.multiMode);
            }
          } else {
            return dispatch(change('contribute', fieldId, shortcut.value));
          }
        }

        default: {
          console.log('Unknown shortcut type', type);
        }
      }
    }

    function handleKeyDown(event) {
      const shortcut = shortcutsMap[event.code];

      const isComposed = event.altKey || event.ctrlKey || event.metaKey;

      if ((form.disableBrowserShortcuts && isComposed) || shortcut != null) {
        event.preventDefault();
        event.stopPropagation();
      }

      return;
    }

    document.addEventListener('keyup', handleKeyup);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keyup', handleKeyup);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [shortcutsMap, form, contributeFormActive, formValues]);

  return null;
}

KeyboardShortcutsProbe.propTypes = {
  shortcuts: PropTypes.array,
  form: PropTypes.object,
};

export default KeyboardShortcutsProbe;
