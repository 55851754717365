import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../../atoms/Button/Button';
import FieldForm from '../../form/FieldForm/FieldForm';
import styles from './EditItem.css';
import List from '../../atoms/list/List';
import useTranslate from '../../../hooks/useTranslate';
import cssvars from '../../../styles/variables';
import { useSelector, useDispatch } from 'react-redux';
import { getFormValues, initialize } from 'redux-form';
import { getTypologyValues } from '../../../helpers/typology';
import { omit } from '../../../helpers/omit';
import { isFormPublishedSelector } from '../../../store/selectors/editForm';

function EditItemTemplate({ items, resource, button, onNewField, field, onChange }) {
  const i18n = useTranslate();
  const values = useSelector(getFormValues('field'));
  const isPublished = useSelector(isFormPublishedSelector);

  const fieldTypology = useMemo(() => (values && values.selectTypology) || field.type, [
    field,
    values,
  ]);
  const item = useMemo(() => items.find((item) => item.type === fieldTypology), [
    items,
    fieldTypology,
  ]);
  const dispatch = useDispatch();

  // When field typology is switched, change the underlying "type" attribute
  useEffect(() => {
    if (values && values.selectTypology) {
      const newField = omit(
        {
          id: values.id,
          label: values.label,
          hideIf: values.hideIf,
          type: values.selectTypology,
          ...(getTypologyValues(values) || {}),
        },
        'box',
      );

      dispatch(initialize('field', newField));
      onChange(newField);
    }
  }, [values && values.selectTypology]);

  // When values are changed, push updates to the store
  useEffect(() => {
    if (values) {
      onChange(values);
    }
  }, [values]);

  // When field id diverge from values id, that means form data is out
  // of date so we hydrate it
  useEffect(() => {
    if (!values || values.id !== field.id) {
      // Delete the box from form values as its not needed
      dispatch(initialize('field', omit(field, 'box')));
    }
  }, [values, field]);

  if (!field || !item) return null;

  return (
    <>
      <div className={styles.EditItemTemplate__buttons}>
        <Button
          onClick={onNewField}
          color={cssvars['color-accent']}
          block
          className={styles.EditItemTemplate__newButton}
          icon="arrow_left_simple"
        >
          {button()}
        </Button>
      </div>
      {item && (
        <List className={styles.EditItemTemplate__typology}>
          <List.Item color={item.meta.color} icon={item.meta.icon}>
            {resource && i18n(`${resource}.${item.type}.label`)}
          </List.Item>
        </List>
      )}
      <FieldForm
        typology={item.type}
        isPublished={isPublished}
        fields={item.options}
        resource={resource}
        shortcut={item.shortcut}
        onSubmit={Function.prototype}
      />
    </>
  );
}

EditItemTemplate.propTypes = {
  onNewField: PropTypes.func,
  onChange: PropTypes.func,
  items: PropTypes.array,
  resource: PropTypes.string,
  button: PropTypes.func,
  field: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    selectTypology: PropTypes.string,
  }),
};
EditItemTemplate.defaultProps = {
  button: () => null,
};

export default EditItemTemplate;
