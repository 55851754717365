export default function deepValueObject(object = {}, path = '') {
  path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  path = path.replace(/^\./, ''); // strip a leading dot
  const pathArray = path.split('.');
  for (const key of pathArray) {
    if (typeof object !== 'object') return null;
    if (!(key in object)) return null;
    object = object[key];
  }

  return object;
}
