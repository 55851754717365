import { v4 as id } from 'uuid';
import React, { useCallback, useEffect } from 'react';
import Button from '../../../../../atoms/Button/Button';
import useTranslate from '../../../../../../hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';
import {
  compareRulesSelector,
  focusedCompareRuleIdSelector,
  formBlocksSelector,
  formFieldsSelector,
  selectFieldsSelector,
  selectModeSelector,
} from '../../../../../../store/selectors/editForm';
import {
  setFocusedCompareRule,
  setSelectFields,
  setSelectMode,
  updateForm,
} from '../../../../../../store/actions/editForm';
import CompareBox from '../../../../../molecules/CompareBox/CompareBox';
import styles from './DrawerContentToCompare.css';
import { ERROR } from '../../../../../../constants/toastType';
import { createToast } from '../../../../../../store/actions/toast';
import { boxSize, getSmallestBlock, isInside } from '../../../../../../helpers/formTree';
import QualitySelectTemplate from '../../../../../templates/QualitySelectTemplate/QualitySelectTemplate';
import { TYPOLOGIES_COMPARE_INVALID } from '../../../../../../constants/typology/typologies';

function DrawerContentToComparePage() {
  const i18n = useTranslate();
  const rules = useSelector(compareRulesSelector);
  const focusedRuleId = useSelector(focusedCompareRuleIdSelector);
  const selectedFields = useSelector(selectFieldsSelector);
  const blocks = useSelector(formBlocksSelector);
  const fields = useSelector(formFieldsSelector);
  const selectMode = useSelector(selectModeSelector);
  const dispatch = useDispatch();

  // Enable / disable select mode on mount / unmount
  useEffect(() => {
    dispatch(setSelectFields([]));
    dispatch(setSelectMode(true));
    return () => {
      dispatch(setSelectMode(false));
    };
  }, []);

  // Monitor added fields and update focused rule accordingly
  useEffect(() => {
    if (!selectMode || selectedFields.length === 0) {
      return;
    }

    const focusedRule = rules.find((rule) => rule.id === focusedRuleId);

    if (!focusedRule) {
      return;
    }

    const field = selectedFields[0];

    dispatch(setSelectFields([]));

    // Check not allowed typologies
    if (TYPOLOGIES_COMPARE_INVALID.includes(field.type) && field.multi) {
      createToast(ERROR, i18n('DrawerContentToComparePage.invalidType'));
      return;
    }

    // Check for duplicates
    if (
      rules.reduce(
        (count, rule) => count + (rule.fields.find((fieldId) => fieldId === field.id) ? 1 : 0),
        0,
      ) > 0
    ) {
      createToast(ERROR, i18n('DrawerContentToComparePage.duplicate'));
      return;
    }

    // Check for parent block
    const parentBlock = getSmallestBlock(field, blocks, { duplicable: true });
    const smallestActualBlock = focusedRule.fields
      .map((fieldId) =>
        getSmallestBlock(
          fields.find((field) => field.id === fieldId),
          blocks,
          { duplicable: true },
        ),
      )
      .reduce(
        (smallest, current) =>
          !smallest || boxSize(current.box) < boxSize(smallest.box) ? current : smallest,
        null,
      );
    const blockScopeBlock = blocks.find((block) => block.id === focusedRule.blockScope);

    // Field block must match block scope
    if (
      focusedRule.fields.length &&
      parentBlock?.id !== focusedRule.blockScope &&
      (!isInside(blockScopeBlock.box, parentBlock.box) ||
        (smallestActualBlock &&
          smallestActualBlock.id !== parentBlock.id &&
          !isInside(smallestActualBlock.box, parentBlock.box)))
    ) {
      createToast(ERROR, i18n('DrawerContentToComparePage.mustBeInSameBlock'));
      return;
    }

    dispatch(
      updateForm({
        compareFields: rules.map((rule) =>
          rule.id === focusedRuleId
            ? {
                ...focusedRule,
                blockScope: rule.blockScope || parentBlock?.id,
                fields: [...focusedRule.fields, field.id],
              }
            : rule,
        ),
      }),
    );
  }, [selectMode, focusedRuleId, selectedFields, rules]);

  const handleAddRule = useCallback(() => {
    const ruleId = id();
    dispatch(setSelectFields([]));
    dispatch(
      updateForm({
        compareFields: [
          ...rules,
          {
            id: ruleId,
            fields: [],
            blockScope: null,
          },
        ],
      }),
    );

    dispatch(setFocusedCompareRule(ruleId));
  }, [rules]);

  const handleDelete = useCallback(
    (removedIndex) => {
      const compareFields = rules.filter((_, index) => index !== removedIndex);
      dispatch(
        updateForm({
          compareFields,
        }),
      );
    },
    [rules],
  );

  const handleUpdate = useCallback(
    (ruleId, changes) => {
      dispatch(
        updateForm({
          compareFields: rules.map((rule) => {
            if (rule.id !== ruleId) {
              return rule;
            }
            const updatedRule = {
              ...rule,
              ...changes,
            };
            updatedRule.blockScope = updatedRule.fields.length ? updatedRule.blockScope : null;
            return updatedRule;
          }),
        }),
      );
    },
    [rules],
  );

  return (
    <>
      <QualitySelectTemplate icon="compare_fields" titleKey="QualityNavTemplate.toCompare" />
      <div className={styles.DrawerContentToCompare}>
        <h3 className={styles.DrawerContentToCompare__title}>
          {i18n('DrawerContentToComparePage.title')}
        </h3>
        <p className={styles.DrawerContentToCompare__description}>
          {i18n('DrawerContentToComparePage.description')}
        </p>
        <p className={styles.DrawerContentToCompare__actions}>
          <Button type="button" onClick={handleAddRule}>
            {i18n('DrawerContentToComparePage.addRule')}
          </Button>
        </p>
        {rules.length ? (
          rules.map((rule, i) => (
            <>
              <CompareBox
                key={i}
                {...rule}
                onUpdate={handleUpdate}
                onDelete={() => handleDelete(i)}
                onFocus={() => dispatch(setFocusedCompareRule(rule.id))}
                focused={focusedRuleId === rule.id}
              />

              <div
                className={styles.DrawerContentToCompare__empty}
                style={{
                  marginBottom: '1rem',
                  textAlign: 'left',
                  position: 'relative',
                  top: '-8px',
                }}
              >
                {rule.blockScope && blocks.find((block) => block.id === rule.blockScope)?.name}
              </div>
            </>
          ))
        ) : (
          <p className={styles.DrawerContentToCompare__empty}>
            {i18n('DrawerContentToComparePage.empty')}
          </p>
        )}
      </div>
    </>
  );
}

DrawerContentToComparePage.propTypes = {};

export default DrawerContentToComparePage;
