import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../atoms/Button/Button';
import { classnames } from '../../../helpers/classnames';
import styles from './InfoButton.css';
import { useActions } from '../../../hooks/useActions';
import { toggleInformationPanel as toggleInformationPanelAction } from '../../../store/actions/editForm';
import { informationOpenSelector } from '../../../store/selectors/editForm';
import { useSelector } from 'react-redux';

const InfoButton = ({ className }) => {
  const [toggleInformationPanel] = useActions([toggleInformationPanelAction]);
  const isOpen = useSelector(informationOpenSelector);

  return (
    <Button
      className={classnames([className, styles.InfoButton])}
      onClick={toggleInformationPanel}
      active={isOpen}
      icon="info"
    />
  );
};

InfoButton.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

InfoButton.defaultProps = {
  disabled: false,
  onClick: () => {},
};

export default InfoButton;
