import React from 'react';
import PropTypes from 'prop-types';
import List from '../../../atoms/list/List';
import DraggableGrid from '../../Grid/DraggableGrid';

function DraggableFieldListItem({ children, item, ...props }) {
  return (
    <DraggableGrid.BoxStub item={item}>
      <List.Item {...props}>{children}</List.Item>
    </DraggableGrid.BoxStub>
  );
}

DraggableFieldListItem.propTypes = {
  children: PropTypes.node,
  item: PropTypes.object,
};

export default DraggableFieldListItem;
