export const COLOR_LIGHTYELLOW = 'rgba(255, 236, 199, 1)';
export const COLOR_LIGHTPURPLE = 'rgba(217, 224, 255, 1)';
export const COLOR_LIGHTCYAN = 'rgba(202, 243, 236, 1)';
export const COLOR_LIGHTBLUE = 'rgba(204, 242, 255, 1)';
export const COLOR_BLUR = 'rgba(37, 104, 160, 1)';
export const COLOR_GREEN = 'rgba(30, 150, 127, 1)';
export const COLOR_YELLOW = 'rgba(255, 171, 0, 1)';
export const COLOR_RED = 'rgba(255, 91, 97, 1)';
export const COLOR_TRANS = 'rgba(0, 0, 0, 0)';

export const COLORS = [
  COLOR_LIGHTYELLOW,
  COLOR_LIGHTPURPLE,
  COLOR_LIGHTCYAN,
  COLOR_LIGHTBLUE,
  COLOR_BLUR,
  COLOR_GREEN,
  COLOR_YELLOW,
  COLOR_RED,
  COLOR_TRANS,
];
