import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import ResizeObserver from 'resize-observer-polyfill';
import GridBox from './GridBox/GridBox';
import styles from './Grid.css';
import GridContext from './GridContext';
import { classnames } from '../../../helpers/classnames';

function Grid({
  columns,
  gutter = 4,
  rowHeight,
  fixedRows = 0,
  children,
  onGridChange,
  dotBackground = true,
  className,
}) {
  // Grid size in pixels, allow us to compute column width and other usefull sutff
  const [realSize, setRealSize] = useState({ width: 0, height: 0 });
  // Holds GridContext value for this grid
  const [grid, setGrid] = useState(null);
  // Reference to grid DOM Node
  const gridRef = useRef(null);

  // Compute fixed height if rowHeight is given
  const style = useMemo(
    () =>
      grid && fixedRows
        ? {
            height: `${grid.rowHeight * fixedRows}px`,
          }
        : null,
    [dotBackground, grid, fixedRows],
  );

  // Compute background style
  const backgroundStyle = useMemo(
    () =>
      dotBackground && grid
        ? {
            backgroundSize: `${grid.columnWidth}px ${grid.rowHeight}px`,
            marginLeft: -2,
            top: grid.boundingRect.offsetTop,
            width: grid.width + 4,
            height: fixedRows ? `${grid.rowHeight * (fixedRows + 1)}px` : undefined,
          }
        : null,
    [dotBackground, grid, fixedRows],
  );

  // Fires on screen resizes and compute grid bounding box
  useEffect(() => {
    function updateSize() {
      if (gridRef.current) {
        const { width, height, top, left } = gridRef.current.getBoundingClientRect();
        setRealSize({
          offsetTop: gridRef.current.offsetTop,
          top,
          left,
          width,
          height,
        });
      }
    }

    // Fire it once when the component is mounted
    updateSize();

    const resizeObserver = new ResizeObserver(updateSize);
    gridRef.current && resizeObserver.observe(gridRef.current);

    return () => {
      gridRef.current && resizeObserver.unobserve(gridRef.current);
    };
  }, [gridRef.current]);

  // Callback handler if grid values change
  useEffect(() => {
    onGridChange && onGridChange(grid);
  }, [onGridChange, grid]);

  // Update grid dimensions properties based on viewport and desired columns
  useEffect(() => {
    setGrid({
      columns,
      rowHeight: rowHeight || realSize.width / columns,
      gutter,
      columnWidth: realSize.width / columns,
      width: realSize.width,
      boundingRect: realSize,
    });
  }, [realSize, columns]);

  return (
    <GridContext.Provider value={grid}>
      <div className={styles.Grid__background} style={backgroundStyle} />
      <div ref={gridRef} className={classnames([styles.Grid, className])} style={style}>
        {grid && children}
      </div>
    </GridContext.Provider>
  );
}

Grid.Box = GridBox;

Grid.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.number,
  rowHeight: PropTypes.number,
  gutter: PropTypes.number,
  children: PropTypes.node,
  onGridChange: PropTypes.func,
  fixedRows: PropTypes.number,
  dotBackground: PropTypes.bool,
};

export default Grid;
