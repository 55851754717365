import { createSelector } from 'reselect';
import { isDirty } from 'redux-form';
import { BLOCK } from '../../constants/draggableType';
import { getBlockDarkerColor } from '../../helpers/color';
import { FIELD_REFERENCE } from '../../constants/formula';
import { getTypologyKind } from '../../helpers/typology';
import { FIELD } from '../../constants/typology/field';
import { TYPOLOGIES_ADVANCED_COMPARE } from '../../constants/typology/typologies';
import { ADVANCEDSELECT, MCQ } from '../../constants/typology/fieldType';
import { PUBLISHED } from '../../constants/formState';

// with undoable (clr-z) redux package , state look like state: {past: [[...],,present: [...],future: [[...]]} -> access your state with state.present
export const editFormSelector = (state) => state?.editForm.present || {};

export const editFormLoadingSelector = createSelector(editFormSelector, (state) => state.loading);

export const editFormDirtySelector = createSelector(
  editFormSelector,
  isDirty('settings'),
  isDirty('create'),
  (form, createDirty, settingsDirty) => form.dirty || createDirty || settingsDirty,
);

export const currentFormSelector = (state) => editFormSelector(state)?.form;
export const isFormPublishedSelector = createSelector(
  currentFormSelector,
  (form) => form?.state === PUBLISHED,
);

export const instructionsFormSelector = createSelector(
  currentFormSelector,
  (form) => form?.instructions,
);

export const focusedFieldSelector = createSelector(editFormSelector, (editForm) => {
  if (!editForm.form) {
    return null;
  }

  return editForm.form?.fields?.find((field) => field.id === editForm.focusedField) || null;
});

export const formFieldsSelector = createSelector(
  editFormSelector,
  ({ form }) => form?.fields || [],
);

export const formAdvancedCompareFieldsSelector = createSelector(editFormSelector, ({ form }) =>
  (form?.fields || []).filter(
    (field) =>
      getTypologyKind(field.type) === FIELD &&
      TYPOLOGIES_ADVANCED_COMPARE.includes(field.type) &&
      !(form.noCompareFields || []).includes(field.id) &&
      ((field.type !== ADVANCEDSELECT && field.type !== MCQ) || field.multi),
  ),
);

export const formAdvancedCompareBlocksSelector = createSelector(editFormSelector, ({ form }) =>
  (form?.blocks || []).filter(
    (block) => block.duplicable && form?.quality?.indeterminateMode === 'bestAnswer',
  ),
);

export const formFieldSelector = (fieldId) => (state) =>
  formFieldsSelector(state)?.find((field) => field.id === fieldId);

export const formBlocksSelector = createSelector(
  editFormSelector,
  ({ form }) => form?.blocks || [],
);

export const formQualitySelector = createSelector(
  editFormSelector,
  ({ form }) => form?.quality || {},
);

export const formBlockSelector = (blockId) => (state) =>
  formBlocksSelector(state)?.find((block) => block.id === blockId);

export const focusedBlockIdSelector = createSelector(
  editFormSelector,
  (editForm) => editForm?.focusedBlock || null,
);

export const focusedCompareRuleIdSelector = createSelector(
  editFormSelector,
  (editForm) => editForm?.focusedCompareRule || null,
);

export const focusedRuleIdSelector = createSelector(editFormSelector, (state) => state.focusedRule);

export const focusedBlockSelector = createSelector(
  focusedBlockIdSelector,
  formBlocksSelector,
  (blockId, blocks) => blocks.find((block) => block.id === blockId),
);

// Map form fields to nice to use items with grid
export const fieldBoxesSelector = createSelector(editFormSelector, ({ form }) =>
  form
    ? form.fields.map((field) => ({
        ...field,
        item: {
          ...field.box,
          fieldType: field.type,
          id: field.id,
          type: 'field',
          field: field.type,
          fixedMode: field.fixedMode,
          numeric: field.numeric,
        },
      }))
    : [],
);

export const blocksBoxesSelector = createSelector(formBlocksSelector, (blocks) =>
  blocks.map((block) => ({
    ...block,
    // TODO: if exact colors are needed, replace with a color map and fallback to pSBC
    // Darken the block color for border, fills & text
    darkerColor: getBlockDarkerColor(block.color),
    item: {
      ...block.box,
      id: block.id,
      type: BLOCK,
      color: block.color,
    },
  })),
);

export const allBoxesSelector = createSelector(
  fieldBoxesSelector,
  blocksBoxesSelector,
  (fields, blocks) => fields.concat(blocks).map((bloc) => bloc.item),
);

// Retrieve lowest occupied row on the form grid
export const lowerBoxYSelector = createSelector(allBoxesSelector, (boxes) =>
  boxes.reduce((lowerY, box) => Math.max(lowerY, box.y + (box.height || 1)), 0),
);

export const informationOpenSelector = createSelector(
  editFormSelector,
  (state) => state.informationOpen,
);

export const validationRulesSelector = createSelector(
  currentFormSelector,
  (state) => state?.validationRules || [],
);

export const compareRulesSelector = createSelector(
  currentFormSelector,
  (state) => state?.compareFields || [],
);

export const focusedRuleSelector = createSelector(
  focusedRuleIdSelector,
  validationRulesSelector,
  (ruleId, rules) => rules.find((rule) => rule.id === ruleId),
);

export const iterationOverloadRulesSelector = createSelector(
  currentFormSelector,
  (state) => state?.iterationOverloads || [],
);

export const focusedIterationOverloadRuleIdSelector = createSelector(
  editFormSelector,
  (state) => state.focusedIterationRule,
);

export const focusedIterationOverloadRuleSelector = createSelector(
  focusedIterationOverloadRuleIdSelector,
  iterationOverloadRulesSelector,
  (ruleId, rules) => rules.find((rule) => rule.id === ruleId),
);

export const selectModeSelector = createSelector(
  editFormSelector,
  (editForm) => editForm?.selectMode,
);

export const selectFieldsSelector = createSelector(
  editFormSelector,
  (editForm) => editForm?.selectFields,
);
export const fieldHasRuleSelector = (state) => (fieldId) =>
  editFormSelector(state)?.form?.validationRules?.find((rule) => {
    return rule.formula.find(
      (formula) => formula.type === FIELD_REFERENCE && formula.field === fieldId,
    );
  });
export const fieldHasNoCompareRuleSelector = (state) => (fieldId) =>
  editFormSelector(state)?.form?.noCompareFields?.find((rule) => {
    return rule === fieldId;
  });
export const fieldHasCompareRuleSelector = (state) => (fieldId) =>
  editFormSelector(state)?.form?.compareFields?.find((rule) => {
    return rule.fields.find((_feildId) => _feildId === fieldId);
  });
export const fieldHasSortingRuleSelector = (state) => (fieldId) =>
  editFormSelector(state)?.form?.blocks?.find((block) => {
    return block.sortingRules?.find((rule) => rule.find((id) => id === fieldId));
  });
export const fieldHasHideIfRuleSelector = (state) => (fieldId) =>
  editFormSelector(state)?.form?.fields?.find((field) => {
    return field?.hideIf?.formula?.find((token) => token.field === fieldId);
  });
export const selectFormIdSelector = createSelector(editFormSelector, (editForm) => editForm?.id);
export const selectShortcutSelector = createSelector(editFormSelector, (editForm) => {
  const empty = {};
  editForm.form?.fields?.map((field) => {
    if (field.shortcut?.enabled && field.shortcut.value !== '') {
      empty[`shift+${field.shortcut.value}`] = field.id;
    }
  });
  return empty;
});

export const shortcutFieldsSelector = createSelector(editFormSelector, (editForm) => {
  return editForm.form?.fields?.reduce((accumulator, field) => {
    if (field?.shortcut?.enabled && field?.shortcut.value !== '') {
      accumulator.push(`<b>${field?.label}</b>: <i>shift+${field.shortcut.value}</i>`);
    }
    return accumulator;
  }, []);
});

export const keyboardShortcutsTextFieldsSelector = createSelector(editFormSelector, (editForm) =>
  editForm?.form?.keyboardShortcuts?.reduce((acc, item) => {
    if (item?.type !== 'multi' && item?.key) {
      acc.push(`<i>${item.key}</i> -> ${item.value}`);
    }
    return acc;
  }, []),
);

export const keyboardShortcutsMultiFieldsSelector = createSelector(editFormSelector, (editForm) =>
  editForm?.form?.keyboardShortcuts?.reduce((acc, item) => {
    if (item?.type === 'multi' && item?.key && item?.field >= 0) {
      const labelField = editForm?.form?.fields[item.field]?.label;
      acc.push(`<b>${labelField}</b>: <i>${item.key}</i> -> ${item?.value}`);
    }
    return acc;
  }, []),
);

export const focusedFieldShortcutSelector = createSelector(
  focusedFieldSelector,
  (focusedField) => focusedField?.shortcut?.value || null,
);

export const shouldDisplayDeliveryAnswerSelector = createSelector(
  formQualitySelector,
  (quality) => quality?.indeterminateMode === 'bestAnswer',
);

export const fieldMulti = createSelector(focusedFieldSelector, (field) => {
  return field.multi;
});

export const fieldInject = createSelector(focusedFieldSelector, (field) => {
  return field.injectChoices;
});

export const fieldLabelValue = createSelector(focusedFieldSelector, (field) => {
  return field.injectLabelValue;
});

export const keyboardShortcutsSelector = createSelector(
  editFormSelector,
  (editForm) => editForm?.form?.keyboardShortcuts || [],
);
