import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import Input from '../../../atoms/Input/Input';
function RegexFieldRender({ input, id, meta: { error, touched }, ...props }) {
  const { value, onChange } = input;
  const [regex, setRegex] = useState(value);
  const handleChange = useCallback(
    (val) => {
      setRegex(val);
      onChange(val);
    },
    [regex, input],
  );

  useEffect(() => {
    if (value) setRegex(value);
  }, [value]);

  return (
    <>
      <Input
        {...input}
        value={regex}
        onChange={handleChange}
        id={id}
        block
        {...props}
        error={touched && error}
      />
    </>
  );
}
RegexFieldRender.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
};

function RegexField({ name, label, required, fieldHoC: FieldHOC, ...props }) {
  const id = useMemo(() => `name_${uuid()}`, [name]);
  return (
    <FormField label={label} name={name} htmlFor={id} required={required}>
      <FieldHOC name={name} component={RegexFieldRender} id={id} {...props} />
    </FormField>
  );
}
RegexField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  fieldHoC: PropTypes.func,
};
RegexField.defaultProps = {
  fieldHoC: Field,
  validation: {},
};
export default RegexField;
