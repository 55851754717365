import React from 'react';
import DraggableBoxStub from '../../../../organisms/Grid/DraggableBoxStub/DraggableBoxStub';
import { useSelector } from 'react-redux';
import {
  currentFormSelector,
  blocksBoxesSelector,
  focusedBlockIdSelector,
  isFormPublishedSelector,
} from '../../../../../store/selectors/editForm';
import Spoiler from '../../../../molecules/Spoiler/Spoiler';
import styles from './DrawerContentBlocks.css';
import SVG from '../../../../atoms/SVG/SVG';
import BlockHeaderTemplate from '../../../../templates/BlockHeaderTemplate/BlockHeaderTemplate';
import { useActions } from '../../../../../hooks/useActions';
import { deleteBlock as deleteBlockAction } from '../../../../../store/actions/form';
import { setFocusedBlock as setFocusedBlockAction } from '../../../../../store/actions/editForm';
import BlockFormTemplate from '../../../../templates/BlockFormTemplate/BlockFormTemplate';
import useTranslate from '../../../../../hooks/useTranslate';

function DrawerContentBlocks() {
  const i18n = useTranslate();
  const form = useSelector(currentFormSelector);
  const blocks = useSelector(blocksBoxesSelector);
  const isPublished = useSelector(isFormPublishedSelector);
  const focusedBlockId = useSelector(focusedBlockIdSelector);
  const [deleteBlock, setFocusedBlock] = useActions([deleteBlockAction, setFocusedBlockAction]);
  // Use a regular div when form is published to prevent drag
  const BoxStubComponent = !isPublished ? DraggableBoxStub : 'div';

  if (!form) {
    return null;
  }

  return (
    <div>
      <h3 className={styles.DrawerContentBlocks__title}>{i18n('DrawerContentBlocks.title')}</h3>
      <p className={styles.DrawerContentBlocks__description}>
        {i18n('DrawerContentBlocks.description')}
      </p>
      <div className={styles.DrawerContentBlocks__stubWrapper}>
        <BoxStubComponent item={{ type: 'block-stub', width: 4, height: 2 }}>
          <div className={styles.DrawerContentBlocks__stub}>
            <div className={styles.DrawerContentBlocks__stubIconWrapper}>
              <SVG glyph="layers" className={styles.DrawerContentBlocks__stubIcon} />
            </div>
          </div>
        </BoxStubComponent>
      </div>
      <div>
        {blocks.map((block, key) => (
          <Spoiler
            key={key}
            title={`Block ${key + 1}`}
            open={block.id === focusedBlockId}
            onClick={() => setFocusedBlock(block.id === focusedBlockId ? null : block.id)}
            renderHeader={() => (
              <BlockHeaderTemplate
                block={block}
                onDelete={() => !isPublished && deleteBlock(form.id, block.id)}
              />
            )}
            render={() => <BlockFormTemplate />}
          />
        ))}
      </div>
    </div>
  );
}

export default DrawerContentBlocks;
