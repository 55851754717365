import React, { useCallback } from 'react';
import DrawerFormControls from '../../../organisms/Drawer/FormControls/DrawerFormControls';
const DrawerFooterFormPage = () => {
  return (
    <>
      <DrawerFormControls />
    </>
  );
};

DrawerFooterFormPage.propTypes = {};

export default DrawerFooterFormPage;
