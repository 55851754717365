import React from 'react';
import PropTypes from 'prop-types';
import DraggableFieldList from '../../organisms/DraggableFieldList/DraggableFieldList';
import styles from './ItemList.css';
import useTranslate from '../../../hooks/useTranslate';
import List from '../../atoms/list/List';

function ItemList({ items, title, description, draggable, resource }) {
  const i18n = useTranslate();
  const RenderBox = draggable ? DraggableFieldList.Item : List.Item;

  return (
    <>
      <h3 className={styles.ItemList__title}>{title()}</h3>
      <p className={styles.ItemList__description}>{description()}</p>
      <DraggableFieldList>
        {items.map(
          (item, i) =>
            item.type !== 'data_numeric' && (
              <RenderBox
                key={i}
                item={{
                  type: 'field-stub',
                  fieldType: item.type,
                  ...item.boxDefaults,
                }}
                color={item.meta.color}
                icon={item.meta.icon}
              >
                {resource && i18n(`${resource}.${item.type}.label`)}
              </RenderBox>
            ),
        )}
      </DraggableFieldList>
    </>
  );
}

ItemList.defaultProps;
ItemList.propTypes = {
  items: PropTypes.array,
  resource: PropTypes.string,
  title: PropTypes.func,
  description: PropTypes.func,
  draggable: PropTypes.bool,
};

ItemList.defaultProps = {
  draggable: true,
  title: () => null,
  description: () => null,
};

export default ItemList;
