import { FORM_BLOCK_MOVE, FORM_FIELD_UPDATE } from '../actions/form';
import { getFieldsInBlock, getBlocksInBlock } from '../../helpers/getFieldsInBlock';
export default function updateMiddleware({ getState, dispatch }) {
  const catchAction = [FORM_BLOCK_MOVE];
  return (next) => async (action) => {
    const state = getState();
    next(action);
    if (
      !catchAction.find((actionVerb) => {
        return actionVerb === action.type;
      })
    )
      return;

    const { blockId, data } = action.payload;
    const block = state.editForm.present.form.blocks.find((block) => block.id === blockId);
    const fields = getFieldsInBlock(state.editForm.present.form, block);
    const innerBlocks = getBlocksInBlock(state.editForm.present.form, block);
    const diff = {
      x: data.box.x - block.box.x,
      y: data.box.y - block.box.y,
    };
    const resize = data.box.width !== block.box.width || data.box.height !== block.box.height;
    innerBlocks.map((innerBlock) => {
      const payload = {
        form: state.editForm.present.form.id,
        blockId: innerBlock.id,
        data: {
          box: {
            x: innerBlock.box.x + diff.x,
            y: innerBlock.box.y + diff.y,
            width: innerBlock.box.width,
            height: innerBlock.box.height,
          },
        },
      };
      dispatch({
        type: FORM_BLOCK_MOVE,
        payload,
      });
    });
    fields.map((field) => {
      const payload = {
        form: state.editForm.present.form.id,
        fieldId: field.id,
        data: {
          box: {
            x: resize ? field.box.x : field.box.x + diff.x,
            y: resize ? field.box.y : field.box.y + diff.y,
            width: field.box.width,
            height: field.box.height,
          },
        },
      };
      dispatch({
        type: FORM_FIELD_UPDATE,
        payload,
      });
    });
  };
}
