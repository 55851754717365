import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { classnames } from '../../../helpers/classnames';
import { bem } from '../../../helpers/styles';

import styles from './Switch.css';

function Switch({
  type,
  label,
  name,
  id,
  checked,
  className,
  style,
  onChange,
  onBlur,
  ...restProps
}) {
  useEffect(() => {
    onBlur();
  }, [checked]);

  const props = {
    type,
    label,
    name,
    id,
    checked,
    className: classnames([bem(styles, 'Switch'), className]),
    style,
    onChange: ({ target: { checked } }) => onChange(checked),
    onBlur,
  };

  return <input {...props} {...restProps} />;
}

Switch.defaultProps = {
  type: 'checkbox',
  id: uuid(),
  onChange: () => null,
  onBlur: () => null,
};

Switch.propTypes = {
  type: PropTypes.oneOf(['checkbox', 'radio']),
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default Switch;
