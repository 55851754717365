export function parseDuration(str) {
  const [h, m, s] = str.split(':');
  let durationMs = 0;

  if (typeof s !== 'undefined') {
    durationMs += parseInt(s, 10);
  }

  if (typeof m !== 'undefined') {
    durationMs += parseInt(m, 10) * 60;
  }

  if (typeof h !== 'undefined') {
    durationMs += parseInt(h, 10) * 60 * 60;
  }

  return durationMs * 1000;
}

export function formatDuration(duration) {
  let seconds = Math.floor((duration / 1000) % 60);
  let minutes = Math.floor((duration / (1000 * 60)) % 60);

  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  return minutes + 'm' + seconds;
}
