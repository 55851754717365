import React from 'react';
import PropTypes from 'prop-types';

function VideoPlayer({ className, url, controls }) {
  return (
    <video className={className} controls={controls} tabIndex={-1}>
      <source src={url} type="video/mp4" />
    </video>
  );
}

VideoPlayer.defaultProps = {
  className: '',
  controls: false,
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
  controls: PropTypes.bool,
};

export default VideoPlayer;
