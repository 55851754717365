import React, { useCallback } from 'react';
import Drawer from '../../../../organisms/Drawer/Drawer';
import CreateForm from '../../../../form/CreateForm/CreateForm';
import { useDispatch, useSelector } from 'react-redux';
import { submit, getFormValues } from 'redux-form';

import { updateForm } from '../../../../../store/actions/editForm';
import useCurrentForm from '../../../../../hooks/useCurrentForm';
import { isFormPublishedSelector } from '../../../../../store/selectors/editForm';

function DrawerHeaderFormPage() {
  const dispatch = useDispatch();
  const [form] = useCurrentForm();
  const isPublished = useSelector(isFormPublishedSelector);
  const valuesForm = useSelector(getFormValues('create'));
  // Trigger submission on blur
  const handleFormBlur = useCallback(() => {
    dispatch(submit('create'));
  }, [valuesForm]);

  // On submit push data to the redux store
  const handleSubmit = useCallback(
    (values) => {
      dispatch(updateForm({ name: values.name }));
    },
    [valuesForm, form],
  );

  return (
    <>
      <Drawer.Title>
        {form && (
          <CreateForm
            disabled={isPublished}
            initialValues={form}
            onSubmit={handleSubmit}
            onBlur={handleFormBlur}
          />
        )}
      </Drawer.Title>
    </>
  );
}

DrawerHeaderFormPage.propTypes = {};

export default DrawerHeaderFormPage;
