import { TEXT, VIDEO, AUDIO, VIEWER, IMAGE, LINK, NUM, IFRAME, PDF_OCR } from '../constants/typology/dataType';
import dummyVideo from '../assets/dummy/video.mp4';
import dummyAudio from '../assets/dummy/sound.mp3';
import dummyPDF from '../assets/dummy/dummy.pdf';
import dummyImage from '../assets/dummy/image.jpg';

export default function getDummyData(fieldType) {
  switch (fieldType) {
    case TEXT:
      return `<p>Contrary to <b>popular belief</b>, Lorem Ipsum is not simply random text.</p> <p>It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.</p>`;
    case NUM:
      return `<p>123456789</p>`;
    case VIDEO:
      return dummyVideo;
    case AUDIO:
      return dummyAudio;
    case VIEWER:
      return dummyPDF;
    case LINK:
      return 'https://www.wirk.io/';
    case IMAGE:
      return dummyImage;
    case IFRAME:
      return 'https://www.wirk.io/';
    case PDF_OCR:
      return dummyPDF;
    default:
      break;
  }
}
