import React, { useMemo, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import Input from '../../../atoms/Input/Input';
import InputDateTime from '../../../atoms/InputDateTime/InputDateTime';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import styles from './DatetimeField.css';
import FieldError from '../../Error/FieldError';
import { getConfidenceFieldStyle } from '../../../../helpers/form';

function DateOrTimeFieldRender({
  input,
  type,
  label,
  placeholder,
  id,
  confidence,
  meta: { error, touched },
  disabled,
  isFocusOnInit,
}) {
  const style = useMemo(() => !touched && getConfidenceFieldStyle(confidence), [
    confidence,
    touched,
  ]);

  return (
    <>
      <Input
        {...input}
        isFocusOnInit={isFocusOnInit}
        type={type}
        error={touched && error}
        label={label}
        id={id}
        block
        placeholder={placeholder}
        disabled={disabled}
        style={style}
      />
      {touched && error && <FieldError error={error} />}
    </>
  );
}

DateOrTimeFieldRender.propTypes = {
  isFocusOnInit: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  type: PropTypes.string,
  iconRight: PropTypes.string,
  disabled: PropTypes.bool,
  confidence: PropTypes.number,
};

/**
 * InputDateFieldRender
 */
function InputDateFieldRender({
  input,
  id,
  confidence,
  meta: { error, touched },
  disabled,
  isFocusOnInit,
}) {
  const style = useMemo(() => !touched && getConfidenceFieldStyle(confidence), [
    confidence,
    touched,
  ]);

  return (
    <>
      <InputDateTime
        isFocusOnInit={isFocusOnInit}
        value={input?.value}
        name={input?.name}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        onChange={input?.onChange}
        error={touched && error}
        id={id}
        disabled={disabled}
        style={style}
      />
      {touched && error && <FieldError error={error} />}
    </>
  );
}

InputDateFieldRender.propTypes = {
  isFocusOnInit: PropTypes.bool,
  input: PropTypes.object,
  meta: PropTypes.shape({
    name: PropTypes.string,
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  disabled: PropTypes.bool,
  confidence: PropTypes.number,
};

const parseDate = (input = '') => {
  try {
    let date = '';
    let time = '';

    if (input.includes('T')) {
      [date, time] = input.split('T');
    } else if (input.includes(':')) {
      time = input;
    } else {
      date = input;
    }
    return [date, time];
  } catch (e) {
    return ['', ''];
  }
};

function DateAndTimeFieldRender({
  input,
  placeholder,
  id,
  confidence,
  meta: { error, touched },
  disabled,
  isFocusOnInit,
}) {
  // The value is stored in format <date>T<time> so we need to split it
  const [dateValue, timeValue] = useMemo(() => parseDate(input?.value), [input.value]);

  // The two change handlers take care of sending back a properly formatted value
  const onChangeDateHandle = (value) => {
    if (value) {
      timeValue ? input.onChange(`${value}T${timeValue}`) : input.onChange(value);
    } else {
      timeValue ? input.onChange(timeValue) : input.onChange();
    }
  };

  const onBlurDateHandle = (value) => {
    if (value) {
      timeValue ? input.onBlur(`${value}T${timeValue}`) : input.onBlur(value);
    } else {
      timeValue ? input.onBlur(timeValue) : input.onBlur();
    }
  };

  const onChangeTimeHandle = (timeValue) => {
    if (timeValue) {
      dateValue ? input.onChange(`${dateValue}T${timeValue}`) : input.onChange(timeValue);
    } else {
      dateValue ? input.onChange(dateValue) : input.onChange();
    }
  };

  const onBlurTimeHandle = (timeValue) => {
    if (timeValue) {
      dateValue ? input.onBlur(`${dateValue}T${timeValue}`) : input.onBlur(timeValue);
    } else {
      dateValue ? input.onBlur(dateValue) : input.onBlur();
    }
  };

  const style = useMemo(() => !touched && getConfidenceFieldStyle(confidence), [
    confidence,
    touched,
  ]);

  return (
    <>
      <div className={styles.DatetimeField__dateAndTime}>
        <InputDateTime
          isFocusOnInit={isFocusOnInit}
          value={dateValue}
          name={input?.name}
          onFocus={input.onFocus}
          onBlur={onBlurDateHandle}
          onChange={onChangeDateHandle}
          error={touched && error}
          id={id}
          disabled={disabled}
          style={style}
        />
        <Input
          onFocus={input.onFocus}
          onBlur={onBlurTimeHandle}
          value={timeValue}
          onChange={onChangeTimeHandle}
          type="time"
          error={touched && error}
          id={id}
          block
          placeholder={placeholder}
          disabled={disabled}
          style={style}
        />
      </div>

      {touched && error && <FieldError error={error} />}
    </>
  );
}

DateAndTimeFieldRender.propTypes = {
  isFocusOnInit: PropTypes.bool,
  input: PropTypes.object,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  iconRight: PropTypes.string,
  disabled: PropTypes.bool,
  confidence: PropTypes.number,
};

function DatetimeField({
  name,
  label,
  required,
  information,
  datetimeFormat,
  validation,
  iconRight,
  fieldHoC: FieldHoC,
  verticalAlign,
  answersChoices,
  ...props
}) {
  const id = useMemo(() => `name_${uuid()}`, [name]);

  const format = (validation && validation.datetimeFormat) || datetimeFormat;

  return (
    <FormField
      label={label}
      name={name}
      htmlFor={id}
      information={information}
      required={required}
      verticalAlign={verticalAlign}
      answersChoices={answersChoices}
    >
      {format === 'date' && (
        <FieldHoC
          name={name}
          type="date"
          iconRight={iconRight}
          component={InputDateFieldRender}
          id={id}
          {...props}
        />
      )}
      {format === 'time' && (
        <FieldHoC
          name={name}
          type="time"
          iconRight={iconRight}
          component={DateOrTimeFieldRender}
          id={id}
          {...props}
        />
      )}
      {format === 'datetime' && (
        <FieldHoC name={name} component={DateAndTimeFieldRender} id={id} {...props} />
      )}
    </FormField>
  );
}

DatetimeField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  information: PropTypes.string,
  datetimeFormat: PropTypes.string,
  iconRight: PropTypes.string,
  fieldHoC: PropTypes.func,
  validation: PropTypes.object,
  verticalAlign: PropTypes.bool,
  answersChoices: PropTypes.array,
};

DatetimeField.defaultProps = { datetimeFormat: 'date', verticalAlign: false, fieldHoC: Field };

export default DatetimeField;
