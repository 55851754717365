import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import useFetchAction from '../../../hooks/useFetchAction';
import { getAnswerCertification as getAnswerCertificationAction } from '../../../store/actions/answer';
import { useParams } from 'react-router';
import Spinner from '../../atoms/Spinner/Spinner';
import ContributeAnswerFormTemplate from '../../templates/ContributeAnswerFormTemplate/ContributeAnswerFormTemplate';
import ErrorContributeTemplate from '../../templates/ErrorContributeTemplate/ErrorContributeTemplate';
import ContributeAnswerDetailTemplate from '../../templates/ContributeAnswerDetailTemplate/ContributeAnswerDetailTemplate';
import ContributeAnswer from '../../organisms/ContributeAnswer/ContributeAnswer';
import ContributeCloseButtonTemplate from '../../templates/ContributeCloseButtonTemplate/ContributeCloseButtonTemplate';
import { getFormTask as getFormTaskAction } from '../../../store/actions/task';

function CertificationViewPage({ withMarking }) {
  const params = useParams();
  const [getAnswerCertification, answerCertificationLoading, answerCertificationError, answerCertification] = useFetchAction(getAnswerCertificationAction);

  const [getForm, formLoading, formError, form] = useFetchAction(getFormTaskAction);
  const columnAnswer =
  answerCertification && answerCertification.Columns
    ? answerCertification.Columns.map((elem) => {
        const tempObj = {
          id: elem.Name,
          type: elem.Type,
          value: elem.Data,
        };
        return tempObj;
      })
    : [];

  const emptyAnswer = { blocks: [], data: columnAnswer, fields: [] };

  // Creates a key-value pair of task data
  const formData = useMemo(() => {
    const formData = { ...answerCertification?.json };
    answerCertification?.columns.forEach((col) => {
      if (!!formData?.data && formData.data.find((elem) => elem.id === col.name) == null) {
        formData.data.push({
          id: col.name,
          type: col.type,
          value: col.data,
        });
      }
    });

    return formData;
  }, [answerCertification]);

 
  // First we load the task
  useEffect(() => {
    getAnswerCertification(params.certificationUserTestId, params.questionId);
  }, []);

  // Once task is loaded, load the form
  useEffect(() => {
    if (answerCertification?.formId && !form && !formLoading) {
      getForm(answerCertification.formId);
    }
  }, [answerCertification, form, formLoading]);

  if (!answerCertificationError && !formError) {
    if (!form || formLoading || answerCertificationLoading) {
      return <Spinner />;
    }
  }


  return (
    <ContributeAnswer>
      <ContributeCloseButtonTemplate isView projectId={answerCertification.certificationId} />
      {withMarking && (
        <ContributeAnswerDetailTemplate
          answer={answerCertification}
          form={form}
        ></ContributeAnswerDetailTemplate>
      )}
      <ErrorContributeTemplate answerError={answerCertificationError} formError={formError}>
        <ContributeAnswerFormTemplate answer={formData ? formData : emptyAnswer} form={form} />
      </ErrorContributeTemplate>
    </ContributeAnswer>
  );
}

CertificationViewPage.propTypes = {
  withMarking: PropTypes.bool,
};

export default CertificationViewPage;
