import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { currentFormSelector, editFormLoadingSelector } from '../store/selectors/editForm';
import { getForm } from '../store/actions/form';
import { emptyForm } from '../constants/emtyForm';
export default function useCurrentForm() {
  const { formId } = useParams();

  // form static (no get ) if url /demo
  const form = (formId === "demo" && window && window.location.href.search("demo/preview") < 0 ) ? emptyForm : useSelector(currentFormSelector);
  const loading = useSelector(editFormLoadingSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if ((!form && !loading) || (form && String(form.id).toLowerCase() !== formId.toLowerCase())) {
      dispatch(getForm(formId));
    }
  }, [formId, form]);

  return [form, loading];
}
