import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import styles from './App.css';
import PreviewPage from '../../pages/Preview/PreviewPage';
import ViewPage from '../../pages/View/ViewPage';
import CreatePage from '../../pages/Create/CreatePage';
import {
  PATH_CREATE_FORM,
  PATH_CONTRIBUTE_FORM,
  PATH_EDITFIELDS_FORM,
  PATH_DATA_FORM,
  PATH_VIEW_FORM,
  PATH_PREVIEW_FORM,
  PATH_SETTINGS_FORM,
  PATH_INFO_FORM,
  PATH_CONTRIBUTE_VIEW_HIT,
  PATH_CERTIFICATION_VIEW,
  PATH_CERTIFICATION_FORM,
  PATH_CONTRIBUTE_VIEW_TASK,
  PATH_CONTRIBUTE_REVIEW_FORM,
  PATH_BLOCKS_FORM,
  PATH_QUALITY_FORM,
  PATH_RESPONDANTS_FORM,
  PATH_STRATEGY_FORM,
  PATH_VALIDATION_FORM,
  PATH_FIELD_NO_COMPARE,
  PATH_FIELD_TO_COMPARE,
  PATH_BLOCKS_SORTING,
  PATH_CERTIFICATION_PUBLISH_QUESTION,
  PATH_KEYBOARD_SHORTCUTS,
  PATH_ADVANCED_FIELD_TO_COMPARE,
  PATH_ITERATION_OVERLOAD,
  PATH_DEMO,
} from '../../../constants/paths';
import AppDrawer from './Drawer/AppDrawer';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import InfoButton from '../../molecules/Info/InfoButton';
import InfoPage from '../../pages/Info/InfoPage';
import ContributePage from '../../pages/Contribute/ContributePage';
import CertificationPage from '../../pages/Certification/CertificationPage';
import CertificationQuestionPage from '../../pages/Certification/CertificationQuestionPage';
import CertificationViewPage from '../../pages/Certification/CertificationViewPage';
import ContributeViewTaskPage from '../../pages/Contribute/ContributeViewTaskPage';
import ContributeViewHitPage from '../../pages/Contribute/ContributeViewHitPage';
import * as config from '../../../helpers/config';
import WYSIWYGGridPage from '../../pages/WYSIWYGGrid/WYSIWYGGridPage';
import { ToastContainer } from 'react-toastify';
import ShortcutKeyboard from '../../atoms/ShortcutKeyboard/ShortcutKeyboard.jsx';
import AlertProvider from '../../molecules/Alert/AlertContext';
import cssvars from '../../../styles/variables';

function App() {
  const path = [
    PATH_PREVIEW_FORM,
    PATH_EDITFIELDS_FORM,
    PATH_DATA_FORM,
    PATH_SETTINGS_FORM,
    PATH_INFO_FORM,
    PATH_BLOCKS_FORM,
    PATH_QUALITY_FORM,
    PATH_RESPONDANTS_FORM,
    PATH_STRATEGY_FORM,
    PATH_CONTRIBUTE_VIEW_HIT,
    PATH_CERTIFICATION_FORM,
    PATH_CONTRIBUTE_VIEW_TASK,
    PATH_CONTRIBUTE_REVIEW_FORM,
    PATH_CERTIFICATION_PUBLISH_QUESTION,
    PATH_FIELD_NO_COMPARE,
  ];

  // Track scrolling offset inside overflow container
  const [scrollOffset, setScrollOffset] = useState({ x: 0, y: 0 });
  const handleScroll = (event) => {
    setScrollOffset({
      y: event?.target?.scrollTop || 0,
      x: event?.target?.scrollLeft || 0,
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <AlertProvider>
        <Router basename={config.get('basename')}>
          <LastLocationProvider>
            <div className={styles.App} style={{ '--font-size-input': cssvars['font-size-input'] }}>
              <Route
                path={[
                  PATH_EDITFIELDS_FORM,
                  PATH_DATA_FORM,
                  PATH_BLOCKS_FORM,
                  PATH_SETTINGS_FORM,
                  PATH_INFO_FORM,
                  PATH_QUALITY_FORM,
                  PATH_RESPONDANTS_FORM,
                  PATH_STRATEGY_FORM,
                  PATH_VALIDATION_FORM,
                  PATH_FIELD_NO_COMPARE,
                  PATH_FIELD_TO_COMPARE,
                  PATH_BLOCKS_SORTING,
                  PATH_KEYBOARD_SHORTCUTS,
                  PATH_ADVANCED_FIELD_TO_COMPARE,
                  PATH_ITERATION_OVERLOAD,
                ]}
                exact
                component={AppDrawer}
              />
              <main onScroll={handleScroll} className={styles.App__main}>
                <Switch>
                  <Route path={PATH_VIEW_FORM} exact component={ViewPage} />
                  <Route path={PATH_PREVIEW_FORM} exact component={PreviewPage} />
                  <Route
                    path={[
                      PATH_EDITFIELDS_FORM,
                      PATH_DATA_FORM,
                      PATH_BLOCKS_FORM,
                      PATH_SETTINGS_FORM,
                      PATH_INFO_FORM,
                      PATH_QUALITY_FORM,
                      PATH_RESPONDANTS_FORM,
                      PATH_STRATEGY_FORM,
                      PATH_VALIDATION_FORM,
                      PATH_FIELD_NO_COMPARE,
                      PATH_FIELD_TO_COMPARE,
                      PATH_BLOCKS_SORTING,
                      PATH_KEYBOARD_SHORTCUTS,
                      PATH_ADVANCED_FIELD_TO_COMPARE,
                      PATH_ITERATION_OVERLOAD,
                    ]}
                    exact
                    render={(props) => <WYSIWYGGridPage scrollOffset={scrollOffset} {...props} />}
                  />
                  <Route path={PATH_CREATE_FORM} exact component={CreatePage} />
                  <Route path={PATH_CONTRIBUTE_FORM} exact component={ContributePage} />
                  <Route path={PATH_CERTIFICATION_FORM} exact component={CertificationPage} />
                  <Route
                    path={PATH_CERTIFICATION_PUBLISH_QUESTION}
                    exact
                    component={CertificationQuestionPage}
                  />
                  <Route path={PATH_CONTRIBUTE_VIEW_HIT} exact component={ContributeViewHitPage} />
                  <Route
                    path={PATH_CONTRIBUTE_VIEW_TASK}
                    exact
                    render={() => <ContributeViewTaskPage />}
                  />
                  <Route
                    path={PATH_CERTIFICATION_VIEW}
                    exact
                    render={() => <CertificationViewPage />}
                  />
                  <Route
                    path={PATH_CONTRIBUTE_REVIEW_FORM}
                    exact
                    render={({ props }) => <ContributePage {...props} isReview />}
                  />
                  <Route path={PATH_BLOCKS_SORTING} exact component={ContributePage} />
                  <Route path={PATH_DEMO} exact component={AppDrawer} />

                  {/* TODO: better not found */}
                  <Route render={() => 'not found'} />
                </Switch>
              </main>
              <Route path={path} exact component={InfoPage} />
              <Route path={path} exact component={InfoButton} />
            </div>
          </LastLocationProvider>
        </Router>
        <ToastContainer
          autoClose={6000}
          position={'bottom-right'}
          hideProgressBar={true}
          pauseOnHover={false}
        />
        <ShortcutKeyboard />
      </AlertProvider>
    </DndProvider>
  );
}

export default App;
