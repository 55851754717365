import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { classnames } from '../../../helpers/classnames';
import styles from './PdfOcrViewer.css';

import * as pdfJsLib from 'pdfjs-dist';
import pdfJsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import PdfOcrViewerPage from './PdfOcrViewerPage';
import useSize from '../../../hooks/useSize';
import { usePdfOcrViewerContext } from '../../organisms/PdfOcrViewer/PdfOcrViewerContext';

pdfJsLib.GlobalWorkerOptions.workerSrc = pdfJsWorker;

function PdfOcrViewer({ name, link, className, metadata }) {
  const ref = useRef(null);
  const { width: viewerWidth } = useSize(ref);
  const [goToField, setGoTo] = useState(null);

  const { registerViewer, unregisterViewer } = usePdfOcrViewerContext();

  const handleGoTo = useCallback(
    (field) => {
      setGoTo(field);
    },
    [ref.current],
  );

  useEffect(() => {
    registerViewer(name, { goTo: handleGoTo });
    return () => unregisterViewer(name);
  }, [name, handleGoTo]);

  const [pdf, setPdf] = useState(null);
  useEffect(() => {
    if (link == null) {
      console.error('Missing link');
      return;
    }

    pdfJsLib.getDocument(link).promise.then((pdf) => {
      console.log('PDF loaded, with', pdf.numPages, 'pages');
      setPdf(pdf);
    });
  }, [link]);

  return (
    <div ref={ref} className={classnames([styles.PdfOcrViewer, className])}>
      {pdf != null ? (
        <>
          {Array.from({ length: pdf.numPages }, (_, i) => i).map((pageNum) => (
            <PdfOcrViewerPage
              key={pageNum}
              pdf={pdf}
              pageNum={pageNum}
              viewerWidth={viewerWidth}
              metadata={metadata?.pages[pageNum]}
              goToField={goToField}
              containerRef={ref}
            />
          ))}
        </>
      ) : null}
    </div>
  );
}

PdfOcrViewer.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  metadata: PropTypes.object,
};

export default PdfOcrViewer;
