import React, { useCallback, useEffect } from 'react';
import styles from './DrawerContentValidation.css';
import useTranslate from '../../../../../hooks/useTranslate';
import Button from '../../../../atoms/Button/Button';
import cssvars from '../../../../../styles/variables';
import { useActions } from '../../../../../hooks/useActions';
import { addValidationRule as addValidationRuleAction } from '../../../../../store/actions/form';
import { useSelector } from 'react-redux';
import {
  focusedRuleIdSelector,
  validationRulesSelector,
} from '../../../../../store/selectors/editForm';
import ValidationRule from '../../../../molecules/ValidationRule/ValidationRule';
import {
  setFocusedValidationRule as setFocusedValidationRuleAction,
  setSelectFields as setSelectFieldsAction,
  setSelectMode as setSelectModeAction,
} from '../../../../../store/actions/editForm';
import QualitySelectTemplate from '../../../../templates/QualitySelectTemplate/QualitySelectTemplate';

function DrawerContentValidation() {
  const i18n = useTranslate();
  const [addValidationRule, setFocusedValidationRule, setSelectMode, setSelectFields] = useActions([
    addValidationRuleAction,
    setFocusedValidationRuleAction,
    setSelectModeAction,
    setSelectFieldsAction,
  ]);
  const rules = useSelector(validationRulesSelector);
  const focusedRuleId = useSelector(focusedRuleIdSelector);

  // Enable / disable select mode on mount / unmount
  useEffect(() => {
    setSelectFields([]);
    setSelectMode(true);
    return () => setSelectMode(false);
  }, []);

  const handleAddRule = useCallback(() => {
    // Handle the case where user started to click on fields but no rules exists yet
    // We clear the state to prevent initializing a rule with a bunch of uneeded fields
    setSelectFields([]);
    addValidationRule({
      name: i18n('DrawerContentValidation.newRuleName', { count: rules.length + 1 }),
    });
  }, [rules]);

  return (
    <div>
      <QualitySelectTemplate icon="ruler" titleKey="QualityNavTemplate.validation" />
      <h3 className={styles.DrawerContentValidation__title}>
        {i18n('DrawerContentValidation.title')}
      </h3>
      <p className={styles.DrawerContentValidation__description}>
        {i18n('DrawerContentValidation.description')}
      </p>
      <div className={styles.DrawerContentValidation__buttons}>
        <Button
          onClick={handleAddRule}
          color={cssvars['color-accent']}
          block
          rounded
          className={styles.DrawerContentValidation__newButton}
          icon="plus_circle"
        >
          {i18n('DrawerContentValidation.newRule')}
        </Button>
        {!rules.length && (
          <div className={styles.DrawerContentValidation__emptyState}>
            {i18n('DrawerContentValidation.noRules')}
          </div>
        )}
      </div>
      <div>
        {!!rules.length &&
          rules.map((rule) => (
            <ValidationRule
              rule={rule}
              key={rule.id}
              open={focusedRuleId === rule.id}
              onFocus={setFocusedValidationRule}
            />
          ))}
      </div>
    </div>
  );
}

DrawerContentValidation.propTypes = {};

export default DrawerContentValidation;
