import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useTranslate from '../../../hooks/useTranslate';
import Error from '../../atoms/Error/Error';
import * as config from '../../../helpers/config';
import Button from '../../atoms/Button/Button';

function ErrorContributeCertificationTemplate({
  children,
  formError,
  answerError,
  certificationAnswerError,
  certificationError,
  certificationsDepleted,
  timerElapsed,
}) {
  const i18n = useTranslate();
  const urlRedirect = useMemo(() => {
    if (answerError) return config.getUrlRedirection('urls.desk.backnav');
    if (certificationError || formError) return config.getUrlRedirection('urls.team.signout');
    if (certificationAnswerError) return config.getUrlRedirection('urls.team.projects');
  }, [formError, answerError, certificationAnswerError, certificationError]);

  // Certification null body handling
  if (certificationsDepleted) {
    return (
      <Error
        fill
        action={() => (
          <Button as="a" href={config.getUrlRedirection('urls.team.certification')}>
            {i18n('ContributePage.noCertificationError.action')}
          </Button>
        )}
      >
        {i18n('ContributePage.noCertificationError.description')}
      </Error>
    );
  }

  // Redirect to team logout page if 401 is received
  if (answerError?.code === 401 || certificationError?.code === 401 || formError?.code === 401) {
    window.location.href = urlRedirect;
  }

  if (timerElapsed) {
    alert('Le temps alloué à la tâche est écoulé. Vous allez être redirigé.');
    window.location.href = urlRedirect;
  }

  // Certification answer error handling
  if (answerError?.code === 500 || certificationAnswerError?.code === 500) {
    return (
      <Error
        fill
        action={() => (
          <Button as="a" href={urlRedirect}>
            {i18n('ContributePage.answerError.action')}
          </Button>
        )}
      >
        {i18n('ContributePage.answerError.description')}
      </Error>
    );
  }

  // Generic error handling
  if (formError || answerError || certificationAnswerError || certificationError) {
    return (
      <Error title={i18n('error.default.title')} fill>
        {i18n('error.default.body')}
      </Error>
    );
  }

  return <>{children}</>;
}

ErrorContributeCertificationTemplate.propTypes = {
  children: PropTypes.node,
  formError: PropTypes.shape({
    code: PropTypes.number,
  }),
  answerError: PropTypes.shape({
    code: PropTypes.number,
  }),
  certificationAnswerError: PropTypes.shape({
    code: PropTypes.number,
  }),
  certificationError: PropTypes.shape({
    code: PropTypes.number,
  }),
  certificationsDepleted: PropTypes.bool,
  timerElapsed: PropTypes.bool,
};

export default ErrorContributeCertificationTemplate;
