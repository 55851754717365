import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import SVG from '../SVG/SVG';
import { classnames } from '../../../helpers/classnames';
import { bem } from '../../../helpers/styles';

import styles from './Button.css';

const Button = React.forwardRef(function Button(
  {
    className,
    color,
    flat,
    icon,
    as: ButtonComponent,
    children,
    block,
    disabled,
    active,
    rounded,
    style,
    ...restProps
  },
  ref,
) {
  const hasIcon = !!icon;
  const props = {
    disabled,
    className: classnames([
      styles.Button,
      bem(styles, 'Button', {
        flat,
        block,
        disabled,
        active,
        rounded,
        [`theme-${color}`]: !!color,
      }),
      className,
      style,
    ]),
  };

  const iconStyle = useMemo(
    () => (icon && style && style.fill ? { fill: style.fill } : undefined),
    [icon, style],
  );

  return (
    <ButtonComponent {...props} {...restProps} ref={ref}>
      {hasIcon && <SVG className={styles.Button__Icon} glyph={icon} style={iconStyle} />}
      {children}
    </ButtonComponent>
  );
});

Button.defaultProps = {
  as: 'button',
};

Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  flat: PropTypes.bool,
  icon: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.node,
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  rounded: PropTypes.bool,
  style: PropTypes.object,
};

export default Button;
