import { createSelector } from 'reselect';

export const certificationsSelector = (state) => state.certification;
export const certificationsQuestionSelector = (state) => state.certificationQuestion;

export const currentCertificationSelector = createSelector(certificationsSelector, (state) => state?.currentCertification);

export const certificationsDepletedSelector = createSelector(
  certificationsSelector,
  (state) => !state?.currentCertification && state.hasReceivedNullCertification,
);

export const hasReceivedNullCertificationSelector = createSelector(
  certificationsSelector,
  (state) => state.hasReceivedNullCertification,
);

export const currentCertificationQuestionSelector = createSelector(certificationsQuestionSelector, (state) => state?.currentCertificationQuestion);
