import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, getFormValues } from 'redux-form';
import TextField from '../../molecules/Field/Text/TextField';
import Form from '../../atoms/Form/Form';
import { FORM_BLOCK } from '../../../constants/form';
import SwitchField from '../../molecules/Field/Switch/SwitchField';
import useTranslate from '../../../hooks/useTranslate';
import { useSelector } from 'react-redux';
import NumberField from '../../molecules/Field/Number/NumberField';
import RadioColorField from '../../molecules/Field/RadioColor/RadioColorField';
import HideRuleFragment from '../HideRuleFragment/HideRuleFragment';
import useFeatToogle from '../../../hooks/useFeatToggle';
import RangeField from '../../molecules/Field/Range/RangeField';
import { focusedBlockSelector } from '../../../store/selectors/editForm';
function BlockForm({ handleSubmit, isPublished, onBlur, form }) {
  const i18n = useTranslate();
  const values = useSelector(getFormValues(FORM_BLOCK));
  const selectedBlock = useSelector(focusedBlockSelector);
  const featHideIf = useFeatToogle('hideIf');

  return (
    <Form onSubmit={handleSubmit}>
      {isPublished && <TextField readonly name="id" placeholder="ID" verticalAlign={false} />}
      <SwitchField
        name="nameEnabled"
        label={i18n('BlockForm.name')}
        onBlur={onBlur}
        disabled={isPublished}
      />
      {values && values.nameEnabled && (
        <TextField
          name="name"
          placeholder={i18n('BlockForm.name')}
          onBlur={onBlur}
          verticalAlign={false}
          disabled={isPublished}
        />
      )}
      <SwitchField
        name="foldable"
        label={i18n('BlockForm.foldable')}
        onBlur={onBlur}
        disabled={isPublished}
      />
      <SwitchField
        name="duplicable"
        label={i18n('BlockForm.duplicable')}
        onBlur={onBlur}
        disabled={isPublished}
      />

      {selectedBlock.duplicable && (
        <>
          <RangeField label={i18n('BlockForm.rowsRange')} name="rowsRange" disabled={isPublished} />
          <SwitchField
            name="floatingControls"
            label={i18n('BlockForm.floatingControls')}
            onBlur={onBlur}
            disabled={isPublished}
          />
        </>
      )}

      <SwitchField
        name="maxRowsEnabled"
        label={i18n('BlockForm.maxRows')}
        onBlur={onBlur}
        disabled={isPublished}
      />
      {values && values.maxRowsEnabled && (
        <NumberField
          name="maxRows"
          placeholder={i18n('BlockForm.maxRows')}
          onBlur={onBlur}
          disabled={isPublished}
        />
      )}
      <RadioColorField
        name="color"
        label={i18n('BlockForm.color')}
        onBlur={onBlur}
        disabled={isPublished}
      />
      {featHideIf && <HideRuleFragment isBlock form={form} />}
    </Form>
  );
}

BlockForm.propTypes = {
  handleSubmit: PropTypes.func,
  onBlur: PropTypes.func,
  form: PropTypes.string,
  disabled: PropTypes.bool,
  isPublished: PropTypes.bool,
};

export default reduxForm({
  form: FORM_BLOCK,
})(BlockForm);
