import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

function FakeField({ component: FieldRender, ...props }) {
  const [value, onChange] = useState(null);
  const input = useMemo(() => ({ value, onChange }), []);

  return <FieldRender input={input} meta={{ touched: false }} {...props} />;
}

FakeField.propTypes = {
  component: PropTypes.func,
};

export default FakeField;
