import React from 'react';
import PropTypes from 'prop-types';
import styles from './Error.css';
import { bem } from '../../../helpers/styles';

function Error({ title, children, fill, action }) {
  return (
    <div className={bem(styles, 'Error', { fill })}>
      {title && <div className={styles.Error__title}>{title}</div>}
      {children && <div className={styles.Error__body}>{children}</div>} {action()}
    </div>
  );
}

Error.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  fill: PropTypes.bool,
  action: PropTypes.func,
};

Error.defaultProps = {
  action: () => null,
};

export default Error;
