import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SwitchField from '../../../molecules/Field/Switch/SwitchField';
import TextField from '../../../molecules/Field/Text/TextField';
import useTranslate from '../../../../hooks/useTranslate';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';
import styles from './InjectFragment.css';

function InjectFragment({ form, fragmentType, remoteOptionsEnabled }) {
  const i18n = useTranslate();
  const injectChoices = useSelector((state) => formValueSelector(form)(state, 'injectChoices'));
  const injectLabelValue = useSelector((state) =>
    formValueSelector(form)(state, 'injectLabelValue'),
  );
  useEffect(() => {
    if (injectLabelValue) {
      document.querySelector(`[name$="injectKeyValueSeparator"]`).focus();
      document.querySelector(`[name$="injectKeyValueSeparator"]`).select();
    }
    if (injectChoices) {
      document.querySelector(`[name$="injectSeparator"]`).focus();
      document.querySelector(`[name$="injectSeparator"]`).select();
    }
  }, [injectLabelValue, injectChoices]);

  return (
    <div className={styles.InjectFragment}>
      {!remoteOptionsEnabled && (
        <SwitchField name="injectChoices" label={i18n('field.options.injectChoices')} />
      )}
      <SwitchField name="injectLabelValue" label={i18n('field.options.activateLabelValue')} />
      {injectChoices && (
        <TextField
          name="injectSeparator"
          label={
            injectLabelValue && injectChoices && fragmentType !== 'advancedSelect'
              ? i18n('field.options.separatorData')
              : i18n('field.options.injectSeparator')
          }
          initialValue="&&"
        />
      )}
      {injectLabelValue && (
        <TextField
          name="injectKeyValueSeparator"
          label={i18n('field.options.injectSeparatorValueLabel')}
          initialValue="@@"
        />
      )}
    </div>
  );
}
InjectFragment.propTypes = {
  form: PropTypes.string,
  fragmentType: PropTypes.string,
  remoteOptionsEnabled: PropTypes.bool,
};

InjectFragment.defaultProps = {
  fragmentType: '',
  remoteOptionsEnabled: false,
};

export default InjectFragment;
