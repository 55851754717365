import React, { useMemo, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import Input from '../../../atoms/Input/Input';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import styles from './RangeField.css';
import useTranslate from '../../../../hooks/useTranslate';
import { integer } from '../../../../helpers/parse';

function NumberFieldRender({ input, label, placeholder, id, meta: { error, touched }, disabled }) {
  return (
    <Input
      type="number"
      step="any"
      {...input}
      error={touched && error}
      label={label}
      id={id}
      block
      placeholder={placeholder}
      disabled={disabled}
    />
  );
}

NumberFieldRender.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  disabled: PropTypes.bool,
};

function RangeField({ name, label, ...props }) {
  const t = useTranslate();
  const id = useMemo(() => `${name}_${uuid()}`, [name]);
  const parse = useCallback((val) => integer(val), []);

  return (
    <FormField label={label} name={name} htmlFor={id}>
      <div className={styles.RangeField__inputs}>
        <Field
          name={`${name}.min`}
          component={NumberFieldRender}
          id={`${id}_min`}
          {...props}
          placeholder={t('RangeField.min')}
          parse={parse}
        />
        <Field
          name={`${name}.max`}
          component={NumberFieldRender}
          id={`${id}_max`}
          {...props}
          placeholder={t('RangeField.max')}
          parse={parse}
        />
      </div>
    </FormField>
  );
}

RangeField.propTypes = { name: PropTypes.string, label: PropTypes.string };

export default RangeField;
