import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import Input from '../../../atoms/Input/Input';
import { Field } from 'redux-form';
import FormField from '../../../atoms/Form/Field/FormField';
import FieldError from '../../Error/FieldError';
import { getConfidenceFieldStyle } from '../../../../helpers/form';

function NumberFieldRender({
  input,
  label,
  placeholder,
  id,
  readonly,
  meta: { error, touched },
  validation: { optionalUnit },
  isBignumber,
  disabled,
  confidence,
  isFocusOnInit
}) {
  const style = useMemo(() => !touched && getConfidenceFieldStyle(confidence), [
    confidence,
    touched,
  ]);

  return (
    <>
      <Input
        isFocusOnInit={isFocusOnInit}
        type={isBignumber ? 'text' : 'number'}
        mode={isBignumber ? 'number' : undefined}
        step="any"
        {...input}
        error={touched && error}
        label={label}
        id={id}
        block
        readonly={readonly}
        placeholder={placeholder}
        labelRight={optionalUnit}
        disabled={disabled}
        style={style}
      />
      {touched && error && <FieldError error={error} />}
    </>
  );
}

NumberFieldRender.propTypes = {
  isFocusOnInit: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  validation: PropTypes.shape({
    optionalUnit: PropTypes.string,
  }),
  meta: PropTypes.shape({
    error: PropTypes.any,
    touched: PropTypes.bool,
  }),
  id: PropTypes.string,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  isBignumber: PropTypes.bool,
  confidence: PropTypes.number,
};

NumberFieldRender.defaultProps = {
  validation: {},
};

function NumberField({
  name,
  label,
  required,
  information,
  verticalAlign,
  fieldHoC: FieldHOC,
  answersChoices,
  isBignumber,
  ...props
}) {
  const id = useMemo(() => `name_${uuid()}`, [name]);

  return (
    <FormField
      label={label}
      name={name}
      htmlFor={id}
      information={information}
      required={required}
      answersChoices={answersChoices}
      verticalAlign={verticalAlign}
    >
      <FieldHOC
        name={name}
        component={NumberFieldRender}
        id={id}
        {...props}
        isBignumber={isBignumber}
      />
    </FormField>
  );
}

NumberField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  information: PropTypes.string,
  fieldHoC: PropTypes.func,
  verticalAlign: PropTypes.bool,
  answersChoices: PropTypes.array,
  isBignumber: PropTypes.bool,
};

NumberField.defaultProps = {
  fieldHoC: Field,
  verticalAlign: false,
  isBignumber: false,
};

export default NumberField;
