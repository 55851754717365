import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { classnames } from '../../../helpers/classnames';
import styles from './PDFViewer.css';
import { bem } from '../../../helpers/styles';
import { isPDFUrl } from '../../../helpers/url';
import useTranslate from '../../../hooks/useTranslate';
import Error from '../../atoms/Error/Error';
function PDFViewer({ link, style, className, fixedMode }) {
  const i18n = useTranslate();
  const ref = useRef(null);
  const [styleOverride, setStyle] = useState(null);
  const error = useMemo(() => !isPDFUrl(link), [link]);

  const finalStyle = useMemo(
    () =>
      fixedMode && styleOverride
        ? {
            ...style,
            position: 'fixed',
            ...styleOverride,
          }
        : style,
    [style, styleOverride],
  );

  // In fixed mode we need to retrieve parent's actuel inner width & height
  // This allow us to set "fixed" position without overflowing parent box
  // See: https://stackoverflow.com/questions/5873565/set-width-of-a-position-fixed-div-relative-to-parent-div
  useEffect(() => {
    function updateDimensions() {
      if (fixedMode && ref.current) {
        const { clientWidth, clientHeight } = ref.current.parentNode;
        setStyle({
          ...style,
          width: clientWidth - 16,
          height: clientHeight - 16,
        });
      }
    }

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, [fixedMode, ref.current]);

  if (error) return <Error>{i18n('PDFViewer.error.description')}</Error>;

  return (
    <div style={finalStyle} className={classnames([styles.PDFViewer, className])} ref={ref}>
      <embed
        src={link}
        className={bem(styles, 'PDFViewer__embed', { fixedMode })}
        type="application/pdf"
        tabIndex={-1}
      />
    </div>
  );
}

PDFViewer.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
  style: PropTypes.string,
  fixedMode: PropTypes.bool,
};

export default PDFViewer;
