import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import AdvancedSelect from '../../atoms/AdvancedSelect/AdvancedSelect';
import { Field } from 'redux-form';
import FormField from '../../atoms/Form/Field/FormField';
import FieldError from '../Error/FieldError';
import useTranslate from '../../../hooks/useTranslate';
import { useActions } from '../../../hooks/useActions';
import { fetchOptions as fetchOptionsAction } from '../../../store/actions/options';
import { useSelector } from 'react-redux';
import { optionsStateSelector } from '../../../store/selectors/options';
import { getConfidenceFieldStyle } from '../../../helpers/form';
import { fetchRemoteOptionsFromSettings } from '../../../store/middleware/options';

function AdvancedSelectFieldRender({
    input,
    meta: { error, touched },
    placeholder,
    disabled,
    confidence,
    ...props
}) {
    const placeholderMemo = useMemo(() => {
        const i18n = useTranslate();
        return placeholder || placeholder === ''
            ? placeholder
            : i18n('AdvancedSelectField.placeholder');
    }, [placeholder]);

    const style = useMemo(() => !touched && getConfidenceFieldStyle(confidence), [
        confidence,
        touched,
    ]);

    return (
        <>
            <AdvancedSelect
                disabled={disabled}
                error={touched && error}
                {...input}
                placeholder={placeholderMemo}
                style={style}
                {...props}
            />
            {touched && error && <FieldError error={error} />}
        </>
    );
}

async function getRemoteOptionTuple(settings, value) {
    const options = await fetchRemoteOptionsFromSettings({ ...settings, query: value });
    const option = options.find((opt) => opt.value == value) || {
        value,
        label: JSON.stringify(value),
    };
    return { value: option, label: option.label };
}

async function getRemoteOptionsTuples(settings, values) {
    const options = await Promise.all(values.map((value) => getRemoteOptionTuple(settings, value)));

    return { value: options, label: options.map((opt) => opt.label).join(', ') };
}

function AdvancedSelectField({
    name,
    label,
    information,
    placeholder,
    fieldHoC: FieldHoC,
    required,
    validation,
    readonly,
    remoteOptions: remoteOptionsSettings,
    answersChoices,
    ...props
}) {
    const id = useMemo(() => `name_${uuid()}`, [name]);
    const [query, setQuery] = useState(null);
    const [resolvedAnswerChoices, setResolvedAnswerChoices] = useState([]);
    const [fetchRemoteOptions] = useActions([fetchOptionsAction]);
    const remoteOptionsState = useSelector(optionsStateSelector({ ...remoteOptionsSettings, query }));

    // Options can come from many places
    const options = useMemo(() => {
        if (remoteOptionsSettings && remoteOptionsState && remoteOptionsState.options) {
            return remoteOptionsState.options;
        }

        if (validation.options) {
            return validation.options;
        }
        if (validation.choices && validation.injectLabelValue) {
            let tempOptions = validation.choices.split('\n').map((choice) => ({
                label: choice,
                value: choice,
            }));
            let newOptions = tempOptions.map((elem) => {
                let splitString = elem.label.split(validation.injectKeyValueSeparator);
                elem.label = splitString[0];
                elem.value = splitString[1];
              });
              return tempOptions;
        }

        if (validation.choices) {
            return validation.choices.split('\n').map((choice) => ({
                label: choice,
                value: choice,
            }));
        }

        return [];
    }, [remoteOptionsState]);

    // Fetch list elements if remoteOptions is provided
    useEffect(() => {
        if (
            remoteOptionsSettings &&
            remoteOptionsSettings.url &&
            (query || !remoteOptionsSettings?.queryMode)
        ) {
            fetchRemoteOptions({ ...remoteOptionsSettings, query });
        }
    }, [remoteOptionsSettings && remoteOptionsSettings.url, query]);

    const handleFirstChoicesOpen = useCallback(async () => {
        if (!validation.remoteOptionsEnabled) {
            setResolvedAnswerChoices(answersChoices);
        } else {
            const remoteAnswersChoices = await Promise.all(
                answersChoices.map(({ value }) =>
                    !validation.multi
                        ? getRemoteOptionTuple(remoteOptionsSettings, value)
                        : getRemoteOptionsTuples(remoteOptionsSettings, value),
                ),
            );
            setResolvedAnswerChoices(remoteAnswersChoices);
        }
    }, [answersChoices]);

    return (
        information,
        (
            <FormField
                label={label}
                name={name}
                information={information}
                htmlFor={id}
                required={required}
                verticalAlign={true}
                multi={validation.multi}
                answersChoices={resolvedAnswerChoices}
                forceAnswersChoicesCaret={!!answersChoices}
                onFirstChoicesOpen={handleFirstChoicesOpen}
            >
                <FieldHoC
                    name={name}
                    component={AdvancedSelectFieldRender}
                    options={options}
                    multi={validation.multi}
                    autoselect={validation.autoselect}
                    placeholder={placeholder}
                    readonly={readonly}
                    loading={remoteOptionsState?.pending}
                    onInputChange={remoteOptionsSettings?.queryMode ? setQuery : null}
                    queryMode={remoteOptionsSettings?.queryMode}
                    remoteMode={validation.remoteOptionsEnabled}
                    {...props}
                />
            </FormField>
        )
    );
}

AdvancedSelectField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    information: PropTypes.string,
    placeholder: PropTypes.string,
    multi: PropTypes.bool,
    fieldHoC: PropTypes.func,
    required: PropTypes.bool,
    validation: PropTypes.object,
    readonly: PropTypes.bool,
    remoteOptions: PropTypes.object,
    answersChoices: PropTypes.array,
    confidence: PropTypes.number,
};

AdvancedSelectFieldRender.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    confidence: PropTypes.number,
};

AdvancedSelectField.defaultProps = {
    fieldHoC: Field,
    validation: {},
};

export default AdvancedSelectField;
