import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { allBoxesSelector } from '../../../../store/selectors/editForm';
import AdaptableField from '../../../molecules/Adaptable/AdaptableField';
import { SWITCH } from '../../../../constants/typology/fieldType';
import useTranslate from '../../../../hooks/useTranslate';
import { updateField } from '../../../../store/actions/form';
import { currentFormSelector } from '../../../../store/selectors/editForm';
import { useParams } from 'react-router';

function DataTextFragment({ form }) {
  const i18n = useTranslate();
  const { id } = useSelector((state) => formValueSelector(form)(state, 'numeric', 'id'));
  const items = useSelector(allBoxesSelector);
  const dispatch = useDispatch();
  const currentItem = items.find((_item) => _item.id === id);
  const { formId } = useParams();
  const currentForm = useSelector(currentFormSelector);

  const onChange = (value) => {
    if (value) {
      const fieldsForm = currentForm.fields.map((elem, i) => elem.id === currentItem.id && elem);
      const field = fieldsForm.find((el) => el !== false);
      field.type = 'data_numeric';
      dispatch(updateField(formId, currentItem.id, field));
    } else {
      const fieldsForm = currentForm.fields.map((elem, i) => elem.id === currentItem.id && elem);
      const field = fieldsForm.find((el) => el !== false);
      field.type = 'data_text';
      dispatch(updateField(formId, currentItem.id, field));
    }
  };

  return (
    <>
      <AdaptableField
        verticalAlign={false}
        fieldType={SWITCH}
        label={i18n('data.data_text.numeric')}
        name="numeric"
        onChange={(value) => onChange(value)}
      />
    </>
  );
}

DataTextFragment.propTypes = {
  form: PropTypes.string,
};

export default DataTextFragment;
