import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Form from '../../atoms/Form/Form';
import useTranslate from '../../../hooks/useTranslate';
import AdaptableField from '../../molecules/Adaptable/AdaptableField';
import { getTypologyByType } from '../../../helpers/typology';
import { SELECT } from '../../../constants/typology/fieldType';
import { getDeliveryAnswerOptions } from '../../../helpers/form';
import { useSelector } from 'react-redux';
import { shouldDisplayDeliveryAnswerSelector } from '../../../store/selectors/editForm';
import { RULE_MULTILIKE_MULTI } from '../../../constants/typology/comparisonRules';

function AdvancedComparisonRulesForm({ handleSubmit, resource, disabled, field: fieldMeta }) {
  const i18n = useTranslate();
  const fields = (getTypologyByType(resource)?.comparisonRules || RULE_MULTILIKE_MULTI).filter(
    (field) => !field.hideField,
  );
  const showDeliveryAnswer = useSelector(shouldDisplayDeliveryAnswerSelector);

  return (
    <Form onSubmit={handleSubmit}>
      {fields.map((field, i) => (
        <AdaptableField
          key={i}
          verticalAlign={false}
          fieldType={field.type}
          label={!field.hideLabel && field?.name && i18n(`comparisonRules.options.${field.name}`)}
          // Put label as placeholder if hidden
          placeholder={field.hideLabel && i18n(`comparisonRules.options.${field.name}`)}
          {...field}
          options={
            field.options &&
            field.options.map(({ value }) => ({
              value,
              label: i18n(`comparisonRules.options.options.${value}`),
            }))
          }
          disabled={disabled}
        />
      ))}
      {showDeliveryAnswer && (
        <AdaptableField
          verticalAlign={false}
          fieldType={SELECT}
          label={i18n(`comparisonRules.options.deliveryAnswer`)}
          // Put label as placeholder if hidden
          placeholder={i18n(`comparisonRules.options.deliveryAnswer`)}
          name="deliveryAnswer"
          options={getDeliveryAnswerOptions(resource, fieldMeta)}
          disabled={disabled}
        />
      )}
    </Form>
  );
}

AdvancedComparisonRulesForm.propTypes = {
  handleSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  resource: PropTypes.string,
  field: PropTypes.object,
};

export default reduxForm({
  form: 'advancedComparisonRules',
})(AdvancedComparisonRulesForm);
