import React from 'react';
import PropTypes from 'prop-types';
import styles from './ContributeAnswer.css';

function ContributeAnswer({ children }) {
  return <div className={styles.ContributeAnswer}>{children}</div>;
}

ContributeAnswer.propTypes = {
  children: PropTypes.node,
};

export default ContributeAnswer;
