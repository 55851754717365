import { ANWSER_TASK_GET_SUCCESS, ANWSER_HIT_GET_SUCCESS, ANSWER_CERTIFICATION_GET_SUCCESS } from '../actions/answer';

export const initialState = {};

export default function answerReducer(state = initialState, action) {
  switch (action.type) {
    case ANWSER_TASK_GET_SUCCESS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        ...action.payload,
      };
    case ANWSER_HIT_GET_SUCCESS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        ...action.payload,
      };
    case ANSWER_CERTIFICATION_GET_SUCCESS:
      if (!action.payload) {
        return state;
      }
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
