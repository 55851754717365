import React from 'react';
import PropTypes from 'prop-types';
import styles from './DrawerBody.css';

function DrawerBody({ children }) {
  return <div className={styles.DrawerBody}>{children}</div>;
}

DrawerBody.propTypes = {
  children: PropTypes.node,
};

export default DrawerBody;
