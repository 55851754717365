import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './CompareBox.css';
import { bem } from '../../../helpers/styles';
import Button from '../../atoms/Button/Button';
import CompareTag from '../../atoms/CompareTag/CompareTag';

function CompareBox({ id, fields, focused, onFocus, onMoveUp, onMoveDown, onDelete, onUpdate }) {
  const handleFocus = useCallback(() => {
    onFocus();
  }, [onFocus]);

  const handleMoveUp = useCallback(
    (event) => {
      event.stopPropagation();
      onMoveUp();
    },
    [onMoveUp],
  );

  const handleMoveDown = useCallback(
    (event) => {
      event.stopPropagation();
      onMoveDown();
    },
    [onMoveDown],
  );

  const handleDelete = useCallback(
    (event) => {
      event.stopPropagation();
      onDelete();
    },
    [onDelete],
  );

  const handleFieldDelete = useCallback(
    (fieldId) => {
      onUpdate(id, {
        fields: fields.filter((fid) => fieldId !== fid),
      });
    },
    [fields, onUpdate],
  );

  return (
    <div className={bem(styles, 'CompareBox', { focused })} onClick={handleFocus}>
      <div className={styles.CompareBox__body}>
        {fields.map((fieldId, i) => (
          <CompareTag key={i} fieldId={fieldId} onDelete={() => handleFieldDelete(fieldId)} />
        ))}
      </div>
      <div className={styles.CompareBox__actions}>
        {onMoveUp && (
          <Button
            className={styles.CompareBox__button}
            icon="arrow_up"
            flat
            onClick={handleMoveUp}
          />
        )}
        {onMoveDown && (
          <Button
            className={styles.CompareBox__button}
            icon="arrow_down"
            flat
            onClick={handleMoveDown}
          />
        )}
        <Button className={styles.CompareBox__button} icon="trash" flat onClick={handleDelete} />
      </div>
    </div>
  );
}

CompareBox.propTypes = {
  id: PropTypes.string,
  fields: PropTypes.array,
  blockScope: PropTypes.string,
  focused: PropTypes.bool,
  onFocus: PropTypes.func,
  onMoveUp: PropTypes.func,
  onMoveDown: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default CompareBox;
