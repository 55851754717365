import { NUMBER, SELECT, SWITCH } from './fieldType';

export const RULE_TEXTLIKE = [
  { name: 'case_sensitive', type: SWITCH, initialValue: false },
  { name: 'space_sensitive', type: SWITCH, initialValue: true },
  { name: 'special_char_sensitive', type: SWITCH, initialValue: true },
  { name: 'accent_sensitive', type: SWITCH, initialValue: true },
  { name: 'distance', type: NUMBER, initialValue: 100 },
  {
    hideField: true,
    name: 'deliveryAnswer',
    type: SELECT,
    options: [],
    initialValue: 1,
  },
];

export const RULE_MULTILIKE_MONO = [
  {
    hideField: true,
    name: 'deliveryAnswer',
    type: SELECT,
    options: [],
    initialValue: 8,
  },
];

export const RULE_MULTILIKE_MULTI = [
  {
    hideField: true,
    name: 'deliveryAnswer',
    type: SELECT,
    options: [],
    initialValue: 1,
  },
];

export const RULE_LOCATIONLIKE = [
  { name: 'distance', type: NUMBER, initialValue: 100 },
  {
    hideField: true,
    name: 'deliveryAnswer',
    type: SELECT,
    options: [],
    initialValue: 1,
  },
];

export const RULE_NUMBERLIKE = [
  { name: 'distance', type: NUMBER, initialValue: 0 },
  {
    hideField: true,
    name: 'deliveryAnswer',
    type: SELECT,
    options: [],
    initialValue: 8,
  },
];

export const RULE_DATELIKE = [
  { name: 'distance', type: NUMBER, initialValue: 0 },
  {
    name: 'timeunit',
    type: SELECT,
    options: [
      { value: 'second', label: 'second' },
      { value: 'minute', label: 'minute' },
      { value: 'hour', label: 'hour' },
      { value: 'day', label: 'day' },
      { value: 'month', label: 'month' },
      { value: 'year', label: 'year' },
    ],
    initialValue: 'second',
  },
  {
    hideField: true,
    name: 'deliveryAnswer',
    type: SELECT,
    options: [],
    initialValue: 1,
  },
];
