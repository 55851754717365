import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../Dropdown/Dropdown';
import { change, formValueSelector } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

function FieldDropdown({ className, icon, name, options, onFirstOpen }) {
  const dispatch = useDispatch();
  const valueField = useSelector((state) => formValueSelector('contribute')(state, name));
  const [opened, setOpened] = useState(false);

  const handleChange = useCallback(
    (value) => {
      if (valueField !== value) dispatch(change('contribute', name, value));
    },
    [valueField],
  );

  const handleOpen = () => {
    if (!opened) {
      setOpened(true);
      onFirstOpen && onFirstOpen();
    }
  };

  return (
    <Dropdown
      className={className}
      icon={icon}
      options={options}
      onSelect={handleChange}
      onOpen={handleOpen}
    />
  );
}

FieldDropdown.defaultProps = {
  icon: 'arrow_dropdown_down',
  className: '',
  name: '',
};

FieldDropdown.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  onFirstOpen: PropTypes.func,
};

export default FieldDropdown;
