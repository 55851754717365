import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FIELD, FIELD_STUB, BLOCK, BLOCK_STUB } from '../../../constants/draggableType';
import Grid from '../../organisms/Grid/Grid';
import SVG from '../../atoms/SVG/SVG';
import { getTypologyByType } from '../../../helpers/typology';
import cssvars from '../../../styles/variables';

function BoxPreview({ item, canDrop }) {
  const style = useMemo(
    () => ({
      borderRadius: 3,
      background: canDrop
        ? cssvars['color-box-placeholder']
        : cssvars['color-box-placeholder-blocked'],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 0,
    }),
    [canDrop],
  );

  if (!item) {
    return null;
  }

  if (![FIELD, FIELD_STUB, BLOCK, BLOCK_STUB].includes(item.type)) {
    return null;
  }

  const typology = getTypologyByType(item.fieldType);

  switch (item.type) {
    case FIELD:
    case FIELD_STUB:
      style.zIndex = 2000;
      break;
    case BLOCK:
    case BLOCK_STUB:
      style.zIndex = 1200;
      break;
    default:
      return null;
  }

  return (
    <Grid.Box x={item.x} y={item.y} width={item.width} height={item.height} style={style}>
      {canDrop ? (
        typology && (
          <div
            style={{
              borderRadius: 3,
              background: cssvars['color-white'],
              width: 24,
              height: 24,
              padding: '0.5rem',
            }}
          >
            <SVG
              glyph={typology.meta.icon}
              style={{ width: 24, height: 24, fill: typology.meta.color }}
            />
          </div>
        )
      ) : (
        <SVG
          glyph="block"
          style={{ width: 24, height: 24, fill: cssvars['color-box-placeholder-blocked'] }}
        />
      )}
    </Grid.Box>
  );
}

BoxPreview.propTypes = {
  item: PropTypes.object,
  canDrop: PropTypes.bool,
};
export default BoxPreview;
